import React from "react";

export function ContactImage() {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 608 920"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_269_18"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="608"
        height="920"
      >
        <rect width="608" height="920" fill="#1B1A1A" />
      </mask>
      <g mask="url(#mask0_269_18)">
        <g opacity="0.7">
          <path
            d="M651.432 0.00537109L-141.994 0.00537109L-141.994 990.002L651.432 990.002L651.432 0.00537109Z"
            fill="black"
          />
          <path
            d="M333.575 478.049L237.658 612.834L240.212 614.774L336.475 479.515C336.683 479.232 336.792 478.891 336.792 478.544C336.792 478.51 336.792 478.48 336.792 478.445L331.114 382.741L327.932 382.939L333.57 478.049H333.575Z"
            fill="#014A4F"
          />
          <path
            d="M247.677 440.142L332.223 426.634L331.822 423.406L247.276 436.92L247.677 440.142Z"
            fill="#014A4F"
          />
          <path
            d="M456.444 385.538C464.775 395.498 451.692 435.598 447.391 448.775C446.549 451.349 445.945 453.205 445.648 454.279C444.193 459.576 442.906 459.215 436.704 457.487C436.209 457.349 435.694 457.205 435.159 457.061C428.12 455.146 333.604 414.036 332.649 413.62C331.837 413.268 330.902 413.65 330.56 414.476C330.214 415.308 330.59 416.258 331.402 416.61C335.307 418.308 427.081 458.23 434.342 460.205C434.872 460.348 435.382 460.492 435.867 460.625C441.99 462.333 446.411 463.566 448.717 455.161C448.99 454.161 449.618 452.24 450.415 449.805C456.34 431.653 462.34 410.952 462.34 396.849C462.34 391.048 461.325 386.36 458.87 383.43C458.301 382.747 457.291 382.667 456.623 383.246C455.954 383.831 455.875 384.855 456.444 385.538Z"
            fill="#014A4F"
          />
          <path
            d="M437.164 371.475L408.637 465.531C408.379 466.377 408.84 467.288 409.671 467.561L429.036 473.956C429.872 474.228 430.768 473.758 431.041 472.907C431.308 472.05 430.848 471.134 430.011 470.862L412.151 464.957L440.208 372.441C440.258 372.282 440.277 372.119 440.277 371.961C440.277 371.267 439.837 370.624 439.154 370.406C438.312 370.139 437.421 370.619 437.159 371.48L437.164 371.475Z"
            fill="#014A4F"
          />
          <path
            d="M343.064 606.8C342.475 607.468 342.529 608.493 343.183 609.097C343.836 609.696 344.846 609.641 345.435 608.978L439.876 460.799C440.149 460.487 440.287 460.101 440.287 459.715C440.287 459.269 440.109 458.829 439.763 458.507C439.109 457.908 438.099 457.962 437.51 458.626L343.069 606.805L343.064 606.8Z"
            fill="#014A4F"
          />
          <path
            d="M406.607 447.097C405.746 448.909 385.52 491.543 382.154 500.854C380.907 504.305 379.432 506.542 377.62 506.765C373.106 507.309 335.916 486.94 332.862 484.277C332.194 483.693 331.189 483.772 330.614 484.455C330.045 485.138 330.119 486.163 330.788 486.747C334.733 490.192 372.234 511.269 378.422 509.908C381.323 509.269 383.491 506.552 385.144 501.978C388.451 492.816 409.266 448.958 409.473 448.518C409.582 448.29 409.632 448.047 409.632 447.81C409.632 447.206 409.3 446.627 408.731 446.345C407.939 445.954 406.989 446.29 406.602 447.097H406.607Z"
            fill="#014A4F"
          />
          <path
            d="M413.493 352.344C413.216 353.096 385.862 427.638 384.095 432.222C382.06 437.499 377.768 442.825 372.615 446.468C371.348 447.369 370.012 448.241 368.72 449.087C363.379 452.582 358.335 455.883 357.033 461.19C356.82 462.061 357.34 462.942 358.191 463.16C359.042 463.378 359.909 462.848 360.122 461.977C361.117 457.918 365.646 454.958 370.437 451.819C371.754 450.958 373.11 450.072 374.427 449.136C380.075 445.137 384.797 439.261 387.055 433.41C388.832 428.802 415.344 356.551 416.473 353.477C416.542 353.289 416.577 353.096 416.577 352.908C416.577 352.249 416.181 351.631 415.542 351.388C414.715 351.071 413.8 351.502 413.493 352.339V352.344Z"
            fill="#014A4F"
          />
          <path
            d="M348.553 292.676C347.821 293.156 347.608 294.151 348.068 294.903C357.023 309.452 380.59 348.314 382.124 353.388C384.362 360.813 397.009 370.728 407.657 373.406C408.513 373.619 409.374 373.089 409.587 372.218C409.617 372.089 409.632 371.956 409.632 371.827C409.632 371.099 409.147 370.431 408.419 370.253C398.182 367.674 386.891 358.14 385.169 352.433C383.357 346.423 356.8 303.002 351.603 294.532L374.051 279.712C374.788 279.227 375.001 278.217 374.521 277.464C374.041 276.712 373.056 276.494 372.318 276.984L348.548 292.671L348.553 292.676Z"
            fill="#014A4F"
          />
          <path
            d="M396.811 397.77C396.559 398.626 397.039 399.537 397.886 399.79L427.536 408.799C428.378 409.056 429.269 408.566 429.516 407.705C429.561 407.551 429.581 407.393 429.581 407.239C429.581 406.541 429.135 405.893 428.442 405.68L398.791 396.671C397.95 396.414 397.059 396.904 396.811 397.765V397.77Z"
            fill="#014A4F"
          />
          <path
            d="M453.677 382.286L448.678 396.339C447.015 395.958 444.074 395.29 441.149 394.651C438.555 394.082 436.476 393.647 434.981 393.359C432.011 392.785 430.783 392.543 430.318 394.077C430.061 394.934 430.526 395.844 431.367 396.112C431.61 396.191 431.862 396.206 432.1 396.161C433.714 396.31 443.025 398.364 449.346 399.829C450.128 400.012 450.92 399.567 451.197 398.8L456.677 383.405C456.741 383.222 456.771 383.034 456.771 382.85C456.771 382.187 456.37 381.564 455.722 381.321C454.895 381.014 453.979 381.45 453.682 382.296L453.677 382.286ZM433.357 395.037C433.303 395.206 433.229 395.364 433.13 395.503C433.229 395.364 433.303 395.211 433.357 395.037Z"
            fill="#014A4F"
          />
          <path
            d="M422.106 421.139C421.809 421.981 422.24 422.916 423.066 423.218L453.494 434.351C454.321 434.653 455.237 434.212 455.534 433.371C455.598 433.187 455.628 433.004 455.628 432.821C455.628 432.153 455.222 431.529 454.573 431.292L424.145 420.159C423.319 419.857 422.403 420.298 422.106 421.139Z"
            fill="#014A4F"
          />
          <path
            d="M437.174 445.043C439.322 451.72 447.851 452.552 448.217 452.587C449.094 452.666 449.866 452.003 449.945 451.107C449.945 451.057 449.95 451.013 449.95 450.963C449.95 450.131 449.326 449.419 448.495 449.344C448.43 449.339 441.698 448.656 440.203 444.028C439.931 443.177 439.03 442.711 438.194 442.989C437.357 443.266 436.902 444.186 437.179 445.038L437.174 445.043Z"
            fill="#014A4F"
          />
          <path
            d="M437.238 395.602L433.649 424.817C433.54 425.713 434.159 426.52 435.03 426.633C435.902 426.747 436.699 426.114 436.807 425.223L440.396 396.008C440.406 395.938 440.411 395.869 440.411 395.805C440.411 394.998 439.822 394.3 439.015 394.196C438.144 394.082 437.347 394.716 437.238 395.607V395.602Z"
            fill="#014A4F"
          />
          <path
            d="M359.008 609.75C330.887 601.706 317.229 611.22 311.591 617.601C305.003 625.066 278.565 675.343 277.793 676.986C277.411 677.798 277.649 678.917 278.446 679.303C279.238 679.689 280.193 679.348 280.57 678.541C280.758 678.145 307.671 626.897 313.958 619.779C320.239 612.661 333.005 605.691 358.146 612.879C396.039 623.714 426.556 615.151 448.876 587.416L478.472 598.236C479.299 598.538 480.214 598.098 480.511 597.256C480.576 597.073 480.605 596.89 480.605 596.707C480.605 596.039 480.199 595.415 479.551 595.177L448.891 583.965C448.252 583.733 447.534 583.936 447.108 584.48C425.606 611.814 395.96 620.314 359.008 609.75Z"
            fill="#014A4F"
          />
          <path
            d="M396.999 467.575C396.519 468.328 396.727 469.333 397.465 469.828L420.962 485.618C421.7 486.108 422.685 485.896 423.165 485.143C423.339 484.871 423.423 484.559 423.423 484.257C423.423 483.727 423.17 483.208 422.7 482.896L399.202 467.105C398.465 466.615 397.479 466.828 396.999 467.58V467.575Z"
            fill="#014A4F"
          />
          <path
            d="M506.549 624.882C506.465 625.011 497.99 637.748 495.129 642.128C494.673 642.826 494.253 643.509 493.852 644.158C492.748 645.95 491.604 647.806 490.768 647.984C490.496 648.044 490.109 647.905 489.624 647.578C486.12 645.197 477.606 642.589 472.879 642.401L457.543 631.184C456.831 630.659 455.831 630.827 455.321 631.555C454.806 632.288 454.969 633.298 455.687 633.822L471.423 645.331C471.695 645.529 472.017 645.638 472.354 645.638C476.329 645.638 484.734 648.158 487.862 650.281C489.09 651.113 490.293 651.41 491.436 651.162C493.584 650.692 494.896 648.573 496.55 645.89C496.936 645.267 497.342 644.608 497.782 643.935C500.638 639.564 509.103 626.838 509.187 626.709C509.37 626.432 509.459 626.11 509.459 625.798C509.459 625.278 509.217 624.764 508.761 624.452C508.034 623.947 507.044 624.14 506.549 624.882Z"
            fill="#014A4F"
          />
          <path
            d="M463.463 618.547C462.944 619.274 463.102 620.289 463.815 620.819L495.52 644.346C496.233 644.876 497.228 644.712 497.747 643.99C497.955 643.703 498.054 643.366 498.054 643.034C498.054 642.534 497.827 642.035 497.396 641.718L465.691 618.19C464.978 617.661 463.983 617.824 463.463 618.547Z"
            fill="#014A4F"
          />
          <path
            d="M471.7 605.671C471.304 606.478 471.626 607.453 472.413 607.854C477.725 610.562 507.796 626.798 510.835 629.506C515.073 633.288 529.586 630.476 532.452 629.877C533.204 629.719 533.724 629.041 533.724 628.288C533.724 628.179 533.714 628.07 533.69 627.956C533.511 627.08 532.67 626.511 531.809 626.694C525.324 628.055 515.177 629.065 512.929 627.065C508.964 623.526 474.631 605.36 473.834 604.954C473.047 604.553 472.091 604.879 471.695 605.681L471.7 605.671Z"
            fill="#014A4F"
          />
          <path
            d="M498.737 620.056L487.441 636.367C486.932 637.099 487.105 638.114 487.823 638.634C488.54 639.154 489.535 638.975 490.045 638.243L501.341 621.932C501.539 621.645 501.633 621.319 501.633 620.997C501.633 620.487 501.401 619.982 500.96 619.665C500.242 619.146 499.247 619.324 498.737 620.056Z"
            fill="#014A4F"
          />
          <path
            d="M489.411 580.416C489.05 581.233 489.412 582.178 490.228 582.525C492.783 583.614 517.052 596.444 517.404 596.454C517.592 597.608 516.31 601.404 515.27 604.483C514.939 605.468 514.587 606.513 514.231 607.607C513.474 609.923 512.231 610.443 511.365 610.631C506.92 611.591 484.417 597.702 481.387 594.306C480.793 593.643 479.789 593.593 479.135 594.197C478.487 594.801 478.437 595.831 479.031 596.494C481.744 599.533 505.42 615.235 512.023 613.814C513.761 613.438 516.077 612.235 517.255 608.641C517.607 607.557 517.958 606.528 518.285 605.547C519.602 601.642 520.582 598.726 520.582 596.662C520.582 595.519 520.28 594.633 519.567 593.984C517.864 592.43 494.441 580.802 491.54 579.57C490.728 579.223 489.773 579.609 489.411 580.426V580.416Z"
            fill="#014A4F"
          />
          <path
            d="M518.186 594.4C517.355 594.692 516.909 595.613 517.191 596.464C517.473 597.316 518.379 597.766 519.216 597.479L532.937 592.697C533.6 592.465 534.016 591.836 534.016 591.158C534.016 590.985 533.987 590.806 533.932 590.633C533.65 589.782 532.744 589.331 531.908 589.618L518.186 594.4Z"
            fill="#014A4F"
          />
          <path
            d="M515.058 547.276C515.181 547.414 527.383 561.383 530.903 572.709C534.442 584.094 529.933 667.522 529.898 668.175C529.843 669.091 530.522 669.839 531.398 669.893C532.274 669.942 533.031 669.259 533.081 668.363C533.18 666.601 535.65 620.135 535.65 597.811C535.65 586.069 535.224 575.847 533.947 571.729C530.22 559.73 517.953 545.697 517.434 545.108C516.845 544.439 515.84 544.385 515.186 544.984C514.533 545.583 514.474 546.612 515.063 547.281L515.058 547.276Z"
            fill="#014A4F"
          />
          <path
            d="M563.098 561.789C555.074 563.715 531.096 566.962 530.853 566.997C529.982 567.115 529.373 567.927 529.482 568.823C529.596 569.714 530.398 570.338 531.269 570.219C532.259 570.085 555.643 566.917 563.825 564.957C576.824 561.839 612.771 553.641 622.825 553.196C627.656 552.983 639.497 551.958 651.426 550.854V547.587C639.412 548.696 627.448 549.736 622.686 549.949C610.272 550.498 563.563 561.68 563.093 561.789H563.098Z"
            fill="#014A4F"
          />
          <path
            d="M531.952 576.238C531.477 576.996 531.695 578.001 532.437 578.486C537.694 581.916 543.565 589.49 544.035 598.004C544.51 606.612 545.877 663.255 545.891 663.775C545.916 664.671 546.649 665.379 547.53 665.354C548.396 665.329 549.079 664.606 549.079 663.73C549.079 663.715 549.148 658.206 549.148 658.191C549.134 657.676 547.698 606.478 547.218 597.82C546.683 588.113 540.268 579.738 534.155 575.748C533.412 575.263 532.427 575.486 531.952 576.243V576.238Z"
            fill="#014A4F"
          />
          <path
            d="M508.934 612.889C510.657 617.888 521.854 619.848 546.381 619.433C547.253 619.418 547.951 618.695 547.951 617.809C547.951 617.799 547.951 617.789 547.951 617.779C547.936 616.883 547.213 616.166 546.332 616.181C517.884 616.666 512.578 613.651 511.944 611.815C511.652 610.968 510.741 610.523 509.915 610.82C509.083 611.117 508.647 612.047 508.939 612.889H508.934Z"
            fill="#014A4F"
          />
          <path
            d="M542.219 586.193C541.377 586.465 540.917 587.376 541.184 588.233C541.451 589.089 542.347 589.559 543.184 589.287L582.061 576.674C582.74 576.456 583.17 575.813 583.17 575.125C583.17 574.962 583.145 574.793 583.096 574.63C582.829 573.774 581.933 573.303 581.096 573.576L542.219 586.188V586.193Z"
            fill="#014A4F"
          />
          <path
            d="M551.396 584.683L580.601 690.896C580.844 691.757 581.725 692.257 582.571 692.015C583.269 691.812 583.73 691.163 583.73 690.451C583.73 690.302 583.71 690.154 583.67 690.005L554.465 583.792C554.222 582.931 553.341 582.431 552.495 582.674C551.648 582.921 551.158 583.817 551.401 584.683H551.396Z"
            fill="#014A4F"
          />
          <path
            d="M572.206 519.125L598.426 687.342C598.55 688.233 599.357 688.847 600.228 688.723C601.025 688.609 601.594 686.966 601.584 686.887L575.364 518.67C575.24 517.779 574.433 517.165 573.562 517.289C572.691 517.412 572.092 518.239 572.206 519.125Z"
            fill="#014A4F"
          />
          <path
            d="M585.299 601.984L559.177 605.795C558.306 605.924 557.702 606.75 557.826 607.632C557.95 608.523 558.757 609.136 559.628 609.008L585.675 605.206L651.426 601.939V598.687L585.447 601.964C585.398 601.964 585.348 601.974 585.299 601.979V601.984Z"
            fill="#014A4F"
          />
          <path
            d="M570.072 647.083C570.137 647.979 570.899 648.653 571.78 648.583L651.431 642.584V639.327L571.543 645.341C570.666 645.405 570.013 646.177 570.072 647.083Z"
            fill="#014A4F"
          />
          <path
            d="M606.643 553.731L608.687 602.429C608.727 603.325 609.47 604.023 610.346 603.983C611.202 603.944 611.87 603.226 611.87 602.36C611.87 602.335 611.87 602.31 611.87 602.29L609.826 553.592C609.786 552.696 609.044 551.998 608.168 552.038C607.287 552.077 606.604 552.85 606.643 553.731Z"
            fill="#014A4F"
          />
          <path
            d="M564.281 454.72C564.281 455.616 564.994 456.344 565.875 456.344H630.26L651.431 465.759V462.215L631.23 453.23C631.027 453.141 630.814 453.097 630.591 453.097H565.875C564.994 453.097 564.281 453.824 564.281 454.72Z"
            fill="#014A4F"
          />
          <path
            d="M42.2601 547.682C43.067 548.038 44.0075 547.662 44.3589 546.84C44.448 546.627 44.4926 546.41 44.4926 546.192C44.4926 545.563 44.1362 544.969 43.5372 544.702L-141.99 462.209V465.754L42.2651 547.677L42.2601 547.682Z"
            fill="#014A4F"
          />
          <path
            d="M455.944 335.36C455.439 336.098 455.618 337.112 456.335 337.622L586.779 424.322C587.071 424.53 587.422 424.634 587.779 424.614C588.234 424.589 633.255 422.114 644.857 426.545C645.847 426.921 648.115 427.876 651.426 429.302V425.777C648.733 424.624 646.862 423.842 645.971 423.5C634.329 419.055 593.63 421.045 588.15 421.337L458.157 334.959C457.434 334.444 456.444 334.623 455.939 335.36H455.944Z"
            fill="#014A4F"
          />
          <path
            d="M60.7385 519.258C61.5454 519.62 62.4859 519.244 62.8374 518.422C62.9265 518.209 62.971 517.986 62.971 517.768C62.971 517.145 62.6146 516.546 62.0157 516.278C54.556 512.947 -108.374 440.172 -142 425.767V429.292C-105.489 445.008 58.9268 518.442 60.7336 519.253L60.7385 519.258Z"
            fill="#014A4F"
          />
          <path
            d="M581.452 284.266C581.027 285.048 581.299 286.038 582.071 286.478L651.431 325.811V322.094L583.621 283.642C582.853 283.206 581.883 283.488 581.452 284.27V284.266Z"
            fill="#014A4F"
          />
          <path
            d="M55.343 437.712L3.3429 564.72C3.00136 565.551 3.38745 566.502 4.19925 566.843C5.01106 567.19 5.94659 566.799 6.28319 565.972L58.8328 437.623C58.9169 437.42 58.9565 437.207 58.9565 436.999C58.9565 436.425 58.6546 435.87 58.1348 435.578L-141.995 322.089V325.806L55.3381 437.712H55.343Z"
            fill="#014A4F"
          />
          <path
            d="M524.096 308.996C523.785 309.729 524.042 310.58 524.7 311.011L620.37 373.322C620.488 373.401 620.617 373.46 620.751 373.505L644.016 380.955L651.431 384.098V380.579L645.174 377.925C645.13 377.906 645.085 377.891 645.041 377.876L621.909 370.471L527.655 309.08C529.408 305.982 535.244 297.022 550.53 283.33C571.859 264.218 610.618 271.94 614.969 272.881C618.622 273.673 633.304 281.528 651.431 291.656V287.949C633.552 277.979 619.523 270.544 615.632 269.698C611.123 268.718 570.929 260.728 548.426 280.885C528.626 298.626 524.27 308.585 524.096 309.001V308.996Z"
            fill="#014A4F"
          />
          <path
            d="M-103.741 313.322C-77.1941 328.455 -52.1173 342.75 -44.9497 345.681C-29.4214 352.027 15.8764 401.116 18.8612 406.338C19.3067 407.111 20.2819 407.373 21.0393 406.923C21.8016 406.467 22.054 405.472 21.6135 404.7C18.5296 399.309 -27.2236 349.428 -43.7666 342.666C-50.7412 339.815 -75.7339 325.569 -102.187 310.486C-115.923 302.655 -129.848 294.72 -141.995 287.948V291.656C-130.021 298.349 -116.542 306.031 -103.741 313.327V313.322Z"
            fill="#014A4F"
          />
          <path
            d="M89.4092 482.168C90.221 482.514 91.1565 482.118 91.4931 481.292C91.5773 481.089 91.6169 480.876 91.6169 480.668C91.6169 480.03 91.2457 479.426 90.6318 479.168L-141.995 380.578V384.098L89.4092 482.168Z"
            fill="#014A4F"
          />
          <path
            d="M624.641 275.143L517.652 443.489C517.201 444.201 517.359 445.142 518.018 445.662L548.49 484.386C549.188 484.935 550.188 484.802 550.723 484.089C551.257 483.376 551.129 482.356 550.431 481.812L521.121 443.998L627.31 276.915C627.483 276.643 627.567 276.336 627.567 276.034C627.567 275.499 627.31 274.98 626.839 274.668C626.102 274.178 625.112 274.395 624.637 275.148L624.641 275.143Z"
            fill="#014A4F"
          />
          <path
            d="M539.986 291.725C539.565 292.517 539.857 293.502 540.629 293.928L594.04 322.732C594.813 323.158 595.783 322.866 596.199 322.074C596.327 321.827 596.392 321.564 596.392 321.302C596.392 320.723 596.09 320.163 595.555 319.871L542.144 291.067C541.372 290.641 540.402 290.933 539.986 291.725Z"
            fill="#014A4F"
          />
          <path
            d="M454.311 283.32C453.841 284.077 454.058 285.082 454.801 285.562L566.988 368.881C567.731 369.362 568.716 369.139 569.186 368.381C569.355 368.114 569.434 367.807 569.434 367.51C569.434 366.971 569.172 366.446 568.696 366.139L456.509 282.82C455.766 282.34 454.781 282.563 454.311 283.32Z"
            fill="#014A4F"
          />
          <path
            d="M588.892 422.698L581.329 464.937L553.901 511.398C553.47 512.121 553.663 513.061 554.336 513.556L566.909 522.748C567.627 523.273 568.622 523.105 569.132 522.372C569.642 521.644 569.478 520.625 568.766 520.105L557.39 511.789L584.21 466.362C584.309 466.194 584.378 466.011 584.413 465.818L592.026 423.287C592.046 423.188 592.051 423.089 592.051 422.995C592.051 422.223 591.511 421.54 590.744 421.396C589.877 421.233 589.046 421.822 588.892 422.703V422.698Z"
            fill="#014A4F"
          />
          <path
            d="M267.437 249.408C265.254 252.219 66.5846 507.958 56.0905 522.07C46.6112 534.822 39.5228 547.904 35.6023 559.908C32.266 570.12 16.4952 672.809 16.2428 673.616C15.9705 674.472 16.3813 674.729 17.213 675.007C18.0495 675.284 18.9504 674.818 19.2226 673.962C19.48 673.155 35.2954 571.14 38.6268 560.938C42.4383 549.266 49.3584 536.505 58.6299 524.035C69.1041 509.953 267.754 254.239 269.937 251.427C270.17 251.13 270.283 250.774 270.283 250.417C270.283 249.937 270.076 249.462 269.68 249.145C268.992 248.586 267.987 248.705 267.442 249.408H267.437Z"
            fill="#014A4F"
          />
          <path
            d="M92.2555 492.518L92.1317 492.741C91.2754 494.276 90.1863 495.756 88.5973 497.543C87.0579 499.275 85.1273 501.211 82.7612 503.562C77.9646 508.319 67.6141 521.58 65.0698 527.297C62.669 532.683 43.7352 581.99 42.9333 584.089C42.6165 584.916 43.0026 585.841 43.8045 586.183L226.273 675.957C227.084 676.303 228.02 675.912 228.357 675.081C228.441 674.878 228.109 673.214 227.5 672.957L46.4725 583.792C49.7247 575.332 65.837 533.435 67.9755 528.644C70.198 523.664 80.0139 510.829 84.9937 505.893C87.3994 503.502 89.3695 501.532 90.9684 499.731C92.7158 497.76 93.9335 496.102 94.9086 494.36L95.0324 494.132C96.8144 490.944 97.7103 489.34 103.457 492.073C108.432 494.444 120.56 500.532 125.648 505.864C130.524 510.972 145.701 521.451 146.344 521.892C147.072 522.397 148.062 522.199 148.557 521.456C149.047 520.714 148.859 519.704 148.126 519.199C147.973 519.09 132.578 508.467 127.925 503.597C122.663 498.087 111.179 492.167 104.799 489.128C97.0124 485.42 94.6017 488.331 92.2603 492.523L92.2555 492.518Z"
            fill="#014A4F"
          />
          <path
            d="M123.124 536.955C122.391 539.088 121.763 540.925 121.268 542.049C120.312 544.197 118.337 547.028 116.05 550.3C112.387 555.547 107.823 562.081 105.195 569.19C101.052 580.382 76.6479 649.974 76.4054 650.677C76.1084 651.524 76.5391 652.454 77.3707 652.756C78.1973 653.058 79.113 652.617 79.4051 651.776C79.6526 651.073 104.041 581.51 108.175 570.343C110.66 563.631 115.085 557.29 118.644 552.196C121.025 548.781 123.084 545.835 124.168 543.395C124.723 542.138 125.376 540.237 126.133 538.034C128.277 531.767 131.871 521.283 136.014 519.353C136.771 519.001 137.479 518.981 138.246 519.293C146.419 522.595 270.932 559.493 276.382 561.101C277.228 561.349 277.822 560.903 278.065 560.042C278.109 559.893 278.416 559.686 278.416 559.537C278.416 558.829 277.961 558.181 277.263 557.978C275.926 557.582 147.448 519.516 139.414 516.269C137.83 515.63 136.236 515.67 134.682 516.397C129.301 518.907 125.728 529.342 123.119 536.965L123.124 536.955Z"
            fill="#014A4F"
          />
          <path
            d="M88.3052 498.018C87.9736 498.849 88.3597 499.8 89.1764 500.136L134.999 519.273C135.816 519.615 136.746 519.214 137.078 518.387C137.157 518.184 137.197 517.976 137.197 517.773C137.197 517.135 136.825 516.526 136.207 516.269L90.3843 497.132C89.5676 496.79 88.6369 497.191 88.3052 498.018Z"
            fill="#014A4F"
          />
          <path
            d="M82.4841 503.928C82.0534 504.715 82.3258 505.7 83.093 506.141L126.267 530.926C127.034 531.366 128.005 531.084 128.435 530.302C128.574 530.049 128.638 529.777 128.638 529.51C128.638 528.941 128.346 528.386 127.826 528.089L84.6522 503.304C83.885 502.864 82.9147 503.146 82.4841 503.928Z"
            fill="#014A4F"
          />
          <path
            d="M68.5942 521.357C68.2477 522.179 68.619 523.139 69.4308 523.491C86.8252 531.109 115.422 544.791 118.109 549.082C118.585 549.84 119.57 550.063 120.312 549.577C120.787 549.266 121.05 548.741 121.05 548.206C121.05 547.909 120.971 547.602 120.797 547.33C116.694 540.786 75.3857 522.56 70.6931 520.501C69.8862 520.149 68.9456 520.531 68.5991 521.352L68.5942 521.357Z"
            fill="#014A4F"
          />
          <path
            d="M56.0707 550.181C55.5559 550.909 55.7143 551.924 56.4271 552.453L62.7433 557.126L53.4224 588.688C53.17 589.549 53.6452 590.455 54.4867 590.712C55.3282 590.97 56.2192 590.485 56.4716 589.623L66.1192 556.958C66.1638 556.805 66.1885 556.646 66.1885 556.488C66.1885 555.978 65.951 555.483 65.5302 555.171L58.2982 549.82C57.5854 549.295 56.5905 549.454 56.0707 550.181Z"
            fill="#014A4F"
          />
          <path
            d="M62.3622 555.894C62.0058 556.71 62.3622 557.676 63.169 558.042L105.111 573.783C105.913 574.149 106.858 573.783 107.214 572.961C107.309 572.749 107.353 572.521 107.353 572.298C107.353 571.674 107.002 571.085 106.408 570.813L64.4659 555.072C63.664 554.706 62.7186 555.072 62.3622 555.894Z"
            fill="#014A4F"
          />
          <path
            d="M111.229 557.493C110.877 558.315 111.254 559.275 112.06 559.626L298.845 641.827C299.652 642.183 300.592 641.802 300.939 640.975C301.028 640.767 300.885 640.644 300.885 640.426C300.885 639.797 300.711 639.104 300.107 638.837L113.323 556.637C112.516 556.28 111.575 556.661 111.229 557.488V557.493Z"
            fill="#014A4F"
          />
          <path
            d="M105.873 567.492C105.551 568.323 105.962 569.269 106.784 569.596L125.579 577.04L116.115 597.593C115.922 598.014 115.912 598.504 116.095 598.929C116.278 599.36 116.629 599.687 117.065 599.83L152.324 611.463C153.161 611.74 154.062 611.27 154.329 610.418C154.383 610.255 154.408 610.082 154.408 609.918C154.408 609.23 153.977 608.597 153.304 608.374L119.763 597.311L129.188 576.852C129.376 576.441 129.391 575.976 129.222 575.555C129.059 575.14 128.732 574.808 128.321 574.645L107.937 566.566C107.116 566.239 106.195 566.655 105.878 567.492H105.873Z"
            fill="#014A4F"
          />
          <path
            d="M177.673 529.787L126.327 685.02C126.044 685.872 126.5 686.793 127.336 687.075C128.173 687.357 129.074 686.897 129.351 686.045L180.698 530.812C180.752 530.644 180.782 530.471 180.782 530.297C180.782 529.614 180.356 528.981 179.688 528.758C178.851 528.476 177.95 528.936 177.673 529.787Z"
            fill="#014A4F"
          />
          <path
            d="M101.2 582.768C100.898 583.609 101.324 584.545 102.151 584.852C102.977 585.159 199.127 620.868 208.141 625.679C209.274 626.283 210.665 626.996 212.234 627.798C223.144 633.377 276.001 678.506 276.713 679.214C277.565 680.066 279.248 677.254 279.114 677.115C267.576 665.275 224.961 630.669 213.665 624.892C212.111 624.1 210.739 623.398 209.621 622.799C200.428 617.888 106.635 583.06 103.249 581.797C102.423 581.491 101.507 581.921 101.205 582.763L101.2 582.768Z"
            fill="#014A4F"
          />
          <path
            d="M124.837 578.649C124.639 579.08 124.639 579.585 124.837 580.015C125.101 580.596 131.077 582.565 142.766 585.921C151.181 588.336 159.581 590.717 159.581 590.717C160.427 590.96 161.308 590.45 161.541 589.589C161.581 589.445 161.6 589.297 161.6 589.153C161.6 588.44 161.14 587.787 160.432 587.589C147.899 584.04 128.891 578.595 127.039 577.907C127.01 577.892 126.98 577.872 126.945 577.862C126.148 577.486 125.198 577.837 124.832 578.654L124.837 578.649Z"
            fill="#014A4F"
          />
          <path
            d="M98.6954 466.882C98.2845 467.674 98.5816 468.659 99.3587 469.08L156.888 500.072C156.942 500.102 157.002 500.132 157.061 500.152C157.64 500.379 171.406 505.72 183.098 506.349C194.553 506.963 303.424 522.392 304.28 522.422C305.161 522.452 305.894 521.749 305.924 520.853C305.924 520.833 305.924 520.818 305.924 520.798C305.924 519.927 305.246 519.204 304.384 519.175C303.528 519.145 194.691 503.721 183.267 503.107C172.54 502.528 159.472 497.607 158.289 497.157L100.844 466.214C100.067 465.793 99.1014 466.1 98.6906 466.892L98.6954 466.882Z"
            fill="#014A4F"
          />
          <path
            d="M250.513 345.344C250.315 347.77 246.197 400.304 247.642 415.516C249.043 430.306 234.688 510.883 234.633 511.101C234.425 511.972 234.95 512.853 235.802 513.066C236.658 513.279 237.519 512.744 237.727 511.873C237.945 510.957 252.265 430.539 250.81 415.204C249.399 400.285 253.691 346.116 253.736 345.527C253.736 345.483 253.686 345.527 253.686 345.478C253.686 344.641 253.934 344.161 253.102 344.087C252.226 344.012 250.577 344.448 250.503 345.344H250.513Z"
            fill="#014A4F"
          />
          <path
            d="M176.554 368.421C176.485 369.322 177.124 370.104 178 370.188L250.102 376.421C250.978 376.5 251.755 375.841 251.835 374.945C251.835 374.896 251.84 374.846 251.84 374.797C251.84 373.965 251.216 373.257 250.389 373.178L178.287 366.946C177.411 366.867 176.634 367.525 176.554 368.421Z"
            fill="#014A4F"
          />
          <path
            d="M591.051 178.558C590.293 179.008 590.031 180.003 590.471 180.78C590.912 181.557 591.887 181.82 592.649 181.369L598.911 177.662L651.431 185.928V182.641L598.842 174.365C598.481 174.306 598.114 174.38 597.797 174.568L591.051 178.568V178.558Z"
            fill="#014A4F"
          />
          <path
            d="M-60.2502 247.363L-80.481 286.261C-80.872 287.008 -80.6295 287.939 -79.9266 288.394L32.9194 361.328C33.6619 361.808 34.6469 361.585 35.1171 360.823C35.2855 360.551 35.3647 360.254 35.3647 359.957C35.3647 359.417 35.1023 358.892 34.6221 358.581L-76.9764 286.454L-57.4386 248.883C-57.0277 248.086 -57.3247 247.106 -58.0969 246.685C-58.8741 246.264 -59.8393 246.566 -60.2502 247.358V247.363Z"
            fill="#014A4F"
          />
          <path
            d="M-73.6302 273.088C-73.9767 273.91 -73.6054 274.87 -72.7985 275.227L83.2413 343.859C83.2859 343.879 83.3354 343.899 83.38 343.914L129.455 358.373C130.291 358.65 131.192 358.184 131.465 357.328C131.519 357.16 131.544 356.991 131.544 356.828C131.544 356.145 131.113 355.506 130.44 355.284L84.4393 340.849L-71.5313 272.247C-72.3382 271.89 -73.2787 272.272 -73.6252 273.098L-73.6302 273.088Z"
            fill="#014A4F"
          />
          <path
            d="M11.4263 205.986L-17.8085 327.232C-18.0164 328.103 -17.4967 328.984 -16.6403 329.197C-15.784 329.41 -14.9227 328.88 -14.7148 328.009L14.5201 206.763C14.5498 206.634 14.5647 206.501 14.5647 206.372C14.5647 205.639 14.0796 204.976 13.3519 204.793C12.4955 204.58 11.6342 205.11 11.4263 205.981V205.986Z"
            fill="#014A4F"
          />
          <path
            d="M231.837 243.963C220.501 253.358 208.705 256.466 195.776 253.472C187.321 251.511 15.6388 226.024 8.33255 224.94C7.46134 224.811 6.65453 225.425 6.52583 226.316C6.40208 227.202 7.00102 228.028 7.87223 228.157C9.65919 228.424 186.732 254.714 195.068 256.645C209.012 259.877 221.694 256.555 233.841 246.487C249.107 233.835 337.074 176.603 337.396 176.32C337.767 175.999 337.955 175.543 337.955 175.083C337.955 174.712 337.832 174.335 337.574 174.029C337.005 173.345 335.995 173.266 335.327 173.845C335.005 174.128 247.068 231.335 231.832 243.963H231.837Z"
            fill="#014A4F"
          />
          <path
            d="M203.503 217.099C202.919 217.257 202.478 217.737 202.354 218.341L181.505 321.356L162.358 384.241C162.145 385.118 162.66 385.994 163.511 386.217C164.363 386.434 165.234 385.91 165.447 385.038L184.608 322.084L205.275 219.98L256.384 205.981C257.097 205.788 257.567 205.129 257.567 204.412C257.567 204.273 257.547 204.13 257.512 203.991C257.285 203.125 256.409 202.61 255.562 202.843L203.508 217.104L203.503 217.099Z"
            fill="#014A4F"
          />
          <path
            d="M201.151 228.746C200.3 228.974 199.79 229.865 200.018 230.731C200.241 231.597 201.112 232.117 201.963 231.889L256.374 217.287C257.087 217.094 257.562 216.435 257.562 215.717C257.562 215.579 257.547 215.44 257.507 215.302C257.285 214.435 256.413 213.916 255.562 214.143L201.151 228.746Z"
            fill="#014A4F"
          />
          <path
            d="M191.197 273.48C191.058 274.366 191.652 275.197 192.523 275.336L245.137 280.083C246.008 280.222 246.296 279.647 246.434 278.761C246.449 278.677 246.979 278.558 246.979 278.474C246.979 277.687 246.419 276.999 245.632 276.87L193.018 272.123C192.147 271.985 191.335 272.589 191.197 273.475V273.48Z"
            fill="#014A4F"
          />
          <path
            d="M250.939 186.27C250.28 186.869 250.226 187.893 250.815 188.567C251.404 189.235 252.409 189.294 253.067 188.695L310.488 135.864C310.839 135.542 311.017 135.096 311.017 134.651C311.017 134.265 310.884 133.879 310.611 133.567C310.022 132.899 309.017 132.839 308.359 133.438L250.939 186.27Z"
            fill="#014A4F"
          />
          <path
            d="M32.4491 218.697L30.4888 229.365C30.3255 230.246 30.8948 231.097 31.761 231.261C32.6273 231.424 33.4589 230.845 33.6222 229.964L35.5824 219.296C35.6022 219.197 35.6072 219.093 35.6072 218.994C35.6072 218.227 35.0726 217.544 34.3053 217.4C33.4391 217.232 32.6075 217.816 32.4441 218.697H32.4491Z"
            fill="#014A4F"
          />
          <path
            d="M119.035 168.474C115.496 184.359 77.0737 356.892 76.3164 365.05C75.2422 376.574 79.92 384.865 89.4834 388.37L91.2209 389.008C101.576 392.83 108.076 394.948 110.957 392.147C112.239 390.899 112.491 388.954 111.709 386.36C109.645 379.549 116.001 360.679 118.619 353.987C118.946 353.155 118.55 352.21 117.733 351.873C116.917 351.541 115.991 351.947 115.664 352.779C115.263 353.804 105.853 378.029 108.665 387.32C109.036 388.543 109.071 389.488 108.759 389.795C107.096 391.414 98.1955 388.127 92.3099 385.954L90.5675 385.311C80.2367 381.524 78.8458 372.366 79.4943 365.357C80.2516 357.194 121.931 170.167 122.352 168.281C122.376 168.162 122.391 168.039 122.391 167.92C122.391 167.474 122.208 167.039 121.881 166.727L-54.9685 0.00488281L-59.6611 0.00488281L119.04 168.474H119.035Z"
            fill="#014A4F"
          />
          <path
            d="M134.009 245.438L131.173 267.307H119.451C118.659 267.307 117.991 267.901 117.877 268.698L115.827 282.994L96.9381 277.593C96.0917 277.351 95.2106 277.855 94.973 278.717C94.7354 279.583 95.2304 280.479 96.0768 280.722L116.709 286.622C117.149 286.746 117.624 286.676 118.005 286.419C118.387 286.167 118.649 285.756 118.713 285.29L120.827 270.559H132.568C133.365 270.559 134.044 269.955 134.148 269.148L137.167 245.868C137.177 245.794 137.182 245.725 137.182 245.651C137.182 244.849 136.598 244.151 135.796 244.042C134.925 243.923 134.123 244.552 134.009 245.438Z"
            fill="#014A4F"
          />
          <path
            d="M90.8051 311.021C90.8645 310.902 90.909 310.773 90.9387 310.639C90.909 310.778 90.8645 310.907 90.8051 311.021Z"
            fill="#014A4F"
          />
          <path
            d="M124.916 303.199L116.431 317.515C89.5725 308.308 89.4141 308.397 88.5973 308.872C88.2013 309.1 87.9192 309.491 87.8202 309.941C87.6271 310.818 88.1766 311.684 89.033 311.877C89.1221 311.897 89.2111 311.907 89.3002 311.911C91.1911 312.362 106.937 317.688 116.624 321.01C117.332 321.252 118.109 320.96 118.496 320.312L127.638 304.882C127.792 304.62 127.866 304.328 127.866 304.046C127.866 303.496 127.594 302.957 127.099 302.655C126.346 302.19 125.366 302.437 124.911 303.204L124.916 303.199ZM90.8001 311.02C90.7407 311.139 90.6665 311.253 90.5873 311.347C90.6714 311.253 90.7407 311.144 90.8001 311.02Z"
            fill="#014A4F"
          />
          <path
            d="M164.16 169.593L128.638 356.452C128.47 357.328 131.148 358.1 131.767 357.071L167.466 169.113C167.313 170.108 167.323 170.004 167.323 169.905C167.323 169.143 166.793 168.46 166.031 168.311C165.169 168.143 164.333 168.717 164.164 169.598L164.16 169.593Z"
            fill="#014A4F"
          />
          <path
            d="M80.7911 39.6843C80.9297 39.8674 94.7551 57.9944 100.962 65.7858C106.957 73.3148 124.203 85.5265 126.737 87.3085L159.224 141.972C159.68 142.739 160.66 142.987 161.412 142.521C161.907 142.214 162.18 141.68 162.18 141.13C162.18 140.843 162.105 140.551 161.947 140.289L129.282 85.3236C129.163 85.1256 129.004 84.9523 128.816 84.8236C128.623 84.69 109.481 71.3298 103.433 63.7365C97.245 55.9699 83.4444 37.8726 83.3058 37.6894C82.7662 36.9815 81.7613 36.8528 81.0683 37.4023C80.3753 37.9518 80.2515 38.9715 80.7861 39.6843H80.7911Z"
            fill="#014A4F"
          />
          <path
            d="M93.5276 24.27L175.451 92.6844L182.138 131.597C182.292 132.483 183.118 133.072 183.985 132.918C184.757 132.78 185.301 132.092 185.301 131.319C185.301 131.225 185.291 131.131 185.277 131.037L178.485 91.531C178.421 91.1449 178.223 90.7984 177.926 90.5559L95.5274 21.7454L68.6339 0.00488281L63.5007 0.00488281L93.5276 24.27Z"
            fill="#014A4F"
          />
          <path
            d="M187.128 71.3843C186.455 71.9635 186.365 72.9882 186.935 73.6762C187.499 74.3643 188.504 74.4534 189.177 73.8742C189.469 73.6267 218.328 48.9409 227.124 45.6343C235.569 42.4613 241.647 42.1643 247.761 50.0348C254.196 58.3261 287.599 98.0502 287.876 98.3719C288.45 99.0501 289.455 99.1293 290.123 98.5402C290.489 98.2185 290.677 97.7631 290.677 97.3077C290.677 96.9315 290.549 96.5553 290.291 96.2484C290.019 95.9217 256.656 56.2521 250.261 48.0152C243.009 38.6745 235.326 39.0854 226.025 42.5801C216.709 46.0798 188.331 70.3498 187.128 71.3794V71.3843Z"
            fill="#014A4F"
          />
          <path
            d="M225.535 78.2404C224.867 78.8246 224.793 79.8492 225.362 80.5323C225.936 81.2154 226.941 81.2946 227.609 80.7056L281.505 33.5416C281.871 33.2199 282.06 32.7645 282.06 32.3091C282.06 31.9329 281.936 31.5567 281.674 31.2498C281.099 30.5667 280.094 30.4875 279.426 31.0765L225.53 78.2404H225.535Z"
            fill="#014A4F"
          />
          <path
            d="M263.076 902.807L227.53 947.021C227.253 947.367 227.124 947.813 227.183 948.254C227.238 948.694 227.466 949.095 227.817 949.357L230.842 989.997H234.173C232.624 968.905 231.054 947.759 231.054 947.759L265.541 904.866C266.096 904.173 265.997 903.149 265.319 902.58C264.635 902.01 263.635 902.114 263.076 902.807Z"
            fill="#014A4F"
          />
          <path
            d="M233.97 42.0257C234.014 42.9217 234.762 43.6097 235.643 43.5652C236.519 43.5206 237.183 42.7732 237.153 41.8673C237.178 41.204 235.688 20.6317 234.173 0.00488281L230.842 0.00488281L233.97 42.0257Z"
            fill="#014A4F"
          />
          <path
            d="M435.446 299.2L437.238 313.293C437.278 313.59 437.392 313.867 437.575 314.099L454.895 336.112L441.683 373.861C441.49 374.742 442.035 375.604 442.896 375.797C443.757 375.99 444.604 375.435 444.797 374.559L458.172 336.053C458.197 335.939 458.211 335.82 458.211 335.701C458.211 335.335 458.088 334.974 457.86 334.682L440.342 312.416L438.604 298.779C438.491 297.888 437.694 297.259 436.817 297.378C435.946 297.492 435.332 298.304 435.441 299.2H435.446Z"
            fill="#014A4F"
          />
          <path
            d="M544.436 174.152L562.657 195.165C563.231 198.927 566.86 222.549 568.662 230.677C569.345 233.755 570.642 235.854 572.518 236.914C575.22 238.433 578.289 237.334 579.938 236.74C580.25 236.626 580.517 236.527 580.73 236.473C581.452 236.29 581.938 235.627 581.938 234.899C581.938 234.77 581.923 234.637 581.888 234.503C581.675 233.632 580.809 233.102 579.958 233.32C579.676 233.394 579.309 233.518 578.883 233.671C577.542 234.151 575.512 234.879 574.062 234.067C573.018 233.478 572.245 232.097 571.775 229.959C569.879 221.415 565.781 194.467 565.741 194.195C565.696 193.888 565.563 193.601 565.36 193.368L546.827 171.999C546.243 171.326 545.238 171.266 544.58 171.86C543.921 172.454 543.857 173.479 544.441 174.152H544.436Z"
            fill="#014A4F"
          />
          <path
            d="M606.916 198.739C606.267 199.348 606.222 200.372 606.816 201.036C607.41 201.699 608.42 201.744 609.069 201.135L622.394 188.68C622.736 188.359 622.909 187.923 622.909 187.482C622.909 187.091 622.77 186.695 622.488 186.384C621.894 185.72 620.884 185.676 620.236 186.285L606.911 198.739H606.916Z"
            fill="#014A4F"
          />
          <path
            d="M515.414 194.532C514.711 195.076 514.578 196.096 515.112 196.809C515.647 197.521 516.647 197.66 517.345 197.115L573.528 153.426L588.254 171.776L585.066 174.647C584.734 174.944 584.541 175.37 584.527 175.82C584.502 176.266 584.685 176.706 585.002 177.023L598.441 190.527L610.811 206.05L594.501 217.876C590.714 216.817 574.176 212.649 567.216 218.386C566.533 218.95 566.424 219.975 566.979 220.673C567.533 221.371 568.533 221.479 569.221 220.915C574.557 216.515 589.17 219.633 594.352 221.207C594.823 221.351 595.332 221.262 595.728 220.975L614.049 207.694C614.41 207.431 614.643 207.035 614.702 206.59C614.712 206.516 614.717 206.446 614.717 206.372C614.717 206.001 614.593 205.639 614.36 205.347L600.862 188.408C600.827 188.364 600.787 188.319 600.743 188.275L588.457 175.929L591.516 173.172C592.159 172.593 592.239 171.603 591.694 170.925L575.017 150.149C574.473 149.471 573.503 149.357 572.82 149.887L515.414 194.532Z"
            fill="#014A4F"
          />
          <path
            d="M422.695 212.901L357.795 250.358L231.955 296.141C231.173 296.551 230.861 297.532 231.267 298.328C231.668 299.125 232.629 299.442 233.411 299.031L359.305 253.214L424.388 215.653C424.472 215.604 424.546 215.549 424.621 215.49L639.343 33.5561C639.724 33.2343 639.917 32.769 639.917 32.3037C639.917 31.9374 639.799 31.5711 639.551 31.2642C638.987 30.5762 637.982 30.4772 637.309 31.0514L422.695 212.896V212.901Z"
            fill="#014A4F"
          />
          <path
            d="M353.276 129.28L467.805 274.747C468.354 275.445 469.359 275.559 470.047 274.999C470.438 274.677 470.641 274.207 470.641 273.732C470.641 273.376 470.527 273.014 470.29 272.717L355.761 127.251C355.211 126.553 354.206 126.439 353.518 127.003C352.83 127.567 352.721 128.587 353.276 129.29V129.28Z"
            fill="#014A4F"
          />
          <path
            d="M394.133 178.637C393.46 179.216 393.371 180.241 393.935 180.929C394.5 181.617 395.504 181.706 396.183 181.132L538.917 59.049C539.293 58.7273 539.486 58.2669 539.486 57.8016C539.486 57.4353 539.362 57.0641 539.115 56.7572C538.551 56.0691 537.546 55.98 536.872 56.5542L394.138 178.637H394.133Z"
            fill="#014A4F"
          />
          <path
            d="M422.358 78.1117L533.754 220.668C534.303 221.371 535.303 221.485 535.991 220.925C536.387 220.603 536.59 220.133 536.59 219.653C536.59 219.297 536.477 218.94 536.244 218.638L424.848 76.0822C424.299 75.3793 423.299 75.2655 422.611 75.8248C421.923 76.3842 421.809 77.4088 422.358 78.1068V78.1117Z"
            fill="#014A4F"
          />
          <path
            d="M463.107 42.5653L561.771 160.634C562.34 161.317 563.345 161.401 564.018 160.817C564.39 160.495 564.578 160.039 564.578 159.579C564.578 159.208 564.454 158.832 564.197 158.525L465.533 40.4566C464.963 39.7735 463.958 39.6893 463.285 40.2734C462.617 40.8575 462.533 41.8822 463.107 42.5653Z"
            fill="#014A4F"
          />
          <path
            d="M542.862 34.165C536.724 35.6104 523.29 37.7092 523.156 37.729C522.285 37.8627 521.691 38.6993 521.819 39.5804C521.953 40.4664 522.765 41.0753 523.636 40.9416C524.191 40.8575 537.323 38.8032 543.575 37.333C549.901 35.8431 564.736 29.512 565.365 29.2447C565.974 28.9823 566.34 28.3784 566.34 27.7448C566.34 27.5369 566.3 27.3241 566.216 27.1162C565.875 26.2895 564.944 25.8985 564.132 26.245C563.979 26.3093 548.921 32.7345 542.857 34.165H542.862Z"
            fill="#014A4F"
          />
          <path
            d="M44.4282 858.375L3.59536 939.764L-32.9359 990.002H-28.966L6.23867 941.591C6.29312 941.516 6.33768 941.442 6.37728 941.363L47.2695 859.856C47.3883 859.618 47.4427 859.365 47.4427 859.118C47.4427 858.529 47.1259 857.96 46.5765 857.668C45.7944 857.257 44.8341 857.574 44.4332 858.375H44.4282Z"
            fill="#014A4F"
          />
          <path
            d="M592.6 131.186C593.159 131.879 594.164 131.978 594.842 131.408L651.431 83.9524V79.7449L592.818 128.899C592.14 129.468 592.041 130.493 592.6 131.186Z"
            fill="#014A4F"
          />
          <path
            d="M76.638 854.945L67.1538 876.488L23.4945 955.926C23.4796 955.956 23.4648 955.981 23.4499 956.01C23.2767 956.372 16.0496 971.553 7.24353 990.002H10.7878C19.0989 972.593 25.8458 958.421 26.2962 957.475L69.9704 878.013C69.9951 877.973 70.0149 877.928 70.0347 877.884L79.5487 856.272C79.6427 856.054 79.6873 855.831 79.6873 855.609C79.6873 854.99 76.9994 854.124 76.638 854.94V854.945Z"
            fill="#014A4F"
          />
          <path
            d="M-24.0703 65.1869C-24.8821 66.8055 -25.6692 68.345 -26.4166 69.8102C-31.1885 79.1658 -34.3367 85.3434 -33.6486 90.1499C-32.9408 95.0702 -25.3771 153.05 -25.3029 153.634C-25.189 154.525 -24.3871 155.149 -23.5159 155.03C-22.6447 154.911 -22.0309 154.094 -22.1448 153.203C-22.219 152.619 -29.7876 94.6148 -30.4955 89.6747C-31.0301 85.9077 -27.9116 79.7845 -23.5902 71.3101C-22.8427 69.8399 -22.0507 68.2955 -21.234 66.6719C-17.8284 59.8953 -1.74567 26.2647 10.7829 0.00976562L7.23863 0.00976562C-5.18101 26.0271 -20.739 58.5538 -24.0703 65.1918V65.1869Z"
            fill="#014A4F"
          />
          <path
            d="M-30.5302 115.846C-30.5649 116.766 -29.8718 117.494 -28.9907 117.524L18.7523 119.162L86.1867 181.092C86.8401 181.691 87.8498 181.637 88.4389 180.973C88.7111 180.661 88.8498 180.275 88.8498 179.884C88.8498 179.439 88.6715 178.998 88.3201 178.676L20.4551 116.351C20.1779 116.093 19.8166 115.945 19.4453 115.935L-28.8769 114.277C-29.758 114.247 -30.4956 114.95 -30.5253 115.846H-30.5302Z"
            fill="#014A4F"
          />
          <path
            d="M92.3594 896.629L50.091 990.002H53.6006L95.2551 897.991C95.3541 897.768 95.4036 897.54 95.4036 897.312C95.4036 896.699 95.0621 896.11 94.478 895.837C93.6811 895.461 92.7307 895.818 92.3644 896.634L92.3594 896.629Z"
            fill="#014A4F"
          />
          <path
            d="M-18.9817 152.575C-19.348 153.392 -19.0015 154.357 -18.2046 154.733C-17.4076 155.109 -16.4572 154.753 -16.0909 153.936L53.6007 0.00488281L50.091 0.00488281L-18.9767 152.575H-18.9817Z"
            fill="#014A4F"
          />
          <path
            d="M-8.27482 88.3531C-8.01742 89.2095 -7.12145 89.6896 -6.27995 89.4273C-3.58713 88.5808 4.20417 86.4969 7.89194 87.0265C12.3024 87.6601 14.0003 88.7491 18.589 93.8873C23.549 99.4511 30.7958 103.213 31.1027 103.372C31.8897 103.777 32.8451 103.456 33.2411 102.654C33.355 102.421 33.4144 102.169 33.4144 101.921C33.4144 101.327 33.0926 100.758 32.5382 100.471C32.4689 100.436 25.5042 96.8127 20.9501 91.7043C15.8269 85.9623 13.5894 84.5614 8.33746 83.809C3.19438 83.0714 -6.78981 86.19 -7.21551 86.3236C-8.05701 86.5909 -8.52727 87.5017 -8.26987 88.3581L-8.27482 88.3531Z"
            fill="#014A4F"
          />
          <path
            d="M23.6381 899.897C23.0887 900.594 23.1976 901.619 23.8807 902.183L131.108 990.002H136.197L25.8706 899.649C25.1826 899.09 24.1826 899.199 23.6332 899.901L23.6381 899.897Z"
            fill="#014A4F"
          />
          <path
            d="M187.153 45.9016C187.841 46.461 188.841 46.3521 189.39 45.6492C189.628 45.3472 189.741 44.9908 189.741 44.6344C189.741 44.1592 189.539 43.6889 189.147 43.3672L136.202 0.00488281L131.113 0.00488281L187.158 45.9016H187.153Z"
            fill="#014A4F"
          />
          <path
            d="M56.4023 867.801L20.3116 931.008C19.8661 931.785 20.1284 932.78 20.8858 933.23C21.6481 933.681 22.6233 933.418 23.0638 932.646L59.1545 869.439C59.303 869.182 59.3723 868.899 59.3723 868.622C59.3723 868.063 59.0902 867.518 58.5803 867.216C57.818 866.766 56.8428 867.028 56.4023 867.801Z"
            fill="#014A4F"
          />
          <path
            d="M191.771 22.7552C192.231 23.1314 192.86 23.2205 193.399 22.9829L207.819 16.6469C208.423 16.3796 208.784 15.7806 208.784 15.152C208.784 14.9391 208.74 14.7213 208.655 14.5085C208.309 13.6818 207.368 13.3056 206.562 13.6571L193.028 19.6021L169.189 0.00488281L164.11 0.00488281L191.766 22.7552H191.771Z"
            fill="#014A4F"
          />
          <path
            d="M121.703 19.6269L150.651 44.882L139.291 60.4795C138.766 61.1973 138.914 62.217 139.617 62.7516C140.325 63.2862 141.32 63.1377 141.845 62.415L154.091 45.5997C154.591 44.9166 154.482 43.9514 153.849 43.3969L124.916 18.1518L138.454 0L134.45 0L121.47 17.4043C120.961 18.0874 121.06 19.0626 121.703 19.6219V19.6269Z"
            fill="#014A4F"
          />
          <path
            d="M28.0535 844.634C22.95 849.624 11.9708 868.008 10.9412 879.057C10.3669 885.22 1.85788 896.283 -4.98307 905.168C-9.1609 910.603 -12.7695 915.291 -14.6307 918.746C-19.4174 927.641 -39.9699 952.614 -52.5628 962.495C-58.2603 966.965 -66.4873 973.167 -74.442 979.161C-79.3425 982.854 -84.3223 986.606 -88.7575 989.992H-83.4263C-79.9266 987.339 -76.2141 984.537 -72.5461 981.775C-64.5766 975.771 -56.3348 969.558 -50.6175 965.069C-37.5048 954.782 -16.8582 929.641 -11.834 920.305C-10.1014 917.083 -6.39386 912.267 -2.47343 907.168C4.99122 897.466 13.4508 886.467 14.1141 879.354C15.0299 869.543 25.5042 851.619 30.2562 846.971C30.5829 846.649 30.7462 846.223 30.7462 845.798C30.7462 845.392 30.5977 844.986 30.3007 844.674C29.6919 844.025 28.6821 844.006 28.0485 844.624L28.0535 844.634Z"
            fill="#014A4F"
          />
          <path
            d="M-21.0459 808.821C-21.0707 808.851 -21.0955 808.88 -21.1153 808.91L-52.8697 852.668C-53.1222 853.02 -53.2311 853.46 -53.1618 853.886C-53.0974 854.317 -52.8647 854.703 -52.5182 854.955L-14.1604 882.987C-13.4476 883.507 -12.4527 883.344 -11.9379 882.616L19.5592 838.066C20.074 837.338 19.9107 836.323 19.1979 835.798C18.4851 835.274 17.4901 835.437 16.9753 836.165L-13.5912 879.398L-49.3404 853.267L-18.5858 810.885L-8.46294 798.837L6.2436 810.657C6.2436 810.657 6.28815 810.692 6.30795 810.707L23.9647 823.478C24.6281 823.958 25.5339 823.849 26.0735 823.235L39.9632 807.37C40.2355 807.059 40.3692 806.672 40.3692 806.286C40.3692 805.841 40.1909 805.395 39.8345 805.074C39.1811 804.475 38.1713 804.534 37.5823 805.202L24.6379 819.983L8.17412 808.078L-7.70557 795.317C-8.37382 794.778 -9.34406 794.877 -9.90342 795.535L-21.0559 808.811L-21.0459 808.821Z"
            fill="#014A4F"
          />
          <path
            d="M-1.78036 821.221L-33.3715 866.964C-33.8764 867.697 -33.7081 868.711 -32.9854 869.231C-32.2676 869.746 -31.2727 869.573 -30.7678 868.84L0.823368 823.097C1.02137 822.815 1.1154 822.488 1.1154 822.161C1.1154 821.651 0.882751 821.146 0.437248 820.83C-0.280506 820.315 -1.27545 820.488 -1.78036 821.221Z"
            fill="#014A4F"
          />
          <path
            d="M27.8802 801.529L-24.6891 873.255C-25.2138 873.973 -25.0702 874.993 -24.3673 875.532C-23.6644 876.072 -22.6645 875.923 -22.1398 875.206L29.7068 804.465L45.2747 810.598C46.0964 810.92 47.0171 810.504 47.3339 809.667C47.4082 809.474 47.4428 809.276 47.4428 809.083C47.4428 808.43 47.0567 807.816 46.4231 807.568L29.7316 800.99C29.0634 800.728 28.3109 800.95 27.8802 801.534V801.529Z"
            fill="#014A4F"
          />
          <path
            d="M178.955 683.402C178.733 683.456 178.52 683.56 178.337 683.709L169.689 690.673C159.517 689.525 34.8944 675.437 25.0983 674.031C17.8366 672.992 14.4211 673.413 12.9262 675.536C11.4907 677.576 12.5698 680.353 13.5103 682.268C14.2231 683.719 16.1288 688.198 18.5444 693.866C26.2467 711.954 39.1465 742.228 46.5419 753.732C53.6155 764.736 54.452 778.437 49.0911 795.619C46.2696 804.668 37.5179 853.193 38.1862 856C38.3991 856.871 39.2653 857.4 40.1167 857.188C40.9186 856.985 41.4235 856.193 41.3096 855.376V855.351C41.3096 851.95 48.8437 807.148 52.1305 796.604C54.2144 789.926 60.5505 769.597 49.2099 751.95C41.9631 740.674 28.6326 709.379 21.4699 692.569C18.9405 686.634 17.1139 682.342 16.3665 680.813C15.1042 678.239 15.4358 677.546 15.52 677.432C15.8071 677.026 17.2723 676.19 24.6627 677.249C34.8696 678.709 168.649 693.822 169.996 693.975C170.411 694.024 170.827 693.901 171.159 693.633L180.044 686.481L263.69 668.73C264.423 668.552 264.913 667.883 264.913 667.151C264.913 667.027 264.898 666.898 264.868 666.77C264.665 665.898 263.804 665.359 262.947 665.567L178.96 683.402H178.955Z"
            fill="#014A4F"
          />
          <path
            d="M42.4383 828.537C41.9086 829.249 42.0423 830.269 42.7452 830.814L117.238 888.689C117.941 889.234 118.941 889.09 119.471 888.378C119.689 888.086 119.58 886.422 119.164 886.101L44.6707 828.225C43.9678 827.68 42.9679 827.824 42.4383 828.537Z"
            fill="#014A4F"
          />
          <path
            d="M44.2797 822.448C44.4381 823.329 45.2647 823.918 46.131 823.76C50.4524 822.963 57.719 824.785 63.461 830.67C69.2674 836.62 120.842 874.963 122.985 876.547C123.698 877.077 124.693 876.913 125.213 876.186C125.416 875.899 125.515 875.562 125.515 875.23C125.515 874.725 125.287 874.23 124.856 873.914C124.337 873.528 71.3465 834.145 65.7183 828.373C59.1298 821.621 50.8484 819.582 45.5618 820.557C44.6955 820.716 44.1163 821.562 44.2797 822.448Z"
            fill="#014A4F"
          />
          <path
            d="M213.254 748.906L134.321 854.629C134.266 854.703 134.212 854.787 134.172 854.871L107.219 908.381C106.818 909.178 107.125 910.158 107.907 910.569C108.69 910.98 109.65 910.663 110.056 909.866L136.944 856.485L215.788 750.881C216.006 750.589 216.115 750.242 216.115 749.896C216.115 749.406 215.897 748.921 215.487 748.604C214.789 748.059 213.789 748.198 213.254 748.911V748.906Z"
            fill="#014A4F"
          />
          <path
            d="M115.565 891.867C115.021 892.575 115.144 893.595 115.837 894.149L160.596 929.864C161.289 930.418 162.289 930.295 162.833 929.587C163.061 929.29 163.17 928.938 163.17 928.587C163.17 928.102 162.962 927.627 162.556 927.305L117.798 891.59C117.105 891.036 116.105 891.16 115.56 891.867H115.565Z"
            fill="#014A4F"
          />
          <path
            d="M120.654 883.135L167.234 921.473C167.917 922.038 168.922 921.924 169.476 921.226C169.714 920.924 169.827 920.568 169.827 920.206C169.827 919.731 169.624 919.261 169.233 918.939L122.317 880.403C121.634 879.839 119.971 882.571 120.654 883.13V883.135Z"
            fill="#014A4F"
          />
          <path
            d="M85.9985 795.599C85.1422 796.713 84.3204 797.708 83.6027 798.589C81.093 801.653 79.4347 803.673 79.7664 806.093C79.9941 807.727 81.1178 809.157 83.4047 810.736C88.9883 814.573 184.43 897.709 185.395 898.545C186.063 899.129 187.068 899.045 187.643 898.367C187.9 898.06 188.024 897.684 188.024 897.313C188.024 896.852 187.836 896.397 187.464 896.075C183.524 892.645 90.9189 811.984 85.1868 808.044C83.4196 806.831 82.979 806.069 82.9196 805.643C82.7761 804.653 84.2215 802.901 86.0431 800.678C86.7807 799.782 87.6172 798.762 88.5033 797.609C93.6959 790.867 130.925 751.415 131.301 751.019C131.915 750.371 131.89 749.346 131.262 748.722C130.628 748.099 129.618 748.119 129.009 748.767C127.47 750.396 91.2901 788.734 85.9985 795.604V795.599Z"
            fill="#014A4F"
          />
          <path
            d="M98.6311 733.649C98.2549 734.224 97.5669 734.377 101.151 746.901C101.299 747.425 101.7 747.841 102.215 747.999C102.73 748.158 103.289 748.044 103.7 747.693C111.679 740.906 121.768 742.104 132.88 751.158C147.8 763.315 205.626 809.707 206.21 810.172C206.903 810.726 207.903 810.603 208.448 809.9C208.675 809.603 208.789 809.246 208.789 808.895C208.789 808.415 208.581 807.934 208.18 807.618C207.596 807.152 149.78 760.766 134.87 748.618C120.619 737.005 110.229 739.114 103.586 743.713C102.453 739.426 101.527 734.986 101.7 733.887C102.047 733.164 99.1211 732.902 98.636 733.649H98.6311Z"
            fill="#014A4F"
          />
          <path
            d="M213.398 797.911C213.348 797.96 213.304 798.015 213.259 798.069L139.093 896.318C138.558 897.03 138.687 898.05 139.385 898.595C140.083 899.144 141.083 899.01 141.617 898.298L215.719 800.133C216.63 799.208 225.609 790.075 229.921 785.422C232.48 782.66 257.636 780.784 280.481 780.566C281.357 780.556 282.06 779.833 282.06 778.942V778.927C282.05 778.032 281.332 777.309 280.451 777.319C272.466 777.393 232.386 778.036 227.604 783.194C223.115 788.036 213.492 797.812 213.393 797.911H213.398Z"
            fill="#014A4F"
          />
          <path
            d="M159.338 751.826L86.251 845.639C85.7015 846.342 85.8154 847.362 86.4985 847.921C87.1866 848.481 88.1865 848.367 88.7359 847.669L160.813 755.143L331.09 898.298C331.768 898.867 332.773 898.768 333.332 898.075C333.574 897.773 333.693 897.407 333.693 897.045C333.693 896.575 333.495 896.11 333.114 895.788L161.59 751.584C161.259 751.306 160.838 751.178 160.412 751.222C159.987 751.267 159.601 751.485 159.333 751.826H159.338Z"
            fill="#014A4F"
          />
          <path
            d="M135.271 722.235C95.1611 730.541 49.2594 753.964 48.804 754.197C48.0169 754.603 47.7001 755.578 48.0962 756.38C48.4922 757.182 49.4475 757.504 50.2345 757.103C50.6899 756.87 96.2154 733.64 135.905 725.423C175.748 717.171 296.41 701.712 297.454 701.583C298.261 701.479 298.85 700.781 298.85 699.974C298.85 699.905 297.652 699.024 297.642 698.955C297.533 698.064 297.929 698.247 297.058 698.361C296.014 698.494 175.228 713.968 135.266 722.245L135.271 722.235Z"
            fill="#014A4F"
          />
          <path
            d="M130.039 688.337L137.642 723.448C137.83 724.324 138.682 724.878 139.543 724.685C140.286 724.517 140.791 723.844 140.791 723.101C140.791 722.982 140.781 722.869 140.751 722.75L133.148 687.639C132.96 686.763 132.108 686.209 131.247 686.402C130.386 686.595 129.846 687.466 130.034 688.337H130.039Z"
            fill="#014A4F"
          />
          <path
            d="M71.1782 681.738L87.8251 738.53C88.0775 739.391 88.9636 739.877 89.8101 739.624C90.5031 739.411 90.9486 738.768 90.9486 738.07C90.9486 737.916 90.9288 737.758 90.8793 737.605L74.2323 680.813C73.9799 679.951 73.0938 679.466 72.2473 679.719C71.4058 679.976 70.9257 680.882 71.1782 681.743V681.738Z"
            fill="#014A4F"
          />
          <path
            d="M21.8412 695.539C20.965 695.455 20.1879 696.108 20.1037 697C20.0097 697.895 20.6581 698.687 21.5343 698.772C22.1729 698.836 85.7214 705.123 97.1015 707.142C108.853 709.231 134.667 703.187 135.761 702.93C136.499 702.756 135.9 699.559 135.044 699.762C134.781 699.821 108.769 705.914 97.651 703.934C86.1521 701.89 24.4647 695.787 21.8412 695.529V695.539Z"
            fill="#014A4F"
          />
          <path
            d="M36.0083 729.234C35.1717 729.511 34.7163 730.427 34.9836 731.279C35.2559 732.135 36.1518 732.6 36.9884 732.323L83.2365 717.062C83.9097 716.839 84.3403 716.201 84.3403 715.518C84.3403 715.354 84.3156 715.181 84.2612 715.018C83.9889 714.166 83.093 713.696 82.2564 713.973L36.0083 729.234Z"
            fill="#014A4F"
          />
          <path
            d="M46.4626 700.569L58.3526 723.854C58.7585 724.65 59.7188 724.957 60.5009 724.546C61.0454 724.254 61.3573 723.69 61.3573 723.106C61.3573 722.854 61.2979 722.596 61.1791 722.359L49.2891 699.074C48.8832 698.277 47.9229 697.97 47.1408 698.381C46.3587 698.796 46.0567 699.777 46.4626 700.573V700.569Z"
            fill="#014A4F"
          />
          <path
            d="M159.992 719.26C160.061 720.101 160.754 720.74 161.571 720.745C164.566 721.403 186.182 736.268 195.439 742.629C198.998 745.074 201.81 747.01 203.057 747.822C206.908 750.321 214.145 749.505 221.144 748.713C224.832 748.297 228.322 747.901 230.802 748.069C238.039 748.559 304.513 747.064 306.112 746.985C306.963 746.941 307.626 746.223 307.626 745.361C307.626 745.337 307.626 744.728 307.626 744.698C307.582 743.802 306.835 743.688 305.953 743.733C305.562 743.753 238.069 745.297 231.015 744.817C228.253 744.629 224.629 745.04 220.793 745.47C214.645 746.163 207.67 746.955 204.765 745.064C203.552 744.277 200.755 742.357 197.216 739.921C171.936 722.542 163.575 717.3 161.437 717.488C160.561 717.567 159.903 718.354 159.987 719.25L159.992 719.26Z"
            fill="#014A4F"
          />
          <path
            d="M241.44 707.266L247.152 780.442C247.221 781.338 247.988 782.007 248.87 781.932C249.701 781.863 250.335 781.155 250.335 780.314C250.335 780.269 250.335 780.224 250.335 780.185L244.622 707.009C244.553 706.113 243.786 705.444 242.905 705.519C242.029 705.588 241.38 706.365 241.445 707.266H241.44Z"
            fill="#014A4F"
          />
          <path
            d="M262.19 704.479L267.407 779.061C267.472 779.957 268.234 780.63 269.11 780.566C269.947 780.507 270.59 779.794 270.59 778.947C270.59 778.908 270.59 778.868 270.59 778.829L265.373 704.246C265.309 703.35 264.546 702.677 263.67 702.742C262.794 702.806 262.136 703.593 262.195 704.479H262.19Z"
            fill="#014A4F"
          />
          <path
            d="M59.4564 806.974L50.1702 802.742C49.3683 802.376 48.4228 802.742 48.0664 803.564C47.71 804.386 48.0664 805.346 48.8733 805.712L59.1199 810.385C59.699 810.652 60.3821 810.539 60.8474 810.098L100.403 772.963L105.68 775.383L297.85 933.562C298.533 934.126 299.538 934.017 300.092 933.314C300.33 933.012 300.444 932.656 300.444 932.295C300.444 931.819 300.241 931.349 299.85 931.027L107.516 772.72C107.412 772.631 107.294 772.562 107.17 772.502L100.745 769.552C100.166 769.285 99.4824 769.399 99.0122 769.839L59.4564 806.979V806.974Z"
            fill="#014A4F"
          />
          <path
            d="M277.6 104.173L300.523 136.71C301.053 137.423 302.053 137.566 302.756 137.022C303.167 136.7 303.384 136.215 303.384 135.725C303.384 135.383 303.28 135.037 303.058 134.745L280.134 102.208C279.604 101.495 278.604 101.352 277.901 101.896C277.199 102.441 277.065 103.461 277.595 104.173H277.6Z"
            fill="#014A4F"
          />
          <path
            d="M316.64 26.646L266.947 67.4293C266.264 67.9936 266.155 69.0133 266.705 69.7162C267.254 70.4142 268.259 70.528 268.947 69.9637L318.843 29.017C318.977 28.9081 319.096 28.7745 319.19 28.626L337.094 0.00488281L333.312 0.00488281L316.64 26.646Z"
            fill="#014A4F"
          />
          <path
            d="M316.64 28.8092L385.58 109.831C386.154 110.51 387.164 110.584 387.827 109.995C388.188 109.673 388.376 109.223 388.376 108.767C388.376 108.391 388.248 108.01 387.985 107.703L319.046 26.6807C318.472 26.0025 317.462 25.9283 316.799 26.5173C316.136 27.1064 316.061 28.131 316.64 28.8092Z"
            fill="#014A4F"
          />
          <path
            d="M542.555 840.61L524.547 898.426L506.93 895.704C506.474 895.634 506.009 895.768 505.658 896.075L453.771 941.095C453.702 941.155 453.643 941.219 453.583 941.288L413.33 990.012H417.497L455.935 943.486L507.167 899.035L525.319 901.842C526.052 901.955 526.765 901.54 527.037 900.832L545.51 841.817C545.585 841.619 545.624 841.417 545.624 841.219C545.624 840.57 545.243 839.961 544.619 839.709C543.803 839.377 542.877 839.788 542.55 840.62L542.555 840.61Z"
            fill="#014A4F"
          />
          <path
            d="M395.173 21.983C394.604 22.6711 394.693 23.6957 395.366 24.2749C396.039 24.8541 397.044 24.765 397.613 24.0819L417.497 0.00488281L413.33 0.00488281L395.168 21.983H395.173Z"
            fill="#014A4F"
          />
          <path
            d="M558.068 914.618C558.068 913.722 557.356 912.994 556.475 912.994H496.153C495.812 912.994 495.48 913.108 495.208 913.311L472.369 930.522C471.661 931.057 471.512 932.072 472.032 932.795L500.232 971.885C500.5 972.251 500.901 972.489 501.346 972.538C501.792 972.588 502.237 972.444 502.569 972.142L520.042 956.406C520.701 955.812 520.765 954.783 520.181 954.114C519.597 953.441 518.592 953.377 517.934 953.971L501.762 968.529L475.537 932.176L496.673 916.247H556.47C557.351 916.247 558.063 915.519 558.063 914.623L558.068 914.618Z"
            fill="#014A4F"
          />
          <path
            d="M536.595 971.127C536.595 970.751 536.467 970.37 536.204 970.063L487.941 913.548C487.362 912.87 486.357 912.801 485.694 913.39C485.031 913.979 484.961 915.009 485.541 915.682L533.803 972.196C534.383 972.875 535.387 972.944 536.051 972.355C536.412 972.033 536.595 971.583 536.595 971.127Z"
            fill="#014A4F"
          />
          <path
            d="M574.478 839.298C574.478 838.892 574.473 838.486 574.458 838.07C573.978 823.23 566.513 735.105 566.439 734.214C566.365 733.318 565.593 732.659 564.716 732.734C563.84 732.813 563.187 733.605 563.266 734.491C563.34 735.377 570.8 823.418 571.275 838.174C571.671 850.505 565.266 858.861 561.024 864.38C560.326 865.286 559.667 866.147 559.098 866.939C554.514 873.354 554.287 911.702 554.886 918.444C555.554 925.968 552.193 929.498 549.114 930.24C546.317 930.908 521.715 928.587 506.86 926.963C506.425 926.914 505.989 927.052 505.658 927.344L468.013 960.059C467.344 960.643 467.26 961.668 467.834 962.351C468.404 963.034 469.408 963.118 470.082 962.534L507.207 930.275C513.474 930.953 545.783 934.383 549.847 933.413C554.173 932.374 558.925 927.76 558.064 918.162C557.301 909.608 558.088 873.879 561.677 868.865C562.212 868.117 562.855 867.281 563.533 866.395C567.919 860.682 574.478 852.134 574.483 839.303L574.478 839.298Z"
            fill="#014A4F"
          />
          <path
            d="M548.505 974.607C548.505 974.345 548.441 974.082 548.312 973.835L525.022 929.928L527.155 900.347C527.215 899.446 526.561 898.674 525.685 898.609C524.809 898.545 524.042 899.218 523.978 900.109L521.814 930.166C521.79 930.473 521.859 930.784 522.003 931.057L545.515 975.384C545.936 976.176 546.901 976.468 547.673 976.043C548.208 975.751 548.51 975.191 548.51 974.612L548.505 974.607Z"
            fill="#014A4F"
          />
          <path
            d="M508.286 897.308C508.286 896.936 508.162 896.56 507.905 896.253L487.06 871.275C486.654 870.79 486.011 870.592 485.412 870.765L479.784 872.399L474.457 866.776C474.156 866.459 473.745 866.281 473.309 866.281H456.613C456.335 866.281 456.063 866.355 455.821 866.494L444.069 873.354L432.679 876.161C432.516 876.201 432.362 876.265 432.219 876.354L424.452 881.225C424.096 881.448 423.838 881.804 423.739 882.22L422.195 888.576L415.998 893.13C415.285 893.654 415.121 894.669 415.636 895.397C416.151 896.124 417.146 896.293 417.859 895.768L424.531 890.868C424.838 890.645 425.056 890.313 425.145 889.942L426.675 883.655L433.674 879.27L445.039 876.468C445.188 876.433 445.326 876.374 445.455 876.3L457.033 869.538H472.631L478.15 875.369C478.561 875.8 479.165 875.968 479.734 875.805L485.293 874.191L505.469 898.377C506.039 899.06 507.044 899.144 507.717 898.56C508.088 898.238 508.276 897.783 508.276 897.322L508.286 897.308Z"
            fill="#014A4F"
          />
          <path
            d="M484.085 918.306C484.085 917.92 483.952 917.539 483.684 917.227L445.866 873.8C445.282 873.127 444.272 873.067 443.619 873.666C442.96 874.26 442.901 875.29 443.485 875.958L481.308 919.385C481.892 920.058 482.902 920.118 483.556 919.519C483.912 919.197 484.09 918.751 484.09 918.301L484.085 918.306Z"
            fill="#014A4F"
          />
          <path
            d="M175.188 742.713C174.639 743.416 174.753 744.436 175.436 744.995L201.973 766.627C202.661 767.186 203.666 767.072 204.215 766.374C204.448 766.073 204.562 765.716 204.562 765.36C204.562 764.885 204.359 764.409 203.963 764.088L177.426 742.456C176.738 741.897 175.733 742.01 175.183 742.713H175.188Z"
            fill="#014A4F"
          />
          <path
            d="M182.618 749.074L175.055 762.35C174.609 763.127 174.872 764.122 175.634 764.572C176.396 765.023 177.371 764.761 177.812 763.983L185.375 750.707C185.524 750.45 185.593 750.168 185.593 749.891C185.593 749.331 185.311 748.787 184.801 748.485C184.039 748.034 183.064 748.297 182.623 749.074H182.618Z"
            fill="#014A4F"
          />
          <path
            d="M121.753 757.806L199.008 819.582C199.701 820.136 200.701 820.013 201.245 819.305C201.473 819.008 201.587 818.656 201.587 818.305C201.587 817.825 201.379 817.345 200.978 817.023L123.723 755.246C123.03 754.692 121.065 757.251 121.758 757.806H121.753Z"
            fill="#014A4F"
          />
          <path
            d="M221.432 764.389C221.387 764.448 216.907 770.626 212.536 776.764C209.967 780.373 207.933 783.264 206.497 785.362C205.636 786.62 204.983 787.6 204.547 788.288C203.889 789.327 203.072 790.619 204.245 791.599C204.923 792.169 205.928 792.07 206.487 791.377C206.641 791.184 206.745 790.966 206.799 790.743C207.859 788.763 217.382 775.443 223.57 766.914L247.543 764.785C248.375 764.711 248.998 764.003 248.998 763.166C248.998 763.117 248.998 763.072 248.993 763.023C248.919 762.127 248.147 761.469 247.266 761.543L222.575 763.736C222.12 763.775 221.704 764.013 221.432 764.389ZM206.735 789.753C206.685 789.629 206.626 789.511 206.547 789.402C206.473 789.293 206.378 789.189 206.265 789.095C206.373 789.189 206.468 789.293 206.547 789.402C206.626 789.515 206.69 789.634 206.735 789.753Z"
            fill="#014A4F"
          />
          <path
            d="M206.547 789.402C206.468 789.288 206.373 789.184 206.265 789.095C206.378 789.189 206.468 789.293 206.547 789.402Z"
            fill="#014A4F"
          />
          <path
            d="M218.229 711.459V741.654C218.229 742.55 218.942 743.277 219.823 743.277C220.704 743.277 221.417 742.55 221.417 741.654V711.459C221.417 710.563 220.704 709.835 219.823 709.835C218.942 709.835 218.229 710.563 218.229 711.459Z"
            fill="#014A4F"
          />
          <path
            d="M114.62 766.612L190.524 830.759C191.202 831.333 192.207 831.234 192.766 830.546C193.013 830.244 193.132 829.878 193.132 829.512C193.132 829.046 192.934 828.581 192.558 828.259L116.654 764.112C115.976 763.538 114.971 763.637 114.412 764.325C113.852 765.018 113.947 766.042 114.62 766.612Z"
            fill="#014A4F"
          />
          <path
            d="M95.7402 785.437C95.8144 786.184 96.0372 788.466 119.377 805.544C120.089 806.069 121.089 805.9 121.599 805.173C121.802 804.886 121.896 804.554 121.896 804.227C121.896 803.722 121.664 803.223 121.233 802.906C111.308 795.644 100.537 787.16 98.9627 785.001C98.9379 784.6 98.7696 784.209 98.4577 783.912C97.8142 783.304 96.8045 783.338 96.2056 783.997C95.8541 784.383 95.6907 784.897 95.7402 785.437Z"
            fill="#014A4F"
          />
          <path
            d="M85.9788 795.624C85.4491 796.342 85.5878 797.361 86.2907 797.901L110.61 816.667C111.313 817.206 112.313 817.068 112.843 816.35C113.06 816.058 113.164 815.711 113.164 815.37C113.164 814.88 112.947 814.39 112.531 814.073L88.2113 795.307C87.5083 794.768 86.5084 794.906 85.9788 795.624Z"
            fill="#014A4F"
          />
          <path
            d="M147.379 837.135C146.839 837.843 146.968 838.868 147.666 839.417L198.152 881.626C198.85 882.175 199.849 882.042 200.384 881.334C200.607 881.037 200.716 880.69 200.716 880.344C200.716 879.859 200.503 879.379 200.097 879.057L149.611 836.848C148.914 836.298 147.914 836.432 147.379 837.14V837.135Z"
            fill="#014A4F"
          />
          <path
            d="M226.099 807.885L195.429 847.08C194.88 847.783 194.993 848.802 195.682 849.362C196.37 849.921 197.374 849.807 197.919 849.104L228.589 809.91C228.822 809.613 228.936 809.251 228.936 808.895C228.936 808.42 228.733 807.945 228.337 807.623C227.649 807.064 226.644 807.177 226.099 807.88V807.885Z"
            fill="#014A4F"
          />
          <path
            d="M234.326 817.276L204.587 854.584C204.032 855.282 204.136 856.302 204.819 856.871C205.502 857.435 206.507 857.331 207.062 856.633L236.801 819.325C237.044 819.023 237.158 818.662 237.158 818.3C237.158 817.83 236.955 817.36 236.569 817.038C235.886 816.474 234.881 816.578 234.326 817.276Z"
            fill="#014A4F"
          />
          <path
            d="M635.012 722.165C635.012 716.314 634.121 705.523 631.21 685.218C628.349 665.25 625.345 641.891 625.31 641.698L622.176 642.277C628.73 679.174 633.611 723.106 631.339 727.041C628.008 729.486 605.01 730.333 591.229 730.843C584.937 731.075 579.507 731.273 576.344 731.555C573.102 731.843 562.984 732.922 550.173 734.293C518.988 737.624 471.864 742.658 458.009 743.351C444.668 744.02 406.489 759.023 403.033 761.315C393.579 767.582 367.007 783.971 357.275 788.936C354.756 790.218 352.177 791.679 349.439 793.223C341.806 797.534 333.911 801.99 327.392 802.598C313.893 803.856 293.078 797.317 287.401 791.525C287.074 791.189 286.614 791.015 286.148 791.055C285.683 791.09 285.257 791.332 284.985 791.718L254.626 831.393L257.201 833.308L286.534 795.069C294.618 801.524 315.047 807.014 327.679 805.836C334.867 805.167 343.059 800.544 350.979 796.069C353.682 794.54 356.236 793.099 358.696 791.847C368.517 786.837 395.262 770.349 404.766 764.043C407.607 762.162 445.277 747.247 458.162 746.604C472.106 745.901 519.285 740.866 550.505 737.53C563.301 736.164 573.404 735.085 576.621 734.798C579.7 734.526 585.096 734.328 591.343 734.095C612.974 733.298 629.809 732.456 633.398 729.531C634.062 728.991 635.012 728.214 635.012 722.17V722.165Z"
            fill="#014A4F"
          />
          <path
            d="M400.202 830.68C400.202 829.868 399.608 829.17 398.801 829.066L366.809 825.087L316.591 733.452C316.16 732.669 315.19 732.387 314.423 732.828C313.656 733.263 313.378 734.253 313.809 735.041L364.423 827.398C364.671 827.849 365.116 828.156 365.621 828.22L398.42 832.304C399.291 832.413 400.088 831.779 400.197 830.888C400.207 830.824 400.207 830.754 400.207 830.69L400.202 830.68Z"
            fill="#014A4F"
          />
          <path
            d="M401.608 615.561C401.608 614.809 401.093 614.136 400.346 613.972C399.484 613.784 398.638 614.349 398.455 615.225L387.966 669.947L377.174 681.912C376.902 682.214 376.754 682.605 376.754 683.011V703.246L344.875 723.254L333.04 719.329C332.59 719.181 332.099 719.24 331.699 719.502C308.285 734.575 319.427 732.867 332.768 722.66L344.588 726.586C345.034 726.734 345.519 726.675 345.915 726.422L379.179 705.543C379.649 705.246 379.937 704.721 379.937 704.157V683.649L390.604 671.824C390.797 671.611 390.931 671.343 390.99 671.061L401.573 615.903C401.598 615.789 401.608 615.675 401.608 615.566V615.561Z"
            fill="#014A4F"
          />
          <path
            d="M359.572 790.387C359.572 790.109 359.503 789.827 359.354 789.57L323.506 726.759C323.447 726.66 323.382 726.566 323.308 726.482L279.01 677.001C278.416 676.338 277.407 676.293 276.758 676.897C276.11 677.501 276.06 678.531 276.654 679.194L320.838 728.546L356.602 791.203C357.043 791.981 358.023 792.243 358.78 791.793C359.29 791.491 359.572 790.946 359.572 790.387Z"
            fill="#014A4F"
          />
          <path
            d="M378.615 779.452C378.615 779.16 378.536 778.873 378.387 778.616L346.449 724.2C345.999 723.428 345.019 723.18 344.262 723.641C343.504 724.101 343.262 725.101 343.712 725.868L374.823 778.873L369.091 782.274L330.748 722.002C330.268 721.25 329.283 721.037 328.545 721.522C327.808 722.012 327.595 723.017 328.075 723.769L367.245 785.338C367.705 786.065 368.65 786.293 369.383 785.857L377.818 780.848C378.184 780.63 378.452 780.269 378.56 779.848C378.595 779.715 378.61 779.576 378.61 779.442L378.615 779.452Z"
            fill="#014A4F"
          />
          <path
            d="M630.933 683.461C630.933 683.372 630.928 683.278 630.908 683.189C630.76 682.303 629.938 681.709 629.072 681.857L461.543 710.914C461.488 710.924 461.434 710.939 461.38 710.954L386.921 732.308L380.466 729.239C380.05 729.041 379.575 729.036 379.154 729.224L364.512 735.733H352.31C351.429 735.733 350.717 736.461 350.717 737.357C350.717 738.253 351.429 738.981 352.31 738.981H364.844C365.062 738.981 365.28 738.936 365.483 738.847L379.768 732.496L386.109 735.511C386.456 735.674 386.847 735.709 387.213 735.6L462.162 714.107L629.606 685.065C630.384 684.931 630.933 684.243 630.933 683.466V683.461Z"
            fill="#014A4F"
          />
          <path
            d="M581.012 732.971C581.012 732.882 581.007 732.793 580.992 732.699L574.458 692.985C574.315 692.099 573.493 691.505 572.622 691.653C571.755 691.802 571.171 692.633 571.315 693.524L577.849 733.239C577.993 734.125 578.814 734.719 579.685 734.57C580.463 734.436 581.017 733.748 581.017 732.971H581.012Z"
            fill="#014A4F"
          />
          <path
            d="M522.557 739.025C522.557 738.931 522.547 738.837 522.532 738.743L516.464 703.029C516.315 702.143 515.488 701.553 514.622 701.707C513.756 701.86 513.177 702.702 513.325 703.588L519.394 739.302C519.542 740.189 520.369 740.778 521.235 740.624C522.013 740.485 522.557 739.802 522.557 739.025Z"
            fill="#014A4F"
          />
          <path
            d="M482.194 743.114C482.194 743.005 482.184 742.896 482.16 742.782L475.532 710.077C475.353 709.196 474.512 708.632 473.651 708.815C472.789 708.998 472.235 709.855 472.413 710.736L479.041 743.441C479.219 744.322 480.061 744.886 480.922 744.703C481.675 744.545 482.194 743.866 482.194 743.114Z"
            fill="#014A4F"
          />
          <path
            d="M452.182 744.782C452.182 744.51 451.222 742.565 451.083 742.312L438.857 720.369C438.421 719.586 437.451 719.314 436.684 719.76C435.921 720.205 435.649 721.195 436.085 721.977L449.202 745.594C449.638 746.376 450.608 746.648 451.376 746.203C451.89 745.906 452.182 745.356 452.182 744.787V744.782Z"
            fill="#014A4F"
          />
          <path
            d="M405.489 762.671C405.489 762.384 405.414 762.092 405.256 761.825L388.144 733.184C387.683 732.417 386.703 732.174 385.956 732.64C385.203 733.105 384.966 734.105 385.426 734.872L402.538 763.513C402.999 764.28 403.979 764.523 404.726 764.058C405.216 763.751 405.489 763.216 405.489 762.667V762.671Z"
            fill="#014A4F"
          />
          <path
            d="M577.205 718.062C577.205 718.017 577.205 717.968 577.2 717.923C577.126 717.027 576.354 716.364 575.478 716.443C547.218 718.889 481.615 725.492 477.799 725.225C477.541 725.135 477.254 725.111 476.962 725.17C476.101 725.353 475.547 726.21 475.725 727.091C476.032 728.61 477.106 728.566 480.655 728.412C482.64 728.328 485.541 728.155 489.283 727.903C495.564 727.477 504.311 726.814 515.271 725.932C533.927 724.433 575.562 719.695 575.75 719.676C576.582 719.601 577.21 718.893 577.21 718.057L577.205 718.062Z"
            fill="#014A4F"
          />
          <path
            d="M632.656 695.92C632.656 695.851 632.656 695.782 632.641 695.712C632.527 694.821 631.73 694.193 630.859 694.307L574.339 701.702C573.468 701.816 572.854 702.628 572.963 703.519C573.077 704.41 573.874 705.038 574.745 704.924L631.27 697.529C632.072 697.425 632.661 696.722 632.661 695.92H632.656Z"
            fill="#014A4F"
          />
          <path
            d="M595.1 654.167C595.1 654.038 595.085 653.914 595.055 653.786C594.847 652.914 593.986 652.375 593.135 652.588L503.351 674.016C502.495 674.224 501.97 675.105 502.178 675.977C502.386 676.848 503.247 677.387 504.098 677.175L593.882 655.746C594.615 655.568 595.105 654.899 595.105 654.167H595.1Z"
            fill="#014A4F"
          />
          <path
            d="M571.325 676.62C571.325 676.457 571.3 676.293 571.251 676.135L566.419 660.359C566.157 659.503 565.261 659.023 564.424 659.29C563.583 659.557 563.118 660.468 563.38 661.324L567.687 675.393L506.351 688.381C505.489 688.565 504.935 689.426 505.118 690.307C505.296 691.183 506.143 691.747 507.004 691.564L570.058 678.209C570.498 678.115 570.879 677.838 571.107 677.442C571.251 677.189 571.325 676.907 571.325 676.62Z"
            fill="#014A4F"
          />
          <path
            d="M511.073 704.449C511.073 703.301 510.231 699.866 506.756 685.669C504.737 677.427 502.702 669.21 502.366 667.849L497.772 643.178C497.772 642.282 497.059 641.554 496.178 641.554C495.297 641.554 494.584 642.282 494.584 643.178L499.178 668.052C499.178 668.185 499.193 668.319 499.227 668.448C502.628 682.179 507.761 703.083 507.959 704.405L509.524 704.157L509.538 704.236L510.875 705.122C510.989 704.944 511.073 704.811 511.073 704.444V704.449Z"
            fill="#014A4F"
          />
          <path
            d="M384.372 678.095C384.372 677.788 384.288 677.481 384.109 677.209C379.065 669.344 361.993 643.881 354.013 646.559C347.415 648.776 310.161 665.681 304.899 671.046C301.167 674.858 287.188 680.184 282.119 681.917C281.282 682.204 280.837 683.125 281.114 683.976C281.391 684.827 282.297 685.288 283.129 685.001C283.901 684.738 302.122 678.476 307.151 673.343C311.631 668.774 347.707 652.093 355.008 649.643C358.884 648.341 371.744 663.859 381.441 678.981C381.922 679.734 382.912 679.942 383.649 679.452C384.114 679.14 384.372 678.62 384.372 678.09V678.095Z"
            fill="#014A4F"
          />
          <path
            d="M379.937 683.016C379.937 682.654 379.823 682.293 379.58 681.991C379.026 681.293 378.021 681.189 377.338 681.753L331.54 719.616C330.857 720.18 330.753 721.205 331.308 721.903C331.862 722.601 332.867 722.705 333.55 722.141L379.348 684.278C379.739 683.956 379.937 683.486 379.937 683.016Z"
            fill="#014A4F"
          />
          <path
            d="M376.66 666.948C376.66 666.641 376.575 666.334 376.397 666.057C375.912 665.304 374.927 665.102 374.19 665.592L307.206 710.528C306.473 711.023 306.265 712.028 306.75 712.78C307.235 713.533 308.22 713.736 308.958 713.246L375.942 668.309C376.407 667.997 376.66 667.478 376.66 666.948Z"
            fill="#014A4F"
          />
          <path
            d="M365.983 653.979C365.983 653.652 365.888 653.32 365.685 653.038C365.176 652.306 364.181 652.137 363.463 652.657L324.189 681.233L313.448 667.067C312.908 666.359 311.908 666.225 311.215 666.774C310.517 667.324 310.389 668.344 310.928 669.056L322.605 684.461C323.13 685.154 324.095 685.298 324.793 684.793L365.314 655.31C365.75 654.993 365.983 654.493 365.983 653.984V653.979Z"
            fill="#014A4F"
          />
          <path
            d="M334.956 802.891C334.956 802.603 334.881 802.316 334.723 802.049L303.577 750.455C303.122 749.688 302.142 749.44 301.389 749.905C300.637 750.371 300.394 751.371 300.85 752.138L331.995 803.732C332.451 804.499 333.431 804.747 334.183 804.282C334.678 803.975 334.951 803.44 334.951 802.891H334.956Z"
            fill="#014A4F"
          />
          <path
            d="M423.081 814.226C423.081 814.182 423.081 814.137 423.076 814.093L418.522 756.964C418.453 756.068 417.681 755.405 416.804 755.474C415.928 755.548 415.28 756.336 415.344 757.227L419.898 814.355C419.968 815.251 420.74 815.914 421.616 815.845C422.448 815.776 423.081 815.063 423.081 814.226Z"
            fill="#014A4F"
          />
          <path
            d="M457.216 809.742C457.216 809.687 457.216 809.633 457.207 809.578L452.311 747.104C452.222 746.213 451.445 745.559 450.569 745.649C449.692 745.738 449.054 746.535 449.138 747.426L454.034 809.9C454.123 810.791 454.9 811.444 455.776 811.355C456.598 811.271 457.211 810.563 457.211 809.742H457.216Z"
            fill="#014A4F"
          />
          <path
            d="M508.286 762.677C508.286 762.632 508.286 762.588 508.281 762.548C507.677 754.905 506.737 742.495 506.732 740.921C506.751 740.788 506.756 740.654 506.742 740.515C506.633 739.377 505.959 739.105 504.999 739.1V739.06C504.895 739.07 504.806 739.085 504.722 739.105C504.395 739.119 504.039 739.154 503.663 739.189L473.819 742.119C472.948 742.203 472.314 742.99 472.383 743.876L477.715 807.267C477.789 808.162 478.561 808.826 479.437 808.747C480.313 808.672 480.962 807.875 480.888 806.989L475.69 745.203C480.982 744.683 498.133 743 503.608 742.461C503.638 742.966 503.673 743.565 503.717 744.233C503.851 746.302 504.049 749.059 504.257 751.811C504.608 756.568 504.984 761.32 505.078 762.523L492.55 803.678C492.312 804.544 492.802 805.44 493.649 805.683C494.495 805.925 495.376 805.425 495.614 804.559L508.222 763.122C508.261 762.979 508.281 762.83 508.281 762.682L508.286 762.677Z"
            fill="#014A4F"
          />
          <path
            d="M545.669 841.352C545.669 841.288 545.669 841.224 545.659 841.159L533.69 737.654C533.586 736.763 532.794 736.124 531.922 736.228C531.046 736.332 530.423 737.139 530.527 738.035L542.496 841.54C542.6 842.431 543.392 843.07 544.263 842.966C545.075 842.867 545.669 842.169 545.669 841.352Z"
            fill="#014A4F"
          />
          <path
            d="M566.345 757.098C566.345 757.048 566.345 756.994 566.34 756.944C566.256 756.048 565.479 755.395 564.603 755.479L506.544 761.058C505.667 761.142 505.024 761.934 505.108 762.83C505.192 763.726 505.969 764.379 506.846 764.295L564.904 758.716C565.731 758.637 566.35 757.929 566.35 757.103L566.345 757.098Z"
            fill="#014A4F"
          />
          <path
            d="M637.987 823.775C613.232 826.388 575.819 830.442 572.953 831.022C572.81 831.076 572.656 831.111 572.493 831.121C571.617 831.18 570.854 830.502 570.795 829.606C570.701 828.086 572.166 827.849 572.874 827.74C573.359 827.665 574.077 827.566 574.988 827.448C576.78 827.215 579.344 826.913 582.418 826.557C587.848 825.928 595.417 825.086 604.921 824.052C621.285 822.27 637.65 820.537 637.65 820.537C638.526 820.443 639.308 821.092 639.398 821.988C639.402 822.042 639.407 822.102 639.407 822.156C639.407 822.978 638.799 823.686 637.977 823.77L637.987 823.775ZM573.894 830.036C573.958 829.843 573.993 829.631 573.978 829.393C573.993 829.616 573.963 829.834 573.894 830.036Z"
            fill="#014A4F"
          />
          <path
            d="M633.715 759.89C633.715 759.845 633.715 759.8 633.71 759.751C633.636 758.855 632.869 758.192 631.992 758.266L567.3 763.741C566.424 763.815 565.766 764.592 565.845 765.493C565.919 766.389 566.687 767.052 567.563 766.978L632.255 761.503C633.086 761.434 633.715 760.721 633.715 759.885V759.89Z"
            fill="#014A4F"
          />
          <path
            d="M639.417 836.422C639.417 836.392 639.417 836.368 639.417 836.338C639.373 835.442 638.626 834.754 637.744 834.798L606.614 836.417L604.391 793.56C604.346 792.664 603.594 791.976 602.718 792.025C601.842 792.075 601.164 792.837 601.213 793.733L603.52 838.209C603.564 839.105 604.317 839.793 605.193 839.748L637.908 838.046C638.759 838.001 639.417 837.283 639.417 836.422Z"
            fill="#014A4F"
          />
          <path
            d="M21.4997 696.163L-28.9758 775.299L-49.0036 779.289C-49.8699 779.462 -50.4342 780.314 -50.261 781.195C-50.0927 782.076 -49.2561 782.65 -48.3898 782.477L-27.6591 778.348C-27.2136 778.259 -26.8275 777.982 -26.5948 777.586L24.242 697.826C24.3955 697.564 24.4648 697.277 24.4648 696.995C24.4648 696.44 24.1876 695.901 23.6876 695.599C22.9302 695.138 21.9551 695.391 21.5046 696.163H21.4997Z"
            fill="#014A4F"
          />
          <path
            d="M-100.494 677.501C-101.35 677.714 -101.88 678.59 -101.667 679.461C-101.459 680.333 -100.598 680.867 -99.7416 680.659L21.658 649.806C22.3857 649.628 22.8757 648.959 22.8757 648.227C22.8757 648.098 22.8609 647.969 22.8312 647.841C22.6233 646.969 21.7619 646.435 20.9056 646.643L-100.494 677.496V677.501Z"
            fill="#014A4F"
          />
          <path
            d="M391.332 732.308L398.722 730.838L600.971 407.056L651.431 332.137V322.094L596.293 403.957L391.332 732.308Z"
            fill="#014A4F"
          />
          <path
            d="M132.212 160.837L132.113 160.871L-72.6055 233.879L-110.305 275.044L-141.995 322.094V332.137L-105.825 278.439L-69.4671 238.785L133.855 166.277L239.351 137.646L344.836 59.247L341.549 54.6533L236.905 132.423L132.212 160.837Z"
            fill="#014A4F"
          />
          <path
            d="M506.573 548.068L509.167 553.102C509.727 552.805 565.37 523.08 596.565 513.437C616.489 507.28 636.22 506.493 651.431 508.581V502.879C635.546 500.825 615.281 501.711 594.946 507.992C563.241 517.793 508.87 546.835 506.573 548.068Z"
            fill="#014A4F"
          />
          <path
            d="M-123.601 513.329C-120.586 514.64 -111.998 518.299 -100.113 523.357C-60.7105 540.128 21.2769 575.026 32.4144 581.08L33.9737 581.926C47.4279 589.218 54.8381 593.237 61.9265 615.829C69.609 640.312 90.909 684.328 90.9981 684.516L96.2649 683.239C96.1808 683.05 74.8016 638.198 67.2379 614.096C59.4614 589.302 50.3633 584.372 36.5923 576.907L35.0429 576.065C23.6727 569.883 -55.4536 536.203 -97.9645 518.11C-109.835 513.056 -118.413 509.408 -121.408 508.101C-126.69 505.8 -133.753 503.943 -141.995 502.879V508.582C-134.644 509.591 -128.348 511.265 -123.601 513.334V513.329Z"
            fill="#014A4F"
          />
          <path
            d="M-41.2322 564.893L-78.9316 670.606L-73.6351 672.388L-35.9604 566.739L17.0842 425.946L17.1138 425.847L89.9189 180.706L84.5829 179.058L11.8074 424.099L-41.2322 564.893Z"
            fill="#014A4F"
          />
          <path
            d="M-75.0112 236.409L-60.2749 250.66L-59.6265 250.853L178.084 321.529C180.411 323.193 193.315 332.568 198.285 339.32C204.849 348.245 218.1 347.398 233.445 346.418L234.435 346.354C249.939 345.364 349.048 370.238 350.474 369.871L350.019 364.981C349.672 365.07 249.077 339.721 234.084 340.676L233.094 340.74C218.793 341.656 207.502 342.379 202.74 335.909C196.751 327.766 181.524 317.04 180.876 316.589L180.505 316.332L180.074 316.203L-57.4237 245.586L-67.0713 236.255L-32.4607 200.169L209.789 33.1453L206.666 28.4329L-36.0544 195.784L-75.0211 236.413L-75.0112 236.409Z"
            fill="#014A4F"
          />
          <path
            d="M564.707 34.2443C566.701 38.4964 568.587 42.5109 570.067 45.7433C577.205 61.3507 639.685 133.804 651.431 146.67V138.25C632.378 116.776 581.101 56.4106 575.121 43.3376C573.631 40.0804 571.741 36.0511 569.741 31.7842C565.093 21.8841 559.296 9.52387 555.331 0L549.282 0C553.242 9.79612 559.435 22.9979 564.712 34.2394L564.707 34.2443Z"
            fill="#014A4F"
          />
          <path
            d="M374.818 114.801C361.532 124.285 349.281 128.255 326.254 130.527C304.102 132.715 241.029 134.477 240.623 134.512L241.118 140.18C241.524 140.145 304.617 138.378 326.788 136.19C350.905 133.809 363.829 129.587 378.016 119.464C386.921 113.108 436.54 69.6122 476.413 34.665C491.822 21.1563 505.979 8.75153 516.043 0.00976562L507.449 0.00976562C497.916 8.32087 485.793 18.9436 472.779 30.3485C433.001 65.2166 383.496 108.609 374.818 114.801Z"
            fill="#014A4F"
          />
          <path
            d="M-141.881 146.793C-127.833 162.148 -114.621 163.431 -102.291 163.431C-92.3363 163.431 -65.7299 162.728 -33.6982 156.203C-11.0766 151.6 -0.08255 180.577 6.48615 197.893C8.00085 201.877 9.3027 205.323 10.5006 207.684L15.4556 205.07C14.3963 202.986 13.1439 199.675 11.6886 195.843C4.96153 178.107 -7.56702 145.086 -34.7872 150.63C-66.3289 157.055 -92.4997 157.748 -102.286 157.748C-113.849 157.748 -125.264 156.614 -137.797 142.918C-138.916 141.695 -140.332 140.121 -141.985 138.255V146.675C-141.951 146.714 -141.906 146.759 -141.871 146.798L-141.881 146.793Z"
            fill="#014A4F"
          />
          <path
            d="M386.778 851.846L382.441 855.425C383.446 856.697 436.357 923.414 488.852 990.002H496.005C442.584 922.226 387.802 853.138 386.778 851.846Z"
            fill="#014A4F"
          />
          <path
            d="M630.596 158.98C634.25 159.911 638.393 160.965 643.204 162.361C646.085 163.198 648.813 163.93 651.431 164.574V158.723C649.288 158.173 647.065 157.569 644.734 156.891C639.833 155.471 635.645 154.406 631.948 153.466C615.33 149.238 609.732 147.818 596.095 128.498C588.086 117.152 542.56 59.0785 496.005 0.00976562L488.852 0.00976562C536.318 60.217 583.442 120.31 591.57 131.819C606.401 152.832 613.484 154.634 630.601 158.985L630.596 158.98Z"
            fill="#014A4F"
          />
          <path
            d="M341.435 370.817C311.344 394.582 267.803 402.354 248.246 405.977L249.246 411.576C269.397 407.838 313.68 399.928 344.851 375.317C374.739 351.715 491.401 259.699 491.401 259.699L491.476 259.639L617.078 153.377L613.519 149.001L487.951 255.234C485.575 257.105 371.012 347.468 341.435 370.827V370.817Z"
            fill="#014A4F"
          />
          <path
            d="M329.199 138.081C336.51 141.809 344.064 145.66 337.624 164.217C329.03 188.948 333.876 207.991 344.484 230.706C348.905 240.171 358.518 257.575 368.695 276.004C381.199 298.641 394.123 322.049 400.395 335.904C410.048 357.234 427.729 368.396 440.639 376.544C443.708 378.48 446.361 380.153 448.554 381.747C460.063 390.092 562.043 454.408 566.38 457.141L569.31 452.305C568.251 451.636 463.147 385.35 451.781 377.109C449.44 375.411 446.589 373.609 443.569 371.703C431.264 363.932 414.404 353.294 405.454 333.519C399.088 319.455 386.104 295.943 373.551 273.212C363.423 254.872 353.855 237.547 349.519 228.261C339.267 206.308 334.896 189.101 342.881 166.118C350.974 142.823 339.371 136.908 331.694 132.998C330.332 132.305 329.045 131.646 328.05 130.983L324.996 135.745C326.263 136.591 327.694 137.319 329.204 138.091L329.199 138.081Z"
            fill="#014A4F"
          />
          <path
            d="M428.135 810.009C412.607 811.989 401.286 819.686 393.519 833.546C386.773 845.595 377.546 859.643 360.31 872.493C345.118 883.814 272.882 959.782 244.236 990.002H252.201C284.812 955.545 349.88 887.314 363.602 877.082C381.689 863.603 391.322 848.936 398.366 836.368C405.202 824.171 415.166 817.394 428.833 815.652C440.104 814.216 568.018 800.183 651.436 791.273V785.556C567.919 794.476 439.441 808.563 428.14 810.004L428.135 810.009Z"
            fill="#014A4F"
          />
          <path
            d="M232.564 12.3057L233.47 19.8446C233.673 19.6416 241.039 11.7958 252.196 0.00976562L244.231 0.00976562C237.509 7.10316 233.178 11.6819 232.559 12.3106L232.564 12.3057Z"
            fill="#014A4F"
          />
          <path
            d="M-71.1947 778.274C-75.328 778.516 -104.013 781.501 -141.995 785.56V791.278C-103.86 787.204 -75.0211 784.199 -70.8779 783.956C-58.7701 783.249 -51.449 783.966 -45.0189 789.867C-40.7272 793.807 35.6022 854.579 102.947 908.197C150.988 946.446 192.479 979.483 198.785 984.685C201.29 986.754 203.012 988.472 204.161 990.002H210.64C209.348 987.106 206.809 983.992 202.295 980.265C195.949 975.028 154.443 941.976 106.378 903.713C42.4382 852.807 -37.1435 789.446 -41.2916 785.64C-49.1374 778.437 -57.8544 777.497 -71.1997 778.274H-71.1947Z"
            fill="#014A4F"
          />
          <path
            d="M205.393 10.2763C204.656 13.2116 203.73 16.8648 204.275 21.5722C205.715 34.0562 225.109 83.4873 226.778 87.7196C228.619 96.1643 257.76 229.662 263.502 244.299C264.541 246.942 265.269 249.274 265.913 251.328C269.145 261.644 320.982 388.518 320.982 388.518L326.555 383.519C326.555 383.519 273.986 258.397 271.229 249.596C270.556 247.452 269.793 245.022 268.685 242.19C263.051 227.82 232.485 87.675 232.178 86.2593L232.044 85.8138C231.837 85.2841 211.21 32.9326 209.824 20.9089C209.403 17.2509 210.116 14.4244 210.804 11.692C211.853 7.529 212.423 3.96993 210.65 0L204.171 0C207.032 3.79668 206.374 6.42019 205.403 10.2713L205.393 10.2763Z"
            fill="#014A4F"
          />
          <path
            d="M435.555 67.0778L439.381 62.9347L373.912 0.00488281L365.779 0.00488281L435.555 67.0778Z"
            fill="#014A4F"
          />
          <path
            d="M319.566 725.121L109.165 990.002H116.342L323.897 728.699L319.566 725.121Z"
            fill="#014A4F"
          />
          <path
            d="M-15.3979 156.822L-11.0666 160.406L116.342 0.00488281L109.165 0.00488281L-15.3979 156.822Z"
            fill="#014A4F"
          />
          <path
            d="M154.675 152.456C154.591 153.842 154.507 155.278 154.334 156.664L154.245 157.381L154.502 158.055C154.7 158.569 159.422 170.737 167.684 174.103C175.47 177.28 190.613 173.266 203.973 169.722L205.577 169.296C216.848 166.316 221.986 154.689 221.986 145.145C221.986 142.898 221.669 140.888 221.07 139.324C218.323 132.176 211.487 127.444 202.314 126.345C193.266 125.261 177.282 129.498 166.263 133.898C155.522 138.185 155.076 145.764 154.68 152.451L154.675 152.456ZM201.661 131.998C208.725 132.844 213.908 136.274 215.878 141.403C216.076 141.917 216.407 143.091 216.407 145.145C216.407 151.337 213.15 161.416 204.176 163.792L202.572 164.217C190.662 167.375 175.842 171.306 169.758 168.821C165.08 166.91 161.328 159.886 159.957 156.619C160.096 155.312 160.17 154.035 160.244 152.798C160.62 146.392 160.873 142.16 168.293 139.2C178.757 135.022 194.008 131.082 201.661 131.998Z"
            fill="#014A4F"
          />
        </g>
      </g>
      <mask
        id="mask1_269_18"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="154"
        y="353"
        width="300"
        height="214"
      >
        <rect x="154" y="353" width="300" height="214" fill="#8D5151" />
      </mask>
      <g mask="url(#mask1_269_18)">
        <path
          d="M323.998 503.133C336.375 515.493 353.162 522.438 370.667 522.438C388.171 522.438 404.958 515.493 417.336 503.133C429.713 490.772 436.667 474.007 436.667 456.526C436.667 439.045 429.713 422.28 417.336 409.919L397.985 429.244C405.23 436.48 409.3 446.293 409.3 456.526C409.3 466.758 405.23 476.572 397.985 483.808C390.74 491.043 380.913 495.108 370.667 495.108C360.42 495.108 350.594 491.043 343.348 483.808L323.998 503.133Z"
          fill="white"
        />
        <path
          d="M349.453 477.711C355.079 483.33 362.71 486.486 370.667 486.486C378.623 486.486 386.254 483.33 391.88 477.711C397.506 472.092 400.667 464.472 400.667 456.526C400.667 448.58 397.506 440.96 391.88 435.341L378.595 448.608C380.698 450.708 381.879 453.556 381.879 456.526C381.879 459.496 380.698 462.344 378.595 464.444C376.492 466.544 373.64 467.724 370.667 467.724C367.693 467.724 364.841 466.544 362.738 464.444L349.453 477.711Z"
          fill="#11B0B9"
        />
        <path
          d="M259.88 477.711C254.254 483.33 246.623 486.486 238.667 486.486C230.71 486.486 223.08 483.33 217.454 477.711C211.827 472.092 208.667 464.472 208.667 456.526C208.667 448.58 211.827 440.96 217.454 435.341L230.738 448.608C228.635 450.708 227.454 453.556 227.454 456.526C227.454 459.496 228.635 462.344 230.738 464.444C232.841 466.544 235.693 467.724 238.667 467.724C241.641 467.724 244.493 466.544 246.595 464.444L259.88 477.711Z"
          fill="#11B0B9"
        />
        <path
          d="M285.336 503.133C272.958 515.493 256.171 522.438 238.667 522.438C221.162 522.438 204.375 515.493 191.998 503.133C179.62 490.772 172.667 474.007 172.667 456.526C172.667 439.045 179.62 422.28 191.998 409.919L211.349 429.244C204.103 436.48 200.033 446.293 200.033 456.526C200.033 466.758 204.103 476.572 211.349 483.808C218.594 491.043 228.42 495.108 238.667 495.108C248.913 495.108 258.74 491.043 265.985 483.808L285.336 503.133Z"
          fill="white"
        />
        <rect
          width="48.1852"
          height="48.1852"
          transform="matrix(0.707578 -0.706635 0.707578 0.706635 270 527.193)"
          fill="white"
        />
        <path
          d="M234 391.516L304.711 423.242L375.421 391.516L304.711 462.133L234 391.516Z"
          fill="white"
        />
        <path
          d="M454 362.731L417.645 362.731L365.468 414.839L286 494.2L304.178 512.354L454 362.731Z"
          fill="white"
        />
        <path
          d="M154 362.731L190.355 362.731L242.532 414.839L322 494.2L303.822 512.354L154 362.731Z"
          fill="white"
        />
        <path
          d="M164.16 382.215L210.436 428.429L206 434.555L186.667 416.579L164.16 382.215Z"
          fill="white"
        />
        <path
          d="M446.231 380.489L398.869 427.787L403.285 433.934L423.333 415.914L446.231 380.489Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
