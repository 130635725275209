import { useSettings } from "../../../../application/SettingsProvider";
import { Container } from "../../components/Container";
import { Box } from "../../../../components";
import { COLORS } from "../../../../constants";
import { Item } from "./Item";
import React from "react";

export function Possibilities() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.WHITE} id="possibilities">
      <Container>
        <Box flexDirection={"row"} justifyContent="space-between">
          <Box justifyContent="center" paddingRight={65}>
            {settings?.possibilities?.items?.map((item, index) => (
              <Box marginTop={35} key={index}>
                <Item text={item} />
              </Box>
            ))}
          </Box>
          <Box>
            <Box backgroundColor={COLORS.TURQUOISE}>
              <img src="image/imagePossibilities.png" alt="dron" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
