import { useSettings } from "../../../application/SettingsProvider";
import { Box } from "../../../components";
import { Item } from "./Item";
import { COLORS } from "../../../constants";
import React from "react";

export function Possibilities() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.WHITE} id="possibilities">
      <Box flexDirection={"column"}>
        <Box
          paddingTop={40}
          paddingBottom={40}
          paddingLeft={16}
          paddingRight={16}
        >
          {settings?.possibilities?.items?.map((item, index) => (
            <Box marginTop={24} key={index}>
              <Item text={item} />
            </Box>
          ))}
        </Box>
        <Box backgroundColor={COLORS.TURQUOISE}>
          <img src="image/imagePossibilitiesSmall.png" alt="drons and map" />
        </Box>
      </Box>
    </Box>
  );
}
