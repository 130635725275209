import React from "react";
import { Container } from "./components/Container";
import { Box, Typography } from "../../components";
import { Link } from "../../components/Link";
import { useSettings } from "../../application/SettingsProvider";
import { COLORS } from "../../constants";
import { ContactImageSmall } from "../../svg/ContactImageSmall";
import { FilinSmall } from "../../svg/FilinSmall";

export function Contacts() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.BLACK} id="contacts">
      <Container>
        <Box
          flexDirection={"column"}
          alignItems="center"
          gap={48}
          paddingTop={60}
          paddingBottom={60}
        >
          <FilinSmall />

          <Typography
            size={32}
            lineHeight={42}
            weight={400}
            color={COLORS.WHITE}
            style={{ textAlign: "center" }}
          >
            {settings?.contacts?.title}
          </Typography>
          <Link href={`mailto:${settings?.contacts?.mail}`}>
            <Typography
              size={40}
              lineHeight={30}
              weight={900}
              color={COLORS.TURQUOISE}
            >
              {settings?.contacts?.mail}
            </Typography>
          </Link>
        </Box>
      </Container>
      <ContactImageSmall style={{ maxWidth: "100%", height: "auto" }} />
    </Box>
  );
}
