import React from "react";
import { Box } from "../../components";
import { Contacts } from "./Contacts";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Home } from "./Home";
import { Info } from "./Info";
import { Possibilities } from "./Possibilities/Possibilities";

export function Mobile() {
  return (
    <Box position="relative" style={{ minHeight: "100%" }}>
      <Header />
      <Box overflow="auto">
        <Home />
        <Info />
        <Possibilities />
        <Contacts />
        <Footer />
      </Box>
    </Box>
  );
}
