import React from "react";
import { Container } from "../../components/Container";
import { Box, Typography } from "../../../../components";
import { Link } from "../../../../components/Link";
import { useSettings } from "../../../../application/SettingsProvider";
import { COLORS } from "../../../../constants";
import { ContactImage } from "../../../../svg/ContactImage";
import { Filin } from "../../../../svg/Filin";

export function Contacts() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.BLACK} id="contacts">
      <Container>
        <Box flexDirection={"row"} justifyContent="space-between">
          <Box flex={0.4}>
            <ContactImage />
          </Box>
          <Box
            flex={0.5}
            flexDirection={"column"}
            justifyContent="center"
            gap={60}
          >
            <Box>
              <Filin />
            </Box>
            <Typography
              size={32}
              lineHeight={40}
              weight={400}
              color={COLORS.WHITE}
            >
              {settings?.contacts?.title}
            </Typography>
            <Link href={`mailto:${settings?.contacts?.mail}`}>
              <Typography
                size={60}
                lineHeight={30}
                weight={900}
                color={COLORS.TURQUOISE}
              >
                {settings?.contacts?.mail}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
