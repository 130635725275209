import React from "react";
import { useSettings } from "../../application/SettingsProvider";
import { Box, Typography } from "../../components";
import { LogoWhite } from "../../svg/LogoWhite";
import { COLORS } from "../../constants";

export function Footer() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.TURQUOISE}>
      <Box
        paddingTop={24}
        paddingBottom={24}
        flexDirection={"column"}
        alignItems={"center"}
        gap={16}
      >
        <LogoWhite />
        <Typography color={COLORS.WHITE} size={16} weight={500} lineHeight={19}>
          {settings?.footer.copyright}
        </Typography>
      </Box>
    </Box>
  );
}
