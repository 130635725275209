import React from "react";
import { Box } from "../../components";
import { Header } from "./blocks/Header/Header";
import { Footer } from "./blocks/Footer/Footer";
import { Home } from "./blocks/Home/Home";
import { Possibilities } from "./blocks/Possibilities/Possibilities";
import { Contacts } from "./blocks/Contacts/Contacts";
import { Info } from "./blocks/Info/Info";

export function Desktop() {
  return (
    <Box overflow="auto">
      <Header />
      <Home />
      <Info />
      <Possibilities />
      <Contacts />
      <Footer />
    </Box>
  );
}
