import React from "react";

export function Logo() {
  return (
    <svg
      width="131"
      height="40"
      viewBox="0 0 131 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66 29H70.314V22.52H76.1108V18.6629H70.314V14.8571H76.9197V11H66V29Z"
        fill="white"
      />
      <path d="M80.5 29H85.0126L85 18.5L80.5 14.8571V29Z" fill="white" />
      <path
        d="M90.1449 29H102.143V25.1429H94.4588V11H90.1449V29Z"
        fill="white"
      />
      <path d="M105 29H109.421L109.258 18.5L105 15V29Z" fill="white" />
      <path
        d="M131 29V11H126.956V21.8514L118.004 11H114.553V29H118.597V18.2L127.549 29H131Z"
        fill="white"
      />
      <path d="M80.5 11H85V16L80.5 12.5V11Z" fill="#11B0B9" />
      <path d="M105 11H109.5V16L105 12.5V11Z" fill="#11B0B9" />
      <mask
        id="mask0_32_24"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="56"
        height="40"
      >
        <rect width="56" height="40" fill="#8D5151" />
      </mask>
      <g mask="url(#mask0_32_24)">
        <path
          d="M31.7329 28.0622C34.0434 30.3726 37.177 31.6706 40.4445 31.6706C43.7119 31.6706 46.8456 30.3726 49.156 28.0622C51.4665 25.7517 52.7645 22.6181 52.7645 19.3506C52.7645 16.0832 51.4665 12.9495 49.156 10.6391L45.5439 14.2512C46.8963 15.6037 47.6561 17.438 47.6561 19.3506C47.6561 21.2633 46.8963 23.0976 45.5439 24.45C44.1914 25.8025 42.3571 26.5623 40.4445 26.5623C38.5318 26.5623 36.6975 25.8025 35.3451 24.45L31.7329 28.0622Z"
          fill="white"
        />
        <path
          d="M36.4846 23.3105C37.5348 24.3607 38.9592 24.9507 40.4444 24.9507C41.9296 24.9507 43.354 24.3607 44.4042 23.3105C45.4544 22.2603 46.0444 20.8359 46.0444 19.3507C46.0444 17.8655 45.4544 16.4411 44.4042 15.3909L41.9244 17.8707C42.317 18.2632 42.5375 18.7956 42.5375 19.3507C42.5375 19.9058 42.317 20.4382 41.9244 20.8307C41.5319 21.2232 40.9995 21.4437 40.4444 21.4437C39.8893 21.4437 39.3569 21.2232 38.9644 20.8307L36.4846 23.3105Z"
          fill="#11B0B9"
        />
        <path
          d="M19.7643 23.3105C18.7141 24.3607 17.2897 24.9507 15.8045 24.9507C14.3193 24.9507 12.8949 24.3607 11.8447 23.3105C10.7945 22.2603 10.2045 20.8359 10.2045 19.3507C10.2045 17.8655 10.7945 16.4411 11.8447 15.3909L14.3245 17.8707C13.9319 18.2632 13.7114 18.7956 13.7114 19.3507C13.7114 19.9058 13.9319 20.4382 14.3245 20.8307C14.717 21.2232 15.2494 21.4437 15.8045 21.4437C16.3596 21.4437 16.892 21.2232 17.2845 20.8307L19.7643 23.3105Z"
          fill="#11B0B9"
        />
        <path
          d="M24.516 28.0622C22.2055 30.3726 19.0719 31.6706 15.8044 31.6706C12.537 31.6706 9.40333 30.3726 7.09288 28.0622C4.78243 25.7517 3.48444 22.6181 3.48444 19.3506C3.48444 16.0832 4.78243 12.9495 7.09288 10.6391L10.705 14.2512C9.3526 15.6037 8.5928 17.438 8.5928 19.3506C8.5928 21.2633 9.3526 23.0976 10.705 24.45C12.0575 25.8025 13.8918 26.5623 15.8044 26.5623C17.7171 26.5623 19.5514 25.8025 20.9038 24.45L24.516 28.0622Z"
          fill="white"
        />
        <rect
          x="21.6533"
          y="32.5594"
          width="9.00056"
          height="9.00056"
          transform="rotate(-45 21.6533 32.5594)"
          fill="white"
        />
        <path
          d="M14.9333 7.19934L28.1327 13.1294L41.332 7.19934L28.1327 20.3987L14.9333 7.19934Z"
          fill="white"
        />
        <path
          d="M56 1.81884L49.2137 1.81883L39.474 11.5586L24.64 26.3926L28.0331 29.7857L56 1.81884Z"
          fill="white"
        />
        <path
          d="M0 1.81884L6.78626 1.81883L16.526 11.5586L31.36 26.3926L27.9669 29.7857L0 1.81884Z"
          fill="white"
        />
        <path
          d="M1.89665 5.46071L10.5348 14.0989L9.70668 15.244L6.09781 11.884L1.89665 5.46071Z"
          fill="white"
        />
        <path
          d="M54.5497 5.13804L45.7089 13.9789L46.5333 15.1277L50.2756 11.7595L54.5497 5.13804Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
