import { Container } from "../../components/Container";
import { useSettings } from "../../../../application/SettingsProvider";
import { Box, Typography } from "../../../../components";
import { COLORS } from "../../../../constants";
import React from "react";
import { Video } from "../../components/Video";
import { MapHome } from "../../../../svg/MapHome";

export function Home() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.BLACK} position="relative" id="home">
      <Container>
        <Box flexDirection={"row"} justifyContent="space-between">
          <Box
            position="absolute"
            style={{
              left: "-5%",
              top: 0,
              width: "100%",
              height: "auto",
              maxHeight: "100%",
            }}
          >
            <MapHome />
          </Box>

          <Box
            flex={0.47}
            flexDirection={"column"}
            justifyContent={"center"}
            zIndex={2}
          >
            <Typography
              size={68}
              lineHeight={80}
              weight={700}
              color={COLORS.WHITE}
            >
              {settings?.home?.title1}
              <Typography
                size={68}
                lineHeight={80}
                weight={700}
                color={COLORS.TURQUOISE}
              >
                {settings?.home?.title2}
              </Typography>
            </Typography>

            <Box marginTop={32}>
              <Typography
                size={20}
                lineHeight={30}
                weight={400}
                color={COLORS.WHITE}
              >
                {settings?.home?.description}
              </Typography>
            </Box>
          </Box>
          <Box
            flex={0.49}
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={70}
            paddingBottom={106}
            zIndex={2}
          >
            <Video />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
