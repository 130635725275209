import React from "react";
import classNames from "classnames";
import styles from "./styles.module.css";

import { PropsWithChildren, CSSProperties } from "react";

type Props = PropsWithChildren<{
  onPress?: () => void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
}>;

export function TouchableOpacity({
  children,
  onPress,
  style,
  className,
}: Props) {
  return (
    <div
      onClick={onPress}
      className={classNames(styles.container)}
      style={style}
    >
      {children}
    </div>
  );
}
