import React from "react";

export function FilinSmall() {
  return (
    <svg
      width="290"
      height="82"
      viewBox="0 0 290 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 81V81.5H1H20.1141H20.6141V81V52.7H45.7988H46.2988V52.2V35.0571V34.5571H45.7988H20.6141V18.6429H49.3827H49.8827V18.1429V1V0.5H49.3827H1H0.5V1V81ZM64.7462 81V81.5H65.2462H85.2403H85.7409L85.7403 80.9994L85.6846 34.3327L85.6843 34.095L85.4998 33.9452L65.5613 17.7547L64.7462 17.0928V18.1429V81ZM107.48 81V81.5H107.98H161.142H161.642V81V63.8572V63.3572H161.142H127.594V1V0.5H127.094H107.98H107.48V1V81ZM173.3 81V81.5H173.8H193.387H193.895L193.887 80.9923L193.166 34.3256L193.162 34.0946L192.984 33.9476L174.118 18.392L173.3 17.7175V18.7778V81ZM289 81.5H289.5V81V1V0.5H289H271.08H270.58V1V47.8334L231.805 0.682413L231.655 0.5H231.419H216.127H215.627V1V81V81.5H216.127H234.047H234.547V81V34.3901L273.323 81.3185L273.473 81.5H273.709H289Z"
        stroke="white"
      />
      <path
        d="M65.2462 1H85.1847V23.2222L65.2462 7.66667V1Z"
        stroke="#14B2BB"
      />
      <path d="M173.8 1H193.739V23.2222L173.8 7.66667V1Z" stroke="#14B2BB" />
    </svg>
  );
}
