import React from "react";
import { PropsWithChildren, CSSProperties } from "react";

type Props = PropsWithChildren<{
  style?: CSSProperties;
}>;

export function MapHome({ style }: Props) {
  return (
    <svg
      width="790"
      height="876"
      viewBox="0 0 790 876"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_33_620"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="790"
        height="876"
      >
        <rect width="790" height="876" fill="url(#paint0_linear_33_620)" />
      </mask>
      <g mask="url(#mask0_33_620)">
        <g clip-path="url(#clip0_33_620)">
          <path
            d="M790 193.344L790 -439.796L6.10352e-05 -439.796L3.33597e-05 193.344L790 193.344Z"
            fill="black"
          />
          <path
            d="M482.351 37.7481C474.404 44.396 442.405 33.9561 431.89 30.5235C429.836 29.852 428.354 29.3701 427.497 29.1331C423.271 27.9718 423.559 26.9447 424.938 21.9953C425.048 21.6003 425.163 21.1895 425.277 20.7629C426.806 15.146 459.611 -60.2761 459.943 -61.0384C460.223 -61.6862 459.919 -62.4328 459.259 -62.7053C458.596 -62.9818 457.837 -62.6817 457.557 -62.0338C456.202 -58.9173 424.345 14.3165 422.769 20.1112C422.654 20.5339 422.54 20.9407 422.433 21.3278C421.071 26.214 420.087 29.7414 426.794 31.5821C427.592 31.7994 429.125 32.301 431.068 32.937C445.553 37.6652 462.072 42.4526 473.326 42.4526C477.955 42.4526 481.696 41.6429 484.034 39.6836C484.579 39.2294 484.642 38.4236 484.18 37.8903C483.714 37.357 482.897 37.2939 482.351 37.7481Z"
            fill="#014A4F"
          />
          <path
            d="M493.573 22.3667L418.519 -0.397394C417.843 -0.602796 417.117 -0.235456 416.899 0.428151L411.796 15.8807C411.579 16.5483 411.954 17.2632 412.633 17.4805C413.317 17.6938 414.047 17.3264 414.265 16.6588L418.977 2.40712L492.803 24.796C492.93 24.8355 493.06 24.8513 493.186 24.8513C493.739 24.8513 494.253 24.4997 494.427 23.9546C494.64 23.2831 494.257 22.5721 493.57 22.3627L493.573 22.3667Z"
            fill="#014A4F"
          />
          <path
            d="M305.789 -52.7275C305.255 -53.1976 304.438 -53.1541 303.956 -52.6327C303.478 -52.1113 303.521 -51.3055 304.051 -50.8354L422.295 24.5274C422.544 24.7446 422.852 24.8552 423.16 24.8552C423.516 24.8552 423.867 24.713 424.124 24.4365C424.602 23.9151 424.558 23.1093 424.029 22.6392L305.785 -52.7236L305.789 -52.7275Z"
            fill="#014A4F"
          />
          <path
            d="M433.229 -2.02089C431.783 -2.7082 397.761 -18.8481 390.331 -21.5341C387.578 -22.5295 385.793 -23.7066 385.615 -25.1523C385.18 -28.7548 401.435 -58.4314 403.56 -60.8686C404.026 -61.4019 403.963 -62.2037 403.418 -62.6619C402.873 -63.1162 402.055 -63.0569 401.589 -62.5237C398.84 -59.3755 382.02 -29.45 383.107 -24.5124C383.616 -22.1977 385.785 -20.4676 389.435 -19.1483C396.746 -16.5096 431.744 0.100246 432.095 0.266148C432.277 0.353046 432.47 0.392574 432.66 0.392574C433.142 0.392574 433.604 0.127895 433.829 -0.326359C434.141 -0.958366 433.873 -1.71674 433.229 -2.02485L433.229 -2.02089Z"
            fill="#014A4F"
          />
          <path
            d="M508.84 3.47359C508.24 3.25239 448.756 -18.5755 445.099 -19.9857C440.888 -21.6091 436.638 -25.0338 433.73 -29.1458C433.011 -30.157 432.316 -31.2235 431.641 -32.2545C428.852 -36.5166 426.217 -40.5417 421.983 -41.5805C421.288 -41.7504 420.585 -41.3356 420.411 -40.6562C420.237 -39.9768 420.66 -39.2856 421.355 -39.1157C424.594 -38.3218 426.956 -34.7075 429.46 -30.8838C430.148 -29.8331 430.855 -28.7508 431.601 -27.7001C434.793 -23.1931 439.482 -19.4248 444.151 -17.6235C447.828 -16.2055 505.483 4.95091 507.936 5.85152C508.086 5.90682 508.24 5.93451 508.39 5.93451C508.915 5.93451 509.409 5.61847 509.603 5.10891C509.856 4.44926 509.512 3.71849 508.844 3.47359L508.84 3.47359Z"
            fill="#014A4F"
          />
          <path
            d="M556.454 -48.343C556.071 -48.9276 555.277 -49.0974 554.676 -48.7301C543.067 -41.5845 512.055 -22.7784 508.007 -21.5539C502.082 -19.7684 494.17 -9.67606 492.033 -1.17952C491.863 -0.49617 492.286 0.191147 492.981 0.361001C493.083 0.384701 493.19 0.396538 493.293 0.396538C493.873 0.396538 494.407 0.00940377 494.549 -0.57125C496.607 -8.73993 504.215 -17.75 508.769 -19.1246C513.564 -20.5703 548.214 -41.7622 554.973 -45.9098L566.799 -27.9963C567.186 -27.4078 567.992 -27.238 568.592 -27.6211C569.193 -28.0043 569.367 -28.7903 568.976 -29.3789L556.458 -48.347L556.454 -48.343Z"
            fill="#014A4F"
          />
          <path
            d="M472.591 -9.83803C471.908 -10.0395 471.181 -9.65631 470.979 -8.98085L463.79 14.6799C463.585 15.3514 463.976 16.0624 464.663 16.2599C464.786 16.2955 464.912 16.3112 465.034 16.3112C465.591 16.3112 466.109 15.9557 466.279 15.4027L473.468 -8.25801C473.673 -8.92952 473.282 -9.64053 472.595 -9.83803L472.591 -9.83803Z"
            fill="#014A4F"
          />
          <path
            d="M484.947 35.54L473.732 31.5505C474.037 30.2233 474.57 27.877 475.079 25.5425C475.534 23.4727 475.881 21.8136 476.11 20.6207C476.569 18.2507 476.762 17.2711 475.538 16.8998C474.854 16.6944 474.127 17.0657 473.914 17.7372C473.851 17.9308 473.839 18.1322 473.875 18.3218C473.756 19.6095 472.117 27.0396 470.948 32.0838C470.801 32.7079 471.157 33.3399 471.769 33.5611L484.054 37.9338C484.2 37.9851 484.35 38.0088 484.496 38.0088C485.026 38.0088 485.523 37.6889 485.717 37.1714C485.962 36.5118 485.614 35.781 484.939 35.544L484.947 35.54ZM474.771 19.3251C474.637 19.2817 474.511 19.2224 474.4 19.1434C474.511 19.2224 474.633 19.2817 474.771 19.3251Z"
            fill="#014A4F"
          />
          <path
            d="M453.943 10.3467C453.271 10.1097 452.525 10.4533 452.284 11.113L443.4 35.3939C443.159 36.0535 443.511 36.7843 444.182 37.0213C444.328 37.0726 444.474 37.0964 444.621 37.0964C445.154 37.0964 445.652 36.7725 445.841 36.255L454.725 11.9741C454.966 11.3144 454.614 10.5837 453.943 10.3467Z"
            fill="#014A4F"
          />
          <path
            d="M434.868 22.3706C429.539 24.0849 428.876 30.8908 428.848 31.1831C428.785 31.8823 429.314 32.4985 430.029 32.5617C430.069 32.5617 430.104 32.5656 430.144 32.5656C430.807 32.5656 431.376 32.068 431.435 31.4044C431.439 31.353 431.984 25.981 435.678 24.788C436.357 24.5708 436.728 23.8519 436.507 23.1843C436.286 22.5167 435.551 22.1534 434.872 22.3746L434.868 22.3706Z"
            fill="#014A4F"
          />
          <path
            d="M474.321 22.4259L451.008 19.5622C450.293 19.4753 449.649 19.969 449.558 20.6642C449.467 21.3594 449.973 21.9954 450.684 22.0823L473.997 24.9461C474.052 24.954 474.108 24.9579 474.159 24.9579C474.803 24.9579 475.36 24.4879 475.443 23.844C475.534 23.1488 475.028 22.5128 474.317 22.4259L474.321 22.4259Z"
            fill="#014A4F"
          />
          <path
            d="M303.434 -40.0005C309.853 -62.4407 302.261 -73.3388 297.17 -77.8379C291.213 -83.0954 251.092 -104.193 249.781 -104.809C249.133 -105.113 248.24 -104.923 247.932 -104.287C247.624 -103.655 247.897 -102.893 248.541 -102.593C248.857 -102.443 289.751 -80.9664 295.432 -75.9498C301.112 -70.9372 306.673 -60.7501 300.938 -40.6918C292.291 -10.4542 299.125 13.8978 321.257 31.7085L312.622 55.3258C312.381 55.9854 312.733 56.7162 313.404 56.9532C313.55 57.0045 313.697 57.0283 313.843 57.0283C314.376 57.0283 314.874 56.7044 315.063 56.1869L324.01 31.7203C324.196 31.2108 324.034 30.638 323.599 30.2983C301.787 13.1394 295.005 -10.5174 303.434 -40.0045L303.434 -40.0005Z"
            fill="#014A4F"
          />
          <path
            d="M416.887 -9.68396C416.287 -10.0671 415.485 -9.90124 415.09 -9.31268L402.49 9.43819C402.098 10.0267 402.268 10.8128 402.869 11.1959C403.086 11.3342 403.335 11.4013 403.576 11.4013C403.998 11.4013 404.413 11.1999 404.662 10.8247L417.263 -7.92622C417.654 -8.51477 417.484 -9.30081 416.883 -9.68396L416.887 -9.68396Z"
            fill="#014A4F"
          />
          <path
            d="M291.359 77.7304C291.256 77.6632 281.093 70.9007 277.597 68.6176C277.04 68.2542 276.495 67.9185 275.978 67.5985C274.548 66.7177 273.067 65.8052 272.924 65.1377C272.877 64.9204 272.988 64.6123 273.248 64.2252C275.148 61.4286 277.23 54.6345 277.38 50.8622L286.331 38.625C286.749 38.0562 286.615 37.2582 286.035 36.8514C285.45 36.4406 284.644 36.571 284.225 37.1437L275.042 49.7009C274.884 49.9181 274.797 50.1749 274.797 50.4435C274.797 53.6154 272.786 60.3226 271.091 62.819C270.428 63.7986 270.191 64.7585 270.388 65.6709C270.764 67.3852 272.454 68.432 274.595 69.7513C275.093 70.0594 275.618 70.3833 276.155 70.7348C279.643 73.014 289.799 79.7686 289.902 79.8357C290.123 79.9819 290.38 80.053 290.628 80.053C291.043 80.053 291.454 79.8595 291.703 79.4961C292.106 78.9154 291.952 78.1254 291.359 77.7304Z"
            fill="#014A4F"
          />
          <path
            d="M296.415 43.3493C295.835 42.9345 295.025 43.0609 294.602 43.6297L275.828 68.9297C275.405 69.4985 275.535 70.2925 276.112 70.7072C276.341 70.8731 276.61 70.9521 276.874 70.9521C277.273 70.9521 277.672 70.7704 277.925 70.4268L296.7 45.1268C297.122 44.558 296.992 43.764 296.415 43.3493Z"
            fill="#014A4F"
          />
          <path
            d="M306.689 49.9221C306.045 49.6061 305.267 49.8629 304.947 50.4909C302.787 54.7293 289.831 78.7258 287.67 81.1511C284.652 84.5324 286.896 96.1139 287.374 98.401C287.5 99.0014 288.041 99.4161 288.642 99.4161C288.728 99.4161 288.815 99.4082 288.906 99.3885C289.605 99.2463 290.06 98.5747 289.913 97.8874C288.827 92.7129 288.021 84.6153 289.617 82.822C292.441 79.658 306.938 52.2605 307.262 51.6246C307.582 50.9965 307.321 50.2342 306.681 49.9182L306.689 49.9221Z"
            fill="#014A4F"
          />
          <path
            d="M295.21 71.5012L282.195 62.4872C281.61 62.0803 280.801 62.2186 280.386 62.7913C279.971 63.3641 280.113 64.158 280.698 64.5649L293.713 73.5789C293.942 73.7369 294.203 73.812 294.46 73.812C294.867 73.812 295.27 73.6263 295.523 73.2748C295.937 72.702 295.795 71.908 295.21 71.5012Z"
            fill="#014A4F"
          />
          <path
            d="M326.842 64.0554C326.191 63.767 325.436 64.0553 325.16 64.7071C324.291 66.7453 314.052 86.1124 314.044 86.3928C313.124 86.5429 310.094 85.5199 307.637 84.6904C306.851 84.4257 306.018 84.1452 305.145 83.8608C303.296 83.2565 302.881 82.265 302.731 81.5738C301.965 78.0266 313.049 60.0697 315.759 57.6523C316.288 57.1783 316.327 56.3765 315.845 55.8551C315.364 55.3376 314.542 55.2981 314.013 55.7721C311.587 57.9367 299.058 76.8298 300.191 82.0991C300.492 83.4856 301.451 85.3342 304.319 86.2743C305.184 86.5548 306.006 86.8352 306.788 87.0959C309.905 88.1466 312.231 88.9287 313.878 88.9287C314.791 88.9287 315.498 88.6878 316.015 88.119C317.256 86.7602 326.534 68.0686 327.518 65.7538C327.794 65.106 327.486 64.3437 326.834 64.0554L326.842 64.0554Z"
            fill="#014A4F"
          />
          <path
            d="M315.683 87.017C315.45 86.3534 314.716 85.9979 314.036 86.223C313.357 86.4482 312.997 87.171 313.227 87.8386L317.042 98.7881C317.228 99.3174 317.73 99.6492 318.271 99.6492C318.409 99.6492 318.551 99.6255 318.689 99.5821C319.369 99.3569 319.728 98.6341 319.499 97.9665L315.683 87.017Z"
            fill="#014A4F"
          />
          <path
            d="M353.288 84.5205C353.177 84.6193 342.03 94.3561 332.993 97.1646C323.907 99.9889 257.334 96.3904 256.812 96.3627C256.081 96.3193 255.485 96.8605 255.441 97.5596C255.402 98.2588 255.947 98.8631 256.662 98.9026C258.068 98.9816 295.147 100.953 312.962 100.953C322.331 100.953 330.488 100.613 333.775 99.5939C343.35 96.6195 354.548 86.8313 355.018 86.4166C355.551 85.9465 355.595 85.1446 355.117 84.6232C354.639 84.1018 353.817 84.0544 353.284 84.5245L353.288 84.5205Z"
            fill="#014A4F"
          />
          <path
            d="M341.706 122.86C340.17 116.457 337.579 97.3226 337.551 97.1291C337.456 96.4339 336.808 95.948 336.093 96.0349C335.382 96.1258 334.885 96.7657 334.979 97.4609C335.086 98.2509 337.614 116.911 339.178 123.44C341.667 133.813 348.208 162.498 348.564 170.521C348.733 174.376 349.551 183.825 350.432 193.344L353.039 193.344C352.154 183.757 351.325 174.21 351.155 170.41C350.716 160.504 341.793 123.231 341.706 122.856L341.706 122.86Z"
            fill="#014A4F"
          />
          <path
            d="M330.176 98.006C329.572 97.6268 328.77 97.8006 328.383 98.3931C325.645 102.588 319.602 107.273 312.808 107.648C305.939 108.027 260.738 109.117 260.324 109.129C259.609 109.149 259.044 109.734 259.064 110.437C259.083 111.128 259.66 111.673 260.359 111.673C260.371 111.673 264.767 111.728 264.779 111.728C265.19 111.717 306.045 110.571 312.954 110.188C320.7 109.761 327.384 104.642 330.567 99.7638C330.954 99.1713 330.777 98.3852 330.172 98.006L330.176 98.006Z"
            fill="#014A4F"
          />
          <path
            d="M300.93 79.6343C296.941 81.0089 295.376 89.9439 295.708 109.516C295.72 110.212 296.297 110.768 297.004 110.768C297.012 110.768 297.02 110.768 297.027 110.768C297.742 110.757 298.315 110.18 298.303 109.477C297.916 86.776 300.322 82.5415 301.787 82.0359C302.463 81.8028 302.818 81.076 302.581 80.4164C302.344 79.7528 301.602 79.4052 300.93 79.6382L300.93 79.6343Z"
            fill="#014A4F"
          />
          <path
            d="M322.233 106.198C322.015 105.527 321.289 105.159 320.605 105.373C319.922 105.586 319.547 106.301 319.764 106.969L329.829 137.992C330.002 138.533 330.516 138.877 331.065 138.877C331.195 138.877 331.33 138.857 331.46 138.818C332.143 138.604 332.519 137.889 332.301 137.222L322.237 106.198L322.233 106.198Z"
            fill="#014A4F"
          />
          <path
            d="M323.437 113.522L238.681 136.827C237.994 137.021 237.595 137.724 237.789 138.399C237.951 138.956 238.468 139.323 239.037 139.323C239.155 139.323 239.274 139.308 239.392 139.276L324.148 115.971C324.836 115.777 325.235 115.074 325.041 114.399C324.844 113.723 324.129 113.332 323.437 113.526L323.437 113.522Z"
            fill="#014A4F"
          />
          <path
            d="M375.752 130.128L241.517 151.051C240.806 151.15 240.317 151.794 240.415 152.489C240.506 153.125 241.818 153.579 241.881 153.571L376.115 132.648C376.826 132.549 377.316 131.905 377.217 131.21C377.118 130.515 376.459 130.037 375.752 130.128Z"
            fill="#014A4F"
          />
          <path
            d="M309.632 140.576L306.59 119.731C306.488 119.036 305.828 118.554 305.125 118.653C304.414 118.752 303.924 119.395 304.027 120.091L307.061 140.876L309.668 193.344L312.263 193.344L309.648 140.694C309.648 140.655 309.64 140.615 309.636 140.576L309.632 140.576Z"
            fill="#014A4F"
          />
          <path
            d="M273.643 128.421C272.928 128.473 272.391 129.081 272.446 129.784L277.234 193.344L279.833 193.344L275.034 129.594C274.982 128.895 274.366 128.374 273.643 128.421Z"
            fill="#014A4F"
          />
          <path
            d="M387.128 168.048L244.531 185.768C243.816 185.831 243.291 186.447 243.354 187.147C243.417 187.846 244.049 188.363 244.76 188.3L387.179 170.596L509.796 193.344L523.748 193.344L387.483 168.064C387.365 168.044 387.246 168.036 387.128 168.048Z"
            fill="#014A4F"
          />
          <path
            d="M418.815 191.156C411.093 189.445 405.586 191.148 401.494 193.348L408.94 193.348C411.191 192.929 413.763 192.858 416.729 193.348L419.384 193.348C419.581 193.178 419.728 192.949 419.791 192.677C419.949 191.993 419.51 191.31 418.811 191.156L418.815 191.156Z"
            fill="#014A4F"
          />
          <path
            d="M348.137 157.604L309.276 159.236C308.562 159.267 308.005 159.86 308.036 160.559C308.068 161.242 308.641 161.775 309.332 161.775C309.352 161.775 309.371 161.775 309.387 161.775L348.248 160.144C348.963 160.112 349.52 159.52 349.488 158.821C349.456 158.118 348.84 157.573 348.137 157.604Z"
            fill="#014A4F"
          />
          <path
            d="M427.146 123.8C426.431 123.8 425.85 124.369 425.85 125.072L425.85 176.45L418.337 193.344L421.165 193.344L428.335 177.224C428.406 177.062 428.441 176.892 428.441 176.714L428.441 125.072C428.441 124.369 427.861 123.8 427.146 123.8Z"
            fill="#014A4F"
          />
          <path
            d="M522.393 37.3492C521.804 36.9463 520.994 37.0885 520.588 37.6612L451.403 141.753C451.237 141.986 451.154 142.266 451.17 142.551C451.189 142.914 453.164 178.84 449.629 188.098C449.329 188.889 448.566 190.698 447.429 193.34L450.241 193.34C451.162 191.191 451.786 189.698 452.058 188.987C455.605 179.697 454.018 147.22 453.784 142.847L522.713 39.1149C523.123 38.5382 522.981 37.7481 522.393 37.3452L522.393 37.3492Z"
            fill="#014A4F"
          />
          <path
            d="M563.165 137.506C562.541 137.167 561.751 137.384 561.399 138L530.012 193.348L532.979 193.348L563.663 139.237C564.01 138.624 563.785 137.85 563.161 137.506L563.165 137.506Z"
            fill="#014A4F"
          />
          <path
            d="M543.431 91.7373C542.846 91.4884 542.167 91.6938 541.823 92.2192L492.1 168.562C492.037 168.656 491.989 168.759 491.954 168.866L486.009 187.431L483.501 193.348L486.309 193.348L488.426 188.355C488.442 188.32 488.454 188.284 488.466 188.249L494.375 169.79L543.363 94.5773C545.836 95.9756 552.986 100.633 563.912 112.83C579.163 129.851 573.001 160.78 572.25 164.252C571.618 167.167 565.349 178.883 557.268 193.348L560.226 193.348C568.182 179.081 574.115 167.886 574.79 164.781C575.572 161.183 581.947 129.109 565.863 111.152C551.706 95.3516 543.758 91.8755 543.427 91.7373L543.431 91.7373Z"
            fill="#014A4F"
          />
          <path
            d="M448.958 192.503L422.398 186.775C421.703 186.621 421.007 187.06 420.853 187.743C420.699 188.43 421.142 189.106 421.841 189.256L440.777 193.34L450.238 193.34L449.491 192.752C449.333 192.629 449.151 192.542 448.958 192.499L448.958 192.503Z"
            fill="#014A4F"
          />
          <path
            d="M570.445 171.97L436.108 86.5943C435.539 86.2348 434.789 86.3612 434.374 86.8866L403.473 111.203C403.034 111.76 403.141 112.558 403.71 112.985C404.279 113.411 405.092 113.308 405.527 112.751L435.701 89.3633L569.031 174.1C569.248 174.238 569.493 174.305 569.734 174.305C570.161 174.305 570.575 174.099 570.824 173.724C571.215 173.136 571.041 172.346 570.441 171.966L570.445 171.97Z"
            fill="#014A4F"
          />
          <path
            d="M557.212 104.417C556.58 104.081 555.794 104.314 555.455 104.93L532.469 147.551C532.13 148.168 532.363 148.942 532.995 149.274C533.192 149.376 533.401 149.428 533.611 149.428C534.073 149.428 534.519 149.187 534.752 148.76L557.738 106.139C558.077 105.523 557.844 104.749 557.212 104.417Z"
            fill="#014A4F"
          />
          <path
            d="M563.919 36.0496C563.315 35.6744 562.513 35.8482 562.13 36.4407L495.643 125.964C495.26 126.557 495.438 127.343 496.042 127.718C496.255 127.852 496.5 127.916 496.737 127.916C497.168 127.916 497.586 127.706 497.831 127.327L564.318 37.8034C564.702 37.2109 564.524 36.4249 563.919 36.0496Z"
            fill="#014A4F"
          />
          <path
            d="M452.698 143.443L418.993 137.408L381.918 115.52C381.341 115.177 380.591 115.331 380.196 115.868L372.86 125.901C372.442 126.474 372.576 127.268 373.16 127.675C373.741 128.082 374.555 127.951 374.97 127.382L381.606 118.305L417.855 139.706C417.989 139.785 418.136 139.841 418.29 139.868L452.228 145.944C452.307 145.959 452.386 145.963 452.461 145.963C453.078 145.963 453.623 145.533 453.737 144.921C453.868 144.229 453.398 143.566 452.695 143.443L452.698 143.443Z"
            fill="#014A4F"
          />
          <path
            d="M647.518 145.162C647.159 144.557 646.365 144.348 645.745 144.699C645.124 145.051 644.915 145.829 645.274 146.437L648.233 151.434L641.636 193.344L644.259 193.344L650.864 151.379C650.911 151.091 650.852 150.798 650.702 150.545L647.51 145.162L647.518 145.162Z"
            fill="#014A4F"
          />
          <path
            d="M551.248 20.9921L540.002 22.422C539.765 22.4536 539.544 22.5444 539.358 22.6906L521.792 36.5118L491.669 25.9691C490.966 25.8151 490.279 26.2496 490.125 26.9369C489.971 27.6242 490.413 28.2997 491.112 28.4537L521.84 39.1267C521.931 39.1464 522.025 39.1583 522.12 39.1583C522.412 39.1583 522.701 39.0595 522.934 38.8778L540.701 24.8987L551.583 23.5122C552.294 23.4214 552.796 22.7854 552.701 22.0863C552.611 21.3911 551.963 20.9012 551.248 20.9881L551.248 20.9921Z"
            fill="#014A4F"
          />
          <path
            d="M651.034 107.964L634.266 122.504C631.264 122.962 612.414 125.858 605.928 127.296C603.471 127.841 601.796 128.875 600.951 130.373C599.738 132.529 600.615 134.978 601.089 136.294C601.18 136.543 601.259 136.76 601.303 136.926C601.449 137.502 601.978 137.89 602.559 137.89C602.661 137.89 602.768 137.878 602.875 137.85C603.57 137.68 603.993 136.989 603.819 136.309C603.76 136.084 603.661 135.792 603.538 135.452C603.155 134.382 602.575 132.762 603.222 131.605C603.692 130.772 604.794 130.155 606.501 129.78C613.319 128.267 634.823 124.997 635.04 124.965C635.285 124.929 635.514 124.823 635.7 124.661L652.752 109.872C653.289 109.406 653.337 108.604 652.863 108.079C652.388 107.553 651.571 107.502 651.034 107.968L651.034 107.964Z"
            fill="#014A4F"
          />
          <path
            d="M631.414 157.821C630.928 157.304 630.11 157.268 629.581 157.742C629.052 158.216 629.016 159.022 629.502 159.54L639.44 170.173C639.697 170.446 640.045 170.584 640.396 170.584C640.708 170.584 641.024 170.473 641.273 170.248C641.802 169.774 641.838 168.968 641.352 168.451L631.414 157.817L631.414 157.821Z"
            fill="#014A4F"
          />
          <path
            d="M634.771 84.8049C634.337 84.244 633.523 84.1374 632.954 84.564C632.385 84.9906 632.275 85.7885 632.709 86.3455L667.572 131.178L652.93 142.93L650.639 140.386C650.402 140.121 650.062 139.967 649.702 139.955C649.347 139.936 648.995 140.082 648.743 140.335L637.967 151.059L625.58 160.93L616.143 147.915C616.988 144.893 620.314 131.696 615.736 126.142C615.286 125.597 614.468 125.51 613.911 125.953C613.354 126.395 613.267 127.193 613.718 127.742C617.229 132 614.741 143.661 613.485 147.796C613.37 148.172 613.441 148.578 613.67 148.894L624.268 163.513C624.478 163.802 624.794 163.987 625.149 164.035C625.208 164.043 625.264 164.047 625.323 164.047C625.619 164.047 625.907 163.948 626.14 163.762L639.658 152.991C639.693 152.963 639.729 152.931 639.764 152.896L649.616 143.092L651.816 145.533C652.278 146.046 653.068 146.11 653.609 145.675L670.187 132.367C670.729 131.933 670.819 131.159 670.397 130.614L634.771 84.8049Z"
            fill="#014A4F"
          />
          <path
            d="M620.113 10.8207L590.223 -40.9682L553.689 -141.386C553.361 -142.01 552.579 -142.259 551.943 -141.935C551.307 -141.615 551.054 -140.849 551.382 -140.225L587.944 -39.7635L617.917 12.1716C617.956 12.2388 617.999 12.2981 618.047 12.3573L763.223 183.702C763.479 184.006 763.851 184.16 764.222 184.16C764.514 184.16 764.807 184.066 765.052 183.868C765.601 183.418 765.68 182.616 765.221 182.079L620.113 10.8207Z"
            fill="#014A4F"
          />
          <path
            d="M686.841 -44.5787L570.761 46.8134C570.204 47.2519 570.113 48.0537 570.56 48.6028C570.816 48.9148 571.192 49.0768 571.571 49.0768C571.855 49.0768 572.143 48.9859 572.38 48.7964L688.46 -42.5957C689.017 -43.0342 689.108 -43.836 688.658 -44.3851C688.207 -44.9341 687.394 -45.0211 686.833 -44.5787L686.841 -44.5787Z"
            fill="#014A4F"
          />
          <path
            d="M647.455 -11.975C646.993 -12.5122 646.175 -12.5832 645.626 -12.1329C645.077 -11.6826 645.006 -10.8808 645.464 -10.3396L742.884 103.56C743.141 103.86 743.508 104.014 743.879 104.014C744.172 104.014 744.468 103.915 744.713 103.718C745.262 103.267 745.333 102.466 744.875 101.928L647.455 -11.971L647.455 -11.975Z"
            fill="#014A4F"
          />
          <path
            d="M727.672 10.5521L613.915 99.4438C613.354 99.8822 613.263 100.68 613.71 101.229C613.966 101.545 614.342 101.707 614.725 101.707C615.009 101.707 615.294 101.616 615.535 101.431L729.292 12.539C729.853 12.1005 729.944 11.3026 729.497 10.7535C729.051 10.2045 728.233 10.1136 727.676 10.5521L727.672 10.5521Z"
            fill="#014A4F"
          />
          <path
            d="M756.038 43.0649L661.821 121.797C661.276 122.251 661.209 123.053 661.675 123.59C661.932 123.887 662.295 124.037 662.663 124.037C662.959 124.037 663.259 123.938 663.504 123.733L757.72 45.0004C758.265 44.5461 758.333 43.7443 757.866 43.2071C757.4 42.6738 756.583 42.6067 756.038 43.0649Z"
            fill="#014A4F"
          />
          <path
            d="M762.741 106.708C761.587 101.81 759.913 91.0895 759.897 90.9828C759.79 90.2876 759.123 89.8136 758.419 89.9163C757.712 90.023 757.227 90.6707 757.333 91.366C757.4 91.8084 759.04 102.288 760.213 107.277C761.402 112.325 766.454 124.163 766.667 124.665C766.876 125.151 767.358 125.443 767.864 125.443C768.03 125.443 768.2 125.411 768.366 125.344C769.025 125.072 769.337 124.329 769.061 123.681C769.009 123.559 763.882 111.543 762.741 106.704L762.741 106.708Z"
            fill="#014A4F"
          />
          <path
            d="M668.56 161.775C667.999 162.21 667.9 163.012 668.347 163.561L692.327 193.344L695.634 193.344L670.381 161.985C669.939 161.432 669.121 161.337 668.56 161.775Z"
            fill="#014A4F"
          />
          <path
            d="M685.32 146.398C684.767 146.844 684.688 147.646 685.142 148.187L723.011 193.344L726.369 193.344L687.145 146.572C686.691 146.031 685.873 145.952 685.32 146.398Z"
            fill="#014A4F"
          />
          <path
            d="M750.717 166.962L718.666 193.344L722.699 193.344L752.384 168.909C752.933 168.459 753.004 167.657 752.542 167.12C752.08 166.583 751.262 166.512 750.713 166.962L750.717 166.962Z"
            fill="#014A4F"
          />
          <path
            d="M119.213 106.463L73.0761 92.0927L75.2486 78.0345C75.3039 77.6711 75.1972 77.2998 74.9523 77.0194L39.0267 35.6151C38.9793 35.5598 38.928 35.5124 38.8727 35.465L-0.00756073 3.34323L-0.00756088 6.66918L37.1188 37.3412L72.5902 78.2242L70.3505 92.7089C70.2597 93.2935 70.5915 93.8623 71.1564 94.0796L118.249 108.821C118.407 108.88 118.569 108.912 118.727 108.912C119.244 108.912 119.73 108.608 119.931 108.11C120.196 107.458 119.868 106.72 119.205 106.459L119.213 106.463Z"
            fill="#014A4F"
          />
          <path
            d="M772.462 -11.1454C771.913 -11.5997 771.095 -11.5286 770.633 -10.9914C770.171 -10.4542 770.242 -9.65235 770.787 -9.19809L790 6.66923L790 3.34328L772.462 -11.1494L772.462 -11.1454Z"
            fill="#014A4F"
          />
          <path
            d="M60.1555 118.842C60.8704 118.842 61.451 118.274 61.451 117.571L61.451 69.4353C61.451 69.1628 61.3602 68.8981 61.1982 68.6809L47.464 50.4554C47.0374 49.8905 46.2276 49.772 45.6509 50.1868L14.4574 72.6902C14.1651 72.9035 13.9756 73.2234 13.9361 73.5789C13.8966 73.9344 14.0111 74.2899 14.252 74.5546L26.8092 88.4982C27.2832 89.0235 28.1048 89.0749 28.6381 88.6088C29.1753 88.1427 29.2267 87.3409 28.7527 86.8155L17.1356 73.9107L46.1446 52.9834L58.8559 69.8501L58.8559 117.567C58.8559 118.27 59.4366 118.838 60.1515 118.838L60.1555 118.842Z"
            fill="#014A4F"
          />
          <path
            d="M15.0618 101.711C15.3621 101.711 15.6662 101.608 15.9111 101.399L61.0087 62.8861C61.5498 62.4239 61.6052 61.6221 61.1351 61.0928C60.6651 60.5635 59.8434 60.5082 59.3062 60.9703L14.2086 99.4832C13.6675 99.9454 13.6122 100.747 14.0822 101.277C14.339 101.565 14.6984 101.711 15.0618 101.711Z"
            fill="#014A4F"
          />
          <path
            d="M120.259 131.937C120.583 131.937 120.907 131.933 121.239 131.921C133.081 131.538 203.404 125.581 204.115 125.522C204.83 125.463 205.355 124.846 205.296 124.147C205.233 123.448 204.601 122.927 203.893 122.99C203.186 123.049 132.931 129.002 121.156 129.381C111.316 129.697 104.649 124.586 100.244 121.201C99.5216 120.644 98.8343 120.118 98.2023 119.664C93.083 116.006 62.482 115.825 57.1021 116.303C51.098 116.836 48.2817 114.154 47.6892 111.697C47.1559 109.465 49.0085 89.8333 50.3041 77.9793C50.3436 77.6317 50.233 77.284 49.9999 77.0194L23.8941 46.9793C23.428 46.4461 22.6104 46.3789 22.0653 46.8371C21.5202 47.2914 21.453 48.0932 21.9191 48.6304L47.6615 78.2557C47.1204 83.2565 44.383 109.038 45.1572 112.281C45.9867 115.734 49.6681 119.526 57.3273 118.838C64.1529 118.23 92.6643 118.858 96.6657 121.722C97.2622 122.149 97.9297 122.662 98.6368 123.203C103.195 126.703 110.017 131.937 120.255 131.941L120.259 131.937Z"
            fill="#014A4F"
          />
          <path
            d="M12.2849 111.211C12.4943 111.211 12.7037 111.16 12.9012 111.057L47.938 92.472L71.5434 94.1705C72.2623 94.2179 72.8785 93.6965 72.9299 92.9973C72.9812 92.2981 72.444 91.6859 71.733 91.6345L47.7484 89.9084C47.5035 89.8886 47.2546 89.9439 47.0374 90.0585L11.6648 108.821C11.0328 109.157 10.7997 109.927 11.1394 110.543C11.3725 110.97 11.8188 111.211 12.281 111.211L12.2849 111.211Z"
            fill="#014A4F"
          />
          <path
            d="M73.9688 79.1168C74.265 79.1168 74.5652 79.0181 74.8101 78.8127L94.742 62.1791C95.1291 61.8552 95.2871 61.3416 95.1489 60.8637L93.8454 56.3725L98.3326 52.1223C98.5854 51.8813 98.7276 51.5535 98.7276 51.2059L98.7276 37.8824C98.7276 37.6612 98.6684 37.4439 98.5578 37.2503L93.083 27.873L90.8433 18.7839C90.8117 18.6536 90.7604 18.5311 90.6893 18.4166L86.8025 12.219C86.6247 11.9345 86.3403 11.7292 86.0085 11.6502L80.9367 10.4178L77.3026 5.4723C76.8839 4.9035 76.0741 4.77315 75.4935 5.18396C74.9128 5.59476 74.7785 6.38871 75.1972 6.95752L79.1078 12.2822C79.2855 12.5271 79.5502 12.7009 79.8464 12.772L84.863 13.9925L88.3627 19.5779L90.5984 28.6472C90.6261 28.7657 90.6735 28.8763 90.7327 28.979L96.1285 38.2181L96.1285 50.6647L91.4753 55.069C91.1317 55.3969 90.9974 55.8788 91.1277 56.333L92.4154 60.7689L73.1156 76.8693C72.5705 77.3235 72.5033 78.1254 72.9694 78.6626C73.2262 78.9588 73.5896 79.1089 73.9569 79.1089L73.9688 79.1168Z"
            fill="#014A4F"
          />
          <path
            d="M57.2126 59.8052C57.5207 59.8052 57.8249 59.6985 58.0737 59.4852L92.7274 29.3069C93.2646 28.8408 93.312 28.0389 92.8341 27.5136C92.3601 26.9882 91.5385 26.9408 91.0052 27.4069L56.3515 57.5891C55.8143 58.0552 55.7669 58.8571 56.2449 59.3825C56.5016 59.6669 56.8571 59.8091 57.2166 59.8091L57.2126 59.8052Z"
            fill="#014A4F"
          />
          <path
            d="M20.9908 81.3881C21.2752 81.3881 21.5596 81.2973 21.8006 81.1077L39.6389 67.1167C40.1998 66.6782 40.2907 65.8764 39.8404 65.3312C39.394 64.7822 38.5764 64.6953 38.0194 65.1337L20.181 79.1248C19.6201 79.5632 19.5293 80.3651 19.9796 80.9102C20.2363 81.2222 20.6116 81.3881 20.9948 81.3881L20.9908 81.3881Z"
            fill="#014A4F"
          />
          <path
            d="M213.729 180.242C218.398 180.242 227.009 179.531 243.212 177.208C259.147 174.925 277.787 172.527 277.941 172.5L277.479 169.999C248.035 175.229 212.978 179.124 209.838 177.311C207.887 174.653 207.211 156.301 206.805 145.304C206.619 140.283 206.461 135.95 206.236 133.426C206.007 130.839 205.146 122.765 204.051 112.542C201.393 87.6569 197.376 50.0525 196.823 38.9963C196.29 28.3509 184.317 -2.11568 182.488 -4.87281C177.487 -12.4174 164.409 -33.6212 160.447 -41.387C159.424 -43.3976 158.259 -45.4555 157.026 -47.6399C153.586 -53.7309 150.031 -60.0312 149.545 -65.2334C148.542 -76.0051 153.76 -92.615 158.381 -97.1457C158.65 -97.4064 158.788 -97.7738 158.756 -98.1451C158.729 -98.5164 158.535 -98.8561 158.227 -99.0734L126.567 -123.299L125.039 -121.245L155.553 -97.837C150.402 -91.3866 146.021 -75.0848 146.961 -65.0043C147.495 -59.2688 151.184 -52.7315 154.755 -46.4114C155.975 -44.2547 157.125 -42.2165 158.124 -40.2533C162.122 -32.4165 175.279 -11.0744 180.312 -3.49029C181.813 -1.22297 193.714 28.8368 194.228 39.1188C194.789 50.246 198.806 87.8938 201.468 112.807C202.558 123.018 203.419 131.08 203.648 133.647C203.866 136.104 204.024 140.41 204.209 145.395C204.845 162.656 205.517 176.09 207.851 178.954C208.282 179.483 208.902 180.242 213.725 180.242L213.729 180.242Z"
            fill="#014A4F"
          />
          <path
            d="M244.614 176.991C244.685 176.991 244.761 176.987 244.832 176.971C245.539 176.853 246.013 176.197 245.894 175.506L222.707 41.8206C222.7 41.7772 222.688 41.7337 222.676 41.6902L205.635 -17.7262L208.084 -22.8771C208.242 -23.2089 208.246 -23.5881 208.096 -23.9239L202.902 -35.6081L202.902 -45.3449C202.902 -46.048 202.321 -46.6168 201.606 -46.6168C200.891 -46.6168 200.311 -46.048 200.311 -45.3449L200.311 -35.3434C200.311 -35.1696 200.346 -34.9958 200.417 -34.8339L205.485 -23.4341L203.08 -18.374C202.949 -18.0975 202.922 -17.7855 203.009 -17.4932L220.16 42.3144L243.335 175.932C243.441 176.553 243.99 176.991 244.61 176.991L244.614 176.991Z"
            fill="#014A4F"
          />
          <path
            d="M205.106 137.151C205.177 137.151 205.248 137.147 205.323 137.135L237.014 131.921C237.722 131.806 238.196 131.151 238.077 130.456C237.958 129.764 237.295 129.298 236.584 129.413L204.893 134.627C204.186 134.741 203.712 135.397 203.83 136.092C203.937 136.712 204.486 137.155 205.106 137.155L205.106 137.151Z"
            fill="#014A4F"
          />
          <path
            d="M200.275 90.5088C200.35 90.5088 200.425 90.5009 200.5 90.489L229 85.6463C229.707 85.5278 230.177 84.8681 230.055 84.1769C229.932 83.4856 229.261 83.0235 228.554 83.142L200.054 87.9847C199.347 88.1032 198.877 88.7629 198.999 89.4541C199.11 90.0743 199.655 90.5088 200.275 90.5088Z"
            fill="#014A4F"
          />
          <path
            d="M197.013 58.2962C197.099 58.2962 197.186 58.2883 197.277 58.2686L223.375 52.9795C224.078 52.8373 224.528 52.1658 224.382 51.4785C224.236 50.7911 223.553 50.3487 222.85 50.4909L196.752 55.7801C196.049 55.9223 195.598 56.5938 195.745 57.2811C195.871 57.8815 196.412 58.2962 197.013 58.2962Z"
            fill="#014A4F"
          />
          <path
            d="M195.681 34.3471C195.899 34.3471 197.451 33.5808 197.652 33.4702L215.163 23.7136C215.787 23.366 216.004 22.5918 215.649 21.9796C215.293 21.3713 214.503 21.154 213.879 21.5016L195.034 31.9692C194.409 32.3168 194.192 33.091 194.548 33.7033C194.785 34.1141 195.223 34.3471 195.677 34.3471L195.681 34.3471Z"
            fill="#014A4F"
          />
          <path
            d="M217.004 134.113C217.039 134.113 217.079 134.113 217.114 134.109C217.829 134.05 218.358 133.434 218.295 132.735C216.344 110.184 211.075 57.8341 211.288 54.7886C211.359 54.5832 211.379 54.3541 211.331 54.1211C211.185 53.4338 210.502 52.9914 209.799 53.1336C208.586 53.3785 208.622 54.2356 208.744 57.0678C208.811 58.6517 208.949 60.9665 209.151 63.9488C209.491 68.9614 210.02 75.9411 210.723 84.6865C211.92 99.5742 215.7 132.798 215.716 132.948C215.775 133.612 216.34 134.113 217.008 134.113L217.004 134.113Z"
            fill="#014A4F"
          />
          <path
            d="M234.672 178.362C234.727 178.362 234.783 178.362 234.838 178.35C235.549 178.259 236.051 177.623 235.96 176.928L230.058 131.826C229.968 131.131 229.32 130.641 228.609 130.728C227.898 130.819 227.396 131.455 227.487 132.15L233.388 177.252C233.471 177.892 234.032 178.362 234.672 178.362Z"
            fill="#014A4F"
          />
          <path
            d="M267.991 148.393C268.093 148.393 268.192 148.381 268.295 148.357C268.99 148.191 269.421 147.504 269.251 146.824L252.151 75.1786C251.985 74.4953 251.282 74.0766 250.587 74.2425C249.892 74.4084 249.461 75.0957 249.631 75.7751L266.731 147.421C266.873 148.006 267.406 148.397 267.991 148.397L267.991 148.393Z"
            fill="#014A4F"
          />
          <path
            d="M250.073 129.421C250.204 129.421 250.334 129.401 250.46 129.361L263.049 125.506C263.733 125.297 264.116 124.582 263.902 123.914C263.689 123.243 262.962 122.871 262.279 123.081L251.053 126.517L240.688 77.5724C240.542 76.8851 239.855 76.4427 239.151 76.5889C238.452 76.7311 238.002 77.4065 238.148 78.0938L248.805 128.409C248.88 128.761 249.102 129.065 249.418 129.247C249.619 129.361 249.844 129.421 250.073 129.421Z"
            fill="#014A4F"
          />
          <path
            d="M227.866 81.3407C228.783 81.3407 231.524 80.6692 242.853 77.8963C249.429 76.2847 255.986 74.6612 257.073 74.3926L276.76 70.727C277.475 70.727 278.055 70.1582 278.055 69.4551C278.055 68.752 277.475 68.1832 276.76 68.1832L256.911 71.8488C256.804 71.8488 256.698 71.8607 256.595 71.8883C245.637 74.602 228.956 78.6982 227.902 78.8562L228.099 80.1044L228.036 80.1162L227.329 81.1828C227.471 81.2736 227.578 81.3407 227.87 81.3407L227.866 81.3407Z"
            fill="#014A4F"
          />
          <path
            d="M140.266 11.1248C140.302 11.1248 140.337 11.1248 140.373 11.1209L185.96 7.48683C186.675 7.43153 187.205 6.81533 187.149 6.11617C187.09 5.41702 186.462 4.89958 185.751 4.95093L140.163 8.58495C139.449 8.64025 138.919 9.25644 138.975 9.9556C139.03 10.6192 139.599 11.1248 140.266 11.1248Z"
            fill="#014A4F"
          />
          <path
            d="M143.845 38.3643C143.888 38.3643 143.932 38.3643 143.975 38.3564L193.829 34.4498C194.54 34.3787 195.061 33.7585 194.99 33.0594C194.919 32.3602 194.283 31.8507 193.572 31.9178L143.719 35.8244C143.008 35.8955 142.486 36.5157 142.557 37.2148C142.624 37.8705 143.189 38.3603 143.845 38.3603L143.845 38.3643Z"
            fill="#014A4F"
          />
          <path
            d="M181.402 79.1168C181.437 79.1168 181.473 79.1169 181.505 79.1129C187.604 78.631 197.506 77.8805 198.762 77.8766C198.869 77.8924 198.976 77.8963 199.086 77.8844C199.995 77.7975 200.212 77.2604 200.216 76.4941L200.248 76.4941C200.24 76.4111 200.228 76.34 200.212 76.2728C200.2 76.0121 200.173 75.7277 200.145 75.4275L197.806 51.6128C197.739 50.9176 197.111 50.4119 196.404 50.4672L145.82 54.7214C145.105 54.7807 144.576 55.3969 144.639 56.096C144.698 56.7952 145.334 57.3126 146.041 57.2534L195.346 53.1058C195.76 57.3284 197.103 71.0153 197.534 75.3841C197.131 75.4078 196.653 75.4354 196.12 75.471C194.469 75.5776 192.268 75.7356 190.072 75.9015C186.276 76.182 182.484 76.4822 181.524 76.5572L148.684 66.5597C147.993 66.3701 147.278 66.7611 147.084 67.4366C146.89 68.112 147.289 68.8151 147.981 69.0047L181.046 79.0655C181.161 79.0971 181.279 79.1129 181.398 79.1129L181.402 79.1168Z"
            fill="#014A4F"
          />
          <path
            d="M118.62 108.948C118.671 108.948 118.723 108.947 118.774 108.94L201.369 99.3884C202.08 99.3055 202.59 98.6734 202.507 97.9782C202.424 97.2791 201.78 96.7814 201.065 96.8643L118.47 106.416C117.759 106.499 117.249 107.13 117.332 107.826C117.411 108.474 117.968 108.948 118.62 108.948Z"
            fill="#014A4F"
          />
          <path
            d="M185.854 125.447C185.893 125.447 185.937 125.447 185.976 125.443C186.691 125.376 187.212 124.756 187.145 124.056L182.694 77.7265C182.626 77.0273 181.994 76.5138 181.279 76.5809C180.564 76.6481 180.043 77.2683 180.11 77.9674L184.562 124.297C184.625 124.957 185.19 125.451 185.85 125.451L185.854 125.447Z"
            fill="#014A4F"
          />
          <path
            d="M101.354 186.74C101.394 186.74 101.437 186.74 101.477 186.736L208.009 176.983C208.724 176.916 209.246 176.3 209.179 175.601C209.111 174.901 208.483 174.384 207.768 174.451L101.236 184.204C100.521 184.271 99.9995 184.887 100.067 185.586C100.13 186.246 100.695 186.74 101.354 186.74Z"
            fill="#014A4F"
          />
          <path
            d="M132.647 182.62C130.561 162.866 127.326 133.011 126.864 130.724C126.82 130.61 126.793 130.487 126.785 130.357C126.737 129.658 127.278 129.049 127.993 129.002C129.206 128.927 129.396 130.096 129.483 130.661C129.542 131.048 129.621 131.621 129.716 132.348C129.901 133.778 130.142 135.824 130.427 138.277C130.928 142.61 131.6 148.649 132.425 156.234C133.847 169.292 135.23 182.355 135.23 182.355C135.305 183.054 134.787 183.678 134.073 183.749C134.029 183.753 133.982 183.757 133.938 183.757C133.283 183.757 132.718 183.272 132.65 182.616L132.647 182.62ZM127.65 131.475C127.804 131.526 127.974 131.554 128.163 131.542C127.986 131.554 127.812 131.53 127.65 131.475Z"
            fill="#014A4F"
          />
          <path
            d="M183.626 179.211C183.661 179.211 183.697 179.211 183.736 179.207C184.451 179.148 184.981 178.535 184.921 177.836L180.553 126.213C180.493 125.514 179.873 124.989 179.154 125.052C178.439 125.111 177.91 125.723 177.969 126.423L182.338 178.046C182.393 178.709 182.962 179.211 183.63 179.211L183.626 179.211Z"
            fill="#014A4F"
          />
          <path
            d="M108.255 169L121.511 165.749C122.207 165.579 122.629 164.888 122.455 164.209C122.282 163.525 121.579 163.114 120.883 163.284L106.932 166.705C106.497 166.812 106.15 167.128 106.011 167.546L97.4754 193.348L100.201 193.348L108.255 169Z"
            fill="#014A4F"
          />
          <path
            d="M148.135 182.403C148.087 181.703 147.471 181.17 146.756 181.217C146.041 181.265 145.5 181.869 145.547 182.572L146.278 193.344L148.877 193.344L148.135 182.403Z"
            fill="#014A4F"
          />
          <path
            d="M122.554 183.757C122.578 183.757 122.598 183.757 122.621 183.757C123.336 183.722 123.885 183.125 123.85 182.422L122.558 157.58L156.758 155.807C157.473 155.771 158.022 155.171 157.982 154.472C157.943 153.773 157.334 153.231 156.619 153.271L121.128 155.112C120.413 155.147 119.864 155.748 119.9 156.447L121.259 182.553C121.294 183.232 121.867 183.757 122.554 183.757Z"
            fill="#014A4F"
          />
          <path
            d="M104.668 176.991L122.203 176.991C122.918 176.991 123.498 176.422 123.498 175.719C123.498 175.016 122.918 174.447 122.203 174.447L104.668 174.447C103.953 174.447 103.373 175.016 103.373 175.719C103.373 176.422 103.953 176.991 104.668 176.991Z"
            fill="#014A4F"
          />
          <path
            d="M242.276 175.996C242.142 175.304 239.594 175.782 239.728 176.47L243.003 193.34L245.641 193.34L242.272 175.992L242.276 175.996Z"
            fill="#014A4F"
          />
          <path
            d="M205.635 -14.2067L206.808 -8.30933L465.18 153.077L524.964 193.344L532.979 193.344L467.653 149.349L205.635 -14.2067Z"
            fill="#014A4F"
          />
          <path
            d="M352.656 77.7502L348.639 79.82C348.876 80.2663 372.596 124.669 380.29 149.562C385.204 165.461 385.832 181.206 384.165 193.344L388.716 193.344C390.355 180.668 389.648 164.497 384.635 148.27C376.814 122.97 353.639 79.583 352.656 77.7502Z"
            fill="#014A4F"
          />
          <path
            d="M13.0868 93.6886C11.3487 91.5279 6.585 86.0058 0.000282288 78.4533L0.000281988 85.3105C4.74428 90.7695 8.13341 94.7235 9.52382 96.4496C14.0901 102.122 14.6036 104.638 14.2283 105.756C13.703 107.316 10.3178 108.387 7.81347 109.058C5.83055 109.587 3.13267 110.567 0.00423476 111.835L0.00423455 116.662C3.70542 115.121 6.86937 113.925 9.0103 113.352C13.2605 112.214 17.3567 110.678 18.5417 107.15C19.6517 103.844 18.0204 99.819 13.0907 93.6965L13.0868 93.6886Z"
            fill="#014A4F"
          />
          <path
            d="M762.678 124.14C759.284 125.731 756.081 127.236 753.502 128.417C741.047 134.113 683.23 183.971 672.964 193.344L679.683 193.344C696.819 178.14 744.989 137.222 755.421 132.45C758.02 131.261 761.236 129.752 764.641 128.157C772.541 124.448 782.404 119.822 790.004 116.658L790.004 111.831C782.187 114.991 771.652 119.933 762.681 124.143L762.678 124.14Z"
            fill="#014A4F"
          />
          <path
            d="M698.395 -27.3881C690.826 -37.99 687.658 -47.7663 685.845 -66.1419C684.099 -83.8183 682.693 -134.15 682.666 -134.474L678.143 -134.079C678.17 -133.755 679.581 -83.4075 681.326 -65.7153C683.226 -46.4707 686.596 -36.1572 694.674 -24.8363C699.746 -17.7302 734.455 21.865 762.342 53.6826C773.121 65.979 783.02 77.2761 789.996 85.3065L789.996 78.4493C783.364 70.8415 774.887 61.1678 765.786 50.7832C737.962 19.0407 703.336 -20.4636 698.395 -27.3881Z"
            fill="#014A4F"
          />
          <path
            d="M110.246 -17.8448L107.39 -21.305C106.375 -20.5032 53.1362 21.7188 0.000255688 63.609L0.000255438 69.3168C54.0842 26.688 109.215 -17.0271 110.246 -17.8448Z"
            fill="#014A4F"
          />
          <path
            d="M663.141 176.722C662.398 179.638 661.557 182.944 660.443 186.783C659.775 189.082 659.19 191.259 658.677 193.348L663.346 193.348C663.784 191.638 664.266 189.864 664.807 188.004C665.941 184.093 666.79 180.751 667.541 177.801C670.914 164.54 672.048 160.073 687.465 149.191C696.518 142.799 742.86 106.471 789.996 69.3208L789.996 63.613C741.952 101.49 693.998 139.094 684.814 145.58C668.046 157.415 666.609 163.067 663.137 176.726L663.141 176.722Z"
            fill="#014A4F"
          />
          <path
            d="M494.099 -54.0232C475.134 -78.0354 468.933 -112.78 466.041 -128.387L461.574 -127.589C464.556 -111.508 470.868 -76.171 490.508 -51.2976C509.342 -27.4473 582.769 65.6472 582.769 65.6472L582.816 65.7065L667.612 165.935L671.104 163.095L586.332 62.8941C584.839 60.998 512.731 -30.4217 494.091 -54.0232L494.099 -54.0232Z"
            fill="#014A4F"
          />
          <path
            d="M679.818 -63.7877C676.843 -57.9534 673.77 -51.9257 658.961 -57.0647C639.227 -63.922 624.031 -60.0549 605.904 -51.5899C598.352 -48.0625 584.464 -40.3916 569.758 -32.2703C551.694 -22.2925 533.014 -11.9789 521.958 -6.97424C504.937 0.72834 496.03 14.8379 489.528 25.1396C487.984 27.5886 486.649 29.7058 485.377 31.4557C478.717 40.6395 427.394 122.018 425.214 125.479L429.073 127.817C429.606 126.972 482.501 43.1004 489.078 34.0311C490.433 32.1628 491.871 29.8875 493.392 27.478C499.593 17.6582 508.082 4.20436 523.862 -2.93731C535.084 -8.01706 553.847 -18.378 571.985 -28.3953C586.62 -36.4771 600.445 -44.1125 607.856 -47.5727C625.374 -55.7533 639.105 -59.2412 657.445 -52.8697C676.033 -46.4114 680.754 -55.6703 683.874 -61.7968C684.427 -62.8831 684.953 -63.9101 685.482 -64.704L681.682 -67.1412C681.007 -66.13 680.426 -64.9885 679.81 -63.7837L679.818 -63.7877Z"
            fill="#014A4F"
          />
          <path
            d="M143.632 15.1578C142.052 2.76655 135.909 -6.26721 124.849 -12.4648C115.235 -17.8487 104.025 -25.2116 93.7703 -38.9656C84.7365 -51.0883 24.1153 -108.731 0.000305176 -131.59L0.000304898 -125.234C27.4965 -99.2116 81.9439 -47.2883 90.1086 -36.3388C100.865 -21.9054 112.569 -14.2186 122.598 -8.59771C132.331 -3.1427 137.738 4.80875 139.129 15.7148C140.274 24.709 151.472 126.782 158.583 193.348L163.145 193.348C156.027 126.703 144.785 24.1797 143.636 15.1618L143.632 15.1578Z"
            fill="#014A4F"
          />
          <path
            d="M736.477 21.0829L739.783 24.1323L790 -28.1109L790 -34.5969L736.477 21.0829Z"
            fill="#014A4F"
          />
          <path
            d="M790 1459.64L790 826.5L-0.000106812 826.5L-0.000134487 1459.64L790 1459.64Z"
            fill="black"
          />
          <path
            d="M350.432 826.5C351.443 837.485 352.537 848.569 352.806 851.267L335.315 869.887C334.829 870.405 334.865 871.206 335.39 871.684C335.639 871.91 335.955 872.02 336.267 872.02C336.618 872.02 336.966 871.882 337.223 871.609L355.109 852.57C355.357 852.306 355.48 851.946 355.444 851.587C355.421 851.365 354.184 838.899 353.043 826.504L350.436 826.504L350.432 826.5Z"
            fill="#014A4F"
          />
          <path
            d="M365.477 881.962C364.411 881.496 339.312 870.515 333.47 868.224C328.39 866.229 317.579 864.562 314.123 864.065L312.259 826.5L309.663 826.5L311.587 865.234C311.619 865.846 312.089 866.348 312.705 866.431C312.847 866.451 326.834 868.358 332.506 870.582C338.301 872.854 364.158 884.167 364.419 884.281C364.589 884.356 364.77 884.392 364.948 884.392C365.446 884.392 365.916 884.111 366.133 883.637C366.425 882.997 366.133 882.243 365.477 881.958L365.477 881.962Z"
            fill="#014A4F"
          />
          <path
            d="M533.852 828.372L523.751 826.5L509.8 826.5L533.37 830.873C533.453 830.888 533.532 830.896 533.611 830.896C534.223 830.896 534.764 830.47 534.883 829.861C535.017 829.17 534.555 828.507 533.852 828.376L533.852 828.372Z"
            fill="#014A4F"
          />
          <path
            d="M418.242 826.788C418.665 826.883 419.083 826.761 419.384 826.5L416.729 826.5C417.223 826.583 417.725 826.674 418.242 826.788Z"
            fill="#014A4F"
          />
          <path
            d="M396.963 829.364C396.394 829.759 395.853 830.134 395.348 830.458L269.823 844.01C269.108 844.058 268.567 844.662 268.615 845.361C268.662 846.033 269.231 846.55 269.906 846.55C269.934 846.55 269.965 846.55 269.993 846.55L395.853 832.974C396.07 832.958 396.28 832.891 396.465 832.776C397.097 832.381 397.757 831.923 398.456 831.441C401.103 829.609 404.373 827.349 408.94 826.5L401.494 826.5C399.744 827.44 398.255 828.471 396.963 829.364Z"
            fill="#014A4F"
          />
          <path
            d="M352.964 973.532C352.679 974.176 352.979 974.927 353.635 975.207C353.805 975.278 353.979 975.314 354.153 975.314C354.654 975.314 355.128 975.029 355.342 974.551L421.169 826.504L418.341 826.504L352.968 973.536L352.964 973.532Z"
            fill="#014A4F"
          />
          <path
            d="M375.645 988.278C375.356 988.922 375.657 989.672 376.312 989.953C376.482 990.024 376.66 990.059 376.834 990.059C377.331 990.059 377.809 989.775 378.023 989.297C380.681 983.344 438.755 853.329 450.249 826.496L447.437 826.496C434.895 855.631 376.297 986.832 375.649 988.274L375.645 988.278Z"
            fill="#014A4F"
          />
          <path
            d="M440.718 983.968L339.368 942.473C338.704 942.2 337.946 942.509 337.673 943.156C337.397 943.804 337.709 944.551 338.368 944.819L440.789 986.753C440.951 986.82 441.121 986.852 441.287 986.852C441.745 986.852 442.187 986.611 442.42 986.196L532.983 826.496L530.016 826.496L440.718 983.968Z"
            fill="#014A4F"
          />
          <path
            d="M539.978 857.022C527.903 878.206 516.495 898.217 514.157 903.936C509.093 916.328 469.92 952.475 465.753 954.856C465.137 955.212 464.927 955.99 465.287 956.594C465.65 957.203 466.444 957.404 467.061 957.053C471.362 954.592 511.167 918.082 516.562 904.881C518.838 899.315 530.206 879.371 542.242 858.262C548.491 847.301 554.822 836.189 560.226 826.496L557.268 826.496C551.927 836.051 545.797 846.807 539.974 857.022L539.978 857.022Z"
            fill="#014A4F"
          />
          <path
            d="M405.242 1011.16C404.966 1011.8 405.282 1012.55 405.942 1012.82C406.104 1012.89 406.273 1012.92 406.439 1012.92C406.949 1012.92 407.431 1012.62 407.636 1012.13L486.309 826.5L483.501 826.5L405.242 1011.16Z"
            fill="#014A4F"
          />
          <path
            d="M532.212 873.106L476.446 847.206L450.237 826.5L440.777 826.5L448.1 828.08L474.937 849.284C475.016 849.347 475.103 849.398 475.194 849.442L531.099 875.409C531.276 875.492 531.466 875.532 531.652 875.532C532.137 875.532 532.604 875.263 532.825 874.805C533.133 874.169 532.856 873.411 532.209 873.11L532.212 873.106Z"
            fill="#014A4F"
          />
          <path
            d="M517.258 900.117L405.238 858.973C404.571 858.728 403.82 859.06 403.568 859.72C403.315 860.375 403.654 861.11 404.326 861.355L516.345 902.499C516.495 902.554 516.649 902.582 516.799 902.582C517.325 902.582 517.819 902.266 518.012 901.756C518.265 901.1 517.925 900.366 517.254 900.121L517.258 900.117Z"
            fill="#014A4F"
          />
          <path
            d="M494.604 929.284L391.315 890.321C390.651 890.068 389.896 890.396 389.64 891.051C389.383 891.707 389.715 892.442 390.386 892.695L493.676 931.658C493.83 931.717 493.988 931.745 494.142 931.745C494.663 931.745 495.153 931.433 495.351 930.927C495.607 930.271 495.276 929.537 494.604 929.284Z"
            fill="#014A4F"
          />
          <path
            d="M590.981 1153.22C588.738 1151.48 384.663 992.943 373.401 984.569C363.226 977.004 352.786 971.348 343.207 968.219C335.058 965.557 253.115 952.972 252.471 952.771C251.787 952.553 251.582 952.881 251.361 953.545C251.14 954.212 251.511 954.931 252.194 955.149C252.838 955.354 334.244 967.974 342.385 970.633C351.7 973.674 361.883 979.197 371.833 986.595C383.071 994.953 587.126 1153.47 589.37 1155.21C589.607 1155.4 589.891 1155.49 590.175 1155.49C590.558 1155.49 590.938 1155.32 591.191 1155.01C591.637 1154.46 591.542 1153.66 590.981 1153.22L590.981 1153.22Z"
            fill="#014A4F"
          />
          <path
            d="M396.983 1013.43L396.805 1013.33C395.581 1012.65 394.4 1011.78 392.974 1010.51C391.591 1009.28 390.047 1007.74 388.17 1005.85C384.374 1002.02 373.792 993.764 369.23 991.734C364.932 989.818 325.586 974.709 323.911 974.069C323.251 973.817 322.513 974.125 322.24 974.765L250.602 1120.37C250.326 1121.02 250.638 1121.77 251.301 1122.03C251.463 1122.1 252.791 1121.84 252.996 1121.35L324.148 976.894C330.899 979.489 364.332 992.346 368.156 994.053C372.129 995.826 382.372 1003.66 386.31 1007.63C388.218 1009.55 389.79 1011.12 391.228 1012.4C392.8 1013.79 394.123 1014.77 395.513 1015.54L395.695 1015.64C398.239 1017.07 399.519 1017.78 397.338 1022.37C395.446 1026.34 390.588 1036.01 386.334 1040.07C382.257 1043.97 373.895 1056.08 373.543 1056.59C373.14 1057.17 373.298 1057.96 373.891 1058.36C374.483 1058.75 375.289 1058.6 375.692 1058.01C375.779 1057.89 384.256 1045.6 388.143 1041.89C392.539 1037.69 397.263 1028.53 399.689 1023.44C402.647 1017.22 400.325 1015.3 396.979 1013.43L396.983 1013.43Z"
            fill="#014A4F"
          />
          <path
            d="M361.523 1038.06C359.821 1037.48 358.355 1036.97 357.459 1036.58C355.744 1035.82 353.485 1034.24 350.874 1032.42C346.687 1029.49 341.473 1025.85 335.801 1023.75C326.87 1020.45 271.336 1000.97 270.775 1000.78C270.1 1000.54 269.357 1000.89 269.116 1001.55C268.875 1002.21 269.227 1002.94 269.898 1003.17C270.459 1003.37 325.969 1022.83 334.88 1026.13C340.237 1028.11 345.297 1031.65 349.361 1034.49C352.087 1036.39 354.437 1038.03 356.384 1038.89C357.388 1039.34 358.904 1039.86 360.662 1040.46C365.663 1042.17 374.029 1045.04 375.57 1048.35C375.85 1048.95 375.866 1049.52 375.617 1050.13C372.982 1056.65 343.539 1156.01 342.255 1160.36C342.058 1161.03 342.413 1161.51 343.1 1161.7C343.219 1161.74 343.385 1161.98 343.503 1161.98C344.068 1161.98 344.586 1161.62 344.748 1161.06C345.064 1159.99 375.439 1057.47 378.031 1051.06C378.54 1049.8 378.509 1048.52 377.928 1047.28C375.925 1042.99 367.599 1040.14 361.515 1038.06L361.523 1038.06Z"
            fill="#014A4F"
          />
          <path
            d="M392.594 1010.28C391.931 1010.01 391.172 1010.32 390.904 1010.97L375.633 1047.54C375.36 1048.19 375.68 1048.93 376.34 1049.2C376.502 1049.26 376.668 1049.29 376.83 1049.29C377.339 1049.29 377.825 1048.99 378.031 1048.5L393.301 1011.93C393.574 1011.28 393.254 1010.54 392.594 1010.28Z"
            fill="#014A4F"
          />
          <path
            d="M387.878 1005.63C387.25 1005.29 386.464 1005.5 386.112 1006.12L366.334 1040.57C365.983 1041.18 366.208 1041.95 366.832 1042.3C367.034 1042.41 367.251 1042.46 367.464 1042.46C367.918 1042.46 368.361 1042.23 368.598 1041.81L388.376 1007.36C388.727 1006.75 388.502 1005.97 387.878 1005.63Z"
            fill="#014A4F"
          />
          <path
            d="M373.97 994.546C373.314 994.27 372.548 994.566 372.267 995.214C366.188 1009.09 355.27 1031.91 351.846 1034.06C351.241 1034.44 351.064 1035.22 351.451 1035.82C351.7 1036.2 352.118 1036.4 352.545 1036.4C352.782 1036.4 353.027 1036.34 353.244 1036.2C358.466 1032.93 373.01 999.966 374.653 996.221C374.934 995.577 374.63 994.827 373.974 994.55L373.97 994.546Z"
            fill="#014A4F"
          />
          <path
            d="M350.969 984.553C350.388 984.142 349.578 984.268 349.156 984.837L345.427 989.877L320.242 982.44C319.554 982.238 318.831 982.617 318.626 983.289C318.421 983.96 318.808 984.671 319.495 984.873L345.561 992.571C345.684 992.607 345.81 992.627 345.937 992.627C346.343 992.627 346.738 992.437 346.987 992.101L351.257 986.33C351.676 985.762 351.55 984.968 350.969 984.553Z"
            fill="#014A4F"
          />
          <path
            d="M346.411 989.573C345.759 989.289 344.989 989.573 344.696 990.217L332.135 1023.69C331.843 1024.33 332.135 1025.08 332.791 1025.36C332.961 1025.44 333.142 1025.48 333.32 1025.48C333.818 1025.48 334.288 1025.19 334.505 1024.72L347.066 991.252C347.359 990.612 347.066 989.858 346.411 989.573Z"
            fill="#014A4F"
          />
          <path
            d="M417.44 1018.56C416.808 1018.23 416.022 1018.47 415.686 1019.09L390.955 1065C390.931 1065.04 390.908 1065.09 390.892 1065.14C390.71 1065.6 386.448 1076.58 385.946 1085.91C385.457 1095.06 373.144 1181.93 373.121 1182.62C373.097 1183.32 373.658 1183.9 374.373 1183.93C374.389 1183.93 374.4 1183.93 374.416 1183.93C375.111 1183.93 375.688 1183.39 375.712 1182.7C375.736 1182.02 388.044 1095.17 388.534 1086.05C388.996 1077.49 392.922 1067.06 393.282 1066.12L417.973 1020.28C418.309 1019.66 418.064 1018.89 417.432 1018.56L417.44 1018.56Z"
            fill="#014A4F"
          />
          <path
            d="M632.326 885.652L627.776 902.447L612.288 912.867L545.315 900.061C544.616 899.923 543.932 900.377 543.794 901.065C543.656 901.752 544.114 902.424 544.817 902.558L612.323 915.467C612.406 915.482 612.489 915.49 612.572 915.49C612.833 915.49 613.09 915.415 613.307 915.269L629.64 904.28C629.897 904.106 630.079 903.854 630.158 903.557L634.85 886.24C634.862 886.197 634.87 886.153 634.878 886.106L644.259 826.5L641.636 826.5L632.326 885.652Z"
            fill="#014A4F"
          />
          <path
            d="M592.613 891.727L561.573 875.583C560.977 875.271 560.234 875.465 559.871 876.025L501.671 966.074C501.288 966.667 501.465 967.453 502.074 967.828C502.291 967.963 502.528 968.026 502.765 968.026C503.195 968.026 503.614 967.817 503.863 967.433L561.419 878.38L591.4 893.971C592.036 894.298 592.818 894.061 593.154 893.445C593.489 892.825 593.248 892.055 592.616 891.727L592.613 891.727Z"
            fill="#014A4F"
          />
          <path
            d="M572.084 881.054C571.428 880.777 570.662 881.074 570.378 881.718L515.61 1006.23C515.595 1006.27 515.579 1006.31 515.567 1006.35L504.029 1043.11C503.808 1043.78 504.179 1044.5 504.862 1044.72C504.997 1044.76 505.131 1044.78 505.261 1044.78C505.806 1044.78 506.316 1044.44 506.494 1043.9L518.012 1007.19L572.756 882.729C573.04 882.085 572.736 881.334 572.076 881.058L572.084 881.054Z"
            fill="#014A4F"
          />
          <path
            d="M625.631 948.923L528.879 925.594C528.183 925.429 527.48 925.843 527.31 926.527C527.141 927.21 527.563 927.897 528.258 928.063L625.011 951.392C625.113 951.416 625.22 951.428 625.323 951.428C625.907 951.428 626.437 951.041 626.583 950.46C626.753 949.777 626.33 949.089 625.635 948.923L625.631 948.923Z"
            fill="#014A4F"
          />
          <path
            d="M595.326 1124.81C587.829 1115.76 585.348 1106.35 587.738 1096.03C589.302 1089.29 609.641 952.289 610.506 946.459C610.609 945.763 610.119 945.12 609.408 945.017C608.701 944.918 608.041 945.396 607.939 946.091C607.725 947.517 586.747 1088.82 585.206 1095.47C582.627 1106.6 585.277 1116.72 593.312 1126.41C603.408 1138.59 649.078 1208.79 649.303 1209.05C649.56 1209.34 649.924 1209.49 650.291 1209.49C650.587 1209.49 650.887 1209.39 651.132 1209.19C651.677 1208.73 651.741 1207.93 651.278 1207.39C651.053 1207.14 605.403 1136.97 595.326 1124.81Z"
            fill="#014A4F"
          />
          <path
            d="M615.487 965.703L606.975 964.139C606.272 964.009 605.592 964.463 605.462 965.154C605.332 965.845 605.794 966.509 606.497 966.639L615.009 968.204C615.088 968.219 615.171 968.223 615.25 968.223C615.862 968.223 616.408 967.797 616.522 967.184C616.656 966.493 616.19 965.83 615.487 965.699L615.487 965.703Z"
            fill="#014A4F"
          />
          <path
            d="M10.4992 882.954C9.97382 882.476 9.1522 882.507 8.66635 883.025L-0.00396675 892.197L-0.00396691 895.942L10.5703 884.747C11.0562 884.23 11.0245 883.428 10.4992 882.95L10.4992 882.954Z"
            fill="#014A4F"
          />
          <path
            d="M655.564 1034.8C642.889 1031.97 505.21 1001.31 498.7 1000.71C489.505 999.851 482.888 1003.58 480.092 1011.22L479.582 1012.6C476.533 1020.87 474.842 1026.05 477.078 1028.35C478.073 1029.37 479.626 1029.58 481.695 1028.95C487.131 1027.3 502.188 1032.38 507.529 1034.47C508.192 1034.73 508.947 1034.41 509.215 1033.76C509.48 1033.11 509.156 1032.37 508.492 1032.11C507.675 1031.79 488.343 1024.28 480.929 1026.52C479.953 1026.82 479.199 1026.85 478.954 1026.6C477.662 1025.27 480.285 1018.17 482.019 1013.47L482.533 1012.08C485.555 1003.84 492.862 1002.73 498.455 1003.24C504.969 1003.85 654.213 1037.11 655.718 1037.44C655.813 1037.46 655.912 1037.48 656.007 1037.48C656.362 1037.48 656.71 1037.33 656.959 1037.07L790 895.946L790 892.201L655.564 1034.8L655.564 1034.8Z"
            fill="#014A4F"
          />
          <path
            d="M594.149 1046.75L576.698 1044.48L576.698 1035.13C576.698 1034.5 576.224 1033.96 575.588 1033.87L564.18 1032.24L568.49 1017.16C568.683 1016.49 568.28 1015.79 567.593 1015.6C566.902 1015.41 566.187 1015.8 565.993 1016.48L561.285 1032.94C561.186 1033.29 561.241 1033.67 561.447 1033.98C561.648 1034.28 561.976 1034.49 562.347 1034.54L574.103 1036.23L574.103 1045.6C574.103 1046.23 574.585 1046.77 575.228 1046.86L593.805 1049.27C593.865 1049.27 593.92 1049.28 593.979 1049.28C594.619 1049.28 595.176 1048.81 595.263 1048.17C595.358 1047.48 594.856 1046.84 594.149 1046.75Z"
            fill="#014A4F"
          />
          <path
            d="M541.815 1012.27C541.91 1012.31 542.013 1012.35 542.119 1012.37C542.009 1012.35 541.906 1012.31 541.815 1012.27Z"
            fill="#014A4F"
          />
          <path
            d="M548.056 1039.49L536.633 1032.72C543.98 1011.29 543.909 1011.16 543.529 1010.51C543.348 1010.19 543.036 1009.97 542.676 1009.89C541.977 1009.73 541.286 1010.17 541.132 1010.86C541.116 1010.93 541.108 1011 541.104 1011.07C540.745 1012.58 536.494 1025.14 533.844 1032.87C533.65 1033.44 533.883 1034.06 534.401 1034.37L546.713 1041.66C546.922 1041.78 547.155 1041.84 547.381 1041.84C547.819 1041.84 548.25 1041.63 548.491 1041.23C548.862 1040.63 548.664 1039.85 548.052 1039.49L548.056 1039.49ZM541.815 1012.27C541.72 1012.22 541.629 1012.16 541.554 1012.1C541.629 1012.16 541.716 1012.22 541.815 1012.27Z"
            fill="#014A4F"
          />
          <path
            d="M758.336 1004.28C758.19 1004.39 743.725 1015.42 737.508 1020.37C731.5 1025.16 721.755 1038.92 720.333 1040.94L676.713 1066.86C676.101 1067.23 675.903 1068.01 676.274 1068.61C676.519 1069 676.946 1069.22 677.384 1069.22C677.613 1069.22 677.846 1069.16 678.056 1069.04L721.917 1042.97C722.075 1042.88 722.213 1042.75 722.316 1042.6C722.423 1042.44 733.084 1027.17 739.143 1022.34C745.341 1017.41 759.782 1006.39 759.928 1006.28C760.493 1005.85 760.596 1005.05 760.157 1004.5C759.719 1003.94 758.905 1003.84 758.336 1004.27L758.336 1004.28Z"
            fill="#014A4F"
          />
          <path
            d="M53.4993 926.365C52.9384 925.93 52.1208 926.021 51.6784 926.57L1.24443e-05 990.478L1.22653e-05 994.574L53.7126 928.15C54.159 927.601 54.0642 926.799 53.4993 926.365Z"
            fill="#014A4F"
          />
          <path
            d="M770.637 1014.44L716.043 1079.81L684.992 1085.15C684.285 1085.27 683.815 1085.93 683.937 1086.62C684.048 1087.24 684.597 1087.67 685.213 1087.67C685.288 1087.67 685.363 1087.66 685.438 1087.65L716.964 1082.23C717.272 1082.18 717.548 1082.02 717.742 1081.79L772.651 1016.03L790 994.574L790 990.478L770.637 1014.44Z"
            fill="#014A4F"
          />
          <path
            d="M105.036 975.262L40.089 942.678L3.05176e-05 913.527L3.03791e-05 916.695L38.6314 944.788C38.6906 944.831 38.7499 944.867 38.8131 944.898L103.854 977.53C104.044 977.624 104.246 977.668 104.443 977.668C104.913 977.668 105.367 977.415 105.6 976.977C105.928 976.353 105.675 975.586 105.036 975.266L105.036 975.262Z"
            fill="#014A4F"
          />
          <path
            d="M740.766 877.724L713.807 849.066L695.633 826.496L692.327 826.496L711.801 850.678C711.82 850.702 711.844 850.729 711.864 850.753L738.937 879.533C738.993 879.592 739.052 879.644 739.119 879.691L790 916.695L790 913.527L740.766 877.724Z"
            fill="#014A4F"
          />
          <path
            d="M726.369 826.5L723.011 826.5L734.186 839.823C734.443 840.128 734.814 840.286 735.189 840.286C735.478 840.286 735.77 840.191 736.011 839.997C736.564 839.551 736.643 838.749 736.189 838.208L726.369 826.5Z"
            fill="#014A4F"
          />
          <path
            d="M682.681 856.117L668.663 835.309C668.461 835.008 668.141 834.807 667.778 834.752L625.52 828.364C625.177 828.313 624.825 828.396 624.549 828.601C624.272 828.803 624.086 829.107 624.039 829.447C622.834 837.908 617.277 843.268 613.603 846.819C612.714 847.676 611.948 848.419 611.383 849.074C608.48 852.444 607.737 856.161 609.29 859.542C610.569 862.323 629.036 875.678 634.669 879.703C634.894 879.861 635.158 879.948 635.431 879.948C635.502 879.948 635.573 879.944 635.644 879.932L662.88 875.516C663.109 875.48 663.322 875.382 663.504 875.235L722.707 826.504L718.674 826.504L682.693 856.121L682.681 856.117ZM662.098 873.059L635.751 877.333C624.501 869.271 612.513 860.099 611.644 858.483C610.526 856.058 611.134 853.293 613.358 850.714C613.848 850.145 614.575 849.446 615.416 848.632C618.932 845.235 624.711 839.661 626.389 831.07L666.814 837.181L680.683 857.764L662.102 873.059L662.098 873.059Z"
            fill="#014A4F"
          />
          <path
            d="M658.479 846.471L621.626 838.638C620.922 838.492 620.235 838.927 620.085 839.61C619.935 840.297 620.377 840.973 621.076 841.123L657.93 848.956C658.021 848.976 658.116 848.984 658.207 848.984C658.803 848.984 659.341 848.577 659.475 847.98C659.625 847.293 659.183 846.618 658.483 846.467L658.479 846.471Z"
            fill="#014A4F"
          />
          <path
            d="M107.773 1000.97L90.5823 993.397L27.1921 958.558C27.1684 958.546 27.1486 958.534 27.1249 958.522C26.8366 958.384 14.7218 952.617 1.52588e-05 945.59L1.51352e-05 948.418C13.8923 955.05 25.2013 960.434 25.9557 960.793L89.3657 995.645C89.3973 995.664 89.4329 995.68 89.4684 995.696L106.714 1003.29C106.888 1003.36 107.066 1003.4 107.244 1003.4C107.737 1003.4 108.429 1001.25 107.777 1000.97L107.773 1000.97Z"
            fill="#014A4F"
          />
          <path
            d="M737.986 920.602C736.694 919.954 735.466 919.326 734.296 918.729C726.831 914.922 721.901 912.409 718.066 912.958C714.139 913.523 667.873 919.559 667.407 919.618C666.696 919.709 666.198 920.349 666.293 921.044C666.387 921.739 667.039 922.229 667.75 922.138C668.216 922.079 714.503 916.039 718.445 915.475C721.451 915.048 726.337 917.536 733.1 920.985C734.273 921.581 735.505 922.213 736.801 922.865C742.208 925.583 769.045 938.416 789.996 948.414L789.996 945.586C769.235 935.675 743.279 923.26 737.982 920.602L737.986 920.602Z"
            fill="#014A4F"
          />
          <path
            d="M697.561 915.443C696.826 915.415 696.246 915.968 696.222 916.671L694.915 954.77L645.496 1008.58C645.018 1009.1 645.061 1009.91 645.59 1010.38C645.839 1010.6 646.147 1010.71 646.459 1010.71C646.815 1010.71 647.166 1010.56 647.423 1010.28L697.158 956.128C697.364 955.907 697.482 955.619 697.49 955.323L698.813 916.762C698.837 916.059 698.276 915.471 697.561 915.447L697.561 915.443Z"
            fill="#014A4F"
          />
          <path
            d="M74.5096 1013.51L5.34058e-05 979.777L5.32833e-05 982.578L73.4234 1015.82C73.6011 1015.9 73.7828 1015.94 73.9645 1015.94C74.4543 1015.94 74.9244 1015.66 75.1416 1015.2C75.4418 1014.56 75.1574 1013.8 74.5057 1013.51L74.5096 1013.51Z"
            fill="#014A4F"
          />
          <path
            d="M668.252 924.662C667.6 924.37 666.83 924.647 666.53 925.282C666.229 925.918 666.514 926.677 667.166 926.969L790 982.578L790 979.777L668.252 924.662Z"
            fill="#014A4F"
          />
          <path
            d="M738.914 877.843C738.14 878.723 719.875 899.473 717.161 903.739C714.005 908.704 715.226 911.26 719.16 915.115C719.413 915.364 719.745 915.486 720.076 915.486C720.408 915.486 720.74 915.364 720.993 915.115C721.498 914.617 721.498 913.812 720.993 913.318C717.394 909.79 717.149 908.562 719.361 905.086C721.968 900.986 740.688 879.723 740.877 879.509C741.347 878.976 741.284 878.174 740.743 877.716C740.202 877.258 739.38 877.317 738.914 877.847L738.914 877.843Z"
            fill="#014A4F"
          />
          <path
            d="M719.5 933.202C718.816 933.408 718.433 934.123 718.642 934.794C719.318 936.943 720.981 943.16 720.558 946.103C720.053 949.623 719.184 950.977 715.083 954.639C710.644 958.597 707.642 964.38 707.515 964.625C707.191 965.253 707.448 966.015 708.088 966.331C708.274 966.422 708.475 966.469 708.673 966.469C709.147 966.469 709.601 966.213 709.83 965.77C709.858 965.715 712.749 960.157 716.825 956.523C721.408 952.435 722.525 950.65 723.126 946.459C723.714 942.354 721.226 934.387 721.119 934.048C720.906 933.376 720.179 933.001 719.496 933.206L719.5 933.202Z"
            fill="#014A4F"
          />
          <path
            d="M71.9026 958.668C71.3457 958.23 70.528 958.317 70.0777 958.866L3.43859e-05 1044.43L3.42084e-05 1048.49L72.1001 960.454C72.5465 959.905 72.4596 959.107 71.8987 958.668L71.9026 958.668Z"
            fill="#014A4F"
          />
          <path
            d="M97.5146 984.814L47.0765 956.014C46.4564 955.658 45.6624 955.868 45.303 956.472C44.9435 957.08 45.1529 957.858 45.7691 958.21L96.2071 987.01C96.4125 987.128 96.6377 987.184 96.8589 987.184C97.3052 987.184 97.7397 986.958 97.9807 986.552C98.3401 985.943 98.1308 985.165 97.5146 984.814Z"
            fill="#014A4F"
          />
          <path
            d="M116.001 962.196C112.019 958.123 97.3488 949.362 88.5323 948.54C83.6145 948.082 74.7861 941.292 67.6958 935.833C63.3587 932.499 59.618 929.62 56.8608 928.134C49.7626 924.315 29.8347 907.914 21.9504 897.865C18.3835 893.319 13.4341 886.754 8.65063 880.406C5.7039 876.496 2.70978 872.522 0.00794983 868.983L0.00794964 873.237C2.12517 876.029 4.36088 878.992 6.565 881.919C11.3564 888.279 16.3137 894.855 19.8964 899.418C28.1046 909.881 48.1668 926.357 55.6166 930.366C58.188 931.749 62.0314 934.707 66.1 937.836C73.8421 943.792 82.6191 950.543 88.2953 951.072C96.1243 951.803 110.427 960.161 114.136 963.953C114.393 964.214 114.733 964.344 115.073 964.344C115.396 964.344 115.72 964.226 115.969 963.989C116.487 963.503 116.502 962.697 116.009 962.192L116.001 962.196Z"
            fill="#014A4F"
          />
          <path
            d="M783.427 860.533C776.57 852.053 771.233 854.869 766.944 857.136L766.237 857.508C761.872 859.775 734.897 872.395 734.624 872.522C733.977 872.822 733.704 873.584 734.012 874.216C734.32 874.848 735.09 875.121 735.742 874.817C736.856 874.295 763.037 862.05 767.449 859.755L768.172 859.376C772.312 857.192 775.886 855.304 781.393 862.113C783.569 864.803 786.635 868.797 789.996 873.229L789.996 868.975C787.448 865.633 785.157 862.674 783.423 860.533L783.427 860.533Z"
            fill="#014A4F"
          />
          <path
            d="M90.1715 922.948C89.8358 922.782 81.8527 918.86 75.5959 920.088C69.7459 921.234 52.7686 925.417 52.0497 925.594C51.3545 925.764 50.9318 926.456 51.1096 927.139C51.2557 927.716 51.785 928.099 52.3657 928.099C52.4723 928.099 52.575 928.087 52.6817 928.059C52.8555 928.016 70.3186 923.714 76.1054 922.581C81.4735 921.53 88.9233 925.176 88.9983 925.215C89.6382 925.531 90.4164 925.279 90.7403 924.654C91.0642 924.026 90.8075 923.264 90.1715 922.944L90.1715 922.948Z"
            fill="#014A4F"
          />
          <path
            d="M144.579 923.011C144.556 922.992 144.532 922.972 144.508 922.956L109.59 897.616C109.309 897.415 108.958 897.328 108.618 897.383C108.275 897.435 107.966 897.62 107.765 897.897L85.3959 928.506C84.9812 929.075 85.1115 929.869 85.6922 930.279L121.243 955.413C121.823 955.824 122.633 955.694 123.052 955.125C123.47 954.556 123.34 953.762 122.759 953.351L88.2597 928.96L109.112 900.433L142.932 924.974L152.547 933.052L143.114 944.788C143.114 944.788 143.086 944.819 143.074 944.839L132.883 958.929C132.5 959.458 132.587 960.181 133.077 960.612L145.737 971.696C145.986 971.913 146.294 972.019 146.602 972.019C146.957 972.019 147.313 971.877 147.57 971.593C148.047 971.071 148 970.266 147.467 969.796L135.672 959.466L145.172 946.328L155.355 933.657C155.786 933.123 155.707 932.349 155.181 931.903L144.587 923.003L144.579 923.011Z"
            fill="#014A4F"
          />
          <path
            d="M134.684 938.389L98.1821 913.179C97.5975 912.777 96.7878 912.911 96.373 913.488C95.9622 914.06 96.1005 914.854 96.6851 915.257L133.187 940.466C133.413 940.624 133.673 940.699 133.934 940.699C134.341 940.699 134.744 940.514 134.997 940.158C135.407 939.586 135.269 938.792 134.684 938.389Z"
            fill="#014A4F"
          />
          <path
            d="M150.398 962.053L93.1617 920.104C92.5889 919.685 91.7752 919.8 91.3447 920.361C90.9141 920.922 91.0326 921.72 91.6054 922.138L148.055 963.511L143.161 975.934C142.905 976.59 143.236 977.324 143.904 977.577C144.058 977.636 144.216 977.664 144.37 977.664C144.891 977.664 145.381 977.356 145.579 976.85L150.828 963.531C151.038 962.997 150.86 962.397 150.394 962.053L150.398 962.053Z"
            fill="#014A4F"
          />
          <path
            d="M244.661 1082.61C244.618 1082.43 244.535 1082.26 244.417 1082.12L238.859 1075.22C239.775 1067.1 251.017 967.655 252.139 959.837C252.968 954.043 252.633 951.317 250.938 950.124C249.311 948.979 247.095 949.84 245.566 950.59C244.409 951.159 240.834 952.68 236.311 954.608C221.878 960.754 197.719 971.048 188.539 976.949C179.758 982.594 168.825 983.261 155.114 978.983C147.893 976.732 109.171 969.748 106.932 970.281C106.236 970.451 105.814 971.142 105.983 971.822C106.145 972.462 106.777 972.865 107.429 972.774L107.449 972.774C110.163 972.774 145.914 978.786 154.328 981.409C159.657 983.072 175.879 988.128 189.961 979.078C198.96 973.295 223.932 962.658 237.346 956.942C242.082 954.924 245.507 953.466 246.727 952.87C248.781 951.862 249.334 952.127 249.425 952.194C249.749 952.423 250.417 953.592 249.571 959.49C248.406 967.635 236.347 1074.39 236.224 1075.46C236.185 1075.79 236.283 1076.13 236.497 1076.39L242.205 1083.48L256.369 1150.23C256.512 1150.81 257.045 1151.2 257.629 1151.2C257.728 1151.2 257.831 1151.19 257.934 1151.17C258.629 1151.01 259.059 1150.32 258.893 1149.64L244.661 1082.62L244.661 1082.61Z"
            fill="#014A4F"
          />
          <path
            d="M128.846 973.674C128.278 973.252 127.464 973.358 127.029 973.919L80.8454 1033.36C80.4109 1033.92 80.5255 1034.72 81.0943 1035.14C81.3273 1035.32 82.6546 1035.23 82.9113 1034.9L129.095 975.456C129.53 974.895 129.415 974.097 128.846 973.674Z"
            fill="#014A4F"
          />
          <path
            d="M133.705 975.144C133.002 975.27 132.532 975.93 132.658 976.621C133.294 980.07 131.84 985.868 127.144 990.45C122.396 995.084 91.7989 1036.24 90.5348 1037.95C90.1122 1038.52 90.2425 1039.31 90.8232 1039.73C91.0523 1039.89 91.3209 1039.97 91.5856 1039.97C91.9885 1039.97 92.3835 1039.79 92.6363 1039.44C92.9444 1039.03 124.371 996.743 128.977 992.251C134.365 986.994 135.992 980.386 135.214 976.167C135.087 975.476 134.412 975.014 133.705 975.144Z"
            fill="#014A4F"
          />
          <path
            d="M155.13 1008.43C154.241 1007.75 153.447 1007.09 152.744 1006.52C150.299 1004.52 148.687 1003.19 146.756 1003.46C145.452 1003.64 144.311 1004.54 143.051 1006.36C139.989 1010.82 73.6485 1086.98 72.9809 1087.75C72.5148 1088.28 72.582 1089.08 73.1231 1089.54C73.368 1089.75 73.6682 1089.85 73.9645 1089.85C74.3319 1089.85 74.6953 1089.69 74.952 1089.4C77.6894 1086.25 142.055 1012.36 145.199 1007.78C146.167 1006.37 146.776 1006.02 147.115 1005.97C147.905 1005.86 149.304 1007.01 151.077 1008.47C151.792 1009.05 152.606 1009.72 153.526 1010.43C158.906 1014.57 190.388 1044.28 190.704 1044.58C191.221 1045.07 192.039 1045.05 192.537 1044.55C193.034 1044.04 193.019 1043.24 192.501 1042.75C191.202 1041.52 160.609 1012.65 155.126 1008.43L155.13 1008.43Z"
            fill="#014A4F"
          />
          <path
            d="M204.565 1018.51C204.106 1018.21 203.984 1017.66 193.99 1020.52C193.572 1020.64 193.24 1020.96 193.113 1021.37C192.987 1021.78 193.078 1022.23 193.358 1022.56C198.774 1028.92 197.818 1036.97 190.593 1045.84C180.892 1057.75 143.872 1103.89 143.501 1104.36C143.059 1104.91 143.157 1105.71 143.718 1106.14C143.955 1106.32 144.24 1106.42 144.52 1106.42C144.903 1106.42 145.286 1106.25 145.539 1105.93C145.91 1105.46 182.926 1059.33 192.62 1047.43C201.887 1036.06 200.204 1027.77 196.534 1022.47C199.955 1021.56 203.498 1020.82 204.375 1020.96C204.952 1021.24 205.161 1018.9 204.565 1018.52L204.565 1018.51Z"
            fill="#014A4F"
          />
          <path
            d="M190.06 1066.96L115.199 1008.64C114.638 1008.2 113.824 1008.29 113.378 1008.83C112.932 1009.38 113.023 1010.18 113.579 1010.62L187.414 1068.14L73.1785 1204.02C72.7242 1204.56 72.8032 1205.36 73.3562 1205.81C73.5972 1206 73.8895 1206.09 74.1779 1206.09C74.5531 1206.09 74.9244 1205.94 75.1811 1205.63L190.254 1068.76C190.475 1068.5 190.578 1068.16 190.542 1067.82C190.506 1067.48 190.333 1067.17 190.06 1066.96Z"
            fill="#014A4F"
          />
          <path
            d="M213.673 1047.75C207.045 1015.75 188.354 979.118 188.168 978.754C187.844 978.126 187.066 977.873 186.426 978.189C185.786 978.505 185.529 979.268 185.849 979.896C186.035 980.259 204.573 1016.59 211.13 1048.26C217.714 1080.05 230.05 1176.34 230.153 1177.17C230.236 1177.82 230.793 1178.29 231.437 1178.29C231.492 1178.29 232.195 1177.33 232.25 1177.32C232.961 1177.24 232.815 1177.55 232.724 1176.86C232.618 1176.02 220.27 1079.64 213.666 1047.75L213.673 1047.75Z"
            fill="#014A4F"
          />
          <path
            d="M245.989 996.608L200.67 1009.89C199.983 1010.09 199.596 1010.8 199.797 1011.48C199.967 1012.03 200.48 1012.38 201.037 1012.38C201.16 1012.38 201.286 1012.37 201.409 1012.33L246.727 999.045C247.415 998.844 247.802 998.137 247.6 997.462C247.395 996.79 246.672 996.407 245.985 996.608L245.989 996.608Z"
            fill="#014A4F"
          />
          <path
            d="M234.976 957.234C235.043 956.535 234.522 955.915 233.811 955.848C233.096 955.773 232.464 956.29 232.397 956.989C232.345 957.499 227.329 1008.21 225.717 1017.29C224.05 1026.67 228.873 1047.27 229.079 1048.14C229.217 1048.73 231.769 1048.25 231.607 1047.57C231.559 1047.36 226.697 1026.6 228.277 1017.73C229.908 1008.55 234.779 959.328 234.984 957.234L234.976 957.234Z"
            fill="#014A4F"
          />
          <path
            d="M208.088 968.539C207.867 967.872 207.136 967.508 206.457 967.722C205.773 967.939 205.402 968.654 205.623 969.321L217.801 1006.23C217.979 1006.76 218.489 1007.11 219.034 1007.11C219.164 1007.11 219.302 1007.09 219.433 1007.04C220.112 1006.83 220.487 1006.11 220.266 1005.44L208.088 968.539Z"
            fill="#014A4F"
          />
          <path
            d="M230.963 976.886L212.382 986.374C211.746 986.698 211.501 987.464 211.829 988.088C212.062 988.523 212.512 988.771 212.978 988.771C213.18 988.771 213.385 988.724 213.575 988.629L232.156 979.141C232.792 978.817 233.037 978.051 232.709 977.427C232.377 976.803 231.595 976.562 230.959 976.886L230.963 976.886Z"
            fill="#014A4F"
          />
          <path
            d="M146.053 987.255L149.43 979.844C149.722 979.204 149.43 978.45 148.774 978.166C148.119 977.881 147.352 978.166 147.06 978.81L143.331 986.986C143.118 987.448 143.209 987.993 143.56 988.365L173.193 1019.93L171.262 1024.14L45.0383 1177.49C44.588 1178.03 44.6749 1178.84 45.2358 1179.28C45.4768 1179.47 45.7612 1179.56 46.0495 1179.56C46.4287 1179.56 46.804 1179.4 47.0608 1179.08L173.387 1025.61C173.458 1025.52 173.513 1025.43 173.561 1025.33L175.915 1020.2C176.128 1019.74 176.037 1019.19 175.686 1018.82L146.049 987.255L146.053 987.255Z"
            fill="#014A4F"
          />
          <path
            d="M163.239 1016.2C162.643 1016.26 160.822 1016.44 147.194 1035.07C146.776 1035.63 146.91 1036.43 147.491 1036.84C147.72 1037 147.984 1037.08 148.245 1037.08C148.648 1037.08 149.047 1036.89 149.3 1036.55C155.094 1028.63 161.865 1020.03 163.587 1018.78C163.907 1018.76 164.219 1018.62 164.456 1018.37C164.942 1017.86 164.914 1017.05 164.389 1016.58C164.081 1016.3 163.67 1016.16 163.239 1016.2Z"
            fill="#014A4F"
          />
          <path
            d="M155.11 1008.42C154.537 1008 153.724 1008.11 153.293 1008.67L138.319 1028.07C137.888 1028.64 137.999 1029.43 138.571 1029.86C138.804 1030.03 139.081 1030.11 139.353 1030.11C139.745 1030.11 140.136 1029.94 140.388 1029.61L155.363 1010.2C155.793 1009.64 155.683 1008.84 155.11 1008.42Z"
            fill="#014A4F"
          />
          <path
            d="M95.5633 832.472L95.5633 841.704C95.5633 842.063 95.7213 842.407 95.9939 842.648C96.2348 842.857 96.5429 842.975 96.8589 842.975C96.9063 842.975 96.9497 842.975 96.9971 842.968L148.383 837.584C149.074 837.513 149.584 836.916 149.541 836.237L148.881 826.5L146.282 826.5L146.87 835.186L98.1585 840.293L98.1585 832.678L100.201 826.504L97.4751 826.504L95.6304 832.085C95.587 832.212 95.5672 832.346 95.5672 832.476L95.5633 832.472Z"
            fill="#014A4F"
          />
          <path
            d="M274.682 843.457C273.054 843.631 111.774 860.834 107.133 861.213C102.31 861.608 99.9083 861.485 98.4271 859.743C97.4751 858.622 97.9057 856.026 98.3955 854.423L163.65 847.261C164.361 847.182 164.875 846.554 164.796 845.855C164.717 845.156 164.073 844.654 163.362 844.733L97.2856 851.986C96.8036 852.037 96.3928 852.349 96.219 852.791C95.9899 853.376 94.0544 858.57 96.4323 861.371C98.1585 863.401 100.6 863.938 103.582 863.938C104.755 863.938 106.015 863.855 107.346 863.745C112.019 863.362 268.31 846.693 274.958 845.982C275.669 845.906 276.187 845.278 276.108 844.579C276.029 843.88 275.389 843.378 274.678 843.453L274.682 843.457Z"
            fill="#014A4F"
          />
          <path
            d="M248.106 847.265C248.185 847.265 248.268 847.257 248.351 847.242C249.054 847.111 249.516 846.444 249.382 845.756L245.641 826.496L243.002 826.496L246.834 846.23C246.952 846.839 247.498 847.265 248.106 847.265Z"
            fill="#014A4F"
          />
          <path
            d="M280.504 854.3L133.768 873.146C133.057 873.237 132.555 873.873 132.65 874.572C132.733 875.212 133.294 875.682 133.934 875.682C133.989 875.682 134.045 875.682 134.104 875.67L280.84 856.824C281.551 856.733 282.053 856.097 281.958 855.398C281.867 854.703 281.219 854.209 280.504 854.3Z"
            fill="#014A4F"
          />
          <path
            d="M179.201 867.383C178.486 867.414 177.933 868.011 177.965 868.714L180.149 914.214C180.181 914.894 180.754 915.427 181.445 915.427C181.465 915.427 181.485 915.427 181.508 915.427C182.223 915.395 182.776 914.799 182.745 914.096L180.56 868.595C180.529 867.892 179.916 867.363 179.205 867.387L179.201 867.383Z"
            fill="#014A4F"
          />
          <path
            d="M234.478 956.966L171.329 916.687L168.145 900.705C168.007 900.014 167.328 899.564 166.625 899.702C165.921 899.836 165.463 900.504 165.601 901.195L168.896 917.738C168.967 918.093 169.188 918.401 169.504 918.587L233.151 959.154C233.36 959.277 233.59 959.332 233.815 959.332C234.257 959.332 234.688 959.111 234.929 958.712C235.296 958.107 235.095 957.329 234.478 956.97L234.478 956.966Z"
            fill="#014A4F"
          />
          <path
            d="M260.023 905.797C259.51 905.69 247.399 903.139 239.147 902.755C232.1 902.439 222.857 907.152 219.567 908.981L216.699 903.451C216.49 903.048 216.075 902.783 215.613 902.755C215.151 902.736 214.712 902.949 214.455 903.328L207.823 913.247L201.499 910.912C201.282 910.833 201.041 910.813 200.816 910.849L169.939 916.233C169.232 916.355 168.761 917.015 168.888 917.706C168.998 918.322 169.548 918.753 170.164 918.753C170.239 918.753 170.314 918.745 170.393 918.733L200.919 913.409L207.879 915.976C208.448 916.186 209.087 915.98 209.419 915.482L215.396 906.547L217.872 911.323C218.038 911.639 218.327 911.876 218.678 911.971C219.03 912.066 219.401 912.014 219.709 911.829C219.824 911.761 231.263 904.928 239.021 905.283C247.059 905.659 259.352 908.246 259.474 908.274C260.177 908.42 260.865 907.985 261.015 907.298C261.165 906.611 260.722 905.935 260.019 905.785L260.023 905.797Z"
            fill="#014A4F"
          />
          <path
            d="M249.37 859.617C249.2 858.934 248.501 858.511 247.806 858.681C247.111 858.847 246.684 859.534 246.85 860.217L271.47 957.092C271.613 957.673 272.146 958.064 272.73 958.064C272.833 958.064 272.936 958.052 273.039 958.028C273.734 957.862 274.16 957.175 273.994 956.492L249.374 859.617L249.37 859.617Z"
            fill="#014A4F"
          />
          <path
            d="M236.339 861.398C235.624 861.398 235.043 861.967 235.043 862.67L235.043 904.012C235.043 904.715 235.624 905.283 236.339 905.283C237.054 905.283 237.634 904.715 237.634 904.012L237.634 862.67C237.634 861.967 237.054 861.398 236.339 861.398Z"
            fill="#014A4F"
          />
          <path
            d="M236.129 882.085L167.118 893.153C166.411 893.267 165.933 893.919 166.048 894.614C166.151 895.238 166.704 895.681 167.328 895.681C167.399 895.681 167.466 895.677 167.537 895.665L236.548 884.597C237.255 884.483 237.733 883.831 237.619 883.136C237.504 882.444 236.837 881.974 236.129 882.085Z"
            fill="#014A4F"
          />
          <path
            d="M283.115 869.781C283.166 870.436 282.692 871.025 282.029 871.127L253.134 879.865C253 879.904 252.866 879.924 252.735 879.924C252.186 879.924 251.677 879.581 251.503 879.043C251.282 878.372 251.657 877.657 252.34 877.444L280.441 868.801L277.23 826.496L279.833 826.496L283.115 869.781Z"
            fill="#014A4F"
          />
          <path
            d="M661.659 1045.31L661.632 1045.23L603.372 881.872L570.524 851.788L532.979 826.5L524.964 826.5L567.814 855.359L599.458 884.372L657.318 1046.62L680.165 1130.8L742.726 1214.98L746.391 1212.36L684.332 1128.86L661.659 1045.31Z"
            fill="#014A4F"
          />
          <path
            d="M380.377 841.178C379.33 843.584 376.411 850.437 372.374 859.921C358.991 891.363 331.144 956.788 326.313 965.676L325.637 966.92C319.819 977.656 316.611 983.569 298.583 989.226C279.047 995.356 243.923 1012.35 243.773 1012.42L244.792 1016.63C244.942 1016.56 280.733 999.5 299.966 993.464C319.752 987.259 323.686 979.998 329.643 969.009L330.314 967.773C335.248 958.7 362.124 895.558 376.561 861.635C380.594 852.163 383.505 845.318 384.548 842.928C386.385 838.713 387.866 833.077 388.715 826.5L384.165 826.5C383.359 832.366 382.024 837.39 380.373 841.178L380.377 841.178Z"
            fill="#014A4F"
          />
          <path
            d="M339.229 906.907L254.872 876.819L253.45 881.046L337.756 911.114L450.107 953.442L450.186 953.466L645.804 1011.56L647.119 1007.3L451.58 949.228L339.229 906.907Z"
            fill="#014A4F"
          />
          <path
            d="M601.354 879.952L589.982 891.711L589.828 892.229L533.429 1081.92C532.102 1083.77 524.621 1094.07 519.233 1098.04C512.111 1103.28 512.786 1113.85 513.568 1126.09L513.62 1126.88C514.41 1139.26 494.561 1218.34 494.853 1219.48L498.756 1219.12C498.685 1218.84 518.913 1138.57 518.15 1126.6L518.099 1125.81C517.368 1114.4 516.792 1105.39 521.954 1101.59C528.452 1096.81 537.012 1084.66 537.371 1084.15L537.577 1083.85L537.679 1083.51L594.031 893.986L601.476 886.288L630.272 913.906L763.554 1107.22L767.315 1104.72L633.772 911.039L601.35 879.944L601.354 879.952Z"
            fill="#014A4F"
          />
          <path
            d="M672.865 826.587C660.612 837.797 659.589 848.34 659.589 858.179C659.589 866.123 660.15 887.354 665.356 912.915C669.03 930.967 645.906 939.74 632.089 944.981C628.909 946.19 626.16 947.229 624.276 948.185L626.362 952.139C628.025 951.294 630.667 950.294 633.725 949.133C647.878 943.765 674.228 933.767 669.804 912.046C664.677 886.876 664.124 865.992 664.124 858.183C664.124 848.956 665.029 839.847 675.958 829.846C676.934 828.953 678.19 827.823 679.679 826.504L672.96 826.504C672.929 826.532 672.893 826.567 672.862 826.595L672.865 826.587Z"
            fill="#014A4F"
          />
          <path
            d="M663.346 826.5L658.677 826.5C654.284 844.342 655.39 855.489 660.415 877.199L664.843 876.215C659.822 854.529 658.827 844.204 663.35 826.5L663.346 826.5Z"
            fill="#014A4F"
          />
          <path
            d="M168.955 882.993C168.762 879.695 166.38 856.805 163.141 826.496L158.578 826.496C161.829 856.927 164.227 879.94 164.42 883.246C164.985 892.908 164.413 898.75 159.704 903.881C156.56 907.306 108.065 968.215 65.2784 1021.96C34.7564 1060.29 8.39388 1093.4 4.24239 1098.43C2.59127 1100.43 1.22061 1101.81 4.89609e-05 1102.72L4.87349e-05 1107.89C2.31082 1106.86 4.79537 1104.84 7.76975 1101.23C11.9489 1096.17 38.3233 1063.05 68.8571 1024.69C109.479 973.67 160.04 910.166 163.077 906.856C168.825 900.595 169.575 893.639 168.955 882.989L168.955 882.993Z"
            fill="#014A4F"
          />
          <path
            d="M664.863 927.518L662.003 930.978L790 1032.64L790 1026.92L664.863 927.518Z"
            fill="#014A4F"
          />
          <path
            d="M790 826.492L790 193.352L-0.000274658 193.352L-0.000302334 826.492L790 826.492Z"
            fill="black"
          />
          <path
            d="M408.529 572.848L300.973 496.308L299.425 498.346L407.36 575.163C407.585 575.329 407.857 575.416 408.134 575.416C408.162 575.416 408.185 575.416 408.213 575.416L484.583 570.885L484.425 568.345L408.529 572.844L408.529 572.848Z"
            fill="#014A4F"
          />
          <path
            d="M438.778 504.303L449.558 571.77L452.133 571.45L441.35 503.983L438.778 504.303Z"
            fill="#014A4F"
          />
          <path
            d="M482.351 670.896C474.404 677.544 442.404 667.104 431.889 663.671C429.835 663 428.354 662.518 427.497 662.281C423.27 661.12 423.559 660.093 424.937 655.143C425.048 654.748 425.162 654.337 425.277 653.911C426.806 648.294 459.611 572.872 459.943 572.109C460.223 571.462 459.919 570.715 459.259 570.443C458.596 570.166 457.837 570.466 457.557 571.114C456.202 574.231 424.345 647.464 422.769 653.259C422.654 653.682 422.54 654.089 422.433 654.476C421.07 659.362 420.087 662.889 426.794 664.73C427.592 664.947 429.124 665.449 431.068 666.085C445.553 670.813 462.072 675.601 473.325 675.601C477.955 675.601 481.695 674.791 484.034 672.832C484.579 672.377 484.642 671.571 484.18 671.038C483.714 670.505 482.896 670.442 482.351 670.896Z"
            fill="#014A4F"
          />
          <path
            d="M493.573 655.511L418.519 632.746C417.843 632.541 417.116 632.908 416.899 633.572L411.796 649.025C411.578 649.692 411.954 650.407 412.633 650.624C413.316 650.838 414.047 650.47 414.264 649.803L418.977 635.551L492.803 657.94C492.929 657.979 493.06 657.995 493.186 657.995C493.739 657.995 494.253 657.644 494.426 657.098C494.64 656.427 494.257 655.716 493.569 655.507L493.573 655.511Z"
            fill="#014A4F"
          />
          <path
            d="M305.788 580.42C305.255 579.95 304.437 579.994 303.955 580.515C303.478 581.037 303.521 581.842 304.05 582.312L422.295 657.675C422.544 657.892 422.852 658.003 423.16 658.003C423.515 658.003 423.867 657.861 424.124 657.584C424.602 657.063 424.558 656.257 424.029 655.787L305.784 580.424L305.788 580.42Z"
            fill="#014A4F"
          />
          <path
            d="M433.228 631.127C431.783 630.44 397.761 614.3 390.331 611.614C387.578 610.618 385.792 609.441 385.615 607.996C385.18 604.393 401.435 574.716 403.56 572.279C404.026 571.746 403.963 570.944 403.417 570.486C402.872 570.032 402.055 570.091 401.589 570.624C398.839 573.772 382.02 603.698 383.106 608.635C383.616 610.95 385.785 612.68 389.434 614C396.746 616.638 431.743 633.248 432.095 633.414C432.276 633.501 432.47 633.541 432.66 633.541C433.142 633.541 433.604 633.276 433.829 632.822C434.141 632.19 433.872 631.431 433.228 631.123L433.228 631.127Z"
            fill="#014A4F"
          />
          <path
            d="M508.84 636.621C508.24 636.4 448.756 614.572 445.098 613.162C440.888 611.539 436.637 608.114 433.73 604.002C433.011 602.991 432.316 601.924 431.641 600.893C428.852 596.631 426.217 592.606 421.983 591.567C421.287 591.397 420.584 591.812 420.411 592.492C420.237 593.171 420.659 593.862 421.355 594.032C424.594 594.826 426.956 598.44 429.46 602.264C430.147 603.315 430.854 604.397 431.601 605.448C434.793 609.955 439.481 613.723 444.15 615.524C447.828 616.942 505.483 638.099 507.936 638.999C508.086 639.055 508.24 639.082 508.39 639.082C508.915 639.082 509.409 638.766 509.602 638.257C509.855 637.597 509.512 636.866 508.844 636.621L508.84 636.621Z"
            fill="#014A4F"
          />
          <path
            d="M556.454 584.801C556.071 584.216 555.277 584.046 554.676 584.414C543.067 591.559 512.055 610.366 508.007 611.59C502.082 613.375 494.17 623.468 492.033 631.964C491.863 632.648 492.285 633.335 492.981 633.505C493.083 633.529 493.19 633.54 493.293 633.54C493.873 633.54 494.407 633.153 494.549 632.573C496.607 624.404 504.215 615.394 508.769 614.019C513.564 612.574 548.214 591.382 554.973 587.234L566.799 605.148C567.186 605.736 567.992 605.906 568.592 605.523C569.193 605.14 569.367 604.354 568.976 603.765L556.458 584.797L556.454 584.801Z"
            fill="#014A4F"
          />
          <path
            d="M472.591 623.31C471.907 623.108 471.18 623.492 470.979 624.167L463.79 647.828C463.585 648.499 463.976 649.21 464.663 649.408C464.785 649.443 464.912 649.459 465.034 649.459C465.591 649.459 466.109 649.104 466.278 648.551L473.468 624.89C473.673 624.218 473.282 623.507 472.595 623.31L472.591 623.31Z"
            fill="#014A4F"
          />
          <path
            d="M484.946 668.688L473.732 664.698C474.036 663.371 474.57 661.025 475.079 658.69C475.533 656.621 475.881 654.962 476.11 653.769C476.568 651.399 476.762 650.419 475.537 650.048C474.854 649.842 474.127 650.214 473.914 650.885C473.851 651.079 473.839 651.28 473.874 651.47C473.756 652.757 472.117 660.187 470.947 665.232C470.801 665.856 471.157 666.488 471.769 666.709L484.054 671.082C484.2 671.133 484.35 671.157 484.496 671.157C485.025 671.157 485.523 670.837 485.717 670.319C485.961 669.66 485.614 668.929 484.938 668.692L484.946 668.688ZM474.771 652.473C474.637 652.43 474.51 652.37 474.4 652.291C474.51 652.37 474.633 652.43 474.771 652.473Z"
            fill="#014A4F"
          />
          <path
            d="M453.942 643.495C453.271 643.258 452.524 643.601 452.283 644.261L443.4 668.542C443.159 669.201 443.51 669.932 444.182 670.169C444.328 670.221 444.474 670.244 444.62 670.244C445.154 670.244 445.651 669.92 445.841 669.403L454.725 645.122C454.966 644.462 454.614 643.732 453.942 643.495Z"
            fill="#014A4F"
          />
          <path
            d="M434.868 655.518C429.539 657.233 428.876 664.039 428.848 664.331C428.785 665.03 429.314 665.646 430.029 665.71C430.068 665.71 430.104 665.714 430.143 665.714C430.807 665.714 431.376 665.216 431.435 664.552C431.439 664.501 431.984 659.129 435.677 657.936C436.357 657.719 436.728 657 436.507 656.332C436.286 655.665 435.551 655.301 434.872 655.522L434.868 655.518Z"
            fill="#014A4F"
          />
          <path
            d="M474.321 655.57L451.008 652.706C450.293 652.619 449.649 653.113 449.558 653.808C449.467 654.503 449.973 655.139 450.684 655.226L473.997 658.09C474.052 658.098 474.107 658.102 474.159 658.102C474.803 658.102 475.36 657.632 475.443 656.988C475.533 656.293 475.028 655.657 474.317 655.57L474.321 655.57Z"
            fill="#014A4F"
          />
          <path
            d="M303.434 593.143C309.853 570.703 302.261 559.805 297.169 555.306C291.213 550.048 251.092 528.951 249.781 528.335C249.133 528.031 248.24 528.221 247.932 528.856C247.624 529.488 247.897 530.251 248.54 530.551C248.856 530.701 289.751 552.178 295.431 557.194C301.111 562.207 306.673 572.394 300.938 592.456C292.291 622.694 299.125 647.046 321.257 664.856L312.622 688.474C312.381 689.133 312.732 689.864 313.404 690.101C313.55 690.152 313.696 690.176 313.842 690.176C314.376 690.176 314.873 689.852 315.063 689.335L324.01 664.868C324.196 664.359 324.034 663.786 323.599 663.446C301.787 646.287 295.005 622.63 303.434 593.143Z"
            fill="#014A4F"
          />
          <path
            d="M416.887 623.46C416.287 623.077 415.485 623.243 415.09 623.831L402.489 642.582C402.098 643.171 402.268 643.957 402.868 644.34C403.086 644.478 403.335 644.545 403.575 644.545C403.998 644.545 404.413 644.344 404.662 643.969L417.262 625.218C417.653 624.629 417.484 623.843 416.883 623.46L416.887 623.46Z"
            fill="#014A4F"
          />
          <path
            d="M291.359 710.878C291.256 710.811 281.093 704.049 277.597 701.766C277.04 701.402 276.495 701.066 275.977 700.746C274.547 699.866 273.066 698.953 272.924 698.286C272.877 698.068 272.987 697.76 273.248 697.373C275.148 694.576 277.23 687.782 277.38 684.01L286.33 671.773C286.749 671.204 286.615 670.406 286.034 669.999C285.45 669.589 284.644 669.719 284.225 670.292L275.041 682.849C274.883 683.066 274.796 683.323 274.796 683.591C274.796 686.763 272.786 693.471 271.091 695.967C270.428 696.947 270.191 697.906 270.388 698.819C270.763 700.533 272.454 701.58 274.595 702.899C275.093 703.207 275.618 703.531 276.155 703.883C279.643 706.162 289.799 712.917 289.901 712.984C290.122 713.13 290.379 713.201 290.628 713.201C291.043 713.201 291.454 713.007 291.703 712.644C292.105 712.063 291.951 711.273 291.359 710.878Z"
            fill="#014A4F"
          />
          <path
            d="M296.415 676.497C295.834 676.082 295.024 676.209 294.602 676.778L275.827 702.078C275.405 702.646 275.535 703.44 276.112 703.855C276.341 704.021 276.609 704.1 276.874 704.1C277.273 704.1 277.672 703.918 277.925 703.575L296.699 678.275C297.122 677.706 296.992 676.912 296.415 676.497Z"
            fill="#014A4F"
          />
          <path
            d="M306.689 683.07C306.045 682.754 305.267 683.011 304.947 683.639C302.786 687.877 289.83 711.874 287.67 714.299C284.652 717.68 286.895 729.262 287.373 731.549C287.5 732.149 288.041 732.564 288.641 732.564C288.728 732.564 288.815 732.556 288.906 732.536C289.605 732.394 290.059 731.723 289.913 731.035C288.827 725.861 288.021 717.763 289.617 715.97C292.441 712.806 306.938 685.408 307.262 684.772C307.582 684.144 307.321 683.382 306.681 683.066L306.689 683.07Z"
            fill="#014A4F"
          />
          <path
            d="M295.21 704.645L282.195 695.631C281.61 695.224 280.8 695.363 280.386 695.935C279.971 696.508 280.113 697.302 280.698 697.709L293.713 706.723C293.942 706.881 294.203 706.956 294.46 706.956C294.866 706.956 295.269 706.77 295.522 706.419C295.937 705.846 295.795 705.052 295.21 704.645Z"
            fill="#014A4F"
          />
          <path
            d="M326.842 697.203C326.19 696.915 325.436 697.203 325.159 697.855C324.29 699.893 314.052 719.26 314.044 719.541C313.124 719.691 310.094 718.668 307.637 717.838C306.851 717.574 306.017 717.293 305.144 717.009C303.296 716.404 302.881 715.413 302.731 714.722C301.965 711.175 313.048 693.218 315.758 690.8C316.288 690.326 316.327 689.524 315.845 689.003C315.363 688.485 314.542 688.446 314.012 688.92C311.587 691.085 299.057 709.978 300.191 715.247C300.491 716.633 301.451 718.482 304.319 719.422C305.184 719.703 306.006 719.983 306.788 720.244C309.904 721.295 312.231 722.077 313.878 722.077C314.79 722.077 315.498 721.836 316.015 721.267C317.255 719.908 326.534 701.216 327.517 698.902C327.794 698.254 327.486 697.492 326.834 697.203L326.842 697.203Z"
            fill="#014A4F"
          />
          <path
            d="M315.683 720.165C315.45 719.501 314.715 719.146 314.036 719.371C313.357 719.596 312.997 720.319 313.226 720.986L317.042 731.936C317.228 732.465 317.729 732.797 318.27 732.797C318.409 732.797 318.551 732.773 318.689 732.73C319.369 732.505 319.728 731.782 319.499 731.114L315.683 720.165Z"
            fill="#014A4F"
          />
          <path
            d="M353.288 717.668C353.177 717.767 342.03 727.504 332.992 730.313C323.907 733.137 257.333 729.538 256.812 729.511C256.081 729.467 255.485 730.008 255.441 730.708C255.402 731.407 255.947 732.011 256.662 732.05C258.068 732.129 295.147 734.101 312.962 734.101C322.331 734.101 330.488 733.761 333.774 732.742C343.349 729.767 354.548 719.979 355.018 719.564C355.551 719.094 355.594 718.293 355.116 717.771C354.638 717.25 353.817 717.202 353.284 717.672L353.288 717.668Z"
            fill="#014A4F"
          />
          <path
            d="M341.706 756.004C340.169 749.601 337.578 730.467 337.551 730.273C337.456 729.578 336.808 729.092 336.093 729.179C335.382 729.27 334.884 729.91 334.979 730.605C335.086 731.395 337.614 750.055 339.178 756.584C341.666 766.957 348.208 795.642 348.563 803.665C348.733 807.52 349.551 816.968 350.432 826.488L353.039 826.488C352.154 816.901 351.324 807.354 351.155 803.554C350.716 793.647 341.793 756.375 341.706 756L341.706 756.004Z"
            fill="#014A4F"
          />
          <path
            d="M350.432 193.352C351.443 204.337 352.537 215.421 352.806 218.119L335.315 236.739C334.829 237.257 334.865 238.059 335.39 238.537C335.639 238.762 335.955 238.872 336.267 238.872C336.618 238.872 336.966 238.734 337.223 238.461L355.109 219.422C355.357 219.158 355.48 218.798 355.444 218.439C355.421 218.218 354.184 205.751 353.043 193.356L350.436 193.356L350.432 193.352Z"
            fill="#014A4F"
          />
          <path
            d="M330.176 731.15C329.572 730.771 328.77 730.944 328.383 731.537C325.645 735.732 319.602 740.417 312.808 740.792C305.938 741.171 260.738 742.261 260.323 742.273C259.608 742.293 259.044 742.878 259.063 743.581C259.083 744.272 259.66 744.817 260.359 744.817C260.371 744.817 264.767 744.872 264.779 744.872C265.19 744.86 306.045 743.715 312.954 743.332C320.7 742.905 327.383 737.786 330.567 732.908C330.954 732.315 330.776 731.529 330.172 731.15L330.176 731.15Z"
            fill="#014A4F"
          />
          <path
            d="M300.93 712.782C296.94 714.157 295.376 723.092 295.708 742.664C295.72 743.359 296.296 743.916 297.003 743.916C297.011 743.916 297.019 743.916 297.027 743.916C297.742 743.905 298.315 743.328 298.303 742.625C297.916 719.924 300.321 715.689 301.787 715.184C302.462 714.951 302.818 714.224 302.581 713.564C302.344 712.901 301.601 712.553 300.93 712.786L300.93 712.782Z"
            fill="#014A4F"
          />
          <path
            d="M322.232 739.342C322.015 738.671 321.288 738.303 320.605 738.517C319.922 738.73 319.546 739.445 319.764 740.113L329.828 771.136C330.002 771.677 330.516 772.021 331.065 772.021C331.195 772.021 331.329 772.001 331.46 771.962C332.143 771.748 332.518 771.033 332.301 770.366L322.236 739.342L322.232 739.342Z"
            fill="#014A4F"
          />
          <path
            d="M323.437 746.666L238.681 769.971C237.994 770.164 237.595 770.868 237.788 771.543C237.95 772.1 238.468 772.467 239.037 772.467C239.155 772.467 239.274 772.452 239.392 772.42L324.148 749.115C324.835 748.921 325.234 748.218 325.041 747.543C324.843 746.867 324.128 746.476 323.437 746.67L323.437 746.666Z"
            fill="#014A4F"
          />
          <path
            d="M375.751 763.272L241.517 784.195C240.806 784.294 240.316 784.938 240.415 785.633C240.506 786.269 241.817 786.723 241.881 786.715L376.115 765.792C376.826 765.693 377.316 765.049 377.217 764.354C377.118 763.659 376.459 763.181 375.751 763.272Z"
            fill="#014A4F"
          />
          <path
            d="M309.632 773.719L306.59 752.875C306.487 752.18 305.828 751.698 305.125 751.797C304.414 751.895 303.924 752.539 304.027 753.235L307.06 774.02L309.667 826.488L312.262 826.488L309.648 773.838C309.648 773.798 309.64 773.759 309.636 773.719L309.632 773.719Z"
            fill="#014A4F"
          />
          <path
            d="M365.477 248.815C364.411 248.348 339.312 237.367 333.47 235.076C328.39 233.082 317.579 231.415 314.123 230.917L312.259 193.352L309.663 193.352L311.587 232.086C311.619 232.698 312.089 233.2 312.705 233.283C312.847 233.303 326.834 235.211 332.506 237.434C338.301 239.706 364.158 251.019 364.419 251.133C364.589 251.208 364.77 251.244 364.948 251.244C365.446 251.244 365.916 250.963 366.133 250.489C366.425 249.849 366.133 249.095 365.477 248.811L365.477 248.815Z"
            fill="#014A4F"
          />
          <path
            d="M273.643 761.569C272.928 761.62 272.391 762.229 272.446 762.932L277.233 826.492L279.833 826.492L275.033 762.742C274.982 762.043 274.366 761.522 273.643 761.569Z"
            fill="#014A4F"
          />
          <path
            d="M387.128 801.192L244.531 818.912C243.816 818.975 243.291 819.591 243.354 820.29C243.417 820.99 244.049 821.507 244.76 821.444L387.179 803.74L509.796 826.488L523.748 826.488L387.483 801.208C387.365 801.188 387.246 801.18 387.128 801.192Z"
            fill="#014A4F"
          />
          <path
            d="M533.852 195.228L523.752 193.356L509.8 193.356L533.37 197.729C533.453 197.744 533.532 197.752 533.611 197.752C534.223 197.752 534.764 197.326 534.883 196.717C535.017 196.026 534.555 195.363 533.852 195.232L533.852 195.228Z"
            fill="#014A4F"
          />
          <path
            d="M418.815 824.3C411.092 822.589 405.586 824.292 401.494 826.492L408.94 826.492C411.191 826.073 413.763 826.002 416.729 826.492L419.384 826.492C419.581 826.322 419.727 826.093 419.79 825.82C419.948 825.137 419.51 824.454 418.811 824.3L418.815 824.3Z"
            fill="#014A4F"
          />
          <path
            d="M418.242 193.64C418.665 193.735 419.083 193.613 419.384 193.352L416.729 193.352C417.223 193.435 417.725 193.526 418.242 193.64Z"
            fill="#014A4F"
          />
          <path
            d="M396.963 196.216C396.394 196.611 395.853 196.986 395.348 197.31L269.823 210.863C269.108 210.91 268.567 211.514 268.615 212.213C268.662 212.885 269.231 213.402 269.906 213.402C269.934 213.402 269.965 213.402 269.993 213.402L395.853 199.826C396.07 199.81 396.28 199.743 396.465 199.629C397.097 199.234 397.757 198.779 398.456 198.294C401.103 196.461 404.373 194.201 408.94 193.352L401.494 193.352C399.744 194.292 398.255 195.323 396.963 196.216Z"
            fill="#014A4F"
          />
          <path
            d="M348.137 790.752L309.276 792.383C308.561 792.415 308.004 793.007 308.036 793.707C308.068 794.39 308.64 794.923 309.332 794.923C309.351 794.923 309.371 794.923 309.387 794.923L348.247 793.292C348.962 793.26 349.519 792.668 349.488 791.969C349.456 791.265 348.84 790.72 348.137 790.752Z"
            fill="#014A4F"
          />
          <path
            d="M427.145 756.948C426.43 756.948 425.85 757.516 425.85 758.22L425.85 809.598L418.337 826.492L421.165 826.492L428.334 810.372C428.405 810.21 428.441 810.04 428.441 809.862L428.441 758.22C428.441 757.516 427.86 756.948 427.145 756.948Z"
            fill="#014A4F"
          />
          <path
            d="M352.964 340.384C352.679 341.028 352.979 341.779 353.635 342.059C353.805 342.13 353.979 342.166 354.153 342.166C354.654 342.166 355.128 341.881 355.342 341.403L421.169 193.356L418.341 193.356L352.968 340.388L352.964 340.384Z"
            fill="#014A4F"
          />
          <path
            d="M522.393 670.497C521.804 670.094 520.994 670.236 520.588 670.809L451.403 774.9C451.237 775.134 451.154 775.414 451.17 775.698C451.189 776.062 453.164 811.987 449.629 821.246C449.329 822.036 448.566 823.845 447.429 826.488L450.241 826.488C451.162 824.339 451.786 822.846 452.058 822.135C455.605 812.845 454.018 780.367 453.784 775.995L522.713 672.263C523.123 671.686 522.981 670.896 522.393 670.493L522.393 670.497Z"
            fill="#014A4F"
          />
          <path
            d="M375.645 355.13C375.356 355.774 375.657 356.524 376.312 356.805C376.482 356.876 376.66 356.911 376.834 356.911C377.331 356.911 377.809 356.627 378.023 356.149C380.681 350.196 438.755 220.181 450.249 193.348L447.437 193.348C434.895 222.484 376.297 353.684 375.649 355.126L375.645 355.13Z"
            fill="#014A4F"
          />
          <path
            d="M563.165 770.65C562.541 770.311 561.751 770.528 561.399 771.144L530.012 826.492L532.979 826.492L563.663 772.38C564.01 771.768 563.785 770.994 563.161 770.65L563.165 770.65Z"
            fill="#014A4F"
          />
          <path
            d="M440.718 350.824L339.368 309.329C338.704 309.057 337.946 309.365 337.673 310.012C337.397 310.66 337.709 311.407 338.368 311.675L440.789 353.609C440.951 353.676 441.121 353.708 441.287 353.708C441.745 353.708 442.187 353.467 442.42 353.052L532.983 193.352L530.016 193.352L440.718 350.82L440.718 350.824Z"
            fill="#014A4F"
          />
          <path
            d="M543.431 724.881C542.846 724.632 542.167 724.838 541.823 725.363L492.1 801.705C492.037 801.8 491.989 801.903 491.954 802.01L486.009 820.575L483.501 826.492L486.309 826.492L488.426 821.499C488.442 821.464 488.454 821.428 488.466 821.393L494.375 802.934L543.363 727.721C545.836 729.12 552.986 733.777 563.912 745.974C579.163 762.995 573.001 793.924 572.25 797.396C571.618 800.311 565.349 812.027 557.268 826.492L560.226 826.492C568.182 812.224 574.115 801.03 574.79 797.925C575.572 794.327 581.947 762.252 565.863 744.296C551.706 728.495 543.758 725.019 543.427 724.881L543.431 724.881Z"
            fill="#014A4F"
          />
          <path
            d="M539.978 223.878C527.903 245.062 516.495 265.073 514.157 270.793C509.093 283.184 469.92 319.331 465.753 321.712C465.137 322.068 464.928 322.846 465.287 323.45C465.65 324.059 466.444 324.26 467.061 323.909C471.362 321.448 511.167 284.938 516.562 271.737C518.838 266.171 530.206 246.227 542.242 225.118C548.491 214.157 554.823 203.045 560.226 193.352L557.268 193.352C551.927 202.907 545.797 213.663 539.974 223.878L539.978 223.878Z"
            fill="#014A4F"
          />
          <path
            d="M405.242 378.008C404.966 378.656 405.282 379.403 405.942 379.671C406.104 379.739 406.273 379.77 406.439 379.77C406.949 379.77 407.431 379.474 407.636 378.984L486.309 193.352L483.501 193.352L405.242 378.008Z"
            fill="#014A4F"
          />
          <path
            d="M448.958 825.651L422.397 819.923C421.702 819.769 421.007 820.207 420.853 820.891C420.699 821.578 421.141 822.254 421.841 822.404L440.777 826.488L450.237 826.488L449.491 825.899C449.333 825.777 449.151 825.69 448.958 825.647L448.958 825.651Z"
            fill="#014A4F"
          />
          <path
            d="M532.212 239.962L476.446 214.062L450.237 193.356L440.777 193.356L448.1 194.936L474.937 216.14C475.016 216.203 475.103 216.254 475.194 216.298L531.099 242.265C531.276 242.348 531.466 242.388 531.652 242.388C532.137 242.388 532.604 242.119 532.825 241.661C533.133 241.025 532.856 240.267 532.209 239.966L532.212 239.962Z"
            fill="#014A4F"
          />
          <path
            d="M517.258 266.969L405.238 225.825C404.571 225.58 403.82 225.912 403.568 226.572C403.315 227.228 403.655 227.962 404.326 228.207L516.345 269.351C516.495 269.406 516.649 269.434 516.799 269.434C517.325 269.434 517.819 269.118 518.012 268.608C518.265 267.952 517.925 267.218 517.254 266.973L517.258 266.969Z"
            fill="#014A4F"
          />
          <path
            d="M494.604 296.136L391.315 257.173C390.651 256.92 389.897 257.248 389.64 257.904C389.383 258.559 389.715 259.294 390.386 259.547L493.676 298.51C493.83 298.569 493.988 298.597 494.142 298.597C494.663 298.597 495.153 298.285 495.351 297.779C495.607 297.124 495.276 296.389 494.604 296.136Z"
            fill="#014A4F"
          />
          <path
            d="M570.445 805.114L436.108 719.738C435.539 719.379 434.789 719.505 434.374 720.031L403.473 744.347C403.034 744.904 403.141 745.702 403.71 746.128C404.279 746.555 405.092 746.452 405.527 745.895L435.701 722.507L569.031 807.243C569.248 807.382 569.493 807.449 569.734 807.449C570.161 807.449 570.575 807.244 570.824 806.868C571.215 806.28 571.041 805.49 570.441 805.11L570.445 805.114Z"
            fill="#014A4F"
          />
          <path
            d="M557.212 737.561C556.58 737.225 555.794 737.458 555.455 738.074L532.469 780.695C532.13 781.311 532.363 782.086 532.995 782.417C533.192 782.52 533.401 782.572 533.611 782.572C534.073 782.572 534.519 782.331 534.752 781.904L557.738 739.283C558.077 738.667 557.844 737.893 557.212 737.561Z"
            fill="#014A4F"
          />
          <path
            d="M563.919 669.194C563.315 668.818 562.513 668.992 562.13 669.585L495.643 759.108C495.26 759.701 495.438 760.487 496.042 760.862C496.255 760.996 496.5 761.06 496.737 761.06C497.168 761.06 497.586 760.85 497.831 760.471L564.318 670.947C564.702 670.355 564.524 669.569 563.919 669.194Z"
            fill="#014A4F"
          />
          <path
            d="M452.698 776.587L418.993 770.551L381.917 748.664C381.341 748.321 380.59 748.475 380.195 749.012L372.86 759.045C372.441 759.618 372.576 760.412 373.16 760.819C373.741 761.225 374.555 761.095 374.969 760.526L381.605 751.449L417.855 772.85C417.989 772.929 418.135 772.985 418.289 773.012L452.228 779.088C452.307 779.103 452.386 779.107 452.461 779.107C453.077 779.107 453.623 778.677 453.737 778.064C453.867 777.373 453.397 776.71 452.694 776.587L452.698 776.587Z"
            fill="#014A4F"
          />
          <path
            d="M590.981 520.072C588.738 518.33 384.663 359.795 373.401 351.421C363.226 343.856 352.786 338.2 343.207 335.072C335.058 332.409 253.115 319.824 252.471 319.623C251.787 319.406 251.582 319.734 251.361 320.397C251.14 321.065 251.511 321.784 252.194 322.001C252.838 322.206 334.244 334.827 342.385 337.485C351.7 340.527 361.883 346.049 371.833 353.447C383.071 361.805 587.126 520.324 589.37 522.066C589.607 522.252 589.891 522.343 590.175 522.343C590.559 522.343 590.938 522.177 591.191 521.861C591.637 521.312 591.542 520.51 590.981 520.076L590.981 520.072Z"
            fill="#014A4F"
          />
          <path
            d="M396.983 380.28L396.805 380.181C395.581 379.498 394.4 378.629 392.974 377.361C391.591 376.132 390.047 374.592 388.17 372.704C384.374 368.876 373.792 360.616 369.23 358.586C364.932 356.67 325.586 341.561 323.911 340.922C323.251 340.669 322.513 340.977 322.24 341.617L250.602 487.223C250.326 487.871 250.638 488.617 251.301 488.886C251.463 488.953 252.791 488.689 252.996 488.203L324.148 343.746C330.899 346.341 364.332 359.198 368.156 360.905C372.129 362.678 382.372 370.511 386.31 374.485C388.218 376.405 389.79 377.977 391.228 379.253C392.8 380.647 394.123 381.619 395.513 382.397L395.695 382.496C398.239 383.918 399.519 384.633 397.338 389.219C395.446 393.188 390.588 402.866 386.334 406.927C382.257 410.818 373.895 422.928 373.543 423.442C373.14 424.022 373.298 424.813 373.891 425.208C374.483 425.599 375.289 425.448 375.692 424.864C375.779 424.741 384.256 412.457 388.143 408.744C392.539 404.545 397.263 395.381 399.689 390.289C402.647 384.076 400.325 382.152 396.979 380.284L396.983 380.28Z"
            fill="#014A4F"
          />
          <path
            d="M361.523 404.912C359.821 404.328 358.356 403.826 357.459 403.431C355.745 402.669 353.485 401.092 350.874 399.268C346.687 396.345 341.473 392.703 335.801 390.605C326.87 387.299 271.336 367.825 270.775 367.632C270.1 367.395 269.357 367.738 269.116 368.402C268.875 369.062 269.227 369.792 269.898 370.025C270.459 370.223 325.969 389.685 334.88 392.983C340.237 394.966 345.297 398.497 349.361 401.337C352.087 403.237 354.437 404.881 356.384 405.746C357.388 406.188 358.905 406.709 360.662 407.314C365.663 409.024 374.029 411.892 375.57 415.198C375.85 415.802 375.866 416.367 375.617 416.98C372.982 423.501 343.539 522.86 342.255 527.209C342.058 527.885 342.413 528.359 343.1 528.552C343.219 528.588 343.385 528.833 343.503 528.833C344.068 528.833 344.586 528.469 344.748 527.913C345.064 526.846 375.439 424.323 378.031 417.912C378.54 416.648 378.509 415.376 377.928 414.136C375.925 409.842 367.599 406.99 361.516 404.908L361.523 404.912Z"
            fill="#014A4F"
          />
          <path
            d="M392.594 377.128C391.931 376.863 391.172 377.171 390.904 377.823L375.633 414.388C375.36 415.04 375.68 415.783 376.34 416.047C376.502 416.11 376.668 416.142 376.83 416.142C377.339 416.142 377.825 415.846 378.031 415.352L393.302 378.787C393.574 378.135 393.254 377.392 392.594 377.128Z"
            fill="#014A4F"
          />
          <path
            d="M387.878 372.482C387.25 372.139 386.464 372.356 386.112 372.968L366.335 407.421C365.983 408.033 366.208 408.807 366.832 409.151C367.034 409.261 367.251 409.313 367.464 409.313C367.919 409.313 368.361 409.08 368.598 408.665L388.376 374.213C388.727 373.6 388.502 372.826 387.878 372.482Z"
            fill="#014A4F"
          />
          <path
            d="M373.97 361.399C373.314 361.122 372.548 361.418 372.268 362.066C366.188 375.947 355.271 398.766 351.846 400.911C351.241 401.29 351.064 402.076 351.451 402.669C351.7 403.048 352.118 403.257 352.545 403.257C352.782 403.257 353.027 403.194 353.244 403.056C358.466 399.781 373.01 366.818 374.653 363.073C374.934 362.43 374.63 361.679 373.974 361.402L373.97 361.399Z"
            fill="#014A4F"
          />
          <path
            d="M350.969 351.405C350.388 350.994 349.578 351.121 349.156 351.689L345.427 356.73L320.242 349.292C319.554 349.09 318.831 349.469 318.626 350.141C318.421 350.812 318.808 351.523 319.495 351.725L345.561 359.423C345.684 359.459 345.81 359.479 345.937 359.479C346.343 359.479 346.738 359.289 346.987 358.953L351.257 353.182C351.676 352.614 351.55 351.82 350.969 351.405Z"
            fill="#014A4F"
          />
          <path
            d="M346.411 356.425C345.759 356.141 344.989 356.425 344.696 357.069L332.135 390.538C331.843 391.178 332.135 391.932 332.791 392.217C332.961 392.292 333.142 392.327 333.32 392.327C333.818 392.327 334.288 392.047 334.505 391.573L347.066 358.104C347.359 357.464 347.066 356.71 346.411 356.425Z"
            fill="#014A4F"
          />
          <path
            d="M345.135 395.42C344.479 395.14 343.713 395.44 343.432 396.084L277.838 545.135C277.553 545.778 277.858 546.529 278.517 546.805C278.683 546.877 278.782 546.762 278.956 546.762C279.457 546.762 280.01 546.624 280.224 546.142L345.818 397.091C346.102 396.447 345.798 395.697 345.139 395.42L345.135 395.42Z"
            fill="#014A4F"
          />
          <path
            d="M337.156 391.146C336.492 390.89 335.738 391.217 335.477 391.873L329.536 406.871L313.135 399.319C312.8 399.165 312.409 399.157 312.069 399.303C311.725 399.449 311.465 399.73 311.35 400.077L302.067 428.213C301.846 428.881 302.221 429.6 302.901 429.813C303.031 429.857 303.169 429.876 303.3 429.876C303.849 429.876 304.354 429.533 304.532 428.996L313.361 402.23L329.686 409.751C330.014 409.901 330.385 409.913 330.721 409.779C331.053 409.648 331.317 409.388 331.448 409.06L337.894 392.794C338.155 392.138 337.823 391.403 337.156 391.15L337.156 391.146Z"
            fill="#014A4F"
          />
          <path
            d="M367.243 448.442L243.37 407.468C242.69 407.243 241.956 407.606 241.731 408.274C241.505 408.941 241.873 409.66 242.552 409.881L366.425 450.855C366.56 450.899 366.698 450.922 366.836 450.922C367.381 450.922 367.887 450.583 368.065 450.049C368.29 449.382 367.922 448.663 367.243 448.442Z"
            fill="#014A4F"
          />
          <path
            d="M324.966 387.417C324.294 387.176 323.548 387.516 323.303 388.176C323.058 388.836 294.562 465.561 290.723 472.754C290.241 473.659 289.672 474.769 289.032 476.021C284.581 484.727 248.568 526.905 248.003 527.474C247.324 528.153 249.567 529.496 249.678 529.39C259.127 520.182 286.741 486.176 291.351 477.162C291.983 475.922 292.544 474.828 293.022 473.935C296.94 466.6 324.733 391.755 325.74 389.053C325.985 388.393 325.641 387.662 324.97 387.421L324.966 387.417Z"
            fill="#014A4F"
          />
          <path
            d="M328.252 406.279C327.909 406.121 327.506 406.121 327.162 406.279C326.699 406.49 325.128 411.259 322.45 420.586C320.522 427.301 318.622 434.004 318.622 434.004C318.429 434.68 318.835 435.383 319.523 435.568C319.637 435.6 319.756 435.616 319.87 435.616C320.439 435.616 320.96 435.249 321.118 434.684C323.951 424.682 328.296 409.514 328.845 408.037C328.857 408.013 328.872 407.989 328.88 407.962C329.181 407.326 328.9 406.567 328.248 406.275L328.252 406.279Z"
            fill="#014A4F"
          />
          <path
            d="M417.44 385.419C416.808 385.091 416.022 385.328 415.686 385.948L390.955 431.855C390.931 431.899 390.908 431.946 390.892 431.994C390.71 432.456 386.448 443.441 385.947 452.771C385.457 461.911 373.144 548.788 373.121 549.472C373.097 550.175 373.658 550.76 374.373 550.783C374.389 550.783 374.401 550.783 374.416 550.783C375.112 550.783 375.688 550.242 375.712 549.555C375.736 548.871 388.044 462.022 388.534 452.905C388.996 444.345 392.922 433.917 393.282 432.973L417.973 387.133C418.309 386.513 418.064 385.743 417.432 385.415L417.44 385.419Z"
            fill="#014A4F"
          />
          <path
            d="M514.425 506.567C512.49 506.409 470.568 503.122 458.43 504.275C446.627 505.393 382.328 493.938 382.154 493.895C381.459 493.729 380.756 494.148 380.586 494.827C380.416 495.51 380.843 496.198 381.538 496.364C382.269 496.537 446.441 507.965 458.679 506.803C470.584 505.678 513.809 509.102 514.279 509.138C514.315 509.138 514.279 509.098 514.319 509.098C514.986 509.098 515.37 509.296 515.429 508.632C515.488 507.933 515.14 506.618 514.425 506.559L514.425 506.567Z"
            fill="#014A4F"
          />
          <path
            d="M496.01 447.549C495.291 447.494 494.667 448.003 494.6 448.702L489.627 506.239C489.564 506.938 490.089 507.558 490.804 507.621C490.844 507.621 490.883 507.625 490.923 507.625C491.586 507.625 492.151 507.127 492.214 506.468L497.188 448.931C497.251 448.232 496.725 447.612 496.01 447.549Z"
            fill="#014A4F"
          />
          <path
            d="M647.518 778.309C647.159 777.705 646.365 777.496 645.744 777.847C645.124 778.199 644.915 778.977 645.274 779.585L648.233 784.582L641.636 826.492L644.259 826.492L650.864 784.527C650.911 784.238 650.852 783.946 650.702 783.693L647.51 778.309L647.518 778.309Z"
            fill="#014A4F"
          />
          <path
            d="M632.326 252.504L627.776 269.299L612.288 279.72L545.315 266.914C544.616 266.775 543.932 267.23 543.794 267.917C543.656 268.604 544.114 269.276 544.817 269.41L612.323 282.319C612.406 282.335 612.489 282.342 612.572 282.342C612.833 282.342 613.09 282.267 613.307 282.121L629.64 271.132C629.897 270.958 630.079 270.706 630.158 270.409L634.85 253.092C634.862 253.049 634.87 253.006 634.878 252.958L644.259 193.352L641.636 193.352L632.326 252.504Z"
            fill="#014A4F"
          />
          <path
            d="M592.613 258.583L561.573 242.439C560.977 242.127 560.234 242.321 559.871 242.882L501.671 332.931C501.288 333.523 501.465 334.309 502.074 334.684C502.291 334.819 502.528 334.882 502.765 334.882C503.196 334.882 503.614 334.673 503.863 334.289L561.419 245.236L591.4 260.827C592.036 261.154 592.818 260.917 593.154 260.301C593.489 259.681 593.248 258.911 592.616 258.583L592.613 258.583Z"
            fill="#014A4F"
          />
          <path
            d="M572.084 247.906C571.428 247.63 570.662 247.926 570.378 248.57L515.61 373.087C515.595 373.122 515.579 373.162 515.567 373.197L504.029 409.964C503.808 410.632 504.179 411.351 504.862 411.568C504.997 411.611 505.131 411.631 505.261 411.631C505.806 411.631 506.316 411.288 506.494 410.75L518.012 374.043L572.756 249.581C573.04 248.937 572.736 248.186 572.076 247.91L572.084 247.906Z"
            fill="#014A4F"
          />
          <path
            d="M625.631 315.78L528.879 292.451C528.183 292.285 527.48 292.699 527.31 293.383C527.141 294.066 527.563 294.754 528.258 294.919L625.011 318.248C625.113 318.272 625.22 318.284 625.323 318.284C625.907 318.284 626.437 317.897 626.583 317.316C626.753 316.633 626.33 315.945 625.635 315.78L625.631 315.78Z"
            fill="#014A4F"
          />
          <path
            d="M595.326 491.663C587.829 482.617 585.348 473.204 587.738 462.887C589.302 456.14 609.641 319.141 610.506 313.311C610.609 312.616 610.119 311.972 609.408 311.869C608.701 311.77 608.041 312.248 607.939 312.943C607.725 314.369 586.747 455.67 585.206 462.322C582.627 473.449 585.277 483.569 593.312 493.263C603.408 505.445 649.078 575.641 649.303 575.898C649.56 576.194 649.924 576.344 650.291 576.344C650.587 576.344 650.887 576.245 651.132 576.04C651.677 575.585 651.741 574.78 651.279 574.246C651.053 573.99 605.403 503.817 595.326 491.659L595.326 491.663Z"
            fill="#014A4F"
          />
          <path
            d="M616.763 469.053C616.637 468.587 616.254 468.235 615.772 468.137L533.567 451.499L483.386 436.22C482.687 436.05 481.988 436.461 481.81 437.141C481.636 437.82 482.055 438.515 482.75 438.685L532.987 453.976L614.464 470.467L625.635 511.251C625.789 511.82 626.314 512.195 626.887 512.195C626.998 512.195 627.112 512.179 627.223 512.152C627.914 511.97 628.325 511.271 628.139 510.596L616.759 469.057L616.763 469.053Z"
            fill="#014A4F"
          />
          <path
            d="M607.469 467.177C607.287 466.497 606.576 466.09 605.885 466.272C605.193 466.45 604.779 467.145 604.96 467.824L616.613 511.243C616.767 511.812 617.292 512.191 617.865 512.191C617.976 512.191 618.086 512.179 618.197 512.148C618.888 511.97 619.303 511.275 619.121 510.596L607.469 467.177Z"
            fill="#014A4F"
          />
          <path
            d="M571.772 459.233C571.065 459.123 570.401 459.597 570.291 460.292L566.503 502.277C566.392 502.972 566.85 503.201 567.557 503.312C567.625 503.324 567.719 503.746 567.787 503.746C568.415 503.746 568.964 503.3 569.066 502.672L572.854 460.687C572.965 459.992 572.483 459.344 571.776 459.233L571.772 459.233Z"
            fill="#014A4F"
          />
          <path
            d="M641.364 506.906C640.886 506.381 640.068 506.337 639.531 506.807C638.998 507.277 638.95 508.079 639.428 508.605L681.587 554.425C681.844 554.706 682.199 554.848 682.555 554.848C682.863 554.848 683.171 554.741 683.42 554.524C683.953 554.054 684.001 553.252 683.523 552.727L641.364 506.906Z"
            fill="#014A4F"
          />
          <path
            d="M615.487 332.555L606.975 330.991C606.272 330.861 605.592 331.315 605.462 332.006C605.332 332.698 605.794 333.361 606.497 333.492L615.009 335.056C615.088 335.072 615.171 335.075 615.25 335.075C615.862 335.075 616.408 334.649 616.522 334.037C616.656 333.345 616.19 332.682 615.487 332.551L615.487 332.555Z"
            fill="#014A4F"
          />
          <path
            d="M10.4992 249.806C9.97382 249.328 9.1522 249.36 8.66635 249.877L-0.00396675 259.049L-0.00396691 262.794L10.5703 251.599C11.0562 251.082 11.0245 250.28 10.4992 249.802L10.4992 249.806Z"
            fill="#014A4F"
          />
          <path
            d="M655.564 401.649C642.889 398.825 505.21 368.165 498.7 367.561C489.505 366.703 482.888 370.436 480.092 378.068L479.582 379.454C476.533 387.718 474.842 392.904 477.078 395.203C478.073 396.226 479.626 396.427 481.695 395.803C487.131 394.156 502.188 399.228 507.529 401.318C508.192 401.578 508.947 401.262 509.215 400.611C509.48 399.959 509.156 399.22 508.493 398.959C507.675 398.639 488.343 391.13 480.929 393.374C479.953 393.67 479.199 393.698 478.954 393.449C477.662 392.122 480.285 385.02 482.019 380.323L482.533 378.933C485.555 370.689 492.862 369.579 498.455 370.097C504.969 370.701 654.213 403.96 655.718 404.296C655.813 404.316 655.912 404.328 656.007 404.328C656.362 404.328 656.71 404.181 656.959 403.921L790 262.798L790 259.053L655.564 401.653L655.564 401.649Z"
            fill="#014A4F"
          />
          <path
            d="M594.149 413.598L576.698 411.335L576.698 401.981C576.698 401.349 576.224 400.816 575.588 400.725L564.18 399.09L568.49 384.016C568.683 383.341 568.28 382.638 567.593 382.448C566.902 382.259 566.187 382.654 565.993 383.329L561.285 399.793C561.186 400.144 561.241 400.524 561.447 400.828C561.648 401.132 561.976 401.341 562.347 401.393L574.103 403.079L574.103 412.449C574.103 413.085 574.585 413.626 575.228 413.709L593.805 416.118C593.865 416.126 593.92 416.13 593.979 416.13C594.619 416.13 595.176 415.664 595.263 415.024C595.358 414.329 594.856 413.689 594.149 413.598Z"
            fill="#014A4F"
          />
          <path
            d="M541.815 379.122C541.91 379.17 542.013 379.205 542.119 379.229C542.009 379.205 541.906 379.17 541.815 379.122Z"
            fill="#014A4F"
          />
          <path
            d="M548.056 406.342L536.633 399.572C543.98 378.139 543.909 378.012 543.529 377.361C543.348 377.045 543.036 376.819 542.676 376.74C541.977 376.586 541.286 377.025 541.132 377.708C541.116 377.779 541.108 377.85 541.104 377.922C540.745 379.43 536.494 391.996 533.844 399.726C533.65 400.291 533.883 400.911 534.401 401.219L546.713 408.515C546.922 408.637 547.155 408.696 547.381 408.696C547.819 408.696 548.25 408.479 548.491 408.084C548.862 407.484 548.664 406.702 548.052 406.338L548.056 406.342ZM541.815 379.118C541.72 379.071 541.629 379.012 541.554 378.949C541.629 379.016 541.716 379.071 541.815 379.118Z"
            fill="#014A4F"
          />
          <path
            d="M654.672 437.658L505.562 409.313C504.862 409.178 504.246 411.315 505.068 411.809L655.055 440.297C654.261 440.174 654.344 440.182 654.423 440.182C655.031 440.182 655.576 439.759 655.695 439.151C655.829 438.464 655.371 437.796 654.668 437.662L654.672 437.658Z"
            fill="#014A4F"
          />
          <path
            d="M758.336 371.131C758.19 371.242 743.725 382.274 737.508 387.228C731.5 392.011 721.755 405.773 720.333 407.796L676.713 433.72C676.101 434.083 675.903 434.865 676.274 435.466C676.519 435.861 676.946 436.078 677.384 436.078C677.613 436.078 677.847 436.019 678.056 435.892L721.917 409.826C722.075 409.731 722.213 409.605 722.316 409.455C722.423 409.301 733.084 394.026 739.143 389.199C745.341 384.261 759.782 373.249 759.928 373.138C760.493 372.707 760.596 371.906 760.157 371.353C759.719 370.8 758.905 370.701 758.336 371.127L758.336 371.131Z"
            fill="#014A4F"
          />
          <path
            d="M53.4993 293.217C52.9384 292.782 52.1208 292.873 51.6784 293.422L1.24443e-05 357.33L1.22653e-05 361.426L53.7126 295.002C54.159 294.453 54.0642 293.651 53.4993 293.217Z"
            fill="#014A4F"
          />
          <path
            d="M770.637 381.295L716.043 446.668L684.992 452.005C684.285 452.127 683.815 452.787 683.937 453.478C684.048 454.094 684.597 454.529 685.213 454.529C685.288 454.529 685.363 454.521 685.438 454.509L716.964 449.089C717.272 449.038 717.548 448.88 717.742 448.643L772.651 382.891L790 361.43L790 357.334L770.637 381.295Z"
            fill="#014A4F"
          />
          <path
            d="M733.04 455.986C732.578 455.449 731.761 455.378 731.212 455.832C730.662 456.282 730.591 457.084 731.054 457.621C731.251 457.855 750.95 480.883 753.588 487.903C756.12 494.641 756.357 499.492 750.077 504.37C743.461 509.505 711.762 536.16 711.505 536.381C710.964 536.84 710.9 537.641 711.37 538.175C711.627 538.467 711.991 538.617 712.354 538.617C712.654 538.617 712.954 538.514 713.199 538.309C713.46 538.092 745.116 511.468 751.689 506.365C759.142 500.578 758.814 494.448 756.026 487.026C753.233 479.592 733.866 456.946 733.044 455.986L733.04 455.986Z"
            fill="#014A4F"
          />
          <path
            d="M727.57 486.635C727.103 486.101 726.286 486.042 725.741 486.496C725.196 486.955 725.132 487.756 725.602 488.29L763.238 531.298C763.495 531.59 763.859 531.74 764.222 531.74C764.522 531.74 764.822 531.641 765.067 531.432C765.612 530.974 765.676 530.172 765.206 529.639L727.57 486.631L727.57 486.635Z"
            fill="#014A4F"
          />
          <path
            d="M69.5799 516.592L34.2981 488.226C34.0216 488.005 33.6661 487.902 33.3146 487.95C32.963 487.993 32.6431 488.175 32.4337 488.455L0.00392901 490.869L0.00392889 493.527C16.835 492.291 33.7096 491.039 33.7096 491.039L67.9367 518.559C68.4897 519.001 69.3074 518.922 69.7616 518.381C70.2159 517.836 70.1329 517.038 69.5799 516.592Z"
            fill="#014A4F"
          />
          <path
            d="M756.468 493.365C755.753 493.401 755.204 493.997 755.24 494.701C755.275 495.4 755.872 495.929 756.594 495.905C757.124 495.925 773.54 494.736 790 493.527L790 490.869L756.468 493.365Z"
            fill="#014A4F"
          />
          <path
            d="M22.2466 507.159C21.7015 506.701 20.8839 506.76 20.4178 507.293C19.9517 507.826 20.011 508.628 20.5561 509.087L51.7258 537.25C51.9707 537.456 52.5435 537.543 52.8437 537.543C53.2071 537.543 53.2979 537.408 53.5547 537.116C54.0208 536.583 53.9615 535.781 53.4164 535.323L22.2466 507.159Z"
            fill="#014A4F"
          />
          <path
            d="M551.248 654.14L540.002 655.57C539.765 655.601 539.544 655.692 539.358 655.838L521.792 669.66L491.669 659.117C490.966 658.963 490.279 659.397 490.125 660.085C489.971 660.772 490.413 661.447 491.112 661.602L521.84 672.275C521.931 672.294 522.025 672.306 522.12 672.306C522.412 672.306 522.701 672.207 522.934 672.026L540.701 658.046L551.583 656.66C552.294 656.569 552.796 655.933 552.701 655.234C552.61 654.539 551.963 654.049 551.248 654.136L551.248 654.14Z"
            fill="#014A4F"
          />
          <path
            d="M651.034 741.112L634.266 755.652C631.264 756.11 612.414 759.006 605.928 760.443C603.471 760.989 601.796 762.023 600.951 763.52C599.738 765.677 600.615 768.126 601.089 769.442C601.18 769.69 601.259 769.904 601.303 770.074C601.449 770.65 601.978 771.037 602.559 771.037C602.661 771.037 602.768 771.026 602.875 770.998C603.57 770.828 603.993 770.137 603.819 769.457C603.76 769.232 603.661 768.94 603.538 768.6C603.155 767.53 602.575 765.91 603.222 764.753C603.692 763.919 604.794 763.303 606.501 762.928C613.319 761.415 634.823 758.144 635.04 758.113C635.285 758.077 635.514 757.971 635.7 757.809L652.752 743.02C653.289 742.554 653.336 741.752 652.862 741.226C652.388 740.701 651.571 740.65 651.034 741.116L651.034 741.112Z"
            fill="#014A4F"
          />
          <path
            d="M631.414 790.969C630.928 790.452 630.11 790.416 629.581 790.89C629.052 791.364 629.016 792.17 629.502 792.688L639.44 803.321C639.697 803.594 640.045 803.732 640.396 803.732C640.708 803.732 641.024 803.621 641.273 803.396C641.802 802.922 641.838 802.116 641.352 801.599L631.414 790.965L631.414 790.969Z"
            fill="#014A4F"
          />
          <path
            d="M634.771 717.953C634.337 717.392 633.523 717.285 632.954 717.712C632.385 718.139 632.275 718.936 632.709 719.493L667.572 764.326L652.93 776.078L650.639 773.534C650.402 773.269 650.062 773.115 649.702 773.103C649.347 773.084 648.995 773.23 648.743 773.483L637.967 784.207L625.58 794.078L616.143 781.063C616.988 778.041 620.314 764.844 615.736 759.29C615.286 758.745 614.468 758.658 613.911 759.1C613.354 759.543 613.267 760.341 613.718 760.89C617.229 765.148 614.741 776.808 613.485 780.944C613.37 781.319 613.441 781.726 613.67 782.042L624.268 796.661C624.478 796.95 624.794 797.135 625.149 797.183C625.208 797.191 625.264 797.195 625.323 797.195C625.619 797.195 625.907 797.096 626.14 796.91L639.658 786.138C639.693 786.111 639.729 786.079 639.764 786.044L649.616 776.24L651.816 778.681C652.278 779.194 653.068 779.258 653.609 778.823L670.187 765.515C670.729 765.081 670.819 764.307 670.397 763.761L634.771 717.953Z"
            fill="#014A4F"
          />
          <path
            d="M620.113 643.965L590.223 592.176L553.689 491.758C553.361 491.134 552.579 490.885 551.943 491.209C551.307 491.529 551.054 492.295 551.382 492.919L587.944 593.38L617.916 645.316C617.956 645.383 617.999 645.442 618.047 645.501L763.227 816.846C763.483 817.15 763.855 817.304 764.226 817.304C764.518 817.304 764.811 817.209 765.055 817.012C765.605 816.562 765.684 815.76 765.225 815.223L620.117 643.965L620.113 643.965Z"
            fill="#014A4F"
          />
          <path
            d="M686.841 588.569L570.761 679.961C570.204 680.4 570.113 681.202 570.559 681.751C570.816 682.063 571.191 682.225 571.571 682.225C571.855 682.225 572.143 682.134 572.38 681.944L688.46 590.552C689.017 590.114 689.108 589.312 688.658 588.763C688.207 588.214 687.394 588.127 686.833 588.569L686.841 588.569Z"
            fill="#014A4F"
          />
          <path
            d="M647.455 621.173C646.993 620.636 646.175 620.565 645.626 621.015C645.077 621.465 645.006 622.267 645.464 622.808L742.884 736.708C743.141 737.008 743.508 737.162 743.879 737.162C744.172 737.162 744.468 737.063 744.713 736.866C745.262 736.415 745.333 735.613 744.875 735.076L647.455 621.177L647.455 621.173Z"
            fill="#014A4F"
          />
          <path
            d="M727.672 643.696L613.915 732.588C613.354 733.026 613.263 733.824 613.71 734.373C613.966 734.689 614.342 734.851 614.725 734.851C615.009 734.851 615.294 734.76 615.535 734.575L729.292 645.683C729.853 645.244 729.944 644.447 729.497 643.897C729.051 643.348 728.233 643.258 727.676 643.696L727.672 643.696Z"
            fill="#014A4F"
          />
          <path
            d="M756.038 676.213L661.821 754.945C661.276 755.399 661.209 756.201 661.675 756.738C661.932 757.034 662.295 757.185 662.663 757.185C662.959 757.185 663.259 757.086 663.504 756.88L757.72 678.148C758.265 677.694 758.332 676.892 757.866 676.355C757.4 675.822 756.583 675.755 756.038 676.213Z"
            fill="#014A4F"
          />
          <path
            d="M762.741 739.856C761.587 734.958 759.913 724.237 759.897 724.131C759.79 723.436 759.122 722.961 758.419 723.064C757.712 723.171 757.226 723.819 757.333 724.514C757.4 724.956 759.04 735.436 760.213 740.425C761.402 745.473 766.454 757.311 766.667 757.813C766.876 758.298 767.358 758.591 767.864 758.591C768.03 758.591 768.2 758.559 768.366 758.492C769.025 758.22 769.337 757.477 769.061 756.829C769.009 756.707 763.882 744.691 762.741 739.852L762.741 739.856Z"
            fill="#014A4F"
          />
          <path
            d="M668.56 794.923C667.999 795.358 667.9 796.16 668.347 796.709L692.327 826.492L695.634 826.492L670.381 795.133C669.939 794.58 669.121 794.485 668.56 794.923Z"
            fill="#014A4F"
          />
          <path
            d="M105.036 342.114L40.089 309.531L3.05176e-05 280.379L3.03791e-05 283.547L38.6314 311.64C38.6906 311.683 38.7499 311.719 38.8131 311.75L103.854 344.382C104.044 344.477 104.246 344.52 104.443 344.52C104.913 344.52 105.367 344.267 105.6 343.829C105.928 343.205 105.675 342.438 105.036 342.118L105.036 342.114Z"
            fill="#014A4F"
          />
          <path
            d="M740.766 244.58L713.807 215.923L695.633 193.352L692.327 193.352L711.801 217.535C711.82 217.558 711.844 217.586 711.864 217.61L738.938 246.39C738.993 246.449 739.052 246.5 739.119 246.548L790 283.548L790 280.38L740.766 244.576L740.766 244.58Z"
            fill="#014A4F"
          />
          <path
            d="M685.32 779.546C684.767 779.992 684.688 780.794 685.142 781.335L723.011 826.492L726.369 826.492L687.145 779.72C686.691 779.178 685.873 779.099 685.32 779.546Z"
            fill="#014A4F"
          />
          <path
            d="M726.369 193.352L723.011 193.352L734.186 206.675C734.443 206.98 734.814 207.138 735.189 207.138C735.478 207.138 735.77 207.043 736.011 206.849C736.564 206.403 736.643 205.601 736.189 205.06L726.369 193.352Z"
            fill="#014A4F"
          />
          <path
            d="M750.717 800.11L718.666 826.492L722.699 826.492L752.384 802.057C752.933 801.607 753.004 800.805 752.542 800.268C752.08 799.731 751.262 799.659 750.713 800.11L750.717 800.11Z"
            fill="#014A4F"
          />
          <path
            d="M682.681 222.969L668.663 202.161C668.461 201.86 668.141 201.659 667.778 201.604L625.52 195.216C625.177 195.165 624.825 195.248 624.549 195.453C624.272 195.655 624.086 195.959 624.039 196.299C622.834 204.76 617.277 210.12 613.603 213.671C612.714 214.528 611.948 215.271 611.383 215.927C608.48 219.296 607.737 223.013 609.29 226.394C610.569 229.175 629.036 242.53 634.669 246.555C634.894 246.713 635.158 246.8 635.431 246.8C635.502 246.8 635.573 246.796 635.644 246.784L662.88 242.368C663.109 242.333 663.322 242.234 663.504 242.088L722.707 193.356L718.674 193.356L682.693 222.973L682.681 222.969ZM662.098 239.911L635.751 244.185C624.501 236.123 612.513 226.951 611.644 225.335C610.526 222.91 611.134 220.145 613.358 217.566C613.848 216.997 614.575 216.298 615.416 215.484C618.932 212.087 624.711 206.514 626.389 197.922L666.814 204.033L680.683 224.617L662.102 239.911L662.098 239.911Z"
            fill="#014A4F"
          />
          <path
            d="M658.479 213.327L621.626 205.494C620.922 205.348 620.235 205.783 620.085 206.466C619.935 207.153 620.377 207.829 621.077 207.979L657.93 215.812C658.021 215.832 658.116 215.84 658.207 215.84C658.803 215.84 659.341 215.433 659.475 214.836C659.625 214.149 659.183 213.474 658.483 213.323L658.479 213.327Z"
            fill="#014A4F"
          />
          <path
            d="M107.773 367.817L90.5823 360.249L27.1921 325.41C27.1684 325.398 27.1486 325.386 27.1249 325.374C26.8366 325.236 14.7218 319.469 1.52588e-05 312.442L1.51352e-05 315.27C13.8923 321.902 25.2013 327.286 25.9557 327.645L89.3657 362.497C89.3973 362.516 89.4329 362.532 89.4684 362.548L106.714 370.14C106.888 370.215 107.066 370.251 107.244 370.251C107.737 370.251 108.429 368.106 107.777 367.817L107.773 367.817Z"
            fill="#014A4F"
          />
          <path
            d="M737.986 287.454C736.694 286.806 735.466 286.178 734.297 285.582C726.831 281.774 721.901 279.261 718.066 279.811C714.139 280.375 667.873 286.411 667.407 286.47C666.696 286.561 666.198 287.201 666.293 287.896C666.387 288.591 667.039 289.081 667.75 288.99C668.216 288.931 714.503 282.892 718.445 282.327C721.451 281.9 726.337 284.389 733.1 287.837C734.273 288.433 735.505 289.065 736.801 289.717C742.208 292.435 769.045 305.269 789.996 315.266L789.996 312.438C769.235 302.527 743.279 290.112 737.982 287.454L737.986 287.454Z"
            fill="#014A4F"
          />
          <path
            d="M697.561 282.299C696.826 282.271 696.246 282.824 696.222 283.527L694.915 321.626L645.496 375.437C645.018 375.958 645.061 376.764 645.59 377.234C645.839 377.451 646.147 377.562 646.459 377.562C646.815 377.562 647.167 377.42 647.423 377.139L697.158 322.984C697.364 322.763 697.482 322.475 697.49 322.179L698.813 283.618C698.837 282.915 698.276 282.327 697.561 282.303L697.561 282.299Z"
            fill="#014A4F"
          />
          <path
            d="M74.5096 380.363L5.34058e-05 346.633L5.32833e-05 349.434L73.4234 382.673C73.6011 382.752 73.7828 382.792 73.9645 382.792C74.4543 382.792 74.9244 382.519 75.1416 382.053C75.4418 381.417 75.1574 380.659 74.5057 380.367L74.5096 380.363Z"
            fill="#014A4F"
          />
          <path
            d="M668.252 291.514C667.6 291.222 666.83 291.499 666.53 292.135C666.229 292.771 666.514 293.529 667.166 293.821L790 349.434L790 346.633L668.252 291.518L668.252 291.514Z"
            fill="#014A4F"
          />
          <path
            d="M738.914 244.699C738.14 245.579 719.875 266.329 717.161 270.595C714.005 275.56 715.226 278.116 719.16 281.971C719.413 282.22 719.745 282.342 720.076 282.342C720.408 282.342 720.74 282.22 720.993 281.971C721.498 281.473 721.498 280.668 720.993 280.174C717.394 276.646 717.149 275.418 719.361 271.942C721.968 267.842 740.688 246.579 740.877 246.366C741.347 245.832 741.284 245.03 740.743 244.572C740.202 244.114 739.38 244.173 738.914 244.703L738.914 244.699Z"
            fill="#014A4F"
          />
          <path
            d="M719.5 300.058C718.816 300.264 718.433 300.979 718.643 301.65C719.318 303.799 720.981 310.016 720.558 312.959C720.053 316.479 719.184 317.834 715.084 321.495C710.644 325.453 707.642 331.236 707.515 331.481C707.191 332.109 707.448 332.871 708.088 333.187C708.274 333.278 708.475 333.326 708.673 333.326C709.147 333.326 709.601 333.069 709.83 332.626C709.858 332.571 712.749 327.013 716.825 323.379C721.408 319.291 722.525 317.506 723.126 313.315C723.714 309.211 721.226 301.243 721.119 300.904C720.906 300.232 720.179 299.857 719.496 300.062L719.5 300.058Z"
            fill="#014A4F"
          />
          <path
            d="M71.9026 325.524C71.3457 325.086 70.528 325.173 70.0777 325.718L3.43861e-05 411.284L3.42086e-05 415.344L72.1001 327.306C72.5465 326.757 72.4596 325.959 71.8987 325.52L71.9026 325.524Z"
            fill="#014A4F"
          />
          <path
            d="M753.375 456.006C752.929 456.555 753.016 457.353 753.577 457.791C753.818 457.981 754.102 458.072 754.386 458.072C754.766 458.072 755.141 457.91 755.398 457.598L790 415.348L790 411.288L753.375 456.01L753.375 456.006Z"
            fill="#014A4F"
          />
          <path
            d="M97.5146 351.67L47.0765 322.87C46.4564 322.514 45.6624 322.724 45.303 323.328C44.9435 323.936 45.1529 324.714 45.7691 325.066L96.2071 353.866C96.4125 353.984 96.6377 354.04 96.8589 354.04C97.3052 354.04 97.7397 353.814 97.9807 353.408C98.3401 352.799 98.1308 352.021 97.5146 351.67Z"
            fill="#014A4F"
          />
          <path
            d="M13.5723 424.556C13.5289 424.216 13.3471 423.904 13.0706 423.699L-2.38419e-05 413.95L-2.39815e-05 417.145L10.4399 424.931L-2.48766e-05 437.622L-2.50538e-05 441.675L13.2958 425.512C13.517 425.243 13.6158 424.899 13.5723 424.56L13.5723 424.556Z"
            fill="#014A4F"
          />
          <path
            d="M771.846 459.691C771.545 460.059 771.474 460.56 771.664 460.991L776.72 472.497C776.933 472.979 777.411 473.268 777.913 473.268C778.083 473.268 778.256 473.232 778.426 473.165C779.086 472.888 779.386 472.138 779.106 471.494L774.362 460.695L790 441.671L790 437.619L771.846 459.687L771.846 459.691Z"
            fill="#014A4F"
          />
          <path
            d="M774.342 403.779L754.189 426.878L741.742 417.813C741.17 417.394 740.356 417.513 739.929 418.074C739.503 418.639 739.621 419.433 740.198 419.851L753.616 429.624C754.161 430.023 754.932 429.936 755.374 429.43L775.519 406.342L790.004 417.145L790.004 413.95L776.116 403.593C775.57 403.186 774.792 403.265 774.346 403.779L774.342 403.779Z"
            fill="#014A4F"
          />
          <path
            d="M116.001 329.048C112.019 324.975 97.3488 316.214 88.5323 315.392C83.6145 314.934 74.7861 308.144 67.6958 302.685C63.3587 299.351 59.618 296.472 56.8608 294.986C49.7626 291.167 29.8347 274.766 21.9504 264.717C18.3835 260.171 13.4341 253.606 8.65063 247.258C5.7039 243.348 2.70978 239.374 0.00794983 235.835L0.00794964 240.089C2.12517 242.882 4.36088 245.844 6.565 248.771C11.3564 255.131 16.3137 261.707 19.8964 266.27C28.1046 276.733 48.1668 293.209 55.6166 297.218C58.188 298.601 62.0314 301.559 66.1 304.688C73.8421 310.644 82.6191 317.395 88.2953 317.924C96.1243 318.655 110.427 327.013 114.136 330.805C114.393 331.066 114.733 331.197 115.073 331.197C115.396 331.197 115.72 331.078 115.969 330.841C116.487 330.355 116.502 329.549 116.009 329.044L116.001 329.048Z"
            fill="#014A4F"
          />
          <path
            d="M783.427 227.39C776.57 218.909 771.233 221.725 766.944 223.992L766.237 224.364C761.872 226.631 734.897 239.251 734.624 239.378C733.977 239.678 733.704 240.44 734.012 241.072C734.32 241.704 735.09 241.977 735.742 241.673C736.856 241.151 763.037 228.906 767.449 226.611L768.172 226.232C772.312 224.048 775.886 222.16 781.393 228.97C783.569 231.66 786.635 235.653 789.996 240.085L789.996 235.831C787.448 232.489 785.157 229.53 783.423 227.39L783.427 227.39Z"
            fill="#014A4F"
          />
          <path
            d="M90.1715 289.8C89.8358 289.634 81.8527 285.712 75.5959 286.94C69.7459 288.086 52.7686 292.269 52.0497 292.447C51.3545 292.616 50.9318 293.308 51.1096 293.991C51.2557 294.568 51.785 294.951 52.3657 294.951C52.4723 294.951 52.575 294.939 52.6817 294.911C52.8555 294.868 70.3186 290.566 76.1054 289.433C81.4735 288.382 88.9233 292.028 88.9983 292.067C89.6382 292.383 90.4164 292.131 90.7403 291.507C91.0642 290.878 90.8075 290.116 90.1715 289.796L90.1715 289.8Z"
            fill="#014A4F"
          />
          <path
            d="M144.579 289.867C144.556 289.847 144.532 289.828 144.508 289.812L109.59 264.472C109.309 264.271 108.958 264.184 108.618 264.239C108.275 264.291 107.966 264.476 107.765 264.753L85.3959 295.362C84.9812 295.931 85.1115 296.725 85.6922 297.135L121.243 322.269C121.823 322.68 122.633 322.55 123.052 321.981C123.47 321.412 123.34 320.618 122.759 320.208L88.2597 295.816L109.112 267.289L142.932 291.83L152.547 299.908L143.114 311.644C143.114 311.644 143.086 311.679 143.074 311.695L132.883 325.785C132.5 326.314 132.587 327.037 133.077 327.468L145.737 338.551C145.986 338.769 146.294 338.875 146.602 338.875C146.957 338.875 147.313 338.733 147.57 338.449C148.047 337.927 148 337.122 147.467 336.652L135.672 326.322L145.172 313.184L155.355 300.513C155.786 299.979 155.707 299.205 155.181 298.759L144.587 289.859L144.579 289.867Z"
            fill="#014A4F"
          />
          <path
            d="M134.684 305.241L98.1821 280.032C97.5975 279.629 96.7878 279.763 96.373 280.34C95.9622 280.913 96.1005 281.706 96.6851 282.109L133.187 307.319C133.413 307.477 133.673 307.552 133.934 307.552C134.341 307.552 134.744 307.366 134.997 307.01C135.407 306.438 135.269 305.644 134.684 305.241Z"
            fill="#014A4F"
          />
          <path
            d="M150.398 328.909L93.1617 286.96C92.5889 286.541 91.7752 286.656 91.3447 287.217C90.9141 287.778 91.0326 288.576 91.6054 288.994L148.055 330.367L143.161 342.79C142.905 343.446 143.236 344.18 143.904 344.433C144.058 344.492 144.216 344.52 144.37 344.52C144.891 344.52 145.381 344.212 145.579 343.706L150.828 330.387C151.038 329.854 150.86 329.253 150.394 328.909L150.398 328.909Z"
            fill="#014A4F"
          />
          <path
            d="M244.661 449.465C244.618 449.287 244.535 449.117 244.417 448.971L238.859 442.07C239.775 433.953 251.017 334.507 252.139 326.69C252.968 320.895 252.633 318.169 250.938 316.976C249.311 315.831 247.095 316.692 245.566 317.443C244.409 318.011 240.834 319.532 236.311 321.46C221.878 327.606 197.719 337.9 188.539 343.801C179.758 349.446 168.825 350.113 155.114 345.835C147.893 343.584 109.171 336.6 106.932 337.133C106.236 337.303 105.814 337.995 105.983 338.674C106.145 339.314 106.777 339.717 107.429 339.626L107.449 339.626C110.163 339.626 145.914 345.638 154.328 348.261C159.657 349.924 175.879 354.98 189.961 345.93C198.96 340.147 223.932 329.51 237.346 323.794C242.082 321.776 245.507 320.318 246.727 319.722C248.781 318.714 249.334 318.979 249.425 319.046C249.749 319.275 250.417 320.445 249.571 326.342C248.406 334.487 236.347 441.241 236.224 442.315C236.185 442.647 236.283 442.979 236.497 443.243L242.205 450.334L256.369 517.081C256.512 517.666 257.045 518.057 257.629 518.057C257.728 518.057 257.831 518.045 257.934 518.022C258.629 517.86 259.059 517.172 258.893 516.489L244.661 449.469L244.661 449.465Z"
            fill="#014A4F"
          />
          <path
            d="M128.846 340.527C128.278 340.104 127.464 340.211 127.029 340.771L80.8454 400.216C80.4109 400.777 80.5255 401.574 81.0943 401.997C81.3273 402.171 82.6546 402.084 82.9113 401.752L129.095 342.308C129.53 341.747 129.415 340.949 128.846 340.527Z"
            fill="#014A4F"
          />
          <path
            d="M133.705 341.996C133.002 342.122 132.532 342.782 132.658 343.473C133.294 346.922 131.84 352.72 127.144 357.302C122.396 361.936 91.7989 403.091 90.5348 404.802C90.1122 405.37 90.2425 406.164 90.8232 406.579C91.0523 406.741 91.3209 406.82 91.5856 406.82C91.9885 406.82 92.3835 406.638 92.6363 406.295C92.9444 405.88 124.371 363.595 128.977 359.104C134.365 353.846 135.992 347.238 135.214 343.019C135.087 342.328 134.412 341.866 133.705 341.996Z"
            fill="#014A4F"
          />
          <path
            d="M192.391 476.834L108.026 413.847C107.966 413.804 107.899 413.76 107.832 413.729L65.1322 392.221C64.4962 391.901 63.7141 392.146 63.3862 392.77C63.0584 393.394 63.3112 394.16 63.9472 394.484L106.544 415.941L190.814 478.857C191.048 479.031 191.324 479.118 191.601 479.118C191.992 479.118 192.379 478.944 192.631 478.616C193.066 478.059 192.955 477.261 192.387 476.834L192.391 476.834Z"
            fill="#014A4F"
          />
          <path
            d="M78.3096 398.88C77.7447 398.446 76.931 398.545 76.4886 399.098L47.9891 434.814C47.5467 435.367 47.6454 436.165 48.2102 436.599C48.4472 436.781 48.7277 436.868 49.0082 436.868C49.3953 436.868 49.7745 436.702 50.0313 436.378L78.5308 400.662C78.9732 400.109 78.8744 399.311 78.3096 398.877L78.3096 398.88Z"
            fill="#014A4F"
          />
          <path
            d="M85.2774 402.941L54.6843 440.111C54.234 440.656 54.3249 441.458 54.8818 441.9C55.1228 442.09 55.4072 442.181 55.6956 442.181C56.0748 442.181 56.45 442.019 56.7068 441.707L87.4579 404.268C87.9082 403.723 85.7277 402.396 85.2814 402.941L85.2774 402.941Z"
            fill="#014A4F"
          />
          <path
            d="M155.13 375.287C154.241 374.603 153.447 373.948 152.744 373.375C150.299 371.372 148.687 370.049 146.756 370.314C145.452 370.495 144.311 371.392 143.051 373.217C139.989 377.673 73.6485 453.833 72.9809 454.604C72.5148 455.137 72.582 455.939 73.1231 456.397C73.368 456.602 73.6682 456.701 73.9645 456.701C74.3319 456.701 74.6953 456.551 74.952 456.255C77.6894 453.11 142.055 379.213 145.199 374.639C146.167 373.229 146.776 372.877 147.115 372.83C147.905 372.715 149.304 373.869 151.077 375.322C151.792 375.911 152.606 376.579 153.526 377.286C158.906 381.429 190.388 411.137 190.704 411.438C191.221 411.927 192.039 411.908 192.537 411.406C193.034 410.9 193.019 410.095 192.501 409.609C191.202 408.38 160.609 379.509 155.126 375.287L155.13 375.287Z"
            fill="#014A4F"
          />
          <path
            d="M204.565 385.367C204.106 385.067 203.984 384.518 193.99 387.378C193.572 387.496 193.24 387.816 193.113 388.227C192.987 388.638 193.078 389.084 193.358 389.412C198.774 395.78 197.818 403.83 190.593 412.698C180.892 424.603 143.872 470.747 143.501 471.214C143.059 471.767 143.157 472.565 143.718 472.999C143.955 473.181 144.24 473.272 144.52 473.272C144.903 473.272 145.286 473.106 145.539 472.786C145.91 472.32 182.926 426.183 192.62 414.286C201.887 402.913 200.204 394.622 196.534 389.321C199.955 388.417 203.498 387.678 204.375 387.816C204.952 388.093 205.161 385.758 204.565 385.371L204.565 385.367Z"
            fill="#014A4F"
          />
          <path
            d="M153.285 476.949C153.246 476.91 153.202 476.874 153.159 476.838L74.7585 417.655C74.1897 417.228 73.376 417.331 72.9415 417.888C72.503 418.445 72.6097 419.243 73.1785 419.67L151.512 478.802C152.25 479.528 159.538 486.694 163.251 490.134C165.455 492.176 166.952 512.251 167.126 530.48C167.134 531.179 167.711 531.74 168.422 531.74L168.434 531.74C169.149 531.732 169.725 531.159 169.717 530.456C169.658 524.085 169.145 492.101 165.029 488.286C161.166 484.703 153.364 477.024 153.285 476.945L153.285 476.949Z"
            fill="#014A4F"
          />
          <path
            d="M190.06 433.811L115.199 375.488C114.638 375.05 113.824 375.141 113.378 375.686C112.932 376.235 113.023 377.033 113.579 377.471L187.414 434.988L73.1785 570.865C72.7242 571.406 72.8032 572.208 73.3562 572.654C73.5972 572.848 73.8895 572.943 74.1779 572.943C74.5531 572.943 74.9244 572.785 75.1811 572.481L190.254 435.608C190.475 435.343 190.578 435.008 190.542 434.668C190.506 434.328 190.333 434.02 190.06 433.807L190.06 433.811Z"
            fill="#014A4F"
          />
          <path
            d="M213.673 414.606C207.045 382.598 188.354 345.97 188.168 345.606C187.844 344.978 187.066 344.725 186.426 345.041C185.786 345.357 185.529 346.12 185.849 346.748C186.035 347.111 204.573 383.44 211.13 415.111C217.714 446.905 230.05 543.191 230.153 544.025C230.236 544.668 230.793 545.139 231.437 545.139C231.492 545.139 232.195 544.183 232.25 544.175C232.961 544.088 232.815 544.404 232.724 543.709C232.618 542.875 220.27 446.49 213.666 414.602L213.673 414.606Z"
            fill="#014A4F"
          />
          <path
            d="M240.723 410.43L212.706 416.498C212.006 416.648 211.564 417.327 211.718 418.014C211.852 418.607 212.39 419.01 212.982 419.01C213.077 419.01 213.168 419.002 213.263 418.978L241.28 412.911C241.979 412.761 242.422 412.081 242.268 411.394C242.114 410.707 241.418 410.276 240.723 410.426L240.723 410.43Z"
            fill="#014A4F"
          />
          <path
            d="M245.989 363.46L200.67 376.744C199.983 376.946 199.596 377.653 199.797 378.328C199.967 378.881 200.48 379.237 201.037 379.237C201.16 379.237 201.286 379.221 201.409 379.182L246.727 365.898C247.415 365.696 247.802 364.989 247.6 364.314C247.395 363.642 246.672 363.259 245.985 363.46L245.989 363.46Z"
            fill="#014A4F"
          />
          <path
            d="M234.976 324.09C235.043 323.391 234.522 322.771 233.811 322.704C233.096 322.629 232.464 323.146 232.397 323.846C232.345 324.355 227.329 375.066 225.717 384.147C224.05 393.524 228.873 414.124 229.079 414.997C229.217 415.585 231.769 415.107 231.607 414.424C231.559 414.215 226.697 393.457 228.277 384.585C229.908 375.409 234.779 326.184 234.984 324.09L234.976 324.09Z"
            fill="#014A4F"
          />
          <path
            d="M208.088 335.395C207.867 334.728 207.136 334.365 206.457 334.578C205.773 334.795 205.402 335.51 205.623 336.178L217.801 373.083C217.979 373.62 218.489 373.964 219.034 373.964C219.164 373.964 219.302 373.944 219.433 373.9C220.112 373.683 220.487 372.968 220.266 372.301L208.088 335.395Z"
            fill="#014A4F"
          />
          <path
            d="M230.963 343.738L212.382 353.226C211.746 353.55 211.501 354.316 211.829 354.94C212.062 355.375 212.512 355.624 212.978 355.624C213.18 355.624 213.385 355.576 213.575 355.481L232.156 345.993C232.792 345.669 233.037 344.903 232.709 344.279C232.377 343.655 231.595 343.414 230.959 343.738L230.963 343.738Z"
            fill="#014A4F"
          />
          <path
            d="M216.047 434.332C215.376 434.387 214.866 434.94 214.862 435.592C214.337 437.982 202.475 455.232 197.399 462.618C195.448 465.458 193.903 467.702 193.256 468.697C191.261 471.771 191.913 477.545 192.545 483.131C192.876 486.074 193.192 488.858 193.058 490.837C192.667 496.612 193.86 549.657 193.923 550.933C193.959 551.613 194.531 552.142 195.219 552.142C195.239 552.142 195.724 552.142 195.748 552.142C196.463 552.106 196.554 551.51 196.518 550.807C196.503 550.495 195.27 496.636 195.653 491.007C195.803 488.803 195.476 485.912 195.132 482.85C194.579 477.944 193.947 472.379 195.456 470.06C196.084 469.092 197.616 466.861 199.56 464.036C213.428 443.863 217.612 437.192 217.461 435.485C217.398 434.786 216.77 434.261 216.055 434.328L216.047 434.332Z"
            fill="#014A4F"
          />
          <path
            d="M225.618 499.326L167.225 503.884C166.51 503.94 165.977 504.552 166.036 505.255C166.091 505.919 166.656 506.424 167.328 506.424C167.363 506.424 167.399 506.424 167.43 506.424L225.824 501.866C226.539 501.811 227.072 501.198 227.013 500.495C226.957 499.796 226.337 499.279 225.618 499.33L225.618 499.326Z"
            fill="#014A4F"
          />
          <path
            d="M227.842 515.885L168.327 520.048C167.612 520.099 167.075 520.708 167.126 521.407C167.173 522.074 167.742 522.588 168.418 522.588C168.449 522.588 168.481 522.588 168.513 522.588L228.028 518.425C228.743 518.373 229.28 517.765 229.229 517.066C229.177 516.366 228.549 515.841 227.842 515.889L227.842 515.885Z"
            fill="#014A4F"
          />
          <path
            d="M146.053 354.107L149.43 346.696C149.722 346.057 149.43 345.302 148.774 345.018C148.119 344.733 147.352 345.018 147.06 345.662L143.331 353.838C143.118 354.3 143.209 354.845 143.56 355.217L173.193 386.781L171.262 390.992L45.0383 544.341C44.588 544.886 44.6749 545.688 45.2358 546.13C45.4768 546.32 45.7612 546.41 46.0495 546.41C46.4287 546.41 46.804 546.248 47.0608 545.936L173.387 392.458C173.458 392.375 173.513 392.28 173.561 392.181L175.915 387.054C176.128 386.592 176.037 386.047 175.686 385.672L146.049 354.107L146.053 354.107Z"
            fill="#014A4F"
          />
          <path
            d="M706.875 528.181L680.912 546.474C680.343 546.896 680.228 547.694 680.663 548.255C680.92 548.583 681.307 548.757 681.698 548.757C681.97 548.757 682.247 548.674 682.48 548.496L708.443 530.203C709.012 529.781 709.127 528.983 708.692 528.422C708.258 527.861 707.444 527.754 706.875 528.177L706.875 528.181Z"
            fill="#014A4F"
          />
          <path
            d="M17.2894 583.458L3.8147e-05 572.639L3.80151e-05 575.657L15.895 585.603C16.1122 585.737 16.3532 585.804 16.5902 585.804C17.0168 585.804 17.4355 585.595 17.6844 585.216C18.0715 584.623 17.8937 583.837 17.2894 583.458Z"
            fill="#014A4F"
          />
          <path
            d="M768.741 559.335L736.196 519.681C735.746 519.135 734.932 519.049 734.372 519.487C733.815 519.925 733.724 520.727 734.174 521.276L766.849 561.093C766.936 561.199 767.042 561.294 767.161 561.369L790 575.657L790 572.639L768.741 559.335Z"
            fill="#014A4F"
          />
          <path
            d="M767.015 559.335L702.36 614.347C701.819 614.805 701.76 615.611 702.23 616.14C702.487 616.429 702.846 616.579 703.21 616.579C703.51 616.579 703.814 616.476 704.059 616.267L768.713 561.255C769.254 560.796 769.314 559.991 768.844 559.461C768.373 558.932 767.556 558.873 767.015 559.335Z"
            fill="#014A4F"
          />
          <path
            d="M119.212 739.611L73.0757 725.241L75.2482 711.182C75.3035 710.819 75.1969 710.448 74.952 710.167L39.0263 668.763C38.9789 668.708 38.9276 668.66 38.8723 668.613L-0.00792694 636.491L-0.00792709 639.817L37.1185 670.489L72.5899 711.372L70.3502 725.857C70.2593 726.441 70.5911 727.01 71.156 727.227L118.248 741.969C118.406 742.028 118.568 742.06 118.726 742.06C119.244 742.06 119.73 741.756 119.931 741.258C120.196 740.606 119.868 739.868 119.204 739.607L119.212 739.611Z"
            fill="#014A4F"
          />
          <path
            d="M772.462 622.002C771.913 621.548 771.095 621.619 770.633 622.156C770.171 622.694 770.242 623.496 770.787 623.95L790 639.817L790 636.491L772.462 621.999L772.462 622.002Z"
            fill="#014A4F"
          />
          <path
            d="M60.1552 751.99C60.8701 751.99 61.4507 751.421 61.4507 750.718L61.4507 702.583C61.4507 702.311 61.3599 702.046 61.1979 701.829L47.4637 683.603C47.0371 683.038 46.2273 682.92 45.6506 683.335L14.4571 705.838C14.1648 706.051 13.9753 706.371 13.9358 706.727C13.8963 707.082 14.0108 707.438 14.2517 707.702L26.8089 721.646C27.2829 722.171 28.1045 722.223 28.6378 721.757C29.175 721.291 29.2264 720.489 28.7524 719.963L17.1353 707.058L46.1443 686.131L58.8556 702.998L58.8556 750.714C58.8556 751.417 59.4363 751.986 60.1512 751.986L60.1552 751.99Z"
            fill="#014A4F"
          />
          <path
            d="M15.0615 734.855C15.3617 734.855 15.6659 734.752 15.9108 734.543L61.0084 696.03C61.5495 695.568 61.6049 694.766 61.1348 694.237C60.6648 693.707 59.8431 693.652 59.3059 694.114L14.2083 732.627C13.6672 733.089 13.6119 733.891 14.0819 734.421C14.3387 734.709 14.6981 734.855 15.0615 734.855Z"
            fill="#014A4F"
          />
          <path
            d="M120.259 765.085C120.583 765.085 120.907 765.081 121.239 765.069C133.081 764.686 203.403 758.729 204.114 758.67C204.829 758.611 205.355 757.994 205.295 757.295C205.232 756.596 204.6 756.075 203.893 756.138C203.186 756.197 132.931 762.15 121.156 762.529C111.316 762.845 104.648 757.734 100.244 754.348C99.5213 753.792 98.834 753.266 98.202 752.812C93.0827 749.154 62.4817 748.972 57.1018 749.45C51.0977 749.984 48.2814 747.302 47.6889 744.845C47.1556 742.613 49.0082 722.981 50.3038 711.127C50.3433 710.779 50.2327 710.432 49.9996 710.167L23.8938 680.127C23.4277 679.594 22.6101 679.527 22.065 679.985C21.5199 680.439 21.4527 681.241 21.9188 681.778L47.6612 711.404C47.1201 716.404 44.3827 742.186 45.1569 745.429C45.9864 748.882 49.6678 752.674 57.327 751.986C64.1526 751.378 92.664 752.006 96.6654 754.87C97.2618 755.296 97.9294 755.81 98.6365 756.351C103.195 759.851 110.017 765.085 120.255 765.089L120.259 765.085Z"
            fill="#014A4F"
          />
          <path
            d="M12.2846 744.359C12.494 744.359 12.7034 744.307 12.9009 744.205L47.9377 725.62L71.5431 727.322C72.262 727.37 72.8782 726.848 72.9296 726.149C72.9809 725.45 72.4437 724.838 71.7327 724.786L47.7481 723.06C47.5032 723.04 47.2543 723.096 47.0371 723.21L11.6645 741.973C11.0325 742.309 10.7994 743.079 11.1391 743.695C11.3722 744.122 11.8185 744.363 12.2807 744.363L12.2846 744.359Z"
            fill="#014A4F"
          />
          <path
            d="M73.9685 712.265C74.2647 712.265 74.5649 712.166 74.8098 711.961L94.7417 695.327C95.1288 695.003 95.2868 694.49 95.1486 694.012L93.845 689.52L98.3323 685.27C98.5851 685.029 98.7273 684.701 98.7273 684.354L98.7273 671.03C98.7273 670.809 98.6681 670.592 98.5575 670.398L93.0827 661.021L90.843 651.932C90.8114 651.801 90.7601 651.679 90.689 651.564L86.8021 645.367C86.6244 645.082 86.34 644.877 86.0082 644.798L80.9364 643.566L77.3023 638.62C76.8836 638.051 76.0738 637.921 75.4932 638.332C74.9125 638.743 74.7782 639.537 75.1969 640.105L79.1075 645.43C79.2852 645.675 79.5499 645.849 79.8461 645.92L84.8627 647.14L88.3624 652.726L90.5981 661.795C90.6258 661.914 90.6732 662.024 90.7324 662.127L96.1282 671.366L96.1282 683.813L91.475 688.217C91.1314 688.545 90.9971 689.027 91.1274 689.481L92.4151 693.917L73.1153 710.017C72.5701 710.471 72.503 711.273 72.9691 711.81C73.2259 712.107 73.5893 712.257 73.9566 712.257L73.9685 712.265Z"
            fill="#014A4F"
          />
          <path
            d="M57.2123 692.953C57.5204 692.953 57.8246 692.846 58.0734 692.633L92.7271 662.455C93.2643 661.989 93.3117 661.183 92.8338 660.661C92.3598 660.136 91.5382 660.089 91.0049 660.555L56.3512 690.737C55.814 691.203 55.7666 692.009 56.2446 692.53C56.5013 692.815 56.8568 692.957 57.2163 692.957L57.2123 692.953Z"
            fill="#014A4F"
          />
          <path
            d="M20.9905 714.536C21.2749 714.536 21.5593 714.445 21.8002 714.256L39.6386 700.265C40.1995 699.826 40.2904 699.024 39.8401 698.479C39.3937 697.93 38.5761 697.843 38.0191 698.282L20.1807 712.273C19.6198 712.711 19.529 713.513 19.9793 714.058C20.236 714.37 20.6113 714.536 20.9945 714.536L20.9905 714.536Z"
            fill="#014A4F"
          />
          <path
            d="M197.332 446.459C196.771 446.02 195.957 446.111 195.511 446.656L178.249 467.832C177.803 468.381 177.894 469.183 178.451 469.622C178.692 469.807 178.976 469.898 179.261 469.898C179.64 469.898 180.019 469.736 180.276 469.42L197.537 448.244C197.984 447.695 197.893 446.893 197.332 446.455L197.332 446.459Z"
            fill="#014A4F"
          />
          <path
            d="M192.256 452.388L181.662 446.352C181.042 445.996 180.248 446.206 179.889 446.814C179.529 447.422 179.739 448.201 180.359 448.552L190.953 454.588C191.158 454.706 191.383 454.762 191.605 454.762C192.051 454.762 192.485 454.537 192.726 454.13C193.086 453.521 192.876 452.743 192.256 452.392L192.256 452.388Z"
            fill="#014A4F"
          />
          <path
            d="M185.288 403.818L135.992 465.466C135.55 466.019 135.648 466.817 136.213 467.252C136.45 467.433 136.731 467.524 137.011 467.524C137.394 467.524 137.777 467.358 138.034 467.038L187.331 405.39C187.773 404.837 185.731 403.269 185.288 403.822L185.288 403.818Z"
            fill="#014A4F"
          />
          <path
            d="M180.035 483.36C179.987 483.324 175.058 479.75 170.16 476.262C167.28 474.212 164.973 472.588 163.299 471.443C162.295 470.755 161.513 470.234 160.964 469.886C160.135 469.361 159.104 468.709 158.321 469.645C157.867 470.187 157.946 470.988 158.499 471.435C158.653 471.557 158.827 471.64 159.005 471.684C160.585 472.529 171.214 480.129 178.02 485.066L179.719 504.196C179.778 504.86 180.343 505.358 181.011 505.358C181.05 505.358 181.086 505.358 181.125 505.354C181.84 505.294 182.365 504.678 182.306 503.975L180.556 484.272C180.525 483.909 180.335 483.577 180.035 483.36ZM159.795 471.632C159.894 471.593 159.988 471.545 160.075 471.482C160.162 471.423 160.245 471.348 160.32 471.257C160.245 471.344 160.162 471.419 160.075 471.482C159.984 471.545 159.89 471.597 159.795 471.632Z"
            fill="#014A4F"
          />
          <path
            d="M160.075 471.482C160.166 471.419 160.249 471.344 160.32 471.257C160.245 471.348 160.162 471.419 160.075 471.482Z"
            fill="#014A4F"
          />
          <path
            d="M222.273 480.804L198.177 480.804C197.462 480.804 196.882 481.373 196.882 482.076C196.882 482.779 197.462 483.348 198.177 483.348L222.273 483.348C222.988 483.348 223.568 482.779 223.568 482.076C223.568 481.373 222.988 480.804 222.273 480.804Z"
            fill="#014A4F"
          />
          <path
            d="M178.261 398.126L127.073 458.696C126.615 459.237 126.694 460.039 127.243 460.485C127.484 460.683 127.776 460.778 128.068 460.778C128.439 460.778 128.811 460.62 129.068 460.319L180.256 399.749C180.714 399.208 180.635 398.406 180.086 397.96C179.533 397.514 178.716 397.589 178.261 398.126Z"
            fill="#014A4F"
          />
          <path
            d="M163.239 383.06C162.643 383.12 160.822 383.297 147.194 401.922C146.776 402.491 146.91 403.289 147.491 403.695C147.72 403.857 147.984 403.933 148.245 403.933C148.648 403.933 149.047 403.747 149.3 403.403C155.094 395.483 161.865 386.888 163.587 385.632C163.907 385.612 164.219 385.478 164.456 385.229C164.942 384.716 164.914 383.91 164.389 383.432C164.081 383.151 163.67 383.021 163.239 383.06Z"
            fill="#014A4F"
          />
          <path
            d="M155.11 375.271C154.537 374.848 153.724 374.959 153.293 375.52L138.319 394.926C137.888 395.487 137.999 396.285 138.571 396.708C138.804 396.882 139.081 396.965 139.353 396.965C139.745 396.965 140.136 396.791 140.388 396.459L155.363 377.053C155.793 376.492 155.683 375.694 155.11 375.271Z"
            fill="#014A4F"
          />
          <path
            d="M121.985 424.267C121.42 423.837 120.603 423.94 120.164 424.496L86.4822 464.783C86.0437 465.34 86.1504 466.138 86.7152 466.564C86.9522 466.742 87.2288 466.829 87.5053 466.829C87.8924 466.829 88.2755 466.659 88.5323 466.335L122.214 426.049C122.653 425.492 122.546 424.694 121.981 424.267L121.985 424.267Z"
            fill="#014A4F"
          />
          <path
            d="M145.326 487.085L114.049 462.61C113.489 462.172 112.675 462.263 112.228 462.812C111.782 463.361 111.873 464.163 112.434 464.597L143.71 489.072C143.947 489.257 144.236 489.348 144.52 489.348C144.899 489.348 145.278 489.186 145.535 488.87C145.982 488.321 145.891 487.519 145.33 487.085L145.326 487.085Z"
            fill="#014A4F"
          />
          <path
            d="M137.833 493.65L108.061 469.918C107.504 469.476 106.691 469.559 106.236 470.104C105.786 470.649 105.869 471.451 106.426 471.893L136.197 495.625C136.438 495.818 136.727 495.909 137.015 495.909C137.39 495.909 137.765 495.747 138.022 495.439C138.473 494.894 138.39 494.092 137.833 493.65Z"
            fill="#014A4F"
          />
          <path
            d="M213.729 813.39C218.398 813.39 227.009 812.679 243.212 810.356C259.146 808.073 277.787 805.675 277.941 805.648L277.478 803.147C248.035 808.377 212.978 812.272 209.838 810.459C207.887 807.8 207.211 789.448 206.804 778.452C206.619 773.431 206.461 769.098 206.236 766.574C206.006 763.987 205.145 755.913 204.051 745.69C201.393 720.805 197.376 683.2 196.823 672.144C196.289 661.499 184.317 631.032 182.488 628.275C177.487 620.73 164.409 599.527 160.447 591.761C159.424 589.75 158.258 587.692 157.026 585.508C153.585 579.417 150.03 573.117 149.545 567.914C148.541 557.143 153.759 540.533 158.381 536.002C158.649 535.741 158.788 535.374 158.756 535.003C158.728 534.631 158.535 534.292 158.227 534.075L126.567 509.849L125.039 511.903L155.553 535.311C150.402 541.761 146.021 558.063 146.961 568.144C147.494 573.879 151.184 580.416 154.755 586.736C155.975 588.893 157.125 590.931 158.124 592.895C162.121 600.731 175.279 622.073 180.311 629.658C181.812 631.925 193.714 661.985 194.227 672.267C194.788 683.394 198.806 721.042 201.468 745.955C202.558 756.166 203.419 764.227 203.648 766.795C203.865 769.252 204.024 773.558 204.209 778.543C204.845 795.804 205.517 809.238 207.851 812.102C208.282 812.631 208.902 813.39 213.725 813.39L213.729 813.39Z"
            fill="#014A4F"
          />
          <path
            d="M127.136 626.016C127.784 626.016 128.341 625.542 128.424 624.898L131.6 599.369L204.723 559.296C205.347 558.952 205.572 558.178 205.22 557.565C204.873 556.953 204.083 556.732 203.455 557.076L129.755 597.465C129.395 597.662 129.15 598.018 129.099 598.421L125.84 624.594C125.753 625.289 126.259 625.925 126.97 626.012C127.021 626.02 127.077 626.02 127.128 626.02L127.136 626.016Z"
            fill="#014A4F"
          />
          <path
            d="M298.797 627.137C299.397 627.137 299.934 626.727 300.065 626.13C300.215 625.443 299.765 624.767 299.065 624.621L255.398 616.251L245.85 607.64C245.61 607.423 245.297 607.304 244.974 607.304L228.826 607.304L212.86 581.866L215.992 572.422C216.111 572.062 216.063 571.671 215.854 571.351C203.826 552.667 205.189 561.559 213.334 572.204L210.201 581.637C210.083 581.992 210.13 582.38 210.332 582.696L226.993 609.24C227.23 609.615 227.649 609.844 228.099 609.844L244.464 609.844L253.901 618.357C254.071 618.511 254.284 618.617 254.509 618.665L298.524 627.11C298.615 627.13 298.706 627.137 298.793 627.137L298.797 627.137Z"
            fill="#014A4F"
          />
          <path
            d="M159.289 593.594C159.51 593.594 159.736 593.538 159.941 593.42L210.063 564.814C210.142 564.766 210.217 564.715 210.284 564.656L249.769 529.307C250.298 528.833 250.334 528.027 249.852 527.51C249.37 526.992 248.548 526.953 248.019 527.427L208.637 562.685L158.638 591.224C158.017 591.575 157.808 592.357 158.167 592.962C158.408 593.369 158.843 593.594 159.289 593.594Z"
            fill="#014A4F"
          />
          <path
            d="M168.015 608.79C168.248 608.79 168.477 608.726 168.683 608.608L212.105 583.122C212.721 582.763 212.919 581.981 212.552 581.376C212.184 580.772 211.386 580.578 210.774 580.938L168.477 605.764L165.763 601.19L213.859 570.593C214.46 570.209 214.629 569.423 214.242 568.835C213.851 568.246 213.049 568.076 212.449 568.46L163.318 599.716C162.738 600.084 162.556 600.838 162.904 601.423L166.901 608.154C167.075 608.446 167.363 608.659 167.699 608.746C167.806 608.774 167.916 608.786 168.023 608.786L168.015 608.79Z"
            fill="#014A4F"
          />
          <path
            d="M244.614 810.135C244.685 810.135 244.76 810.131 244.831 810.115C245.538 809.997 246.012 809.341 245.894 808.65L222.707 674.964C222.699 674.921 222.687 674.878 222.676 674.834L205.635 615.418L208.084 610.267C208.242 609.935 208.246 609.556 208.096 609.22L202.902 597.536L202.902 587.799C202.902 587.096 202.321 586.527 201.606 586.527C200.891 586.527 200.31 587.096 200.31 587.799L200.31 597.801C200.31 597.974 200.346 598.148 200.417 598.31L205.485 609.71L203.079 614.77C202.949 615.046 202.921 615.358 203.008 615.651L220.159 675.458L243.334 809.076C243.441 809.696 243.99 810.135 244.61 810.135L244.614 810.135Z"
            fill="#014A4F"
          />
          <path
            d="M205.106 770.299C205.177 770.299 205.248 770.295 205.323 770.283L237.014 765.069C237.721 764.954 238.195 764.299 238.077 763.603C237.958 762.912 237.295 762.446 236.584 762.561L204.892 767.775C204.185 767.889 203.711 768.545 203.83 769.24C203.937 769.86 204.486 770.303 205.106 770.303L205.106 770.299Z"
            fill="#014A4F"
          />
          <path
            d="M200.275 723.653C200.35 723.653 200.425 723.645 200.5 723.633L229 718.79C229.707 718.672 230.177 718.012 230.054 717.321C229.932 716.629 229.26 716.167 228.553 716.286L200.054 721.129C199.347 721.247 198.877 721.907 198.999 722.598C199.11 723.218 199.655 723.653 200.275 723.653Z"
            fill="#014A4F"
          />
          <path
            d="M197.012 691.444C197.099 691.444 197.186 691.436 197.277 691.416L223.375 686.127C224.078 685.985 224.528 685.314 224.382 684.626C224.236 683.939 223.552 683.497 222.849 683.639L196.751 688.928C196.048 689.07 195.598 689.742 195.744 690.429C195.871 691.029 196.412 691.444 197.012 691.444Z"
            fill="#014A4F"
          />
          <path
            d="M195.681 667.495C195.898 667.495 197.451 666.729 197.652 666.618L215.163 656.862C215.787 656.514 216.004 655.74 215.648 655.127C215.293 654.519 214.503 654.302 213.879 654.649L195.033 665.117C194.409 665.465 194.192 666.239 194.547 666.851C194.784 667.262 195.223 667.495 195.677 667.495L195.681 667.495Z"
            fill="#014A4F"
          />
          <path
            d="M181.406 630.234C181.635 630.234 181.868 630.175 182.081 630.049L204.936 616.393C205.548 616.026 205.742 615.244 205.37 614.647C204.999 614.047 204.201 613.857 203.589 614.225L180.734 627.88C180.122 628.247 179.928 629.029 180.3 629.626C180.544 630.017 180.971 630.234 181.41 630.234L181.406 630.234Z"
            fill="#014A4F"
          />
          <path
            d="M217.003 767.261C217.039 767.261 217.078 767.261 217.114 767.257C217.829 767.198 218.358 766.582 218.295 765.883C216.344 743.332 211.074 690.982 211.288 687.936C211.359 687.731 211.378 687.502 211.331 687.269C211.185 686.582 210.501 686.139 209.798 686.281C208.586 686.526 208.621 687.383 208.744 690.216C208.811 691.8 208.949 694.114 209.151 697.1C209.49 702.113 210.02 709.093 210.723 717.838C211.92 732.726 215.7 765.95 215.716 766.1C215.775 766.763 216.34 767.265 217.007 767.265L217.003 767.261Z"
            fill="#014A4F"
          />
          <path
            d="M234.672 811.509C234.727 811.509 234.782 811.509 234.838 811.498C235.549 811.407 236.05 810.771 235.96 810.076L230.058 764.974C229.967 764.279 229.32 763.789 228.609 763.876C227.898 763.967 227.396 764.603 227.487 765.298L233.388 810.403C233.471 811.043 234.032 811.513 234.672 811.513L234.672 811.509Z"
            fill="#014A4F"
          />
          <path
            d="M267.99 781.541C268.093 781.541 268.192 781.529 268.295 781.505C268.99 781.339 269.42 780.652 269.25 779.972L252.151 708.327C251.985 707.643 251.282 707.224 250.587 707.39C249.891 707.556 249.461 708.244 249.631 708.923L266.73 780.569C266.873 781.153 267.406 781.544 267.99 781.544L267.99 781.541Z"
            fill="#014A4F"
          />
          <path
            d="M250.073 762.569C250.203 762.569 250.334 762.549 250.46 762.509L263.049 758.654C263.732 758.445 264.115 757.73 263.902 757.062C263.689 756.391 262.962 756.019 262.279 756.229L251.053 759.665L240.688 710.72C240.542 710.033 239.854 709.591 239.151 709.737C238.452 709.879 238.002 710.554 238.148 711.242L248.805 761.557C248.88 761.909 249.101 762.213 249.417 762.395C249.619 762.509 249.844 762.569 250.073 762.569Z"
            fill="#014A4F"
          />
          <path
            d="M227.866 714.489C228.782 714.489 231.524 713.817 242.852 711.044C249.429 709.433 255.986 707.809 257.073 707.541L276.76 703.875C277.474 703.875 278.055 703.306 278.055 702.603C278.055 701.9 277.474 701.331 276.76 701.331L256.911 704.997C256.804 704.997 256.697 705.009 256.595 705.036C245.637 707.75 228.956 711.846 227.901 712.004L228.099 713.252L228.036 713.264L227.329 714.331C227.471 714.421 227.578 714.489 227.87 714.489L227.866 714.489Z"
            fill="#014A4F"
          />
          <path
            d="M248.896 613.383C249.141 613.383 249.386 613.316 249.603 613.174C255.88 609.149 276.199 595.525 274.062 589.158C272.292 583.892 258.803 554.164 254.521 549.965C251.479 546.987 247.229 535.832 245.847 531.787C245.617 531.12 244.883 530.764 244.203 530.986C243.524 531.207 243.157 531.93 243.386 532.593C243.595 533.209 248.592 547.75 252.688 551.763C256.334 555.338 269.645 584.125 271.601 589.952C272.64 593.045 260.256 603.307 248.189 611.045C247.588 611.428 247.423 612.218 247.814 612.807C248.062 613.178 248.477 613.383 248.9 613.383L248.896 613.383Z"
            fill="#014A4F"
          />
          <path
            d="M244.97 609.844C245.258 609.844 245.546 609.753 245.787 609.56C246.344 609.117 246.427 608.316 245.977 607.77L215.763 571.225C215.313 570.68 214.495 570.597 213.938 571.039C213.381 571.481 213.298 572.283 213.748 572.828L243.962 609.374C244.219 609.686 244.594 609.844 244.97 609.844Z"
            fill="#014A4F"
          />
          <path
            d="M257.791 607.229C258.036 607.229 258.281 607.162 258.502 607.02C259.103 606.633 259.265 605.847 258.874 605.258L223.015 551.806C222.62 551.222 221.818 551.056 221.218 551.443C220.618 551.83 220.456 552.616 220.847 553.205L256.705 606.656C256.954 607.028 257.369 607.229 257.791 607.229Z"
            fill="#014A4F"
          />
          <path
            d="M268.141 598.709C268.401 598.709 268.666 598.634 268.891 598.472C269.476 598.065 269.61 597.271 269.195 596.698L246.392 565.359L257.697 556.787C258.262 556.357 258.368 555.559 257.93 555.006C257.491 554.449 256.678 554.346 256.109 554.777L243.816 564.095C243.263 564.514 243.149 565.284 243.552 565.841L267.078 598.176C267.331 598.523 267.73 598.709 268.137 598.709L268.141 598.709Z"
            fill="#014A4F"
          />
          <path
            d="M149.311 573.95C149.541 573.95 149.77 573.891 149.983 573.764L191.154 548.911C191.766 548.547 191.964 547.765 191.593 547.165C191.221 546.565 190.423 546.371 189.811 546.734L148.64 571.588C148.028 571.951 147.83 572.734 148.201 573.334C148.446 573.729 148.873 573.946 149.311 573.946L149.311 573.95Z"
            fill="#014A4F"
          />
          <path
            d="M140.266 644.273C140.301 644.273 140.337 644.273 140.373 644.269L185.96 640.635C186.675 640.579 187.204 639.963 187.149 639.264C187.09 638.565 186.462 638.047 185.751 638.099L140.163 641.733C139.448 641.788 138.919 642.404 138.974 643.104C139.03 643.767 139.598 644.273 140.266 644.273Z"
            fill="#014A4F"
          />
          <path
            d="M143.845 671.512C143.888 671.512 143.932 671.512 143.975 671.504L193.828 667.598C194.539 667.527 195.061 666.906 194.99 666.207C194.919 665.508 194.283 664.999 193.572 665.066L143.718 668.972C143.007 669.043 142.486 669.664 142.557 670.363C142.624 671.018 143.189 671.508 143.845 671.508L143.845 671.512Z"
            fill="#014A4F"
          />
          <path
            d="M181.402 712.265C181.437 712.265 181.473 712.265 181.504 712.261C187.603 711.779 197.506 711.028 198.762 711.024C198.869 711.04 198.975 711.044 199.086 711.032C199.994 710.945 200.212 710.408 200.216 709.642L200.247 709.642C200.239 709.559 200.227 709.488 200.212 709.421C200.2 709.16 200.172 708.876 200.144 708.575L197.806 684.761C197.739 684.065 197.111 683.56 196.404 683.615L145.82 687.869C145.105 687.929 144.575 688.545 144.639 689.244C144.698 689.943 145.334 690.46 146.041 690.401L195.345 686.254C195.76 690.476 197.103 704.163 197.534 708.532C197.131 708.556 196.653 708.583 196.119 708.619C194.468 708.726 192.268 708.883 190.072 709.049C186.276 709.33 182.484 709.63 181.524 709.705L148.683 699.708C147.992 699.518 147.277 699.909 147.084 700.584C146.89 701.26 147.289 701.963 147.98 702.153L181.046 712.213C181.161 712.245 181.279 712.261 181.398 712.261L181.402 712.265Z"
            fill="#014A4F"
          />
          <path
            d="M118.62 742.095C118.671 742.095 118.722 742.095 118.774 742.088L201.369 732.536C202.08 732.453 202.59 731.821 202.507 731.126C202.424 730.427 201.78 729.929 201.065 730.012L118.47 739.563C117.759 739.646 117.249 740.278 117.332 740.974C117.411 741.621 117.968 742.095 118.62 742.095Z"
            fill="#014A4F"
          />
          <path
            d="M185.853 758.595C185.893 758.595 185.936 758.595 185.976 758.591C186.691 758.524 187.212 757.904 187.145 757.204L182.693 710.874C182.626 710.175 181.994 709.662 181.279 709.729C180.564 709.796 180.043 710.416 180.11 711.115L184.562 757.445C184.625 758.105 185.19 758.599 185.849 758.599L185.853 758.595Z"
            fill="#014A4F"
          />
          <path
            d="M101.354 819.887C101.394 819.887 101.437 819.887 101.477 819.884L208.009 810.131C208.724 810.064 209.245 809.448 209.178 808.748C209.111 808.049 208.483 807.532 207.768 807.599L101.236 817.352C100.521 817.415 99.9992 818.035 100.066 818.734C100.13 819.394 100.694 819.887 101.354 819.887Z"
            fill="#014A4F"
          />
          <path
            d="M132.646 815.764C130.561 796.01 127.326 766.155 126.863 763.868C126.82 763.753 126.792 763.631 126.784 763.501C126.737 762.802 127.278 762.193 127.993 762.146C129.206 762.071 129.395 763.24 129.482 763.805C129.542 764.192 129.621 764.765 129.715 765.492C129.901 766.921 130.142 768.968 130.426 771.421C130.928 775.754 131.6 781.793 132.425 789.377C133.847 802.436 135.23 815.495 135.23 815.495C135.305 816.194 134.787 816.818 134.072 816.889C134.029 816.893 133.981 816.897 133.938 816.897C133.282 816.897 132.717 816.412 132.65 815.756L132.646 815.764ZM127.649 764.619C127.804 764.67 127.973 764.698 128.163 764.686C127.985 764.698 127.811 764.674 127.649 764.619Z"
            fill="#014A4F"
          />
          <path
            d="M183.626 812.355C183.661 812.355 183.697 812.355 183.736 812.351C184.451 812.292 184.98 811.679 184.921 810.98L180.552 759.357C180.493 758.658 179.873 758.133 179.154 758.196C178.439 758.255 177.91 758.867 177.969 759.567L182.338 811.19C182.393 811.853 182.962 812.355 183.629 812.355L183.626 812.355Z"
            fill="#014A4F"
          />
          <path
            d="M108.255 802.144L121.511 798.893C122.206 798.723 122.629 798.032 122.455 797.353C122.281 796.669 121.578 796.258 120.883 796.428L106.932 799.849C106.497 799.956 106.149 800.272 106.011 800.69L97.4752 826.492L100.201 826.492L108.255 802.144Z"
            fill="#014A4F"
          />
          <path
            d="M148.134 815.55C148.087 814.851 147.471 814.318 146.756 814.365C146.041 814.413 145.5 815.017 145.547 815.72L146.278 826.492L148.877 826.492L148.134 815.55Z"
            fill="#014A4F"
          />
          <path
            d="M95.5633 199.328L95.5633 208.56C95.5633 208.919 95.7213 209.263 95.9939 209.504C96.2348 209.713 96.5429 209.832 96.8589 209.832C96.9063 209.832 96.9497 209.832 96.9971 209.824L148.383 204.44C149.074 204.369 149.584 203.772 149.541 203.093L148.881 193.356L146.282 193.356L146.87 202.042L98.1585 207.149L98.1585 199.534L100.201 193.36L97.4751 193.36L95.6304 198.941C95.587 199.068 95.5672 199.202 95.5672 199.332L95.5633 199.328Z"
            fill="#014A4F"
          />
          <path
            d="M122.554 816.905C122.578 816.905 122.597 816.905 122.621 816.905C123.336 816.87 123.885 816.273 123.85 815.57L122.558 790.728L156.757 788.955C157.472 788.919 158.021 788.319 157.982 787.62C157.942 786.921 157.334 786.379 156.619 786.419L121.128 788.26C120.413 788.295 119.864 788.896 119.9 789.595L121.258 815.7C121.294 816.38 121.867 816.905 122.554 816.905Z"
            fill="#014A4F"
          />
          <path
            d="M104.668 810.135L122.202 810.135C122.917 810.135 123.498 809.566 123.498 808.863C123.498 808.16 122.917 807.591 122.202 807.591L104.668 807.591C103.953 807.591 103.373 808.16 103.373 808.863C103.373 809.566 103.953 810.135 104.668 810.135Z"
            fill="#014A4F"
          />
          <path
            d="M274.682 210.31C273.054 210.483 111.774 227.686 107.133 228.065C102.31 228.46 99.9083 228.338 98.4271 226.596C97.4751 225.474 97.9057 222.879 98.3955 221.275L163.65 214.113C164.361 214.034 164.875 213.406 164.796 212.707C164.717 212.008 164.073 211.506 163.362 211.585L97.2856 218.838C96.8036 218.889 96.3928 219.201 96.219 219.643C95.9899 220.228 94.0544 225.422 96.4323 228.223C98.1585 230.253 100.6 230.791 103.582 230.791C104.755 230.791 106.015 230.708 107.346 230.597C112.019 230.214 268.31 213.545 274.958 212.834C275.669 212.759 276.187 212.131 276.108 211.431C276.029 210.732 275.389 210.231 274.678 210.306L274.682 210.31Z"
            fill="#014A4F"
          />
          <path
            d="M242.276 809.143C242.141 808.452 239.594 808.93 239.728 809.617L243.002 826.488L245.641 826.488L242.272 809.139L242.276 809.143Z"
            fill="#014A4F"
          />
          <path
            d="M248.106 214.121C248.185 214.121 248.268 214.113 248.351 214.098C249.054 213.967 249.516 213.3 249.382 212.612L245.641 193.352L243.002 193.352L246.834 213.086C246.952 213.695 247.498 214.121 248.106 214.121Z"
            fill="#014A4F"
          />
          <path
            d="M280.504 221.152L133.768 239.998C133.057 240.089 132.555 240.725 132.65 241.424C132.733 242.064 133.294 242.534 133.934 242.534C133.989 242.534 134.045 242.534 134.104 242.522L280.84 223.676C281.551 223.586 282.053 222.95 281.958 222.251C281.867 221.555 281.219 221.062 280.504 221.152Z"
            fill="#014A4F"
          />
          <path
            d="M179.201 234.235C178.486 234.267 177.933 234.863 177.965 235.566L180.149 281.067C180.181 281.746 180.754 282.279 181.445 282.279C181.465 282.279 181.485 282.279 181.508 282.279C182.223 282.248 182.776 281.651 182.745 280.948L180.56 235.448C180.529 234.744 179.916 234.215 179.205 234.239L179.201 234.235Z"
            fill="#014A4F"
          />
          <path
            d="M234.478 323.818L171.329 283.539L168.145 267.557C168.007 266.866 167.328 266.416 166.625 266.554C165.921 266.688 165.463 267.356 165.601 268.047L168.896 284.59C168.967 284.946 169.188 285.254 169.504 285.439L233.151 326.006C233.36 326.129 233.59 326.184 233.815 326.184C234.257 326.184 234.688 325.963 234.929 325.564C235.296 324.959 235.095 324.181 234.478 323.822L234.478 323.818Z"
            fill="#014A4F"
          />
          <path
            d="M260.023 272.653C259.51 272.546 247.399 269.995 239.147 269.611C232.1 269.295 222.857 274.008 219.567 275.837L216.699 270.307C216.49 269.904 216.075 269.639 215.613 269.611C215.151 269.592 214.712 269.805 214.455 270.184L207.823 280.103L201.499 277.768C201.282 277.689 201.041 277.67 200.816 277.705L169.939 283.089C169.232 283.211 168.761 283.871 168.888 284.562C168.998 285.179 169.548 285.609 170.164 285.609C170.239 285.609 170.314 285.601 170.393 285.589L200.919 280.265L207.879 282.832C208.448 283.042 209.087 282.836 209.419 282.339L215.396 273.404L217.872 278.179C218.038 278.495 218.327 278.732 218.678 278.827C219.03 278.922 219.401 278.87 219.709 278.685C219.824 278.618 231.263 271.784 239.021 272.139C247.059 272.515 259.352 275.102 259.474 275.13C260.177 275.276 260.865 274.841 261.015 274.154C261.165 273.467 260.722 272.791 260.019 272.641L260.023 272.653Z"
            fill="#014A4F"
          />
          <path
            d="M249.37 226.469C249.2 225.786 248.501 225.363 247.806 225.533C247.111 225.699 246.684 226.386 246.85 227.07L271.47 323.944C271.613 324.525 272.146 324.916 272.73 324.916C272.833 324.916 272.936 324.904 273.039 324.88C273.734 324.715 274.16 324.027 273.994 323.344L249.374 226.469L249.37 226.469Z"
            fill="#014A4F"
          />
          <path
            d="M236.339 228.251C235.624 228.251 235.043 228.819 235.043 229.522L235.043 270.864C235.043 271.567 235.624 272.136 236.339 272.136C237.054 272.136 237.634 271.567 237.634 270.864L237.634 229.522C237.634 228.819 237.054 228.251 236.339 228.251Z"
            fill="#014A4F"
          />
          <path
            d="M236.129 248.937L167.118 260.005C166.411 260.12 165.933 260.771 166.048 261.466C166.151 262.091 166.704 262.533 167.328 262.533C167.399 262.533 167.466 262.529 167.537 262.517L236.548 251.449C237.255 251.335 237.733 250.683 237.619 249.988C237.504 249.296 236.837 248.826 236.129 248.937Z"
            fill="#014A4F"
          />
          <path
            d="M283.115 236.637C283.167 237.292 282.692 237.881 282.029 237.984L253.134 246.721C253 246.76 252.866 246.78 252.735 246.78C252.186 246.78 251.677 246.437 251.503 245.899C251.282 245.228 251.657 244.513 252.34 244.3L280.441 235.657L277.23 193.352L279.833 193.352L283.115 236.637Z"
            fill="#014A4F"
          />
          <path
            d="M205.635 618.937L206.808 624.835L465.18 786.225L524.964 826.492L532.979 826.492L467.653 782.493L205.635 618.937Z"
            fill="#014A4F"
          />
          <path
            d="M661.659 412.165L661.632 412.085L603.372 248.724L570.524 218.64L532.979 193.352L524.964 193.352L567.814 222.215L599.458 251.228L657.318 413.476L680.165 497.659L742.726 581.834L746.392 579.212L684.332 495.708L661.659 412.165Z"
            fill="#014A4F"
          />
          <path
            d="M352.656 710.898L348.638 712.968C348.875 713.414 372.595 757.817 380.29 782.71C385.204 798.609 385.832 814.354 384.165 826.492L388.715 826.492C390.355 813.816 389.648 797.645 384.635 781.418C376.814 756.118 353.639 712.731 352.656 710.898Z"
            fill="#014A4F"
          />
          <path
            d="M380.377 208.03C379.33 210.436 376.411 217.289 372.374 226.773C358.991 258.216 331.144 323.64 326.313 332.528L325.637 333.772C319.819 344.508 316.611 350.421 298.583 356.078C279.047 362.208 243.923 379.205 243.773 379.276L244.792 383.479C244.942 383.412 280.733 366.352 299.966 360.316C319.752 354.111 323.686 346.851 329.643 335.862L330.314 334.625C335.248 325.552 362.124 262.411 376.561 228.488C380.594 219.015 383.505 212.17 384.548 209.78C386.385 205.566 387.866 199.929 388.715 193.352L384.165 193.352C383.359 199.218 382.024 204.242 380.373 208.03L380.377 208.03Z"
            fill="#014A4F"
          />
          <path
            d="M339.229 273.759L254.872 243.676L253.45 247.902L337.756 277.966L450.107 320.294L450.186 320.318L645.804 378.415L647.119 374.157L451.58 316.084L339.229 273.759Z"
            fill="#014A4F"
          />
          <path
            d="M601.354 246.804L589.982 258.563L589.828 259.081L533.429 448.769C532.102 450.626 524.621 460.924 519.233 464.89C512.111 470.127 512.786 480.702 513.568 492.947L513.62 493.737C514.41 506.108 494.561 585.196 494.853 586.334L498.756 585.97C498.685 585.694 518.913 505.421 518.15 493.456L518.099 492.666C517.368 481.255 516.792 472.245 521.954 468.445C528.452 463.665 537.012 451.515 537.371 450.997L537.577 450.701L537.679 450.357L594.031 260.838L601.476 253.14L630.272 280.759L763.554 474.069L767.315 471.577L633.772 277.891L601.35 246.796L601.354 246.804Z"
            fill="#014A4F"
          />
          <path
            d="M13.0865 726.836C11.3484 724.676 6.58469 719.154 -2.28882e-05 711.601L-2.31879e-05 718.458C4.74397 723.917 8.1331 727.871 9.52351 729.598C14.0898 735.27 14.6033 737.786 14.228 738.904C13.7027 740.464 10.3175 741.534 7.81317 742.206C5.83025 742.735 3.13236 743.715 0.00392959 744.983L0.00392938 749.81C3.70512 748.269 6.86907 747.072 9.00999 746.5C13.2602 745.362 17.3564 743.826 18.5414 740.298C19.6514 736.992 18.0201 732.967 13.0904 726.844L13.0865 726.836Z"
            fill="#014A4F"
          />
          <path
            d="M762.678 757.287C759.284 758.879 756.081 760.384 753.502 761.565C741.047 767.261 683.23 817.119 672.964 826.492L679.683 826.492C696.819 811.288 744.989 770.37 755.421 765.598C758.02 764.409 761.236 762.9 764.641 761.304C772.541 757.595 782.404 752.97 790.004 749.806L790.004 744.979C782.187 748.139 771.652 753.081 762.681 757.291L762.678 757.287Z"
            fill="#014A4F"
          />
          <path
            d="M698.395 605.76C690.826 595.158 687.658 585.382 685.845 567.006C684.099 549.33 682.693 498.998 682.666 498.674L678.143 499.069C678.17 499.393 679.581 549.74 681.326 567.433C683.226 586.677 686.596 596.991 694.674 608.312C699.745 615.418 734.454 655.013 762.342 686.83C773.121 699.127 783.02 710.424 789.996 718.454L789.996 711.597C783.364 703.989 774.887 694.316 765.786 683.931C737.962 652.189 703.336 612.684 698.395 605.76Z"
            fill="#014A4F"
          />
          <path
            d="M672.866 193.443C660.612 204.653 659.589 215.196 659.589 225.035C659.589 232.979 660.15 254.21 665.356 279.771C669.03 297.823 645.906 306.596 632.089 311.837C628.909 313.046 626.16 314.085 624.276 315.041L626.362 318.995C628.025 318.15 630.667 317.15 633.725 315.989C647.878 310.621 674.228 300.623 669.804 278.902C664.677 253.732 664.124 232.848 664.124 225.039C664.124 215.812 665.029 206.703 675.958 196.702C676.934 195.809 678.19 194.679 679.679 193.36L672.96 193.36C672.929 193.388 672.893 193.423 672.862 193.451L672.866 193.443Z"
            fill="#014A4F"
          />
          <path
            d="M110.246 615.303L107.39 611.843C106.375 612.645 53.1359 654.867 1.15471e-05 696.757L1.12976e-05 702.465C54.084 659.836 109.215 616.121 110.246 615.303Z"
            fill="#014A4F"
          />
          <path
            d="M663.14 809.866C662.398 812.781 661.557 816.088 660.443 819.927C659.775 822.226 659.19 824.402 658.677 826.492L663.346 826.492C663.784 824.782 664.266 823.008 664.807 821.148C665.941 817.237 666.79 813.895 667.541 810.945C670.914 797.684 672.048 793.217 687.465 782.334C696.518 775.943 742.86 739.615 789.996 702.465L789.996 696.757C741.952 734.634 693.998 772.238 684.814 778.724C668.046 790.559 666.609 796.211 663.137 809.87L663.14 809.866Z"
            fill="#014A4F"
          />
          <path
            d="M663.346 193.352L658.677 193.352C654.285 211.194 655.391 222.341 660.415 244.051L664.843 243.067C659.822 221.381 658.827 211.056 663.35 193.352L663.346 193.352Z"
            fill="#014A4F"
          />
          <path
            d="M494.099 579.121C475.134 555.108 468.933 520.364 466.041 504.757L461.574 505.555C464.556 521.636 470.868 556.973 490.508 581.846C509.342 605.697 582.769 698.791 582.769 698.791L582.816 698.85L667.612 799.079L671.104 796.239L586.332 696.038C584.839 694.142 512.731 602.722 494.091 579.121L494.099 579.121Z"
            fill="#014A4F"
          />
          <path
            d="M679.818 569.356C676.843 575.19 673.77 581.218 658.961 576.079C639.227 569.222 624.031 573.089 605.904 581.554C598.352 585.081 584.464 592.752 569.758 600.874C551.694 610.851 533.014 621.165 521.958 626.17C504.937 633.872 496.03 647.982 489.528 658.283C487.984 660.732 486.649 662.85 485.377 664.6C478.717 673.783 427.394 755.162 425.214 758.622L429.073 760.961C429.606 760.116 482.501 676.244 489.078 667.175C490.433 665.307 491.871 663.031 493.391 660.622C499.593 650.802 508.082 637.348 523.862 630.207C535.084 625.127 553.847 614.766 571.985 604.749C586.62 596.667 600.445 589.031 607.856 585.571C625.374 577.391 639.104 573.903 657.445 580.274C676.033 586.732 680.754 577.474 683.874 571.347C684.427 570.261 684.953 569.234 685.482 568.44L681.682 566.003C681.007 567.014 680.426 568.155 679.81 569.36L679.818 569.356Z"
            fill="#014A4F"
          />
          <path
            d="M143.631 648.306C142.051 635.914 135.909 626.881 124.849 620.683C115.235 615.299 104.024 607.936 93.77 594.182C84.7363 582.06 24.1151 524.417 6.10352e-05 501.558L6.07573e-05 507.913C27.4963 533.936 81.9436 585.86 90.1083 596.809C100.864 611.242 112.568 618.929 122.597 624.55C132.33 630.005 137.738 637.957 139.128 648.863C140.274 657.857 151.472 759.93 158.582 826.496L163.145 826.496C156.027 759.851 144.785 657.328 143.635 648.31L143.631 648.306Z"
            fill="#014A4F"
          />
          <path
            d="M780.184 492.244L774.168 492.966C774.33 493.128 780.591 499.006 789.996 507.909L789.996 501.554C784.336 496.19 780.682 492.733 780.18 492.24L780.184 492.244Z"
            fill="#014A4F"
          />
          <path
            d="M168.955 249.849C168.762 246.551 166.38 223.661 163.141 193.352L158.578 193.352C161.829 223.783 164.227 246.796 164.42 250.102C164.985 259.764 164.413 265.606 159.704 270.737C156.56 274.162 108.065 335.072 65.2784 388.812C34.7564 427.147 8.39388 460.256 4.24239 465.289C2.59127 467.287 1.22061 468.662 4.89609e-05 469.578L4.87349e-05 474.749C2.31082 473.718 4.79537 471.691 7.76975 468.089C11.9489 463.025 38.3233 429.904 68.8571 391.549C109.479 340.526 160.04 277.022 163.077 273.712C168.825 267.451 169.575 260.495 168.955 249.845L168.955 249.849Z"
            fill="#014A4F"
          />
          <path
            d="M781.804 470.562C779.461 469.973 776.546 469.235 772.79 469.669C762.828 470.819 723.383 486.295 720.005 487.626C713.267 489.095 606.738 512.349 595.058 516.931C592.948 517.761 591.088 518.342 589.449 518.855C581.217 521.434 479.973 562.799 479.973 562.799L483.963 567.247C483.963 567.247 583.808 525.298 590.831 523.097C592.541 522.56 594.481 521.952 596.74 521.067C608.207 516.572 720.041 492.18 721.171 491.936L721.526 491.829C721.949 491.663 763.724 475.203 773.319 474.097C776.238 473.761 778.493 474.33 780.674 474.879C783.996 475.717 786.836 476.171 790.004 474.757L790.004 469.586C786.974 471.869 784.881 471.344 781.808 470.57L781.804 470.562Z"
            fill="#014A4F"
          />
          <path
            d="M44.5327 546.45L40.7288 544.021L14.8008 583.15L-2.52716e-05 598.547L-2.55553e-05 605.037L18.3835 585.911L44.5327 546.45Z"
            fill="#014A4F"
          />
          <path
            d="M736.477 654.227L739.783 657.28L790 605.037L790 598.547L736.477 654.227Z"
            fill="#014A4F"
          />
          <path
            d="M211.371 561.67L0 393.773L-2.5036e-07 399.501L208.515 565.126L211.371 561.67Z"
            fill="#014A4F"
          />
          <path
            d="M664.863 294.374L662.003 297.831L790 399.501L790 393.773L664.863 294.374Z"
            fill="#014A4F"
          />
          <path
            d="M668.347 430.09C667.241 430.023 666.095 429.955 664.989 429.817L664.416 429.746L663.879 429.952C663.468 430.11 653.759 433.878 651.073 440.47C648.537 446.684 651.741 458.767 654.569 469.428L654.909 470.708C657.287 479.702 666.565 483.802 674.181 483.802C675.974 483.802 677.578 483.55 678.826 483.072C684.53 480.879 688.306 475.424 689.183 468.105C690.048 460.884 686.667 448.13 683.155 439.337C679.735 430.765 673.687 430.41 668.351 430.094L668.347 430.09ZM684.672 467.583C683.997 473.22 681.259 477.356 677.167 478.928C676.756 479.086 675.82 479.351 674.181 479.351C669.239 479.351 661.197 476.752 659.301 469.59L658.961 468.31C656.441 458.807 653.305 446.98 655.288 442.125C656.813 438.393 662.418 435.399 665.025 434.304C666.067 434.415 667.087 434.474 668.074 434.534C673.185 434.834 676.563 435.035 678.925 440.956C682.259 449.307 685.403 461.477 684.672 467.583Z"
            fill="#014A4F"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_33_620"
          x1="2.37799e-05"
          y1="448.5"
          x2="790"
          y2="448.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D9D9D9" />
          <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_33_620">
          <rect
            width="1899.44"
            height="790"
            fill="white"
            transform="translate(790 -439.796) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
