import React from "react";
import { Box, Typography } from "../../components";
import { useSettings } from "../../application/SettingsProvider";
import { COLORS } from "../../constants";

export function Info() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.TURQUOISE} id="info">
      <Box flexDirection="row" position="relative" paddingTop={305}>
        <img
          src="image/collage.png"
          alt="collage"
          style={{ width: "100%", height: "auto", maxWidth: "640px" }}
        />
        <Box
          position="absolute"
          style={{
            top: "50%",
            right: "0%",
            transform: "translate(0%, -50%)",
            width: "60%",
          }}
          backgroundColor={COLORS.BLACK}
          paddingTop={20}
          paddingRight={16}
          paddingBottom={20}
          paddingLeft={20}
        >
          <Typography
            size={18}
            lineHeight={24}
            weight={700}
            color={COLORS.WHITE}
          >
            {settings?.info?.title}
          </Typography>
          <Box paddingTop={20}>
            <Typography
              size={14}
              lineHeight={21}
              weight={400}
              color={COLORS.WHITE}
            >
              {settings?.info?.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
