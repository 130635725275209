import { Desktop } from "./desktop";
import { Laptop } from "./laptop";
import { Mobile } from "./mobile";
import React, { useEffect, useState } from "react";

export function MainPage() {
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width < 640) {
        setDeviceType("phone");
      } else if (width >= 640 && width < 1140) {
        setDeviceType("laptop");
      } else {
        setDeviceType("desktop");
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resizeLaptop", handleResize);
  }, []);

  return deviceType === "phone" ? (
    <Mobile />
  ) : deviceType === "laptop" ? (
    <Laptop />
  ) : (
    <Desktop />
  );
}
