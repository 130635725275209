import React from "react";

export function MapHomeSmall() {
  return (
    <svg
      width="306"
      height="395"
      viewBox="0 0 306 395"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_380_46"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="306"
        height="395"
      >
        <rect width="305.931" height="395" fill="url(#paint0_linear_380_46)" />
      </mask>
      <g mask="url(#mask0_380_46)">
        <g clip-path="url(#clip0_380_46)">
          <path
            d="M305.931 74.8734L305.931 -170.313L6.10352e-05 -170.313L5.03177e-05 74.8734L305.931 74.8734Z"
            fill="black"
          />
          <path
            d="M186.793 14.6182C183.715 17.1926 171.323 13.1497 167.251 11.8204C166.456 11.5604 165.882 11.3737 165.55 11.2819C163.914 10.8322 164.025 10.4345 164.559 8.51782C164.602 8.36485 164.646 8.20577 164.691 8.04056C165.283 5.86537 177.986 -23.3422 178.115 -23.6374C178.224 -23.8883 178.106 -24.1774 177.85 -24.2829C177.593 -24.39 177.3 -24.2738 177.191 -24.0229C176.666 -22.816 164.33 5.54415 163.719 7.78818C163.675 7.95186 163.631 8.10941 163.589 8.25931C163.061 10.1515 162.681 11.5175 165.278 12.2303C165.587 12.3145 166.18 12.5087 166.933 12.755C172.542 14.586 178.939 16.44 183.298 16.44C185.09 16.44 186.539 16.1264 187.444 15.3677C187.656 15.1918 187.68 14.8797 187.501 14.6732C187.321 14.4667 187.004 14.4422 186.793 14.6182Z"
            fill="#014A4F"
          />
          <path
            d="M191.139 8.66159L162.073 -0.15391C161.812 -0.233453 161.53 -0.0911983 161.446 0.165787L159.47 6.14986C159.386 6.40838 159.531 6.68526 159.794 6.76939C160.059 6.85199 160.342 6.70972 160.426 6.4512L162.251 0.932154L190.84 9.60235C190.889 9.61764 190.94 9.62376 190.989 9.62376C191.203 9.62376 191.402 9.48762 191.469 9.27652C191.552 9.01648 191.403 8.74113 191.137 8.66006L191.139 8.66159Z"
            fill="#014A4F"
          />
          <path
            d="M118.418 -20.419C118.212 -20.6011 117.895 -20.5842 117.708 -20.3823C117.523 -20.1804 117.54 -19.8683 117.745 -19.6863L163.536 9.4983C163.632 9.58243 163.751 9.62526 163.871 9.62526C164.008 9.62526 164.145 9.5702 164.244 9.46312C164.429 9.26121 164.412 8.94915 164.207 8.76711L118.417 -20.4175L118.418 -20.419Z"
            fill="#014A4F"
          />
          <path
            d="M167.77 -0.782616C167.21 -1.04878 154.035 -7.29902 151.158 -8.3392C150.091 -8.72468 149.4 -9.18053 149.331 -9.74039C149.163 -11.1354 155.458 -22.6279 156.28 -23.5717C156.461 -23.7782 156.437 -24.0887 156.225 -24.2661C156.014 -24.4421 155.698 -24.4191 155.517 -24.2126C154.453 -22.9935 147.939 -11.4047 148.36 -9.49258C148.557 -8.59619 149.397 -7.92618 150.81 -7.41527C153.642 -6.39345 167.195 0.0388048 167.331 0.103051C167.401 0.136704 167.476 0.152011 167.55 0.152011C167.736 0.152011 167.915 0.0495126 168.002 -0.1264C168.123 -0.371147 168.019 -0.664833 167.77 -0.784148L167.77 -0.782616Z"
            fill="#014A4F"
          />
          <path
            d="M197.051 1.34517C196.818 1.2595 173.783 -7.19345 172.366 -7.73954C170.736 -8.36824 169.09 -9.69447 167.964 -11.2869C167.686 -11.6785 167.416 -12.0915 167.155 -12.4907C166.075 -14.1412 165.055 -15.7 163.415 -16.1023C163.146 -16.168 162.873 -16.0074 162.806 -15.7443C162.739 -15.4812 162.902 -15.2135 163.172 -15.1478C164.426 -14.8403 165.341 -13.4406 166.31 -11.9599C166.577 -11.553 166.85 -11.1339 167.14 -10.727C168.376 -8.98164 170.191 -7.52233 171.999 -6.8248C173.423 -6.27565 195.751 1.91727 196.7 2.26603C196.759 2.28745 196.818 2.29817 196.876 2.29817C197.08 2.29817 197.271 2.17578 197.346 1.97845C197.444 1.723 197.311 1.44001 197.052 1.34517L197.051 1.34517Z"
            fill="#014A4F"
          />
          <path
            d="M215.489 -18.7211C215.341 -18.9475 215.034 -19.0132 214.801 -18.871C210.305 -16.1038 198.296 -8.82104 196.728 -8.34684C194.433 -7.65543 191.369 -3.74711 190.542 -0.456784C190.476 -0.19215 190.64 0.0740163 190.909 0.139792C190.949 0.14897 190.99 0.153554 191.03 0.153554C191.255 0.153554 191.461 0.00363498 191.516 -0.221227C192.313 -3.38459 195.259 -6.87377 197.023 -7.40609C198.88 -7.96595 212.298 -16.1726 214.916 -17.7788L219.496 -10.8417C219.645 -10.6138 219.958 -10.548 220.19 -10.6964C220.423 -10.8448 220.49 -11.1492 220.338 -11.3771L215.491 -18.7226L215.489 -18.7211Z"
            fill="#014A4F"
          />
          <path
            d="M183.013 -3.80985C182.748 -3.88786 182.467 -3.73947 182.389 -3.4779L179.605 5.68484C179.525 5.94488 179.677 6.22023 179.943 6.29671C179.99 6.31048 180.039 6.31658 180.087 6.31658C180.302 6.31658 180.503 6.17892 180.569 5.96476L183.353 -3.19798C183.432 -3.45802 183.281 -3.73336 183.015 -3.80985L183.013 -3.80985Z"
            fill="#014A4F"
          />
          <path
            d="M187.798 13.763L183.455 12.2181C183.573 11.7041 183.779 10.7955 183.977 9.89143C184.153 9.08988 184.287 8.44741 184.376 7.98545C184.553 7.06764 184.628 6.6883 184.154 6.54451C183.889 6.46497 183.608 6.60875 183.525 6.8688C183.501 6.94375 183.496 7.02176 183.51 7.09519C183.464 7.59386 182.829 10.4712 182.377 12.4246C182.32 12.6662 182.458 12.911 182.695 12.9967L187.452 14.69C187.509 14.7099 187.567 14.7191 187.623 14.7191C187.828 14.7191 188.021 14.5952 188.096 14.3948C188.191 14.1393 188.056 13.8563 187.795 13.7646L187.798 13.763ZM183.857 7.48371C183.805 7.46688 183.756 7.44395 183.714 7.41335C183.756 7.44395 183.804 7.46688 183.857 7.48371Z"
            fill="#014A4F"
          />
          <path
            d="M175.791 4.00679C175.531 3.91501 175.242 4.0481 175.149 4.30355L171.709 13.7064C171.615 13.9619 171.752 14.2449 172.012 14.3367C172.068 14.3566 172.125 14.3657 172.181 14.3657C172.388 14.3657 172.581 14.2403 172.654 14.0399L176.094 4.63701C176.188 4.38155 176.052 4.09857 175.791 4.00679Z"
            fill="#014A4F"
          />
          <path
            d="M168.405 8.66313C166.341 9.327 166.084 11.9626 166.073 12.0758C166.049 12.3466 166.254 12.5852 166.531 12.6097C166.546 12.6097 166.56 12.6112 166.575 12.6112C166.832 12.6112 167.052 12.4185 167.075 12.1615C167.077 12.1416 167.288 10.0612 168.718 9.59929C168.981 9.51515 169.125 9.23674 169.039 8.97823C168.954 8.71971 168.669 8.579 168.406 8.66466L168.405 8.66313Z"
            fill="#014A4F"
          />
          <path
            d="M183.683 8.68454L174.655 7.57553C174.378 7.54187 174.129 7.73307 174.094 8.00229C174.058 8.27151 174.254 8.51781 174.53 8.55146L183.558 9.66047C183.579 9.66353 183.6 9.66505 183.62 9.66505C183.87 9.66505 184.085 9.48302 184.117 9.23369C184.153 8.96446 183.957 8.71819 183.682 8.68454L183.683 8.68454Z"
            fill="#014A4F"
          />
          <path
            d="M117.506 -15.4904C119.992 -24.1805 117.052 -28.4008 115.08 -30.1431C112.774 -32.1791 97.2368 -40.3491 96.7289 -40.5877C96.4781 -40.7055 96.1323 -40.6321 96.013 -40.3858C95.8937 -40.1411 95.9993 -39.8458 96.2486 -39.7296C96.371 -39.6715 112.208 -31.3546 114.407 -29.412C116.607 -27.4708 118.761 -23.5258 116.54 -15.7581C113.191 -4.04846 115.838 5.38198 124.408 12.2793L121.064 21.4252C120.971 21.6806 121.107 21.9636 121.367 22.0554C121.424 22.0753 121.481 22.0845 121.537 22.0845C121.744 22.0845 121.936 21.959 122.01 21.7586L125.475 12.2838C125.546 12.0865 125.484 11.8647 125.315 11.7332C116.869 5.08827 114.242 -4.07293 117.506 -15.4919L117.506 -15.4904Z"
            fill="#014A4F"
          />
          <path
            d="M161.442 -3.75017C161.209 -3.89855 160.899 -3.83431 160.746 -3.60639L155.866 3.65498C155.715 3.8829 155.78 4.1873 156.013 4.33567C156.097 4.38921 156.193 4.41522 156.287 4.41522C156.45 4.41522 156.611 4.33721 156.707 4.19189L161.587 -3.06947C161.738 -3.2974 161.673 -3.60179 161.44 -3.75017L161.442 -3.75017Z"
            fill="#014A4F"
          />
          <path
            d="M112.83 30.1015C112.79 30.0755 108.855 27.4567 107.501 26.5725C107.285 26.4318 107.074 26.3018 106.874 26.1779C106.32 25.8367 105.746 25.4834 105.691 25.2249C105.673 25.1407 105.716 25.0214 105.817 24.8715C106.552 23.7885 107.359 21.1575 107.417 19.6966L110.883 14.9577C111.045 14.7374 110.993 14.4284 110.768 14.2709C110.542 14.1118 110.23 14.1623 110.068 14.3841L106.511 19.2469C106.45 19.331 106.416 19.4305 106.416 19.5345C106.416 20.7628 105.638 23.3602 104.981 24.327C104.725 24.7063 104.633 25.078 104.709 25.4314C104.855 26.0953 105.509 26.5006 106.338 27.0115C106.531 27.1308 106.735 27.2563 106.943 27.3924C108.293 28.275 112.226 30.8908 112.266 30.9168C112.351 30.9734 112.451 31.0009 112.547 31.0009C112.708 31.0009 112.867 30.926 112.963 30.7852C113.119 30.5604 113.06 30.2544 112.83 30.1015Z"
            fill="#014A4F"
          />
          <path
            d="M114.788 16.7872C114.563 16.6266 114.25 16.6755 114.086 16.8958L106.816 26.6934C106.652 26.9136 106.702 27.2211 106.926 27.3817C107.014 27.446 107.118 27.4765 107.221 27.4765C107.375 27.4765 107.53 27.4062 107.628 27.2731L114.898 17.4755C115.062 17.2553 115.012 16.9478 114.788 16.7872Z"
            fill="#014A4F"
          />
          <path
            d="M118.767 19.3326C118.517 19.2102 118.216 19.3096 118.092 19.5529C117.255 21.1942 112.238 30.487 111.401 31.4262C110.233 32.7356 111.102 37.2206 111.287 38.1063C111.336 38.3388 111.545 38.4994 111.778 38.4994C111.811 38.4994 111.845 38.4963 111.88 38.4887C112.151 38.4336 112.327 38.1736 112.27 37.9074C111.85 35.9035 111.537 32.7677 112.155 32.0732C113.249 30.848 118.863 20.2381 118.989 19.9919C119.112 19.7487 119.011 19.4534 118.764 19.3311L118.767 19.3326Z"
            fill="#014A4F"
          />
          <path
            d="M114.322 27.6892L109.281 24.1985C109.055 24.0409 108.741 24.0945 108.581 24.3163C108.42 24.5381 108.475 24.8455 108.702 25.0031L113.742 28.4938C113.831 28.555 113.932 28.584 114.031 28.584C114.189 28.584 114.345 28.5122 114.443 28.376C114.603 28.1542 114.548 27.8467 114.322 27.6892Z"
            fill="#014A4F"
          />
          <path
            d="M126.571 24.8057C126.319 24.6941 126.027 24.8057 125.92 25.0581C125.583 25.8474 121.618 33.3474 121.615 33.456C121.259 33.5142 120.085 33.118 119.134 32.7967C118.829 32.6943 118.507 32.5856 118.169 32.4755C117.453 32.2415 117.292 31.8575 117.234 31.5898C116.937 30.2162 121.23 23.2623 122.279 22.3261C122.484 22.1426 122.499 21.8321 122.313 21.6301C122.126 21.4298 121.808 21.4144 121.603 21.598C120.664 22.4363 115.811 29.7527 116.25 31.7933C116.367 32.3302 116.738 33.0461 117.849 33.4101C118.184 33.5188 118.502 33.6274 118.805 33.7283C120.012 34.1352 120.913 34.4381 121.551 34.4381C121.904 34.4381 122.178 34.3448 122.378 34.1245C122.859 33.5983 126.452 26.3599 126.833 25.4635C126.94 25.2126 126.821 24.9174 126.568 24.8057L126.571 24.8057Z"
            fill="#014A4F"
          />
          <path
            d="M122.25 33.6977C122.16 33.4408 121.875 33.3031 121.612 33.3903C121.349 33.4775 121.21 33.7574 121.298 34.0159L122.776 38.2562C122.848 38.4611 123.042 38.5896 123.252 38.5896C123.305 38.5896 123.36 38.5805 123.414 38.5636C123.677 38.4764 123.816 38.1965 123.727 37.938L122.25 33.6977Z"
            fill="#014A4F"
          />
          <path
            d="M136.812 32.731C136.77 32.7692 132.453 36.5399 128.953 37.6275C125.435 38.7212 99.6537 37.3276 99.4518 37.3169C99.1688 37.3001 98.9378 37.5097 98.921 37.7804C98.9057 38.0512 99.1168 38.2852 99.3936 38.3005C99.9382 38.3311 114.297 39.0944 121.196 39.0944C124.824 39.0944 127.983 38.9629 129.256 38.5682C132.964 37.4164 137.3 33.6258 137.482 33.4652C137.689 33.2832 137.706 32.9727 137.521 32.7708C137.336 32.5688 137.017 32.5505 136.811 32.7325L136.812 32.731Z"
            fill="#014A4F"
          />
          <path
            d="M132.327 47.578C131.732 45.0984 130.729 37.6887 130.718 37.6137C130.681 37.3445 130.431 37.1563 130.154 37.19C129.878 37.2252 129.686 37.473 129.722 37.7422C129.764 38.0481 130.743 45.2743 131.348 47.8029C132.312 51.8198 134.845 62.9282 134.983 66.035C135.049 67.528 135.365 71.1869 135.706 74.8735L136.716 74.8735C136.373 71.1609 136.052 67.4637 135.986 65.9922C135.817 62.1558 132.361 47.7218 132.327 47.5765L132.327 47.578Z"
            fill="#014A4F"
          />
          <path
            d="M127.862 37.9533C127.628 37.8065 127.318 37.8738 127.168 38.1032C126.108 39.7277 123.767 41.5419 121.136 41.6872C118.476 41.8341 100.972 42.2563 100.812 42.2609C100.535 42.2685 100.316 42.4949 100.324 42.7672C100.331 43.0349 100.555 43.246 100.825 43.246C100.83 43.246 102.532 43.2674 102.537 43.2674C102.696 43.2628 118.517 42.8192 121.193 42.6708C124.193 42.5056 126.781 40.5232 128.014 38.634C128.164 38.4046 128.095 38.1002 127.861 37.9533L127.862 37.9533Z"
            fill="#014A4F"
          />
          <path
            d="M116.536 30.8388C114.992 31.3711 114.386 34.8312 114.514 42.4107C114.519 42.6799 114.742 42.8956 115.016 42.8956C115.019 42.8956 115.022 42.8956 115.025 42.8956C115.302 42.891 115.524 42.6677 115.519 42.3954C115.369 33.6044 116.301 31.9646 116.868 31.7688C117.13 31.6785 117.268 31.3971 117.176 31.1416C117.084 30.8846 116.797 30.75 116.536 30.8403L116.536 30.8388Z"
            fill="#014A4F"
          />
          <path
            d="M124.786 41.1258C124.702 40.8658 124.42 40.7235 124.156 40.8061C123.891 40.8887 123.746 41.1656 123.83 41.4241L127.728 53.4382C127.795 53.6477 127.994 53.7808 128.206 53.7808C128.257 53.7808 128.309 53.7731 128.359 53.7578C128.624 53.6752 128.769 53.3984 128.685 53.1399L124.788 41.1258L124.786 41.1258Z"
            fill="#014A4F"
          />
          <path
            d="M125.253 43.9618L92.4304 52.9869C92.1643 53.0618 92.0098 53.3341 92.0847 53.5957C92.1475 53.8114 92.3478 53.9536 92.5681 53.9536C92.614 53.9536 92.6599 53.9475 92.7058 53.9353L125.528 44.9102C125.794 44.8353 125.949 44.563 125.874 44.3014C125.797 44.0398 125.52 43.8884 125.253 43.9634L125.253 43.9618Z"
            fill="#014A4F"
          />
          <path
            d="M145.512 50.3926L93.5287 58.4952C93.2534 58.5335 93.0637 58.7828 93.102 59.052C93.1371 59.2983 93.645 59.4742 93.6695 59.4712L145.652 51.3685C145.928 51.3303 146.117 51.0809 146.079 50.8117C146.041 50.5425 145.785 50.3574 145.512 50.3926Z"
            fill="#014A4F"
          />
          <path
            d="M119.907 54.4386L118.729 46.3665C118.689 46.0973 118.433 45.9107 118.161 45.9489C117.886 45.9871 117.696 46.2365 117.736 46.5057L118.911 54.5548L119.92 74.8735L120.925 74.8735L119.913 54.4845C119.913 54.4692 119.91 54.4539 119.908 54.4386L119.907 54.4386Z"
            fill="#014A4F"
          />
          <path
            d="M105.969 49.7318C105.693 49.7517 105.485 49.9872 105.506 50.2595L107.36 74.8735L108.366 74.8735L106.508 50.1861C106.488 49.9153 106.249 49.7134 105.969 49.7318Z"
            fill="#014A4F"
          />
          <path
            d="M149.917 65.0774L94.6958 71.9395C94.4189 71.964 94.2155 72.2026 94.24 72.4734C94.2644 72.7441 94.5092 72.9445 94.7845 72.92L149.937 66.0641L197.421 74.8734L202.824 74.8734L150.055 65.0835C150.009 65.0759 149.963 65.0728 149.917 65.0774Z"
            fill="#014A4F"
          />
          <path
            d="M162.188 74.026C159.198 73.3637 157.065 74.023 155.48 74.875L158.364 74.875C159.236 74.7128 160.232 74.6853 161.38 74.875L162.408 74.875C162.485 74.8092 162.541 74.7205 162.566 74.6149C162.627 74.3503 162.457 74.0857 162.187 74.026L162.188 74.026Z"
            fill="#014A4F"
          />
          <path
            d="M134.818 61.033L119.769 61.6647C119.492 61.677 119.276 61.9064 119.288 62.1772C119.301 62.4418 119.522 62.6483 119.79 62.6483C119.798 62.6483 119.805 62.6483 119.812 62.6483L134.86 62.0165C135.137 62.0043 135.353 61.7749 135.341 61.5041C135.329 61.2318 135.09 61.0207 134.818 61.033Z"
            fill="#014A4F"
          />
          <path
            d="M165.414 47.942C165.137 47.942 164.912 48.1623 164.912 48.4346L164.912 68.331L162.003 74.8734L163.098 74.8734L165.874 68.6309C165.902 68.5681 165.916 68.5024 165.916 68.4335L165.916 48.4346C165.916 48.1623 165.691 47.942 165.414 47.942Z"
            fill="#014A4F"
          />
          <path
            d="M202.299 14.4636C202.071 14.3076 201.758 14.3627 201.6 14.5845L174.808 54.8944C174.744 54.9847 174.712 55.0933 174.718 55.2034C174.725 55.3441 175.49 69.2565 174.121 72.842C174.005 73.148 173.71 73.8486 173.269 74.8719L174.358 74.8719C174.715 74.0398 174.956 73.4616 175.062 73.1862C176.435 69.5884 175.821 57.0115 175.73 55.3181L202.423 15.1474C202.582 14.9241 202.527 14.6181 202.299 14.4621L202.299 14.4636Z"
            fill="#014A4F"
          />
          <path
            d="M218.088 53.25C217.847 53.1184 217.541 53.2026 217.404 53.4412L205.25 74.875L206.398 74.875L218.281 53.92C218.416 53.6829 218.328 53.3831 218.087 53.25L218.088 53.25Z"
            fill="#014A4F"
          />
          <path
            d="M210.446 35.5257C210.22 35.4293 209.957 35.5089 209.824 35.7123L190.568 65.2763C190.544 65.313 190.525 65.3528 190.511 65.3941L188.209 72.5835L187.238 74.875L188.325 74.875L189.145 72.9415C189.152 72.9277 189.156 72.9139 189.161 72.9002L191.449 65.752L210.42 36.6255C211.378 37.167 214.146 38.9705 218.377 43.6941C224.284 50.2855 221.897 62.2628 221.607 63.6074C221.362 64.7363 218.934 69.2733 215.805 74.875L216.95 74.875C220.031 69.3498 222.329 65.0147 222.59 63.8124C222.893 62.4189 225.362 49.9979 219.133 43.044C213.651 36.9253 210.573 35.5792 210.445 35.5257L210.446 35.5257Z"
            fill="#014A4F"
          />
          <path
            d="M173.861 74.5476L163.576 72.3296C163.306 72.2699 163.037 72.4397 162.977 72.7043C162.918 72.9705 163.089 73.2321 163.36 73.2902L170.693 74.8719L174.357 74.8719L174.068 74.644C174.006 74.5965 173.936 74.5629 173.861 74.5461L173.861 74.5476Z"
            fill="#014A4F"
          />
          <path
            d="M220.907 66.5964L168.885 33.534C168.665 33.3948 168.374 33.4438 168.213 33.6472L156.247 43.0639C156.077 43.2796 156.118 43.5886 156.338 43.7538C156.559 43.919 156.874 43.8792 157.042 43.6635L168.727 34.6064L220.36 67.4209C220.444 67.4744 220.539 67.5004 220.632 67.5004C220.797 67.5004 220.958 67.4209 221.054 67.2756C221.206 67.0476 221.138 66.7417 220.906 66.5949L220.907 66.5964Z"
            fill="#014A4F"
          />
          <path
            d="M215.783 40.4359C215.538 40.3059 215.234 40.3962 215.102 40.6348L206.201 57.1399C206.07 57.3786 206.16 57.6784 206.405 57.8069C206.481 57.8467 206.562 57.8665 206.643 57.8665C206.822 57.8665 206.995 57.7732 207.085 57.608L215.986 41.1029C216.118 40.8642 216.028 40.5644 215.783 40.4359Z"
            fill="#014A4F"
          />
          <path
            d="M218.381 13.9604C218.147 13.8151 217.836 13.8824 217.688 14.1118L191.94 48.7803C191.792 49.0098 191.861 49.3142 192.095 49.4595C192.177 49.5115 192.272 49.536 192.364 49.536C192.531 49.536 192.693 49.4549 192.788 49.3081L218.535 14.6396C218.683 14.4101 218.615 14.1057 218.381 13.9604Z"
            fill="#014A4F"
          />
          <path
            d="M175.309 55.5491L162.257 53.2117L147.899 44.7358C147.676 44.6027 147.385 44.6624 147.232 44.8704L144.392 48.7558C144.23 48.9776 144.282 49.2851 144.508 49.4426C144.733 49.6002 145.048 49.5497 145.209 49.3294L147.778 45.8142L161.816 54.102C161.868 54.1326 161.925 54.154 161.985 54.1647L175.127 56.5173C175.158 56.5235 175.189 56.525 175.218 56.525C175.456 56.525 175.667 56.3583 175.712 56.1212C175.762 55.8535 175.58 55.5965 175.308 55.5491L175.309 55.5491Z"
            fill="#014A4F"
          />
          <path
            d="M250.755 56.2145C250.615 55.9805 250.308 55.8994 250.068 56.0355C249.828 56.1717 249.747 56.473 249.886 56.7086L251.031 58.6436L248.477 74.8734L249.493 74.8734L252.05 58.6222C252.069 58.5105 252.046 58.3973 251.987 58.2994L250.752 56.2145L250.755 56.2145Z"
            fill="#014A4F"
          />
          <path
            d="M213.473 8.12927L209.118 8.68301C209.027 8.69525 208.941 8.73042 208.869 8.78702L202.067 14.1393L190.401 10.0567C190.129 9.99699 189.863 10.1653 189.803 10.4314C189.744 10.6976 189.915 10.9592 190.186 11.0188L202.085 15.152C202.12 15.1596 202.157 15.1642 202.194 15.1642C202.307 15.1642 202.418 15.126 202.509 15.0556L209.389 9.64212L213.603 9.1052C213.879 9.07002 214.073 8.82374 214.036 8.55299C214.001 8.28377 213.75 8.09409 213.473 8.12774L213.473 8.12927Z"
            fill="#014A4F"
          />
          <path
            d="M252.116 41.8096L245.622 47.4403C244.46 47.6178 237.16 48.739 234.649 49.2958C233.697 49.5069 233.049 49.9077 232.721 50.4874C232.252 51.3226 232.591 52.271 232.775 52.7804C232.81 52.8768 232.84 52.9609 232.857 53.0251C232.914 53.2485 233.119 53.3984 233.344 53.3984C233.384 53.3984 233.425 53.3938 233.466 53.3831C233.735 53.3173 233.899 53.0496 233.832 52.7865C233.809 52.6993 233.771 52.5861 233.723 52.4546C233.575 52.04 233.35 51.4129 233.601 50.9647C233.783 50.6419 234.21 50.4033 234.87 50.258C237.511 49.6721 245.838 48.4055 245.922 48.3933C246.017 48.3795 246.106 48.3382 246.178 48.2755L252.781 42.5484C252.989 42.3679 253.008 42.0574 252.824 41.8539C252.641 41.6505 252.324 41.6306 252.116 41.8111L252.116 41.8096Z"
            fill="#014A4F"
          />
          <path
            d="M244.518 61.1171C244.33 60.9167 244.013 60.9029 243.808 61.0865C243.603 61.2701 243.59 61.5821 243.778 61.7825L247.626 65.9004C247.726 66.0059 247.86 66.0595 247.996 66.0595C248.117 66.0595 248.24 66.0166 248.336 65.9295C248.541 65.7459 248.555 65.4338 248.367 65.2334L244.518 61.1156L244.518 61.1171Z"
            fill="#014A4F"
          />
          <path
            d="M245.818 32.8411C245.65 32.6239 245.335 32.5826 245.114 32.7478C244.894 32.913 244.851 33.222 245.02 33.4377L258.521 50.7995L252.85 55.3502L251.963 54.3651C251.871 54.2627 251.739 54.203 251.6 54.1984C251.463 54.1908 251.326 54.2474 251.229 54.3453L247.056 58.4983L242.259 62.321L238.604 57.2807C238.932 56.1105 240.22 50.9999 238.447 48.8491C238.272 48.638 237.956 48.6044 237.74 48.7757C237.524 48.947 237.491 49.256 237.665 49.4687C239.025 51.1177 238.061 55.6332 237.575 57.2348C237.53 57.3801 237.558 57.5377 237.647 57.66L241.751 63.3214C241.832 63.433 241.954 63.5049 242.092 63.5233C242.115 63.5264 242.136 63.5279 242.159 63.5279C242.274 63.5279 242.386 63.4896 242.476 63.4177L247.71 59.2463C247.724 59.2356 247.738 59.2234 247.752 59.2096L251.567 55.413L252.419 56.3583C252.598 56.5572 252.904 56.5816 253.113 56.4134L259.533 51.2599C259.743 51.0916 259.778 50.7918 259.614 50.5807L245.818 32.8411Z"
            fill="#014A4F"
          />
          <path
            d="M240.142 4.19035L228.566 -15.8652L214.419 -54.7525C214.292 -54.9942 213.989 -55.0906 213.742 -54.9651C213.496 -54.8412 213.398 -54.5445 213.525 -54.3028L227.684 -15.3986L239.291 4.71349C239.306 4.73949 239.323 4.76245 239.342 4.7854L295.562 71.1395C295.661 71.2573 295.805 71.3169 295.949 71.3169C296.062 71.3169 296.175 71.2802 296.27 71.2038C296.482 71.0294 296.513 70.7188 296.336 70.5108L240.142 4.19035Z"
            fill="#014A4F"
          />
          <path
            d="M265.982 -17.2633L221.03 18.1287C220.814 18.2985 220.779 18.609 220.952 18.8216C221.051 18.9425 221.197 19.0052 221.344 19.0052C221.454 19.0052 221.565 18.97 221.657 18.8966L266.61 -16.4954C266.825 -16.6652 266.86 -16.9757 266.686 -17.1883C266.512 -17.401 266.197 -17.4346 265.979 -17.2633L265.982 -17.2633Z"
            fill="#014A4F"
          />
          <path
            d="M250.73 -4.63738C250.551 -4.84541 250.234 -4.87293 250.022 -4.69855C249.809 -4.52417 249.782 -4.21366 249.959 -4.00409L287.685 40.104C287.785 40.2203 287.927 40.2799 288.071 40.2799C288.184 40.2799 288.299 40.2417 288.394 40.1652C288.606 39.9908 288.634 39.6803 288.456 39.4722L250.73 -4.63585L250.73 -4.63738Z"
            fill="#014A4F"
          />
          <path
            d="M281.794 4.08632L237.741 38.5101C237.524 38.6799 237.489 38.9889 237.662 39.2015C237.761 39.3239 237.907 39.3866 238.055 39.3866C238.165 39.3866 238.275 39.3514 238.369 39.2795L282.422 4.85575C282.639 4.68596 282.674 4.37696 282.501 4.16433C282.328 3.95171 282.012 3.91653 281.796 4.08632L281.794 4.08632Z"
            fill="#014A4F"
          />
          <path
            d="M292.779 16.6771L256.294 47.1665C256.082 47.3424 256.056 47.6529 256.237 47.861C256.336 47.9757 256.477 48.0338 256.619 48.0338C256.734 48.0338 256.85 47.9956 256.945 47.916L293.431 17.4266C293.642 17.2507 293.668 16.9402 293.487 16.7321C293.307 16.5256 292.99 16.4996 292.779 16.6771Z"
            fill="#014A4F"
          />
          <path
            d="M295.375 41.3231C294.928 39.4264 294.28 35.2748 294.274 35.2335C294.232 34.9643 293.974 34.7807 293.702 34.8205C293.428 34.8618 293.24 35.1127 293.281 35.3819C293.307 35.5532 293.942 39.6114 294.396 41.5434C294.857 43.4983 296.813 48.0828 296.896 48.277C296.977 48.4652 297.163 48.5784 297.359 48.5784C297.423 48.5784 297.489 48.5661 297.553 48.5401C297.809 48.4346 297.93 48.147 297.823 47.8961C297.803 47.8487 295.817 43.1955 295.375 41.3216L295.375 41.3231Z"
            fill="#014A4F"
          />
          <path
            d="M258.903 62.6483C258.686 62.8166 258.648 63.1271 258.82 63.3397L268.107 74.8734L269.387 74.8734L259.608 62.7294C259.437 62.5152 259.12 62.4785 258.903 62.6483Z"
            fill="#014A4F"
          />
          <path
            d="M265.393 56.6933C265.179 56.8662 265.149 57.1767 265.325 57.3863L279.99 74.8735L281.29 74.8735L266.1 56.7606C265.924 56.551 265.608 56.5205 265.393 56.6933Z"
            fill="#014A4F"
          />
          <path
            d="M290.719 64.6568L278.307 74.8734L279.869 74.8734L291.364 65.4109C291.577 65.2365 291.604 64.926 291.425 64.718C291.246 64.5099 290.93 64.4824 290.717 64.6568L290.719 64.6568Z"
            fill="#014A4F"
          />
          <path
            d="M46.1655 41.2283L28.299 35.6634L29.1403 30.2192C29.1617 30.0785 29.1204 29.9347 29.0256 29.8261L15.1132 13.7921C15.0949 13.7707 15.075 13.7523 15.0536 13.734L-0.00300015 1.29468L-0.0030002 2.58267L14.3744 14.4606L28.1108 30.2927L27.2435 35.902C27.2083 36.1284 27.3368 36.3486 27.5556 36.4328L45.7923 42.1415C45.8535 42.1645 45.9162 42.1767 45.9774 42.1767C46.1778 42.1767 46.3659 42.0589 46.444 41.8662C46.5464 41.6138 46.4195 41.3277 46.1625 41.2268L46.1655 41.2283Z"
            fill="#014A4F"
          />
          <path
            d="M299.139 -4.31615C298.927 -4.49206 298.61 -4.46454 298.431 -4.25651C298.252 -4.04847 298.28 -3.73794 298.491 -3.56203L305.931 2.58267L305.931 1.29468L299.139 -4.31768L299.139 -4.31615Z"
            fill="#014A4F"
          />
          <path
            d="M23.2954 46.0223C23.5723 46.0223 23.7971 45.802 23.7971 45.5298L23.7971 26.8892C23.7971 26.7836 23.7619 26.6811 23.6992 26.597L18.3806 19.5391C18.2154 19.3203 17.9018 19.2744 17.6784 19.4351L5.59862 28.1496C5.48542 28.2322 5.41201 28.3561 5.39671 28.4938C5.38141 28.6315 5.42576 28.7691 5.51907 28.8716L10.3819 34.2714C10.5655 34.4748 10.8836 34.4947 11.0901 34.3142C11.2982 34.1337 11.3181 33.8232 11.1345 33.6197L6.63574 28.6223L17.8696 20.5181L22.7921 27.0498L22.7921 45.5282C22.7921 45.8005 23.017 46.0208 23.2939 46.0208L23.2954 46.0223Z"
            fill="#014A4F"
          />
          <path
            d="M5.83269 39.3881C5.94894 39.3881 6.06673 39.3483 6.16157 39.2672L23.6258 24.3529C23.8354 24.174 23.8568 23.8635 23.6748 23.6585C23.4928 23.4535 23.1746 23.4321 22.9665 23.611L5.50228 38.5253C5.29271 38.7043 5.2713 39.0148 5.45333 39.2198C5.55276 39.3315 5.69196 39.3881 5.83269 39.3881Z"
            fill="#014A4F"
          />
          <path
            d="M46.5709 51.0932C46.6963 51.0932 46.8217 51.0916 46.9502 51.087C51.5362 50.9386 78.7689 48.6319 79.0443 48.609C79.3212 48.586 79.5246 48.3474 79.5017 48.0766C79.4772 47.8059 79.2324 47.604 78.9586 47.6284C78.6848 47.6514 51.4781 49.9566 46.9181 50.1034C43.1077 50.2258 40.5256 48.2464 38.82 46.9355C38.5401 46.7198 38.2739 46.5164 38.0292 46.3404C36.0467 44.924 24.1963 44.8536 22.1129 45.0387C19.7878 45.2452 18.6972 44.2066 18.4677 43.2551C18.2612 42.3908 18.9786 34.7884 19.4804 30.1978C19.4957 30.0632 19.4528 29.9286 19.3626 29.8261L9.25299 18.1929C9.07249 17.9864 8.75585 17.9604 8.54475 18.1379C8.33366 18.3138 8.30765 18.6243 8.48816 18.8324L18.457 30.3049C18.2475 32.2415 17.1874 42.2256 17.4872 43.4815C17.8084 44.8184 19.2341 46.2869 22.2001 46.0208C24.8434 45.7852 35.8846 46.0284 37.4341 47.1374C37.6651 47.3026 37.9236 47.5015 38.1974 47.711C39.9627 49.0663 42.6044 51.0932 46.5693 51.0947L46.5709 51.0932Z"
            fill="#014A4F"
          />
          <path
            d="M4.75728 43.067C4.83835 43.067 4.91943 43.0471 4.99592 43.0073L18.5641 35.8102L27.7054 36.468C27.9838 36.4863 28.2225 36.2844 28.2423 36.0136C28.2622 35.7429 28.0542 35.5058 27.7788 35.4859L18.4907 34.8174C18.3958 34.8098 18.2995 34.8312 18.2153 34.8756L4.51713 42.1415C4.27238 42.2715 4.18212 42.5698 4.31367 42.8084C4.40392 42.9737 4.57678 43.067 4.75575 43.067L4.75728 43.067Z"
            fill="#014A4F"
          />
          <path
            d="M28.6447 30.6384C28.7594 30.6384 28.8757 30.6002 28.9705 30.5206L36.6892 24.0792C36.8391 23.9537 36.9003 23.7549 36.8468 23.5698L36.342 21.8305L38.0797 20.1846C38.1776 20.0913 38.2327 19.9643 38.2327 19.8297L38.2327 14.6701C38.2327 14.5845 38.2097 14.5003 38.1669 14.4254L36.0468 10.7939L35.1794 7.27417C35.1672 7.22369 35.1473 7.17628 35.1198 7.13192L33.6146 4.73186C33.5458 4.62172 33.4356 4.54218 33.3071 4.51159L31.343 4.03433L29.9357 2.11918C29.7736 1.89891 29.46 1.84843 29.2351 2.00752C29.0103 2.1666 28.9583 2.47407 29.1204 2.69434L30.6348 4.75634C30.7036 4.85117 30.8061 4.91848 30.9208 4.94601L32.8635 5.41868L34.2188 7.58164L35.0846 11.0938C35.0953 11.1397 35.1137 11.1825 35.1366 11.2223L37.2261 14.8002L37.2261 19.6202L35.4242 21.3258C35.2911 21.4527 35.2391 21.6393 35.2896 21.8152L35.7883 23.5331L28.3143 29.768C28.1032 29.9439 28.0772 30.2544 28.2577 30.4625C28.3571 30.5772 28.4978 30.6353 28.6401 30.6353L28.6447 30.6384Z"
            fill="#014A4F"
          />
          <path
            d="M22.1557 23.1598C22.275 23.1598 22.3928 23.1185 22.4891 23.0359L35.909 11.3492C36.117 11.1687 36.1354 10.8582 35.9503 10.6548C35.7667 10.4513 35.4485 10.433 35.242 10.6135L21.8222 22.3017C21.6142 22.4822 21.5958 22.7927 21.7809 22.9961C21.8803 23.1063 22.018 23.1614 22.1572 23.1614L22.1557 23.1598Z"
            fill="#014A4F"
          />
          <path
            d="M8.12882 31.518C8.23895 31.518 8.34908 31.4828 8.44239 31.4093L15.3504 25.9913C15.5676 25.8215 15.6028 25.5109 15.4284 25.2998C15.2556 25.0872 14.9389 25.0536 14.7232 25.2234L7.81523 30.6415C7.59801 30.8112 7.56283 31.1218 7.73721 31.3329C7.83664 31.4537 7.98197 31.518 8.13035 31.518L8.12882 31.518Z"
            fill="#014A4F"
          />
          <path
            d="M82.7674 69.7995C84.5755 69.7995 87.9102 69.5242 94.1849 68.6248C100.356 67.7406 107.574 66.8121 107.634 66.8014L107.455 65.8331C96.0526 67.8584 82.4768 69.3667 81.2607 68.6645C80.505 67.6351 80.2435 60.5282 80.0859 56.2696C80.014 54.3254 79.9528 52.6473 79.8656 51.6699C79.7769 50.6679 79.4435 47.5413 79.0197 43.5825C77.9903 33.9456 76.4346 19.3831 76.2204 15.1015C76.0139 10.9791 71.3775 -0.819289 70.6693 -1.887C68.7327 -4.80867 63.6679 -13.02 62.1337 -16.0273C61.7375 -16.8059 61.2863 -17.6029 60.809 -18.4488C59.4767 -20.8075 58.0999 -23.2474 57.9118 -25.2619C57.5233 -29.4333 59.544 -35.8656 61.3337 -37.6201C61.4377 -37.7211 61.4912 -37.8634 61.479 -38.0072C61.4683 -38.1509 61.3933 -38.2825 61.274 -38.3666L49.0137 -47.7481L48.4217 -46.9527L60.2384 -37.8878C58.2437 -35.3899 56.5473 -29.0769 56.9114 -25.1732C57.1179 -22.9521 58.5466 -20.4205 59.9294 -17.973C60.4021 -17.1378 60.8472 -16.3485 61.2342 -15.5883C62.7823 -12.5534 67.8776 -4.28859 69.8264 -1.35161C70.4077 -0.473583 75.0166 11.1672 75.2154 15.149C75.4327 19.458 76.9883 34.0373 78.0193 43.685C78.4415 47.6392 78.775 50.7612 78.8637 51.7555C78.9478 52.707 79.009 54.3743 79.0809 56.3048C79.3272 62.9894 79.5872 68.1919 80.4913 69.3009C80.658 69.5058 80.8982 69.7995 82.7659 69.7995L82.7674 69.7995Z"
            fill="#014A4F"
          />
          <path
            d="M94.7281 68.5406C94.7556 68.5406 94.7847 68.5391 94.8122 68.5329C95.086 68.4871 95.2696 68.2331 95.2237 67.9654L86.2445 16.1952C86.2414 16.1784 86.2369 16.1616 86.2323 16.1447L79.6333 -6.86459L80.5817 -8.85929C80.6429 -8.98778 80.6444 -9.13463 80.5862 -9.26465L78.5747 -13.7894L78.5747 -17.5601C78.5747 -17.8323 78.3499 -18.0526 78.073 -18.0526C77.7961 -18.0526 77.5713 -17.8323 77.5713 -17.5601L77.5713 -13.6869C77.5713 -13.6196 77.585 -13.5523 77.6126 -13.4896L79.5751 -9.07498L78.6436 -7.11546C78.5931 -7.00838 78.5824 -6.88754 78.616 -6.77434L85.2579 16.3864L94.2325 68.1306C94.2738 68.3708 94.4864 68.5406 94.7265 68.5406L94.7281 68.5406Z"
            fill="#014A4F"
          />
          <path
            d="M79.4282 53.1123C79.4557 53.1123 79.4832 53.1108 79.5123 53.1062L91.7849 51.087C92.0587 51.0427 92.2422 50.7888 92.1963 50.5195C92.1504 50.2519 91.8935 50.0714 91.6181 50.1157L79.3456 52.1349C79.0718 52.1792 78.8882 52.4332 78.9341 52.7024C78.9754 52.9425 79.188 53.1139 79.4282 53.1139L79.4282 53.1123Z"
            fill="#014A4F"
          />
          <path
            d="M77.5575 35.05C77.5865 35.05 77.6156 35.0469 77.6446 35.0423L88.6812 33.1669C88.955 33.121 89.1371 32.8656 89.0897 32.5979C89.0422 32.3302 88.7822 32.1512 88.5084 32.1971L77.4718 34.0725C77.198 34.1184 77.016 34.3738 77.0634 34.6415C77.1062 34.8817 77.3173 35.05 77.5575 35.05Z"
            fill="#014A4F"
          />
          <path
            d="M76.2941 22.5755C76.3277 22.5755 76.3614 22.5725 76.3965 22.5648L86.5031 20.5166C86.7754 20.4615 86.9497 20.2014 86.8931 19.9353C86.8366 19.6691 86.5719 19.4978 86.2996 19.5529L76.1931 21.6011C75.9208 21.6562 75.7464 21.9162 75.803 22.1824C75.852 22.4149 76.0616 22.5755 76.2941 22.5755Z"
            fill="#014A4F"
          />
          <path
            d="M75.7785 13.3011C75.8626 13.3011 76.4638 13.0043 76.5418 12.9615L83.3229 9.18323C83.5645 9.04862 83.6487 8.7488 83.511 8.5117C83.3733 8.27613 83.0674 8.19199 82.8257 8.3266L75.5276 12.3802C75.286 12.5148 75.2018 12.8147 75.3395 13.0518C75.4313 13.2108 75.6011 13.3011 75.777 13.3011L75.7785 13.3011Z"
            fill="#014A4F"
          />
          <path
            d="M84.0357 51.936C84.0494 51.936 84.0647 51.936 84.0785 51.9345C84.3554 51.9115 84.5603 51.6729 84.5359 51.4022C83.7802 42.6693 81.7396 22.3965 81.8222 21.2171C81.8498 21.1376 81.8574 21.0489 81.839 20.9586C81.7824 20.6925 81.5178 20.5211 81.2455 20.5762C80.7759 20.6711 80.7897 21.003 80.8371 22.0998C80.8631 22.7132 80.9167 23.6096 80.9947 24.7645C81.1262 26.7056 81.3312 29.4085 81.6035 32.7952C82.067 38.5606 83.5309 51.4266 83.537 51.4848C83.5599 51.7417 83.7787 51.936 84.0372 51.936L84.0357 51.936Z"
            fill="#014A4F"
          />
          <path
            d="M90.8778 69.0714C90.8992 69.0714 90.9207 69.0714 90.9421 69.0668C91.2174 69.0316 91.4117 68.7853 91.3765 68.5161L89.0912 51.0503C89.056 50.7811 88.8051 50.5914 88.5298 50.6251C88.2544 50.6602 88.0602 50.9065 88.0954 51.1757L90.3807 68.6415C90.4128 68.8893 90.63 69.0714 90.8778 69.0714Z"
            fill="#014A4F"
          />
          <path
            d="M103.781 57.4658C103.82 57.4658 103.859 57.4612 103.898 57.452C104.168 57.3878 104.334 57.1216 104.269 56.8585L97.6467 29.1133C97.5824 28.8487 97.3101 28.6865 97.0409 28.7508C96.7717 28.815 96.6049 29.0812 96.6707 29.3443L103.293 57.0895C103.348 57.3159 103.554 57.4673 103.781 57.4673L103.781 57.4658Z"
            fill="#014A4F"
          />
          <path
            d="M96.842 50.1188C96.8925 50.1188 96.9429 50.1111 96.9919 50.0958L101.867 48.6029C102.132 48.5218 102.28 48.2449 102.197 47.9864C102.115 47.7263 101.833 47.5826 101.569 47.6636L97.2213 48.9944L93.2075 30.0403C93.1509 29.7741 92.8847 29.6028 92.6124 29.6594C92.3417 29.7145 92.1673 29.976 92.2239 30.2422L96.351 49.7272C96.38 49.8633 96.4657 49.9811 96.588 50.0515C96.6661 50.0958 96.7533 50.1188 96.842 50.1188Z"
            fill="#014A4F"
          />
          <path
            d="M88.2422 31.4996C88.5971 31.4996 89.6587 31.2396 94.0458 30.1657C96.5927 29.5416 99.1319 28.9129 99.5526 28.8089L107.177 27.3894C107.453 27.3894 107.678 27.1691 107.678 26.8968C107.678 26.6245 107.453 26.4043 107.177 26.4043L99.4899 27.8238C99.4486 27.8238 99.4073 27.8284 99.3675 27.8391C95.1242 28.89 88.6644 30.4763 88.256 30.5374L88.3325 31.0208L88.308 31.0254L88.0342 31.4384C88.0893 31.4736 88.1306 31.4996 88.2438 31.4996L88.2422 31.4996Z"
            fill="#014A4F"
          />
          <path
            d="M54.3186 4.30813C54.3324 4.30813 54.3461 4.30813 54.3599 4.3066L72.0139 2.89929C72.2907 2.87788 72.4957 2.63925 72.4743 2.3685C72.4513 2.09775 72.2081 1.89737 71.9328 1.91725L54.2788 3.32454C54.002 3.34596 53.797 3.58458 53.8184 3.85534C53.8398 4.11232 54.0601 4.30813 54.3186 4.30813Z"
            fill="#014A4F"
          />
          <path
            d="M55.7045 14.8568C55.7213 14.8568 55.7381 14.8568 55.755 14.8537L75.0609 13.3409C75.3363 13.3133 75.5382 13.0732 75.5107 12.8024C75.4831 12.5317 75.2368 12.3343 74.9615 12.3603L55.6555 13.8732C55.3802 13.9007 55.1783 14.1409 55.2058 14.4116C55.2318 14.6656 55.4506 14.8552 55.7045 14.8552L55.7045 14.8568Z"
            fill="#014A4F"
          />
          <path
            d="M70.2488 30.6384C70.2626 30.6384 70.2764 30.6384 70.2886 30.6369C72.6504 30.4503 76.4853 30.1596 76.9717 30.1581C77.013 30.1642 77.0543 30.1657 77.0972 30.1611C77.449 30.1275 77.5331 29.9195 77.5346 29.6227L77.5469 29.6227C77.5438 29.5906 77.5392 29.563 77.5331 29.537C77.5285 29.4361 77.5178 29.3259 77.5071 29.2097L76.6015 19.9873C76.5755 19.7181 76.3323 19.5223 76.0585 19.5437L56.4695 21.1911C56.1927 21.2141 55.9877 21.4527 56.0121 21.7235C56.0351 21.9942 56.2814 22.1946 56.5552 22.1717L75.6486 20.5655C75.8092 22.2007 76.3293 27.501 76.496 29.1929C76.34 29.202 76.1549 29.2127 75.9484 29.2265C75.309 29.2678 74.4569 29.329 73.6064 29.3932C72.1364 29.5019 70.6679 29.6181 70.2962 29.6472L57.5785 25.7756C57.3109 25.7021 57.034 25.8536 56.959 26.1151C56.8841 26.3767 57.0386 26.649 57.3063 26.7224L70.1111 30.6185C70.1555 30.6308 70.2014 30.6369 70.2473 30.6369L70.2488 30.6384Z"
            fill="#014A4F"
          />
          <path
            d="M45.936 42.1904C45.9559 42.1904 45.9758 42.1904 45.9956 42.1874L77.9811 38.4887C78.2564 38.4565 78.4537 38.2118 78.4216 37.9425C78.3895 37.6718 78.1402 37.4791 77.8633 37.5112L45.8779 41.2099C45.6025 41.2421 45.4052 41.4868 45.4373 41.756C45.4679 42.0069 45.6836 42.1904 45.936 42.1904Z"
            fill="#014A4F"
          />
          <path
            d="M71.9726 48.5799C71.9879 48.5799 72.0048 48.5799 72.02 48.5784C72.2969 48.5524 72.4988 48.3122 72.4728 48.0415L70.7489 30.0999C70.7229 29.8292 70.4781 29.6303 70.2013 29.6563C69.9244 29.6823 69.7225 29.9225 69.7485 30.1933L71.4724 48.1348C71.4969 48.3902 71.7157 48.5814 71.9711 48.5814L71.9726 48.5799Z"
            fill="#014A4F"
          />
          <path
            d="M39.2499 72.3158C39.2652 72.3158 39.282 72.3158 39.2973 72.3143L80.5525 68.5375C80.8294 68.5115 81.0313 68.2729 81.0053 68.0021C80.9793 67.7314 80.7361 67.531 80.4592 67.557L39.204 71.3338C38.9271 71.3598 38.7252 71.5984 38.7512 71.8692C38.7757 72.1246 38.9944 72.3158 39.2499 72.3158Z"
            fill="#014A4F"
          />
          <path
            d="M51.3679 70.7204C50.5602 63.0705 49.3074 51.5092 49.1284 50.6236C49.1116 50.5792 49.1009 50.5318 49.0979 50.4813C49.0795 50.2106 49.2891 49.975 49.5659 49.9566C50.0356 49.9276 50.109 50.3804 50.1426 50.5991C50.1656 50.749 50.1962 50.9708 50.2329 51.2523C50.3048 51.806 50.3981 52.5984 50.5082 53.5483C50.7025 55.2263 50.9625 57.5652 51.2822 60.5022C51.8329 65.5593 52.3683 70.6179 52.3683 70.6179C52.3974 70.8887 52.197 71.1303 51.9201 71.1579C51.9033 71.1594 51.8849 71.1609 51.8681 71.1609C51.6142 71.1609 51.3954 70.9728 51.3694 70.7189L51.3679 70.7204ZM49.4329 50.9142C49.4925 50.9341 49.5583 50.9448 49.6317 50.9402C49.5629 50.9448 49.4956 50.9356 49.4329 50.9142Z"
            fill="#014A4F"
          />
          <path
            d="M71.1098 69.4003C71.1236 69.4003 71.1373 69.4003 71.1526 69.3988C71.4295 69.3758 71.6345 69.1387 71.6115 68.868L69.9197 48.8767C69.8968 48.6059 69.6566 48.4025 69.3782 48.427C69.1013 48.4499 68.8963 48.687 68.9193 48.9577L70.6111 68.949C70.6325 69.206 70.8528 69.4003 71.1113 69.4003L71.1098 69.4003Z"
            fill="#014A4F"
          />
          <path
            d="M41.9222 65.4461L47.0558 64.1871C47.325 64.1214 47.4887 63.8537 47.4214 63.5906C47.3541 63.3259 47.0818 63.1669 46.8126 63.2326L41.4098 64.5573C41.2415 64.5986 41.1069 64.721 41.0534 64.8832L37.7478 74.875L38.8032 74.875L41.9222 65.4461Z"
            fill="#014A4F"
          />
          <path
            d="M57.3657 70.6363C57.3474 70.3655 57.1088 70.159 56.8319 70.1774C56.555 70.1957 56.3454 70.4298 56.3638 70.702L56.6468 74.8735L57.6533 74.8735L57.3657 70.6363Z"
            fill="#014A4F"
          />
          <path
            d="M47.4595 71.1609C47.4687 71.1609 47.4764 71.1609 47.4855 71.1609C47.7624 71.1472 47.975 70.9162 47.9613 70.6439L47.4611 61.0238L60.705 60.337C60.9818 60.3232 61.1945 60.0907 61.1792 59.82C61.1639 59.5492 60.9283 59.3396 60.6514 59.3549L46.9073 60.0678C46.6305 60.0815 46.4178 60.314 46.4316 60.5848L46.9578 70.6944C46.9716 70.9575 47.1934 71.1609 47.4595 71.1609Z"
            fill="#014A4F"
          />
          <path
            d="M40.5332 68.5406L47.3235 68.5406C47.6003 68.5406 47.8252 68.3203 47.8252 68.048C47.8252 67.7757 47.6003 67.5555 47.3235 67.5555L40.5332 67.5555C40.2564 67.5555 40.0315 67.7757 40.0315 68.048C40.0315 68.3203 40.2564 68.5406 40.5332 68.5406Z"
            fill="#014A4F"
          />
          <path
            d="M93.8224 68.1551C93.7704 67.8874 92.7838 68.0725 92.8358 68.3387L94.1039 74.8719L95.1257 74.8719L93.8209 68.1536L93.8224 68.1551Z"
            fill="#014A4F"
          />
          <path
            d="M79.6332 -5.50162L80.0876 -3.21783L180.143 59.28L203.295 74.8735L206.399 74.8735L181.101 57.836L79.6332 -5.50162Z"
            fill="#014A4F"
          />
          <path
            d="M136.568 30.1091L135.012 30.9107C135.104 31.0835 144.289 48.2786 147.269 57.9186C149.172 64.0755 149.415 70.1728 148.77 74.8735L150.532 74.8735C151.167 69.9647 150.893 63.7023 148.952 57.4184C145.923 47.6208 136.949 30.8189 136.568 30.1091Z"
            fill="#014A4F"
          />
          <path
            d="M5.06782 36.2813C4.39476 35.4446 2.54998 33.3061 1.53212e-05 30.3814L1.52052e-05 33.0369C1.83715 35.1509 3.14961 36.6821 3.68805 37.3506C5.45635 39.5472 5.65522 40.5216 5.5099 40.9545C5.30645 41.5587 3.99552 41.9733 3.02571 42.2333C2.25782 42.4383 1.21305 42.8176 0.00154592 43.3087L0.00154584 45.1779C1.43485 44.5814 2.6601 44.1179 3.48919 43.8961C5.13511 43.4555 6.72138 42.8605 7.18029 41.4945C7.61012 40.2141 6.97838 38.6554 5.06935 36.2844L5.06782 36.2813Z"
            fill="#014A4F"
          />
          <path
            d="M295.351 48.0736C294.037 48.6901 292.796 49.2729 291.797 49.7303C286.974 51.936 264.584 71.2436 260.609 74.8735L263.211 74.8735C269.846 68.9858 288.501 53.1399 292.541 51.292C293.547 50.8316 294.792 50.2473 296.111 49.6293C299.17 48.1929 302.99 46.4017 305.933 45.1764L305.933 43.3072C302.906 44.5309 298.826 46.4445 295.352 48.0752L295.351 48.0736Z"
            fill="#014A4F"
          />
          <path
            d="M270.457 -10.6061C267.526 -14.7118 266.299 -18.4977 265.597 -25.6138C264.921 -32.459 264.376 -51.9501 264.365 -52.0756L262.614 -51.9226C262.625 -51.7972 263.171 -32.3 263.847 -25.4486C264.583 -17.996 265.887 -14.002 269.016 -9.61798C270.98 -6.8661 284.421 8.46734 295.22 20.7888C299.395 25.5507 303.228 29.9256 305.93 33.0354L305.93 30.3799C303.361 27.4337 300.079 23.6876 296.554 19.6661C285.779 7.37361 272.37 -7.92463 270.457 -10.6061Z"
            fill="#014A4F"
          />
          <path
            d="M42.6931 -6.91049L41.5872 -8.25049C41.194 -7.93996 20.5771 8.41071 -2.79e-05 24.6329L-2.79966e-05 26.8433C20.9442 10.335 42.2939 -6.59385 42.6931 -6.91049Z"
            fill="#014A4F"
          />
          <path
            d="M256.804 68.4366C256.517 69.5655 256.191 70.8458 255.76 72.3327C255.501 73.2229 255.275 74.0658 255.076 74.875L256.884 74.875C257.054 74.2126 257.24 73.5258 257.45 72.8053C257.889 71.291 258.218 69.9969 258.508 68.8542C259.815 63.7191 260.254 61.989 266.224 57.7748C269.73 55.2998 287.676 41.2314 305.93 26.8448L305.93 24.6344C287.324 39.3025 268.754 53.8649 265.198 56.3767C258.704 60.9596 258.147 63.1485 256.803 68.4381L256.804 68.4366Z"
            fill="#014A4F"
          />
          <path
            d="M191.342 -20.9207C183.998 -30.2196 181.597 -43.6746 180.477 -49.7183L178.747 -49.4093C179.902 -43.182 182.346 -29.4976 189.952 -19.8652C197.245 -10.6291 225.68 25.4222 225.68 25.4222L225.698 25.4452L258.536 64.2591L259.888 63.1593L227.06 24.356C226.482 23.6218 198.558 -11.7809 191.339 -20.9207L191.342 -20.9207Z"
            fill="#014A4F"
          />
          <path
            d="M263.263 -24.7021C262.111 -22.4428 260.921 -20.1085 255.186 -22.0986C247.544 -24.7541 241.659 -23.2566 234.639 -19.9785C231.715 -18.6125 226.336 -15.6419 220.641 -12.4969C213.646 -8.6329 206.412 -4.63892 202.131 -2.70083C195.539 0.282031 192.09 5.74603 189.572 9.73541C188.974 10.6838 188.457 11.5037 187.964 12.1814C185.385 15.7378 165.51 47.2522 164.666 48.5921L166.16 49.4977C166.367 49.1704 186.851 16.6908 189.398 13.1787C189.922 12.4552 190.479 11.5741 191.068 10.641C193.47 6.8382 196.757 1.62814 202.868 -1.13751C207.214 -3.10467 214.48 -7.11699 221.504 -10.9962C227.171 -14.1259 232.525 -17.0828 235.395 -18.4228C242.179 -21.5907 247.496 -22.9414 254.598 -20.4741C261.797 -17.9731 263.625 -21.5586 264.833 -23.9311C265.048 -24.3518 265.251 -24.7495 265.456 -25.057L263.985 -26.0008C263.723 -25.6092 263.498 -25.1671 263.259 -24.7006L263.263 -24.7021Z"
            fill="#014A4F"
          />
          <path
            d="M55.6219 5.86993C55.01 1.07135 52.6314 -2.42702 48.3483 -4.82708C44.6251 -6.91202 40.2839 -9.76333 36.3128 -15.0896C32.8145 -19.7842 9.3386 -42.1067 -4.90039e-05 -50.9589L-4.91115e-05 -48.4977C10.648 -38.4202 31.733 -18.3127 34.8948 -14.0724C39.0601 -8.48299 43.5925 -5.50625 47.4764 -3.32952C51.2455 -1.21705 53.3396 1.86219 53.8781 6.08562C54.3217 9.56868 58.6583 49.0969 61.4117 74.875L63.1785 74.875C60.422 49.0663 56.0685 9.3637 55.6234 5.87146L55.6219 5.86993Z"
            fill="#014A4F"
          />
          <path
            d="M285.204 8.16442L286.484 9.34533L305.931 -10.8861L305.931 -13.3978L285.204 8.16442Z"
            fill="#014A4F"
          />
          <path
            d="M305.931 565.253L305.931 320.066L6.10352e-05 320.066L5.03177e-05 565.253L305.931 565.253Z"
            fill="black"
          />
          <path
            d="M135.706 320.066C136.098 324.32 136.522 328.612 136.626 329.657L129.852 336.868C129.664 337.068 129.678 337.379 129.881 337.564C129.978 337.651 130.1 337.694 130.221 337.694C130.357 337.694 130.492 337.641 130.591 337.535L137.518 330.162C137.614 330.059 137.661 329.92 137.648 329.781C137.638 329.695 137.16 324.868 136.718 320.068L135.708 320.068L135.706 320.066Z"
            fill="#014A4F"
          />
          <path
            d="M141.533 341.544C141.12 341.364 131.4 337.111 129.138 336.224C127.171 335.452 122.984 334.806 121.646 334.613L120.924 320.066L119.919 320.066L120.664 335.066C120.676 335.303 120.858 335.497 121.097 335.53C121.152 335.537 126.568 336.276 128.765 337.137C131.009 338.017 141.022 342.398 141.123 342.442C141.189 342.471 141.259 342.485 141.328 342.485C141.521 342.485 141.703 342.376 141.787 342.193C141.9 341.945 141.787 341.653 141.533 341.543L141.533 341.544Z"
            fill="#014A4F"
          />
          <path
            d="M206.737 320.791L202.825 320.066L197.423 320.066L206.55 321.759C206.582 321.766 206.613 321.769 206.643 321.769C206.881 321.769 207.09 321.603 207.136 321.368C207.188 321.1 207.009 320.843 206.737 320.793L206.737 320.791Z"
            fill="#014A4F"
          />
          <path
            d="M161.966 320.178C162.13 320.215 162.292 320.167 162.408 320.066L161.38 320.066C161.571 320.098 161.766 320.133 161.966 320.178Z"
            fill="#014A4F"
          />
          <path
            d="M153.726 321.175C153.506 321.328 153.296 321.473 153.1 321.599L104.49 326.847C104.213 326.866 104.004 327.1 104.022 327.37C104.041 327.63 104.261 327.831 104.522 327.831C104.533 327.831 104.545 327.831 104.556 327.831L153.296 322.573C153.38 322.567 153.461 322.541 153.533 322.497C153.778 322.344 154.033 322.166 154.304 321.98C155.329 321.27 156.595 320.395 158.364 320.066L155.48 320.066C154.803 320.43 154.226 320.829 153.726 321.175Z"
            fill="#014A4F"
          />
          <path
            d="M136.687 377.005C136.577 377.254 136.693 377.545 136.947 377.654C137.013 377.681 137.08 377.695 137.147 377.695C137.341 377.695 137.525 377.585 137.608 377.4L163.1 320.068L162.004 320.068L136.688 377.007L136.687 377.005Z"
            fill="#014A4F"
          />
          <path
            d="M145.47 382.715C145.359 382.965 145.475 383.255 145.729 383.364C145.794 383.392 145.863 383.405 145.931 383.405C146.123 383.405 146.308 383.295 146.391 383.11C147.421 380.805 169.91 330.456 174.361 320.065L173.272 320.065C168.415 331.347 145.723 382.156 145.472 382.714L145.47 382.715Z"
            fill="#014A4F"
          />
          <path
            d="M170.67 381.047L131.422 364.977C131.165 364.872 130.871 364.991 130.766 365.242C130.658 365.493 130.779 365.782 131.035 365.886L170.698 382.125C170.76 382.151 170.826 382.163 170.89 382.163C171.068 382.163 171.239 382.07 171.329 381.909L206.4 320.065L205.251 320.065L170.67 381.047Z"
            fill="#014A4F"
          />
          <path
            d="M209.109 331.886C204.433 340.09 200.015 347.839 199.11 350.054C197.149 354.852 181.979 368.85 180.365 369.773C180.126 369.91 180.045 370.212 180.185 370.446C180.325 370.681 180.633 370.759 180.871 370.623C182.537 369.67 197.952 355.532 200.041 350.419C200.922 348.264 205.325 340.541 209.986 332.366C212.406 328.121 214.858 323.818 216.95 320.065L215.804 320.065C213.736 323.765 211.362 327.93 209.108 331.886L209.109 331.886Z"
            fill="#014A4F"
          />
          <path
            d="M156.932 391.575C156.825 391.826 156.947 392.115 157.203 392.219C157.265 392.245 157.331 392.257 157.395 392.257C157.593 392.257 157.779 392.143 157.859 391.953L188.325 320.066L187.238 320.066L156.932 391.575Z"
            fill="#014A4F"
          />
          <path
            d="M206.102 338.115L184.506 328.085L174.357 320.066L170.693 320.066L173.529 320.678L183.922 328.889C183.952 328.914 183.986 328.934 184.021 328.95L205.67 339.007C205.739 339.039 205.813 339.054 205.885 339.054C206.073 339.054 206.253 338.95 206.339 338.772C206.458 338.526 206.351 338.233 206.1 338.116L206.102 338.115Z"
            fill="#014A4F"
          />
          <path
            d="M200.31 348.575L156.93 332.642C156.672 332.547 156.381 332.675 156.283 332.931C156.186 333.185 156.317 333.469 156.577 333.564L199.957 349.497C200.015 349.518 200.075 349.529 200.133 349.529C200.336 349.529 200.528 349.407 200.603 349.209C200.701 348.956 200.569 348.671 200.309 348.576L200.31 348.575Z"
            fill="#014A4F"
          />
          <path
            d="M191.538 359.87L151.538 344.781C151.282 344.683 150.989 344.81 150.89 345.064C150.79 345.318 150.919 345.603 151.179 345.7L191.178 360.789C191.238 360.812 191.299 360.823 191.359 360.823C191.561 360.823 191.751 360.702 191.827 360.506C191.926 360.252 191.798 359.968 191.538 359.87Z"
            fill="#014A4F"
          />
          <path
            d="M228.86 446.59C227.992 445.915 148.963 384.522 144.601 381.279C140.661 378.35 136.618 376.159 132.909 374.948C129.753 373.917 98.0199 369.043 97.7706 368.965C97.506 368.881 97.4264 369.008 97.3407 369.265C97.2551 369.523 97.3989 369.802 97.6635 369.886C97.9128 369.966 129.438 374.853 132.591 375.882C136.197 377.06 140.141 379.199 143.994 382.064C148.346 385.301 227.367 446.688 228.236 447.362C228.328 447.434 228.438 447.469 228.548 447.469C228.697 447.469 228.844 447.405 228.941 447.283C229.114 447.07 229.078 446.76 228.86 446.591L228.86 446.59Z"
            fill="#014A4F"
          />
          <path
            d="M153.734 392.455L153.665 392.417C153.191 392.152 152.733 391.815 152.181 391.324C151.646 390.849 151.048 390.252 150.321 389.521C148.851 388.039 144.753 384.84 142.986 384.054C141.322 383.312 126.085 377.461 125.436 377.213C125.181 377.115 124.895 377.235 124.789 377.482L97.047 433.869C96.94 434.12 97.0608 434.409 97.3178 434.513C97.3805 434.539 97.8945 434.437 97.974 434.248L125.528 378.307C128.142 379.312 141.089 384.291 142.57 384.952C144.109 385.639 148.075 388.672 149.6 390.211C150.339 390.954 150.948 391.563 151.505 392.057C152.114 392.597 152.626 392.973 153.165 393.275L153.235 393.313C154.22 393.864 154.716 394.14 153.871 395.916C153.139 397.454 151.257 401.201 149.61 402.774C148.031 404.281 144.793 408.971 144.657 409.17C144.501 409.394 144.562 409.7 144.791 409.853C145.021 410.005 145.333 409.947 145.489 409.72C145.522 409.673 148.805 404.916 150.31 403.478C152.013 401.852 153.842 398.303 154.781 396.331C155.927 393.925 155.028 393.18 153.732 392.456L153.734 392.455Z"
            fill="#014A4F"
          />
          <path
            d="M140.002 401.994C139.342 401.767 138.775 401.573 138.428 401.42C137.764 401.125 136.889 400.515 135.878 399.808C134.256 398.676 132.237 397.266 130.04 396.453C126.582 395.173 105.076 387.632 104.859 387.557C104.597 387.465 104.31 387.598 104.217 387.855C104.123 388.111 104.259 388.394 104.519 388.484C104.737 388.56 126.233 396.097 129.684 397.374C131.758 398.142 133.718 399.51 135.292 400.609C136.347 401.345 137.257 401.982 138.012 402.317C138.4 402.488 138.987 402.69 139.668 402.924C141.605 403.586 144.845 404.697 145.441 405.977C145.55 406.211 145.556 406.43 145.46 406.667C144.439 409.192 133.037 447.67 132.54 449.354C132.463 449.616 132.601 449.799 132.867 449.874C132.913 449.888 132.977 449.983 133.023 449.983C133.242 449.983 133.442 449.842 133.505 449.626C133.628 449.213 145.391 409.511 146.394 407.028C146.591 406.538 146.579 406.046 146.354 405.566C145.579 403.903 142.354 402.798 139.999 401.992L140.002 401.994Z"
            fill="#014A4F"
          />
          <path
            d="M152.034 391.234C151.777 391.132 151.484 391.251 151.379 391.503L145.466 405.664C145.36 405.916 145.484 406.203 145.74 406.306C145.802 406.33 145.867 406.343 145.929 406.343C146.127 406.343 146.315 406.228 146.394 406.037L152.308 391.877C152.414 391.624 152.29 391.337 152.034 391.234Z"
            fill="#014A4F"
          />
          <path
            d="M150.208 389.435C149.965 389.302 149.66 389.386 149.524 389.623L141.865 402.965C141.729 403.202 141.816 403.502 142.058 403.635C142.136 403.678 142.22 403.698 142.302 403.698C142.478 403.698 142.65 403.608 142.741 403.447L150.401 390.105C150.537 389.868 150.449 389.568 150.208 389.435Z"
            fill="#014A4F"
          />
          <path
            d="M144.822 385.143C144.568 385.036 144.271 385.151 144.162 385.401C141.808 390.777 137.58 399.614 136.254 400.444C136.02 400.591 135.951 400.896 136.101 401.125C136.197 401.272 136.36 401.353 136.525 401.353C136.616 401.353 136.711 401.328 136.795 401.275C138.818 400.007 144.45 387.242 145.086 385.792C145.195 385.542 145.077 385.252 144.823 385.144L144.822 385.143Z"
            fill="#014A4F"
          />
          <path
            d="M135.914 381.273C135.69 381.114 135.376 381.163 135.212 381.383L133.768 383.335L124.015 380.455C123.749 380.377 123.469 380.523 123.389 380.783C123.31 381.043 123.46 381.319 123.726 381.397L133.82 384.378C133.868 384.392 133.917 384.4 133.966 384.4C134.123 384.4 134.276 384.326 134.372 384.196L136.026 381.961C136.188 381.741 136.139 381.434 135.914 381.273Z"
            fill="#014A4F"
          />
          <path
            d="M134.149 383.217C133.897 383.107 133.599 383.217 133.485 383.466L128.621 396.427C128.508 396.675 128.621 396.967 128.875 397.077C128.941 397.107 129.011 397.12 129.08 397.12C129.273 397.12 129.455 397.012 129.539 396.828L134.403 383.867C134.516 383.619 134.403 383.327 134.149 383.217Z"
            fill="#014A4F"
          />
          <path
            d="M161.656 394.443C161.411 394.316 161.107 394.408 160.977 394.648L151.399 412.426C151.39 412.443 151.381 412.461 151.375 412.48C151.305 412.659 149.654 416.913 149.46 420.526C149.27 424.065 144.502 457.709 144.493 457.974C144.484 458.246 144.701 458.472 144.978 458.482C144.984 458.482 144.989 458.482 144.995 458.482C145.264 458.482 145.487 458.272 145.496 458.006C145.506 457.741 150.272 424.108 150.462 420.578C150.641 417.263 152.161 413.225 152.3 412.859L161.862 395.107C161.992 394.867 161.897 394.569 161.653 394.442L161.656 394.443Z"
            fill="#014A4F"
          />
          <path
            d="M244.871 342.973L243.109 349.477L237.111 353.512L211.176 348.553C210.905 348.5 210.64 348.676 210.587 348.942C210.533 349.208 210.711 349.468 210.983 349.52L237.125 354.519C237.157 354.525 237.189 354.528 237.221 354.528C237.322 354.528 237.422 354.499 237.506 354.442L243.831 350.187C243.931 350.12 244.001 350.022 244.031 349.907L245.849 343.201C245.853 343.184 245.856 343.167 245.859 343.149L249.492 320.066L248.477 320.066L244.871 342.973Z"
            fill="#014A4F"
          />
          <path
            d="M229.492 345.326L217.472 339.074C217.241 338.953 216.953 339.028 216.813 339.245L194.274 374.117C194.126 374.347 194.195 374.651 194.43 374.796C194.515 374.848 194.606 374.873 194.698 374.873C194.865 374.873 195.027 374.792 195.123 374.643L217.412 340.157L229.022 346.194C229.269 346.321 229.572 346.23 229.702 345.991C229.832 345.751 229.738 345.453 229.494 345.326L229.492 345.326Z"
            fill="#014A4F"
          />
          <path
            d="M221.542 341.192C221.288 341.085 220.992 341.2 220.882 341.449L199.673 389.669C199.667 389.683 199.66 389.698 199.656 389.712L195.188 403.95C195.102 404.209 195.246 404.487 195.51 404.571C195.562 404.588 195.614 404.596 195.665 404.596C195.876 404.596 196.073 404.463 196.142 404.255L200.603 390.039L221.802 341.841C221.913 341.592 221.795 341.301 221.539 341.194L221.542 341.192Z"
            fill="#014A4F"
          />
          <path
            d="M242.279 367.475L204.811 358.441C204.542 358.377 204.269 358.537 204.204 358.802C204.138 359.067 204.302 359.333 204.571 359.397L242.039 368.431C242.078 368.44 242.12 368.445 242.159 368.445C242.386 368.445 242.591 368.295 242.647 368.07C242.713 367.806 242.549 367.539 242.28 367.475L242.279 367.475Z"
            fill="#014A4F"
          />
          <path
            d="M230.543 435.587C227.64 432.084 226.679 428.439 227.604 424.445C228.21 421.832 236.086 368.779 236.421 366.521C236.461 366.252 236.272 366.002 235.996 365.962C235.722 365.924 235.467 366.109 235.427 366.378C235.345 366.931 227.221 421.65 226.624 424.226C225.625 428.535 226.651 432.454 229.763 436.208C233.673 440.926 251.359 468.109 251.446 468.209C251.545 468.324 251.686 468.382 251.828 468.382C251.943 468.382 252.059 468.343 252.154 468.264C252.365 468.088 252.39 467.776 252.211 467.569C252.124 467.47 234.445 440.295 230.543 435.587Z"
            fill="#014A4F"
          />
          <path
            d="M238.35 373.973L235.054 373.368C234.782 373.317 234.518 373.493 234.468 373.761C234.418 374.028 234.596 374.285 234.869 374.336L238.165 374.942C238.196 374.948 238.228 374.949 238.259 374.949C238.496 374.949 238.707 374.784 238.751 374.547C238.803 374.279 238.623 374.022 238.35 373.972L238.35 373.973Z"
            fill="#014A4F"
          />
          <path
            d="M4.06581 341.928C3.86236 341.743 3.54419 341.755 3.35604 341.956L-0.00158425 345.508L-0.00158431 346.958L4.09335 342.623C4.2815 342.422 4.26925 342.112 4.06581 341.927L4.06581 341.928Z"
            fill="#014A4F"
          />
          <path
            d="M253.871 400.73C248.962 399.637 195.645 387.763 193.124 387.529C189.563 387.197 187.001 388.643 185.918 391.598L185.721 392.135C184.54 395.335 183.885 397.344 184.751 398.234C185.136 398.63 185.737 398.708 186.539 398.466C188.644 397.829 194.475 399.793 196.543 400.602C196.8 400.703 197.092 400.58 197.196 400.328C197.299 400.076 197.173 399.79 196.916 399.689C196.6 399.565 189.113 396.657 186.242 397.526C185.864 397.64 185.572 397.651 185.477 397.555C184.977 397.041 185.993 394.29 186.664 392.472L186.863 391.933C188.033 388.741 190.863 388.311 193.029 388.511C195.552 388.745 253.347 401.625 253.93 401.755C253.967 401.763 254.005 401.767 254.042 401.767C254.18 401.767 254.314 401.711 254.411 401.61L305.931 346.959L305.931 345.509L253.871 400.732L253.871 400.73Z"
            fill="#014A4F"
          />
          <path
            d="M230.087 405.358L223.329 404.481L223.329 400.859C223.329 400.614 223.145 400.408 222.899 400.372L218.482 399.739L220.15 393.902C220.225 393.64 220.069 393.368 219.803 393.295C219.535 393.221 219.259 393.374 219.184 393.636L217.36 400.011C217.322 400.147 217.343 400.294 217.423 400.412C217.501 400.53 217.628 400.611 217.772 400.631L222.324 401.284L222.324 404.912C222.324 405.159 222.511 405.368 222.76 405.4L229.954 406.334C229.977 406.337 229.998 406.338 230.021 406.338C230.269 406.338 230.485 406.158 230.518 405.91C230.555 405.641 230.361 405.393 230.087 405.358Z"
            fill="#014A4F"
          />
          <path
            d="M209.82 392.005C209.857 392.023 209.897 392.037 209.938 392.046C209.895 392.037 209.856 392.023 209.82 392.005Z"
            fill="#014A4F"
          />
          <path
            d="M212.237 402.548L207.814 399.926C210.659 391.626 210.631 391.577 210.484 391.324C210.414 391.202 210.293 391.115 210.154 391.084C209.883 391.025 209.616 391.194 209.556 391.459C209.55 391.487 209.547 391.514 209.545 391.542C209.406 392.126 207.76 396.992 206.734 399.985C206.659 400.204 206.749 400.444 206.949 400.564L211.717 403.389C211.798 403.436 211.889 403.459 211.976 403.459C212.146 403.459 212.312 403.375 212.406 403.222C212.549 402.99 212.473 402.687 212.236 402.546L212.237 402.548ZM209.821 392.005C209.784 391.987 209.749 391.964 209.72 391.939C209.749 391.965 209.782 391.987 209.821 392.005Z"
            fill="#014A4F"
          />
          <path
            d="M293.669 388.911C293.613 388.953 288.011 393.226 285.604 395.144C283.277 396.996 279.503 402.326 278.953 403.109L262.06 413.148C261.823 413.289 261.747 413.592 261.891 413.824C261.985 413.977 262.151 414.061 262.32 414.061C262.409 414.061 262.499 414.038 262.58 413.99L279.566 403.895C279.627 403.859 279.681 403.81 279.72 403.751C279.762 403.692 283.89 397.777 286.237 395.907C288.637 393.995 294.229 389.73 294.286 389.688C294.505 389.521 294.544 389.21 294.375 388.996C294.205 388.782 293.89 388.744 293.669 388.909L293.669 388.911Z"
            fill="#014A4F"
          />
          <path
            d="M20.7179 358.739C20.5007 358.571 20.184 358.606 20.0127 358.819L4.39868e-06 383.567L4.32934e-06 385.154L20.8005 359.431C20.9733 359.218 20.9366 358.908 20.7179 358.739Z"
            fill="#014A4F"
          />
          <path
            d="M298.433 392.846L277.291 418.162L265.267 420.229C264.993 420.277 264.811 420.532 264.858 420.8C264.901 421.038 265.114 421.207 265.352 421.207C265.381 421.207 265.41 421.203 265.439 421.199L277.648 419.1C277.767 419.08 277.874 419.019 277.949 418.927L299.213 393.464L305.931 385.154L305.931 383.567L298.433 392.846Z"
            fill="#014A4F"
          />
          <path
            d="M40.6754 377.675L15.5245 365.057L-0.000123024 353.768L-0.000123077 354.995L14.9601 365.874C14.983 365.891 15.006 365.904 15.0304 365.917L40.218 378.553C40.2914 378.59 40.3695 378.607 40.4459 378.607C40.628 378.607 40.8039 378.509 40.8941 378.339C41.0211 378.097 40.9232 377.801 40.6754 377.677L40.6754 377.675Z"
            fill="#014A4F"
          />
          <path
            d="M286.865 339.903L276.425 328.805L269.387 320.065L268.107 320.065L275.648 329.429C275.656 329.438 275.665 329.449 275.673 329.458L286.157 340.604C286.178 340.626 286.201 340.646 286.227 340.665L305.931 354.995L305.931 353.768L286.865 339.903Z"
            fill="#014A4F"
          />
          <path
            d="M281.29 320.066L279.99 320.066L284.317 325.226C284.416 325.344 284.56 325.405 284.706 325.405C284.817 325.405 284.93 325.368 285.024 325.293C285.238 325.12 285.268 324.81 285.093 324.6L281.29 320.066Z"
            fill="#014A4F"
          />
          <path
            d="M264.372 331.536L258.943 323.477C258.865 323.361 258.741 323.283 258.6 323.262L242.236 320.788C242.103 320.768 241.967 320.8 241.859 320.88C241.752 320.958 241.681 321.076 241.662 321.207C241.196 324.484 239.043 326.56 237.621 327.935C237.277 328.267 236.98 328.554 236.761 328.808C235.637 330.113 235.349 331.552 235.95 332.862C236.446 333.939 243.597 339.111 245.778 340.669C245.866 340.73 245.968 340.764 246.074 340.764C246.101 340.764 246.129 340.763 246.156 340.758L256.703 339.048C256.792 339.034 256.875 338.996 256.945 338.939L279.872 320.068L278.31 320.068L264.376 331.537L264.372 331.536ZM256.401 338.096L246.198 339.751C241.841 336.629 237.199 333.078 236.862 332.452C236.429 331.513 236.665 330.442 237.526 329.443C237.716 329.223 237.997 328.952 238.323 328.637C239.684 327.321 241.922 325.163 242.572 321.836L258.227 324.202L263.598 332.173L256.402 338.096L256.401 338.096Z"
            fill="#014A4F"
          />
          <path
            d="M254.999 327.8L240.728 324.767C240.455 324.71 240.189 324.878 240.131 325.143C240.073 325.409 240.244 325.671 240.515 325.729L254.787 328.762C254.822 328.77 254.859 328.773 254.894 328.773C255.125 328.773 255.333 328.615 255.385 328.384C255.443 328.118 255.272 327.857 255.001 327.799L254.999 327.8Z"
            fill="#014A4F"
          />
          <path
            d="M41.7356 387.629L35.0785 384.698L10.5303 371.206C10.5212 371.202 10.5135 371.197 10.5043 371.192C10.3927 371.139 5.70116 368.905 7.16405e-05 366.184L7.15926e-05 367.279C5.37993 369.848 9.75937 371.933 10.0515 372.072L34.6074 385.568C34.6196 385.576 34.6334 385.582 34.6471 385.588L41.3257 388.528C41.393 388.557 41.4618 388.571 41.5307 388.571C41.7219 388.571 41.9896 387.74 41.7372 387.629L41.7356 387.629Z"
            fill="#014A4F"
          />
          <path
            d="M285.788 356.508C285.288 356.257 284.813 356.013 284.36 355.782C281.469 354.308 279.56 353.335 278.074 353.548C276.554 353.766 258.637 356.104 258.456 356.127C258.181 356.162 257.988 356.41 258.025 356.679C258.062 356.948 258.314 357.138 258.589 357.103C258.77 357.08 276.695 354.741 278.221 354.522C279.385 354.357 281.277 355.32 283.896 356.656C284.351 356.887 284.828 357.132 285.33 357.384C287.424 358.436 297.816 363.406 305.93 367.278L305.93 366.183C297.89 362.345 287.838 357.537 285.787 356.508L285.788 356.508Z"
            fill="#014A4F"
          />
          <path
            d="M270.134 354.51C269.849 354.499 269.625 354.713 269.615 354.986L269.109 369.739L249.971 390.578C249.786 390.78 249.803 391.092 250.008 391.274C250.104 391.358 250.224 391.401 250.345 391.401C250.482 391.401 250.618 391.346 250.718 391.237L269.978 370.265C270.057 370.18 270.103 370.068 270.106 369.953L270.619 355.021C270.628 354.748 270.411 354.52 270.134 354.511L270.134 354.51Z"
            fill="#014A4F"
          />
          <path
            d="M28.8542 392.485L5.5001e-06 379.424L5.45269e-06 380.508L28.4335 393.38C28.5024 393.411 28.5727 393.426 28.6431 393.426C28.8328 393.426 29.0148 393.321 29.099 393.14C29.2152 392.894 29.1051 392.6 28.8527 392.487L28.8542 392.485Z"
            fill="#014A4F"
          />
          <path
            d="M258.784 358.08C258.531 357.967 258.233 358.074 258.117 358.32C258.001 358.566 258.111 358.86 258.363 358.973L305.931 380.508L305.931 379.424L258.784 358.08Z"
            fill="#014A4F"
          />
          <path
            d="M286.148 339.949C285.848 340.29 278.775 348.325 277.724 349.977C276.502 351.9 276.975 352.89 278.498 354.383C278.596 354.479 278.725 354.527 278.853 354.527C278.982 354.527 279.11 354.479 279.208 354.383C279.404 354.19 279.404 353.878 279.208 353.687C277.814 352.321 277.72 351.845 278.576 350.499C279.586 348.911 286.835 340.677 286.908 340.594C287.09 340.388 287.066 340.077 286.856 339.9C286.647 339.722 286.329 339.745 286.148 339.95L286.148 339.949Z"
            fill="#014A4F"
          />
          <path
            d="M278.63 361.387C278.365 361.467 278.217 361.744 278.298 362.004C278.559 362.836 279.203 365.243 279.04 366.383C278.844 367.746 278.507 368.271 276.92 369.689C275.2 371.221 274.038 373.461 273.989 373.556C273.863 373.799 273.963 374.094 274.211 374.217C274.282 374.252 274.36 374.27 274.437 374.27C274.621 374.27 274.796 374.171 274.885 373.999C274.896 373.978 276.016 371.826 277.594 370.418C279.369 368.835 279.802 368.144 280.034 366.521C280.262 364.931 279.298 361.846 279.257 361.715C279.174 361.454 278.893 361.309 278.628 361.389L278.63 361.387Z"
            fill="#014A4F"
          />
          <path
            d="M27.8445 371.249C27.6288 371.079 27.3122 371.113 27.1378 371.325L-0.000114505 404.461L-0.000114574 406.034L27.921 371.94C28.0938 371.728 28.0602 371.419 27.843 371.249L27.8445 371.249Z"
            fill="#014A4F"
          />
          <path
            d="M37.7629 381.374L18.2305 370.221C17.9903 370.083 17.6829 370.164 17.5437 370.398C17.4045 370.634 17.4855 370.935 17.7242 371.072L37.2565 382.224C37.3361 382.27 37.4233 382.292 37.5089 382.292C37.6818 382.292 37.8501 382.204 37.9434 382.047C38.0826 381.811 38.0015 381.51 37.7629 381.374Z"
            fill="#014A4F"
          />
          <path
            d="M44.9218 372.615C43.3799 371.038 37.6987 367.645 34.2845 367.327C32.3801 367.149 28.9612 364.52 26.2155 362.406C24.5359 361.115 23.0873 360 22.0196 359.425C19.2708 357.945 11.5536 351.594 8.50035 347.703C7.11905 345.942 5.20239 343.4 3.34995 340.942C2.20882 339.427 1.04933 337.888 0.00303066 336.518L0.00303058 338.165C0.822935 339.247 1.68873 340.394 2.54228 341.527C4.39777 343.99 6.31751 346.537 7.70492 348.304C10.8836 352.356 18.6528 358.736 21.5377 360.289C22.5336 360.824 24.0219 361.97 25.5975 363.181C28.5957 365.488 31.9946 368.102 34.1927 368.307C37.2245 368.59 42.7635 371.827 44.1998 373.296C44.2993 373.397 44.4308 373.447 44.5624 373.447C44.6878 373.447 44.8132 373.401 44.9096 373.309C45.11 373.121 45.1161 372.809 44.9249 372.613L44.9218 372.615Z"
            fill="#014A4F"
          />
          <path
            d="M303.386 333.246C300.73 329.962 298.664 331.052 297.003 331.93L296.729 332.074C295.038 332.952 284.592 337.839 284.487 337.888C284.236 338.005 284.13 338.3 284.25 338.545C284.369 338.789 284.667 338.895 284.92 338.777C285.351 338.575 295.49 333.833 297.198 332.944L297.478 332.798C299.081 331.952 300.466 331.22 302.598 333.858C303.441 334.899 304.628 336.446 305.93 338.162L305.93 336.515C304.943 335.221 304.056 334.075 303.384 333.246L303.386 333.246Z"
            fill="#014A4F"
          />
          <path
            d="M34.9193 357.416C34.7893 357.352 31.6978 355.833 29.2748 356.309C27.0093 356.752 20.4348 358.372 20.1564 358.441C19.8872 358.507 19.7235 358.774 19.7923 359.039C19.8489 359.262 20.0539 359.411 20.2788 359.411C20.3201 359.411 20.3599 359.406 20.4012 359.396C20.4685 359.379 27.2311 357.713 29.4721 357.274C31.5509 356.867 34.4359 358.279 34.4649 358.294C34.7128 358.417 35.0141 358.319 35.1395 358.077C35.265 357.834 35.1656 357.539 34.9193 357.415L34.9193 357.416Z"
            fill="#014A4F"
          />
          <path
            d="M55.989 357.441C55.9798 357.433 55.9706 357.425 55.9615 357.419L42.4392 347.606C42.3306 347.528 42.1944 347.495 42.0629 347.516C41.9298 347.536 41.8105 347.608 41.7325 347.715L33.0699 359.568C32.9093 359.789 32.9598 360.096 33.1846 360.255L46.9517 369.989C47.1766 370.148 47.4902 370.097 47.6523 369.877C47.8144 369.657 47.764 369.349 47.5391 369.19L34.1789 359.744L42.2541 348.697L55.3511 358.201L59.0743 361.329L55.4215 365.874C55.4215 365.874 55.4108 365.886 55.4062 365.894L51.4597 371.35C51.3113 371.555 51.3449 371.835 51.5346 372.002L56.4372 376.294C56.5336 376.378 56.6529 376.419 56.7722 376.419C56.9099 376.419 57.0475 376.364 57.147 376.254C57.3321 376.052 57.3137 375.74 57.1072 375.558L52.5396 371.558L56.2185 366.47L60.1619 361.563C60.3287 361.357 60.2981 361.057 60.0946 360.884L55.9921 357.438L55.989 357.441Z"
            fill="#014A4F"
          />
          <path
            d="M52.1573 363.396L38.0216 353.633C37.7952 353.477 37.4816 353.529 37.321 353.753C37.1619 353.974 37.2155 354.282 37.4419 354.438L51.5776 364.2C51.6647 364.261 51.7657 364.29 51.8667 364.29C52.0242 364.29 52.1803 364.219 52.2782 364.081C52.4372 363.859 52.3837 363.552 52.1573 363.396Z"
            fill="#014A4F"
          />
          <path
            d="M58.2422 372.56L36.0773 356.315C35.8555 356.153 35.5404 356.197 35.3736 356.414C35.2069 356.631 35.2528 356.94 35.4746 357.103L57.3351 373.124L55.4398 377.935C55.3404 378.189 55.4689 378.474 55.7274 378.572C55.7871 378.594 55.8483 378.605 55.9079 378.605C56.1098 378.605 56.2995 378.486 56.376 378.29L58.4089 373.132C58.49 372.925 58.4212 372.693 58.2407 372.56L58.2422 372.56Z"
            fill="#014A4F"
          />
          <path
            d="M94.7463 419.247C94.7295 419.178 94.6974 419.112 94.6515 419.056L92.4992 416.383C92.8541 413.24 97.2076 374.729 97.642 371.702C97.9632 369.458 97.8332 368.402 97.177 367.94C96.5467 367.497 95.6886 367.83 95.0966 368.121C94.6484 368.341 93.2641 368.93 91.5126 369.676C85.9232 372.057 76.5677 376.043 73.0127 378.328C69.6123 380.514 65.3781 380.773 60.0686 379.116C57.2724 378.244 42.277 375.54 41.4097 375.746C41.1405 375.812 40.9768 376.08 41.0426 376.343C41.1053 376.591 41.3501 376.747 41.6024 376.711L41.6101 376.711C42.661 376.711 56.506 379.04 59.7642 380.055C61.8278 380.699 68.1101 382.657 73.5634 379.153C77.048 376.913 86.7186 372.794 91.9134 370.58C93.7474 369.799 95.0737 369.234 95.5463 369.003C96.3418 368.613 96.5559 368.716 96.5911 368.742C96.7165 368.831 96.975 369.283 96.6477 371.567C96.1964 374.721 91.5264 416.062 91.4789 416.478C91.4636 416.607 91.5019 416.735 91.5845 416.838L93.7948 419.584L99.2803 445.432C99.3353 445.658 99.5418 445.81 99.7682 445.81C99.8065 445.81 99.8462 445.805 99.886 445.796C100.155 445.733 100.322 445.467 100.258 445.202L94.7463 419.249L94.7463 419.247Z"
            fill="#014A4F"
          />
          <path
            d="M49.8963 377.06C49.6761 376.897 49.361 376.938 49.1927 377.155L31.3078 400.175C31.1395 400.392 31.1839 400.701 31.4041 400.865C31.4944 400.932 32.0084 400.899 32.1078 400.77L49.9927 377.75C50.161 377.533 50.1166 377.224 49.8963 377.06Z"
            fill="#014A4F"
          />
          <path
            d="M51.7777 377.629C51.5055 377.678 51.3234 377.934 51.3724 378.201C51.6187 379.537 51.0557 381.782 49.237 383.557C47.3983 385.351 35.5495 401.289 35.06 401.951C34.8963 402.171 34.9468 402.479 35.1716 402.639C35.2603 402.702 35.3644 402.733 35.4669 402.733C35.6229 402.733 35.7759 402.662 35.8738 402.529C35.9931 402.369 48.1631 385.993 49.9467 384.254C52.0332 382.218 52.6634 379.659 52.3621 378.025C52.3131 377.758 52.0516 377.579 51.7777 377.629Z"
            fill="#014A4F"
          />
          <path
            d="M60.0748 390.52C59.7306 390.255 59.4231 390.001 59.1509 389.779C58.204 389.004 57.5799 388.491 56.8319 388.594C56.3271 388.664 55.885 389.012 55.3971 389.718C54.2116 391.444 28.5207 420.937 28.2622 421.236C28.0817 421.442 28.1077 421.753 28.3173 421.93C28.4121 422.01 28.5284 422.048 28.6431 422.048C28.7854 422.048 28.9261 421.99 29.0255 421.875C30.0856 420.657 55.0116 392.04 56.2292 390.269C56.604 389.723 56.8395 389.587 56.9711 389.568C57.277 389.524 57.8185 389.971 58.5053 390.534C58.7822 390.761 59.0973 391.02 59.4537 391.294C61.5372 392.898 73.7287 404.403 73.851 404.519C74.0514 404.709 74.368 404.701 74.5608 404.507C74.7535 404.311 74.7474 403.999 74.547 403.811C74.0438 403.335 62.1964 392.155 60.0733 390.52L60.0748 390.52Z"
            fill="#014A4F"
          />
          <path
            d="M79.2187 394.423C79.0413 394.307 78.9938 394.095 75.1238 395.202C74.9616 395.248 74.8331 395.372 74.7842 395.531C74.7352 395.69 74.7704 395.863 74.879 395.99C76.9762 398.456 76.606 401.573 73.8082 405.007C70.0514 409.618 55.7153 427.487 55.5715 427.668C55.4002 427.882 55.4384 428.191 55.6556 428.359C55.7474 428.43 55.8575 428.465 55.9661 428.465C56.1145 428.465 56.2629 428.401 56.3608 428.277C56.5046 428.096 70.8392 410.23 74.593 405.622C78.1816 401.218 77.5299 398.007 76.1089 395.955C77.4336 395.604 78.8057 395.318 79.1453 395.372C79.3686 395.479 79.4497 394.575 79.2187 394.425L79.2187 394.423Z"
            fill="#014A4F"
          />
          <path
            d="M73.6017 413.185L44.6114 390.599C44.3942 390.429 44.0791 390.465 43.9062 390.676C43.7334 390.888 43.7685 391.197 43.9842 391.369L72.5768 413.642L28.3387 466.261C28.1628 466.471 28.1934 466.782 28.4076 466.954C28.5009 467.029 28.6141 467.066 28.7257 467.066C28.8711 467.066 29.0148 467.005 29.1143 466.887L73.6767 413.882C73.7623 413.78 73.8021 413.65 73.7883 413.518C73.7746 413.387 73.7073 413.268 73.6017 413.185Z"
            fill="#014A4F"
          />
          <path
            d="M82.746 405.748C80.1792 393.353 72.9408 379.168 72.8689 379.027C72.7434 378.784 72.4421 378.686 72.1943 378.809C71.9465 378.931 71.8471 379.226 71.971 379.469C72.0429 379.61 79.2216 393.679 81.7609 405.943C84.3108 418.256 89.088 455.543 89.1278 455.866C89.1599 456.115 89.3756 456.297 89.6249 456.297C89.6463 456.297 89.9186 455.927 89.94 455.924C90.2153 455.89 90.1587 456.013 90.1236 455.743C90.0823 455.421 85.3005 418.095 82.7429 405.746L82.746 405.748Z"
            fill="#014A4F"
          />
          <path
            d="M95.2603 385.941L77.7104 391.086C77.4442 391.164 77.2943 391.438 77.3723 391.699C77.4381 391.913 77.6369 392.051 77.8526 392.051C77.9 392.051 77.949 392.045 77.9964 392.03L95.5464 386.885C95.8125 386.807 95.9624 386.533 95.8844 386.272C95.8049 386.012 95.5249 385.863 95.2588 385.941L95.2603 385.941Z"
            fill="#014A4F"
          />
          <path
            d="M90.9957 370.694C91.0217 370.423 90.8198 370.183 90.5445 370.157C90.2676 370.128 90.0228 370.328 89.9968 370.599C89.977 370.796 88.0343 390.434 87.4102 393.951C86.7646 397.582 88.6324 405.559 88.7119 405.898C88.7654 406.125 89.7536 405.94 89.6909 405.676C89.6725 405.595 87.7895 397.556 88.4014 394.121C89.0332 390.567 90.9192 371.504 90.9988 370.694L90.9957 370.694Z"
            fill="#014A4F"
          />
          <path
            d="M80.5831 375.072C80.4975 374.813 80.2145 374.672 79.9514 374.755C79.6867 374.839 79.543 375.116 79.6286 375.374L84.3446 389.666C84.4134 389.874 84.6108 390.007 84.8219 390.007C84.8723 390.007 84.9259 390 84.9764 389.983C85.2395 389.899 85.3848 389.622 85.2991 389.363L80.5831 375.072Z"
            fill="#014A4F"
          />
          <path
            d="M89.4414 378.304L82.2458 381.978C81.9996 382.104 81.9047 382.4 82.0317 382.642C82.122 382.81 82.2963 382.907 82.4768 382.907C82.5548 382.907 82.6344 382.888 82.7078 382.852L89.9034 379.177C90.1497 379.052 90.2445 378.755 90.1175 378.513C89.9891 378.272 89.6862 378.178 89.4399 378.304L89.4414 378.304Z"
            fill="#014A4F"
          />
          <path
            d="M56.5597 382.319L57.8676 379.45C57.9807 379.202 57.8676 378.91 57.6136 378.799C57.3597 378.689 57.0629 378.799 56.9497 379.049L55.5057 382.215C55.4231 382.394 55.4583 382.605 55.5945 382.749L67.0701 394.973L66.3221 396.603L17.4414 455.988C17.267 456.199 17.3007 456.51 17.5179 456.681C17.6112 456.755 17.7213 456.79 17.833 456.79C17.9798 456.79 18.1252 456.727 18.2246 456.606L67.145 397.171C67.1725 397.139 67.194 397.102 67.2123 397.064L68.124 395.078C68.2066 394.899 68.1714 394.688 68.0353 394.543L56.5582 382.319L56.5597 382.319Z"
            fill="#014A4F"
          />
          <path
            d="M63.2153 393.53C62.9843 393.553 62.2791 393.622 57.0017 400.834C56.8396 401.055 56.8916 401.364 57.1165 401.521C57.2052 401.584 57.3077 401.613 57.4086 401.613C57.5646 401.613 57.7191 401.541 57.817 401.408C60.0611 398.341 62.6829 395.012 63.3499 394.526C63.4738 394.518 63.5946 394.466 63.6864 394.37C63.8745 394.171 63.8638 393.859 63.6604 393.674C63.5411 393.565 63.382 393.515 63.2153 393.53Z"
            fill="#014A4F"
          />
          <path
            d="M60.0672 390.515C59.8454 390.352 59.5303 390.394 59.3635 390.612L53.5646 398.127C53.3978 398.344 53.4407 398.653 53.6625 398.817C53.7527 398.884 53.8598 398.916 53.9653 398.916C54.1168 398.916 54.2682 398.849 54.3661 398.72L60.1651 391.205C60.3318 390.988 60.289 390.679 60.0672 390.515Z"
            fill="#014A4F"
          />
          <path
            d="M37.0074 322.379L37.0074 325.954C37.0074 326.093 37.0686 326.226 37.1741 326.319C37.2675 326.4 37.3868 326.446 37.5091 326.446C37.5275 326.446 37.5443 326.446 37.5627 326.443L57.4622 324.358C57.7299 324.331 57.9272 324.1 57.9104 323.837L57.6549 320.066L56.6484 320.066L56.8763 323.43L38.0124 325.408L38.0124 322.459L38.8032 320.068L37.7478 320.068L37.0334 322.229C37.0166 322.278 37.0089 322.33 37.0089 322.381L37.0074 322.379Z"
            fill="#014A4F"
          />
          <path
            d="M106.372 326.633C105.742 326.7 43.2852 333.362 41.4879 333.509C39.6201 333.662 38.6901 333.614 38.1165 332.94C37.7478 332.505 37.9145 331.5 38.1042 330.879L63.3744 328.106C63.6497 328.076 63.8486 327.832 63.818 327.562C63.7874 327.291 63.5381 327.096 63.2627 327.127L37.6744 329.936C37.4878 329.955 37.3287 330.076 37.2614 330.248C37.1726 330.474 36.4231 332.486 37.344 333.57C38.0124 334.356 38.9578 334.564 40.1127 334.564C40.567 334.564 41.055 334.532 41.5705 334.489C43.3801 334.341 103.905 327.886 106.479 327.61C106.754 327.581 106.955 327.338 106.924 327.067C106.894 326.797 106.646 326.602 106.37 326.631L106.372 326.633Z"
            fill="#014A4F"
          />
          <path
            d="M96.0802 328.108C96.1108 328.108 96.1429 328.105 96.175 328.098C96.4473 328.048 96.6263 327.789 96.5743 327.523L95.1257 320.065L94.1038 320.065L95.5876 327.707C95.6335 327.942 95.8446 328.108 96.0802 328.108Z"
            fill="#014A4F"
          />
          <path
            d="M108.626 330.832L51.8022 338.13C51.5269 338.165 51.3326 338.411 51.3693 338.682C51.4014 338.93 51.6187 339.112 51.8665 339.112C51.8879 339.112 51.9093 339.112 51.9323 339.107L108.757 331.809C109.032 331.774 109.226 331.528 109.189 331.257C109.154 330.988 108.903 330.797 108.626 330.832Z"
            fill="#014A4F"
          />
          <path
            d="M69.3967 335.898C69.1198 335.91 68.9057 336.141 68.9179 336.414L69.7638 354.034C69.776 354.297 69.9978 354.504 70.2655 354.504C70.2732 354.504 70.2808 354.504 70.29 354.504C70.5669 354.491 70.781 354.26 70.7688 353.988L69.9229 336.368C69.9106 336.096 69.6735 335.891 69.3982 335.9L69.3967 335.898Z"
            fill="#014A4F"
          />
          <path
            d="M90.8029 370.59L66.348 354.992L65.1151 348.803C65.0616 348.535 64.7985 348.36 64.5262 348.414C64.2539 348.466 64.0765 348.725 64.13 348.992L65.4057 355.398C65.4333 355.536 65.5189 355.655 65.6413 355.727L90.2889 371.437C90.37 371.485 90.4587 371.506 90.5459 371.506C90.7172 371.506 90.884 371.42 90.9773 371.266C91.1195 371.032 91.0415 370.73 90.8029 370.591L90.8029 370.59Z"
            fill="#014A4F"
          />
          <path
            d="M100.695 350.774C100.496 350.733 95.8065 349.745 92.611 349.596C89.882 349.474 86.3026 351.299 85.0284 352.007L83.9179 349.866C83.8368 349.71 83.6762 349.607 83.4972 349.596C83.3182 349.589 83.1484 349.671 83.049 349.818L80.4807 353.659L78.0317 352.755C77.9476 352.725 77.8542 352.717 77.7671 352.731L65.8096 354.816C65.5358 354.863 65.3538 355.119 65.4027 355.386C65.4455 355.625 65.6582 355.792 65.8968 355.792C65.9259 355.792 65.9549 355.789 65.9855 355.784L77.8068 353.722L80.5021 354.716C80.7224 354.797 80.9702 354.718 81.0987 354.525L83.4131 351.065L84.3722 352.914C84.4364 353.037 84.5481 353.128 84.6842 353.165C84.8204 353.202 84.9642 353.182 85.0835 353.11C85.1278 353.084 89.5578 350.438 92.562 350.575C95.6749 350.721 100.435 351.723 100.483 351.733C100.755 351.79 101.021 351.622 101.079 351.356C101.137 351.089 100.966 350.828 100.694 350.77L100.695 350.774Z"
            fill="#014A4F"
          />
          <path
            d="M96.5698 332.891C96.504 332.626 96.2333 332.463 95.964 332.528C95.6948 332.593 95.5296 332.859 95.5939 333.123L105.128 370.639C105.183 370.863 105.39 371.015 105.616 371.015C105.656 371.015 105.696 371.01 105.736 371.001C106.005 370.937 106.17 370.671 106.106 370.406L96.5713 332.891L96.5698 332.891Z"
            fill="#014A4F"
          />
          <path
            d="M91.5234 333.581C91.2465 333.581 91.0217 333.801 91.0217 334.073L91.0217 350.083C91.0217 350.355 91.2465 350.575 91.5234 350.575C91.8003 350.575 92.0251 350.355 92.0251 350.083L92.0251 334.073C92.0251 333.801 91.8003 333.581 91.5234 333.581Z"
            fill="#014A4F"
          />
          <path
            d="M91.4424 341.592L64.7175 345.878C64.4437 345.922 64.2586 346.175 64.303 346.444C64.3427 346.685 64.5569 346.857 64.7986 346.857C64.8261 346.857 64.8521 346.855 64.8796 346.851L91.6045 342.565C91.8783 342.52 92.0634 342.268 92.0191 341.999C91.9747 341.731 91.7162 341.549 91.4424 341.592Z"
            fill="#014A4F"
          />
          <path
            d="M109.638 336.827C109.658 337.081 109.474 337.309 109.217 337.348L98.0275 340.732C97.9755 340.747 97.9235 340.755 97.873 340.755C97.6603 340.755 97.463 340.622 97.3957 340.414C97.3101 340.154 97.4554 339.877 97.72 339.794L108.602 336.447L107.358 320.065L108.367 320.065L109.638 336.827Z"
            fill="#014A4F"
          />
          <path
            d="M256.231 404.802L256.22 404.772L233.659 341.509L220.938 329.859L206.399 320.066L203.295 320.066L219.889 331.242L232.143 342.477L254.55 405.309L263.397 437.909L287.624 470.506L289.044 469.492L265.011 437.155L256.231 404.802Z"
            fill="#014A4F"
          />
          <path
            d="M147.303 325.75C146.897 326.682 145.767 329.336 144.204 333.009C139.021 345.185 128.237 370.521 126.366 373.963L126.105 374.444C123.851 378.602 122.609 380.892 115.628 383.082C108.062 385.457 94.4603 392.039 94.4022 392.066L94.7969 393.694C94.855 393.668 108.715 387.061 116.163 384.724C123.825 382.321 125.349 379.509 127.656 375.254L127.916 374.775C129.826 371.261 140.234 346.809 145.825 333.673C147.387 330.004 148.514 327.354 148.918 326.428C149.629 324.796 150.203 322.613 150.532 320.066L148.77 320.066C148.458 322.338 147.941 324.283 147.301 325.75L147.303 325.75Z"
            fill="#014A4F"
          />
          <path
            d="M131.368 351.204L98.7006 339.553L98.1499 341.189L130.798 352.833L174.306 369.225L174.337 369.234L250.091 391.731L250.6 390.082L174.877 367.593L131.368 351.204Z"
            fill="#014A4F"
          />
          <path
            d="M232.877 340.766L228.473 345.32L228.414 345.52L206.573 418.978C206.059 419.697 203.162 423.685 201.075 425.22C198.317 427.249 198.579 431.344 198.882 436.086L198.902 436.392C199.208 441.183 191.521 471.81 191.634 472.25L193.146 472.109C193.118 472.002 200.952 440.916 200.656 436.283L200.636 435.977C200.353 431.558 200.13 428.069 202.129 426.597C204.646 424.746 207.96 420.041 208.1 419.841L208.179 419.726L208.219 419.593L230.041 346.201L232.925 343.219L244.076 353.915L295.69 428.775L297.146 427.81L245.431 352.804L232.876 340.763L232.877 340.766Z"
            fill="#014A4F"
          />
          <path
            d="M260.571 320.1C255.826 324.441 255.429 328.524 255.429 332.334C255.429 335.41 255.647 343.632 257.663 353.531C259.085 360.521 250.131 363.919 244.78 365.949C243.548 366.417 242.484 366.819 241.754 367.189L242.562 368.72C243.206 368.393 244.229 368.006 245.413 367.556C250.894 365.478 261.098 361.606 259.385 353.194C257.4 343.447 257.185 335.36 257.185 332.336C257.185 328.762 257.536 325.235 261.768 321.362C262.146 321.016 262.633 320.579 263.209 320.068L260.607 320.068C260.595 320.078 260.581 320.092 260.569 320.103L260.571 320.1Z"
            fill="#014A4F"
          />
          <path
            d="M256.884 320.066L255.076 320.066C253.375 326.976 253.803 331.292 255.749 339.699L257.464 339.319C255.519 330.921 255.134 326.922 256.885 320.066L256.884 320.066Z"
            fill="#014A4F"
          />
          <path
            d="M65.4286 341.944C65.3537 340.666 64.4313 331.802 63.1769 320.065L61.4102 320.065C62.6691 331.849 63.5976 340.761 63.6726 342.041C63.8913 345.783 63.6695 348.045 61.8461 350.032C60.6285 351.359 41.8487 374.946 25.2793 395.757C13.4595 410.603 3.25051 423.425 1.64282 425.373C1.00342 426.147 0.472626 426.68 -4.23111e-05 427.035L-4.23986e-05 429.037C0.894816 428.638 1.85697 427.853 3.00881 426.458C4.6272 424.497 14.8408 411.671 26.6652 396.817C42.3963 377.059 61.9761 352.466 63.1525 351.184C65.3781 348.76 65.6688 346.066 65.4286 341.942L65.4286 341.944Z"
            fill="#014A4F"
          />
          <path
            d="M257.471 359.186L256.364 360.526L305.931 399.897L305.931 397.68L257.471 359.186Z"
            fill="#014A4F"
          />
          <path
            d="M305.931 320.063L305.931 74.8767L-0.000183105 74.8767L-0.000193823 320.063L305.931 320.063Z"
            fill="black"
          />
          <path
            d="M158.205 221.838L116.553 192.198L115.954 192.987L157.752 222.735C157.839 222.799 157.945 222.833 158.052 222.833C158.062 222.833 158.072 222.833 158.082 222.833L187.657 221.078L187.596 220.094L158.205 221.837L158.205 221.838Z"
            fill="#014A4F"
          />
          <path
            d="M169.919 195.294L174.093 221.421L175.091 221.297L170.915 195.17L169.919 195.294Z"
            fill="#014A4F"
          />
          <path
            d="M186.793 259.808C183.715 262.382 171.323 258.339 167.251 257.01C166.456 256.75 165.882 256.563 165.55 256.472C163.914 256.022 164.025 255.624 164.559 253.707C164.602 253.554 164.646 253.395 164.691 253.23C165.283 251.055 177.986 221.847 178.115 221.552C178.224 221.301 178.106 221.012 177.85 220.907C177.593 220.8 177.3 220.916 177.191 221.167C176.666 222.374 164.33 250.734 163.719 252.978C163.675 253.141 163.631 253.299 163.589 253.449C163.061 255.341 162.681 256.707 165.278 257.42C165.587 257.504 166.18 257.698 166.933 257.945C172.542 259.776 178.939 261.63 183.298 261.63C185.09 261.63 186.539 261.316 187.444 260.557C187.656 260.381 187.68 260.069 187.501 259.863C187.321 259.656 187.004 259.632 186.793 259.808Z"
            fill="#014A4F"
          />
          <path
            d="M191.139 253.85L162.073 245.034C161.812 244.955 161.53 245.097 161.446 245.354L159.47 251.338C159.386 251.596 159.531 251.873 159.794 251.957C160.059 252.04 160.342 251.898 160.426 251.639L162.251 246.12L190.84 254.79C190.889 254.806 190.94 254.812 190.989 254.812C191.203 254.812 191.402 254.676 191.469 254.465C191.552 254.205 191.403 253.929 191.137 253.848L191.139 253.85Z"
            fill="#014A4F"
          />
          <path
            d="M118.418 224.771C118.212 224.589 117.895 224.605 117.708 224.807C117.523 225.009 117.54 225.321 117.745 225.503L163.536 254.688C163.632 254.772 163.751 254.815 163.871 254.815C164.008 254.815 164.145 254.76 164.244 254.653C164.429 254.451 164.412 254.139 164.207 253.957L118.417 224.772L118.418 224.771Z"
            fill="#014A4F"
          />
          <path
            d="M167.77 244.407C167.21 244.141 154.035 237.891 151.158 236.85C150.091 236.465 149.4 236.009 149.331 235.449C149.163 234.054 155.458 222.562 156.28 221.618C156.461 221.411 156.437 221.101 156.225 220.924C156.014 220.748 155.698 220.771 155.517 220.977C154.453 222.196 147.939 233.785 148.36 235.697C148.557 236.593 149.397 237.263 150.81 237.774C153.642 238.796 167.195 245.228 167.331 245.293C167.401 245.326 167.476 245.342 167.55 245.342C167.736 245.342 167.915 245.239 168.002 245.063C168.123 244.819 168.019 244.525 167.77 244.406L167.77 244.407Z"
            fill="#014A4F"
          />
          <path
            d="M197.051 246.535C196.818 246.449 173.783 237.996 172.366 237.45C170.736 236.821 169.09 235.495 167.964 233.903C167.686 233.511 167.416 233.098 167.155 232.699C166.075 231.048 165.055 229.49 163.415 229.087C163.146 229.022 162.873 229.182 162.806 229.445C162.739 229.708 162.902 229.976 163.172 230.042C164.426 230.349 165.341 231.749 166.31 233.23C166.577 233.637 166.85 234.056 167.14 234.463C168.376 236.208 170.191 237.667 171.999 238.365C173.423 238.914 195.751 247.107 196.7 247.456C196.759 247.477 196.818 247.488 196.876 247.488C197.08 247.488 197.271 247.365 197.346 247.168C197.444 246.913 197.311 246.63 197.052 246.535L197.051 246.535Z"
            fill="#014A4F"
          />
          <path
            d="M215.489 226.467C215.341 226.241 215.034 226.175 214.801 226.317C210.305 229.084 198.296 236.367 196.728 236.841C194.433 237.533 191.369 241.441 190.542 244.731C190.476 244.996 190.64 245.262 190.909 245.328C190.949 245.337 190.99 245.342 191.03 245.342C191.255 245.342 191.461 245.192 191.516 244.967C192.313 241.804 195.259 238.314 197.023 237.782C198.88 237.222 212.298 229.015 214.916 227.409L219.496 234.346C219.645 234.574 219.958 234.64 220.19 234.492C220.423 234.343 220.49 234.039 220.338 233.811L215.491 226.465L215.489 226.467Z"
            fill="#014A4F"
          />
          <path
            d="M183.013 241.38C182.748 241.302 182.467 241.45 182.389 241.712L179.605 250.875C179.525 251.135 179.677 251.41 179.943 251.486C179.99 251.5 180.039 251.506 180.087 251.506C180.302 251.506 180.503 251.369 180.569 251.154L183.353 241.992C183.432 241.732 183.281 241.456 183.015 241.38L183.013 241.38Z"
            fill="#014A4F"
          />
          <path
            d="M187.798 258.953L183.455 257.408C183.573 256.894 183.779 255.985 183.977 255.081C184.153 254.28 184.287 253.637 184.376 253.175C184.553 252.257 184.628 251.878 184.154 251.734C183.889 251.655 183.608 251.798 183.525 252.058C183.501 252.133 183.496 252.211 183.51 252.285C183.464 252.784 182.829 255.661 182.377 257.614C182.32 257.856 182.458 258.101 182.695 258.186L187.452 259.88C187.509 259.9 187.567 259.909 187.623 259.909C187.828 259.909 188.021 259.785 188.096 259.584C188.191 259.329 188.056 259.046 187.795 258.954L187.798 258.953ZM183.857 252.673C183.805 252.657 183.756 252.634 183.714 252.603C183.756 252.634 183.804 252.657 183.857 252.673Z"
            fill="#014A4F"
          />
          <path
            d="M175.791 249.196C175.531 249.105 175.242 249.238 175.149 249.493L171.709 258.896C171.615 259.152 171.752 259.435 172.012 259.526C172.068 259.546 172.125 259.555 172.181 259.555C172.388 259.555 172.581 259.43 172.654 259.23L176.094 249.827C176.188 249.571 176.052 249.288 175.791 249.196Z"
            fill="#014A4F"
          />
          <path
            d="M168.405 253.853C166.341 254.517 166.084 257.152 166.073 257.265C166.049 257.536 166.254 257.775 166.531 257.799C166.546 257.799 166.56 257.801 166.575 257.801C166.832 257.801 167.052 257.608 167.075 257.351C167.077 257.331 167.288 255.251 168.718 254.789C168.981 254.705 169.125 254.426 169.039 254.168C168.954 253.909 168.669 253.769 168.406 253.854L168.405 253.853Z"
            fill="#014A4F"
          />
          <path
            d="M183.683 253.873L174.655 252.764C174.378 252.73 174.129 252.921 174.094 253.19C174.058 253.46 174.254 253.706 174.53 253.74L183.558 254.849C183.579 254.852 183.6 254.853 183.62 254.853C183.87 254.853 184.085 254.671 184.117 254.422C184.153 254.153 183.957 253.906 183.682 253.873L183.683 253.873Z"
            fill="#014A4F"
          />
          <path
            d="M117.506 229.698C119.992 221.008 117.052 216.787 115.08 215.045C112.773 213.009 97.2365 204.839 96.7287 204.6C96.4778 204.483 96.1321 204.556 96.0128 204.802C95.8935 205.047 95.999 205.342 96.2484 205.459C96.3707 205.517 112.207 213.833 114.407 215.776C116.607 217.717 118.761 221.662 116.539 229.432C113.191 241.141 115.837 250.572 124.408 257.469L121.064 266.615C120.971 266.87 121.107 267.153 121.367 267.245C121.424 267.265 121.48 267.274 121.537 267.274C121.743 267.274 121.936 267.149 122.01 266.948L125.474 257.473C125.546 257.276 125.483 257.054 125.315 256.923C116.868 250.278 114.242 241.117 117.506 229.698Z"
            fill="#014A4F"
          />
          <path
            d="M161.442 241.438C161.209 241.29 160.899 241.354 160.746 241.582L155.866 248.843C155.715 249.071 155.78 249.375 156.013 249.524C156.097 249.577 156.193 249.603 156.287 249.603C156.45 249.603 156.611 249.525 156.707 249.38L161.587 242.119C161.738 241.891 161.673 241.586 161.44 241.438L161.442 241.438Z"
            fill="#014A4F"
          />
          <path
            d="M112.83 275.291C112.79 275.265 108.854 272.646 107.501 271.762C107.285 271.621 107.074 271.491 106.873 271.367C106.32 271.026 105.746 270.673 105.691 270.415C105.673 270.33 105.716 270.211 105.816 270.061C106.552 268.978 107.358 266.347 107.416 264.886L110.883 260.147C111.045 259.927 110.993 259.618 110.768 259.461C110.542 259.301 110.23 259.352 110.067 259.574L106.511 264.437C106.45 264.521 106.416 264.62 106.416 264.724C106.416 265.952 105.637 268.55 104.981 269.517C104.724 269.896 104.633 270.268 104.709 270.621C104.854 271.285 105.509 271.69 106.338 272.201C106.531 272.321 106.734 272.446 106.942 272.582C108.293 273.465 112.226 276.08 112.266 276.106C112.351 276.163 112.451 276.191 112.547 276.191C112.708 276.191 112.867 276.116 112.963 275.975C113.119 275.75 113.059 275.444 112.83 275.291Z"
            fill="#014A4F"
          />
          <path
            d="M114.788 261.977C114.563 261.816 114.25 261.865 114.086 262.085L106.815 271.883C106.652 272.103 106.702 272.411 106.925 272.571C107.014 272.636 107.118 272.666 107.221 272.666C107.375 272.666 107.53 272.596 107.628 272.463L114.898 262.665C115.062 262.445 115.011 262.137 114.788 261.977Z"
            fill="#014A4F"
          />
          <path
            d="M118.767 264.522C118.517 264.4 118.216 264.499 118.092 264.743C117.255 266.384 112.238 275.677 111.401 276.616C110.233 277.925 111.102 282.41 111.287 283.296C111.336 283.528 111.545 283.689 111.778 283.689C111.811 283.689 111.845 283.686 111.88 283.678C112.151 283.623 112.327 283.363 112.27 283.097C111.85 281.093 111.537 277.957 112.155 277.263C113.249 276.038 118.863 265.428 118.989 265.182C119.112 264.938 119.011 264.643 118.764 264.521L118.767 264.522Z"
            fill="#014A4F"
          />
          <path
            d="M114.321 272.877L109.281 269.387C109.055 269.229 108.741 269.283 108.581 269.504C108.42 269.726 108.475 270.034 108.701 270.191L113.742 273.682C113.83 273.743 113.931 273.772 114.031 273.772C114.188 273.772 114.344 273.7 114.442 273.564C114.603 273.342 114.548 273.035 114.321 272.877Z"
            fill="#014A4F"
          />
          <path
            d="M126.571 269.995C126.319 269.884 126.027 269.995 125.92 270.248C125.583 271.037 121.618 278.537 121.615 278.646C121.259 278.704 120.085 278.308 119.134 277.986C118.829 277.884 118.507 277.775 118.169 277.665C117.453 277.431 117.292 277.047 117.234 276.779C116.937 275.406 121.23 268.452 122.279 267.516C122.484 267.332 122.499 267.022 122.313 266.82C122.126 266.619 121.808 266.604 121.603 266.788C120.664 267.626 115.811 274.942 116.25 276.983C116.367 277.52 116.738 278.236 117.849 278.6C118.184 278.708 118.502 278.817 118.805 278.918C120.012 279.325 120.913 279.628 121.551 279.628C121.904 279.628 122.178 279.534 122.378 279.314C122.859 278.788 126.452 271.55 126.833 270.653C126.94 270.402 126.821 270.107 126.568 269.995L126.571 269.995Z"
            fill="#014A4F"
          />
          <path
            d="M122.25 278.887C122.16 278.63 121.875 278.493 121.612 278.58C121.349 278.667 121.21 278.947 121.298 279.206L122.776 283.446C122.848 283.651 123.042 283.779 123.252 283.779C123.305 283.779 123.36 283.77 123.414 283.753C123.677 283.666 123.816 283.386 123.727 283.128L122.25 278.887Z"
            fill="#014A4F"
          />
          <path
            d="M136.812 277.921C136.769 277.959 132.453 281.73 128.953 282.817C125.435 283.911 99.6534 282.517 99.4515 282.507C99.1685 282.49 98.9375 282.699 98.9207 282.97C98.9054 283.241 99.1165 283.475 99.3934 283.49C99.938 283.521 114.297 284.284 121.196 284.284C124.824 284.284 127.983 284.153 129.256 283.758C132.964 282.606 137.3 278.815 137.482 278.655C137.689 278.473 137.706 278.162 137.52 277.96C137.335 277.758 137.017 277.74 136.811 277.922L136.812 277.921Z"
            fill="#014A4F"
          />
          <path
            d="M132.327 292.766C131.732 290.287 130.729 282.877 130.718 282.802C130.681 282.533 130.431 282.344 130.154 282.378C129.878 282.413 129.686 282.661 129.722 282.93C129.764 283.236 130.743 290.462 131.348 292.991C132.312 297.008 134.845 308.116 134.983 311.223C135.049 312.716 135.365 316.375 135.706 320.062L136.716 320.062C136.373 316.349 136.052 312.652 135.986 311.18C135.817 307.344 132.361 292.91 132.327 292.765L132.327 292.766Z"
            fill="#014A4F"
          />
          <path
            d="M135.706 74.8765C136.098 79.1305 136.522 83.4228 136.626 84.4676L129.852 91.6784C129.664 91.8788 129.678 92.1894 129.881 92.3744C129.978 92.4616 130.1 92.5045 130.221 92.5045C130.357 92.5045 130.492 92.4509 130.591 92.3454L137.518 84.9724C137.614 84.8699 137.661 84.7307 137.648 84.5915C137.638 84.5058 137.16 79.6782 136.718 74.8781L135.708 74.8781L135.706 74.8765Z"
            fill="#014A4F"
          />
          <path
            d="M127.862 283.141C127.628 282.995 127.318 283.062 127.168 283.291C126.108 284.916 123.767 286.73 121.136 286.875C118.476 287.022 100.972 287.444 100.812 287.449C100.535 287.457 100.316 287.683 100.324 287.955C100.331 288.223 100.555 288.434 100.825 288.434C100.83 288.434 102.532 288.455 102.537 288.455C102.696 288.451 118.517 288.007 121.193 287.859C124.193 287.694 126.781 285.711 128.014 283.822C128.164 283.593 128.095 283.288 127.861 283.141L127.862 283.141Z"
            fill="#014A4F"
          />
          <path
            d="M116.536 276.028C114.992 276.561 114.386 280.021 114.514 287.6C114.519 287.87 114.742 288.085 115.016 288.085C115.019 288.085 115.022 288.085 115.025 288.085C115.302 288.081 115.524 287.857 115.519 287.585C115.369 278.794 116.301 277.154 116.868 276.958C117.13 276.868 117.268 276.587 117.176 276.331C117.084 276.074 116.797 275.94 116.536 276.03L116.536 276.028Z"
            fill="#014A4F"
          />
          <path
            d="M124.786 286.314C124.702 286.054 124.42 285.912 124.156 285.994C123.891 286.077 123.746 286.354 123.83 286.612L127.728 298.626C127.795 298.836 127.994 298.969 128.206 298.969C128.257 298.969 128.309 298.961 128.359 298.946C128.624 298.863 128.769 298.586 128.685 298.328L124.788 286.314L124.786 286.314Z"
            fill="#014A4F"
          />
          <path
            d="M125.253 289.15L92.4304 298.175C92.1643 298.25 92.0098 298.522 92.0847 298.784C92.1475 298.999 92.3478 299.142 92.5681 299.142C92.614 299.142 92.6599 299.136 92.7058 299.123L125.528 290.098C125.794 290.023 125.949 289.751 125.874 289.49C125.797 289.228 125.52 289.077 125.253 289.151L125.253 289.15Z"
            fill="#014A4F"
          />
          <path
            d="M145.512 295.581L93.5287 303.683C93.2534 303.722 93.0637 303.971 93.102 304.24C93.1371 304.486 93.645 304.662 93.6695 304.659L145.652 296.557C145.928 296.518 146.117 296.269 146.079 296C146.041 295.731 145.785 295.546 145.512 295.581Z"
            fill="#014A4F"
          />
          <path
            d="M119.906 299.627L118.728 291.555C118.689 291.285 118.433 291.099 118.161 291.137C117.886 291.175 117.696 291.425 117.736 291.694L118.91 299.743L119.92 320.062L120.925 320.062L119.912 299.673C119.912 299.657 119.909 299.642 119.908 299.627L119.906 299.627Z"
            fill="#014A4F"
          />
          <path
            d="M141.533 96.3547C141.12 96.1742 131.4 91.9217 129.138 91.0345C127.171 90.262 122.984 89.6164 121.646 89.4237L120.924 74.8765L119.919 74.8765L120.664 89.8765C120.676 90.1136 120.858 90.3079 121.097 90.34C121.152 90.3476 126.568 91.0865 128.765 91.9477C131.009 92.8272 141.022 97.2082 141.123 97.2526C141.189 97.2816 141.259 97.2954 141.328 97.2954C141.521 97.2954 141.703 97.1868 141.787 97.0032C141.9 96.7554 141.787 96.4633 141.533 96.3531L141.533 96.3547Z"
            fill="#014A4F"
          />
          <path
            d="M105.969 294.921C105.693 294.941 105.485 295.177 105.506 295.449L107.36 320.063L108.366 320.063L106.508 295.376C106.488 295.105 106.249 294.903 105.969 294.921Z"
            fill="#014A4F"
          />
          <path
            d="M149.917 310.266L94.6958 317.128C94.4189 317.152 94.2155 317.391 94.24 317.662C94.2644 317.932 94.5092 318.133 94.7845 318.108L149.937 311.252L197.421 320.062L202.824 320.062L150.055 310.272C150.009 310.264 149.963 310.261 149.917 310.266Z"
            fill="#014A4F"
          />
          <path
            d="M206.737 75.6031L202.825 74.8781L197.423 74.8781L206.55 76.5714C206.582 76.5775 206.613 76.5806 206.643 76.5806C206.881 76.5806 207.09 76.4154 207.136 76.1798C207.188 75.9121 207.009 75.6551 206.737 75.6046L206.737 75.6031Z"
            fill="#014A4F"
          />
          <path
            d="M162.188 319.214C159.198 318.552 157.065 319.211 155.48 320.063L158.364 320.063C159.236 319.901 160.232 319.873 161.38 320.063L162.408 320.063C162.485 319.997 162.541 319.909 162.566 319.803C162.627 319.538 162.457 319.274 162.187 319.214L162.188 319.214Z"
            fill="#014A4F"
          />
          <path
            d="M161.966 74.9882C162.13 75.0249 162.292 74.9775 162.408 74.8765L161.38 74.8765C161.571 74.9086 161.766 74.9438 161.966 74.9882Z"
            fill="#014A4F"
          />
          <path
            d="M153.726 75.9855C153.506 76.1385 153.296 76.2838 153.1 76.4093L104.49 81.6576C104.213 81.6759 104.004 81.91 104.022 82.1807C104.041 82.4408 104.261 82.6411 104.522 82.6411C104.533 82.6411 104.545 82.6411 104.556 82.6411L153.296 77.3837C153.38 77.3775 153.461 77.3515 153.533 77.3072C153.778 77.1542 154.033 76.9783 154.304 76.7901C155.329 76.0804 156.595 75.2054 158.364 74.8765L155.48 74.8765C154.803 75.2406 154.226 75.6398 153.726 75.9855Z"
            fill="#014A4F"
          />
          <path
            d="M134.818 306.223L119.769 306.854C119.492 306.867 119.276 307.096 119.288 307.367C119.301 307.631 119.522 307.838 119.79 307.838C119.798 307.838 119.805 307.838 119.812 307.838L134.86 307.206C135.137 307.194 135.353 306.965 135.341 306.694C135.329 306.421 135.09 306.21 134.818 306.223Z"
            fill="#014A4F"
          />
          <path
            d="M165.414 293.132C165.137 293.132 164.912 293.352 164.912 293.624L164.912 313.521L162.003 320.063L163.098 320.063L165.874 313.821C165.902 313.758 165.916 313.692 165.916 313.623L165.916 293.624C165.916 293.352 165.691 293.132 165.414 293.132Z"
            fill="#014A4F"
          />
          <path
            d="M136.687 131.816C136.577 132.065 136.693 132.355 136.947 132.464C137.013 132.492 137.08 132.505 137.147 132.505C137.341 132.505 137.525 132.395 137.608 132.21L163.1 74.8781L162.004 74.8781L136.688 131.817L136.687 131.816Z"
            fill="#014A4F"
          />
          <path
            d="M202.299 259.653C202.071 259.497 201.758 259.552 201.6 259.774L174.808 300.084C174.744 300.174 174.712 300.283 174.718 300.393C174.725 300.534 175.49 314.446 174.121 318.032C174.005 318.338 173.71 319.038 173.269 320.062L174.358 320.062C174.715 319.229 174.956 318.651 175.062 318.376C176.435 314.778 175.821 302.201 175.73 300.508L202.423 260.337C202.582 260.114 202.527 259.808 202.299 259.652L202.299 259.653Z"
            fill="#014A4F"
          />
          <path
            d="M145.47 137.526C145.359 137.775 145.475 138.066 145.729 138.174C145.794 138.202 145.863 138.216 145.931 138.216C146.123 138.216 146.308 138.106 146.391 137.92C147.421 135.615 169.91 85.2661 174.361 74.875L173.272 74.875C168.415 86.1579 145.723 136.966 145.472 137.524L145.47 137.526Z"
            fill="#014A4F"
          />
          <path
            d="M218.088 298.438C217.847 298.307 217.541 298.391 217.405 298.629L205.25 320.063L206.399 320.063L218.281 299.108C218.416 298.871 218.329 298.571 218.087 298.438L218.088 298.438Z"
            fill="#014A4F"
          />
          <path
            d="M170.67 135.858L131.422 119.789C131.165 119.684 130.871 119.803 130.766 120.054C130.658 120.305 130.779 120.594 131.035 120.698L170.698 136.937C170.76 136.963 170.826 136.975 170.89 136.975C171.068 136.975 171.239 136.882 171.329 136.721L206.4 74.8765L205.251 74.8765L170.67 135.857L170.67 135.858Z"
            fill="#014A4F"
          />
          <path
            d="M210.446 280.714C210.22 280.617 209.957 280.697 209.824 280.9L190.568 310.464C190.544 310.501 190.525 310.541 190.511 310.582L188.209 317.772L187.238 320.063L188.325 320.063L189.145 318.13C189.152 318.116 189.156 318.102 189.161 318.088L191.449 310.94L210.42 281.814C211.378 282.355 214.146 284.159 218.377 288.882C224.284 295.474 221.897 307.451 221.607 308.796C221.362 309.924 218.934 314.461 215.805 320.063L216.95 320.063C220.031 314.538 222.329 310.203 222.59 309C222.893 307.607 225.362 295.186 219.133 288.232C213.651 282.113 210.573 280.767 210.445 280.714L210.446 280.714Z"
            fill="#014A4F"
          />
          <path
            d="M209.109 86.6978C204.433 94.9015 200.015 102.651 199.11 104.866C197.149 109.664 181.979 123.662 180.365 124.585C180.126 124.722 180.045 125.024 180.185 125.258C180.325 125.493 180.633 125.571 180.871 125.435C182.537 124.482 197.952 110.343 200.041 105.231C200.922 103.076 205.325 95.3527 209.986 87.1782C212.406 82.9333 214.858 78.6303 216.95 74.8765L215.804 74.8765C213.736 78.5768 211.362 82.7421 209.108 86.6978L209.109 86.6978Z"
            fill="#014A4F"
          />
          <path
            d="M156.932 146.386C156.825 146.637 156.947 146.926 157.203 147.03C157.265 147.056 157.331 147.068 157.395 147.068C157.593 147.068 157.779 146.953 157.859 146.763L188.325 74.8765L187.238 74.8765L156.932 146.386Z"
            fill="#014A4F"
          />
          <path
            d="M173.861 319.737L163.576 317.519C163.306 317.46 163.037 317.629 162.977 317.894C162.918 318.16 163.089 318.422 163.36 318.48L170.693 320.062L174.357 320.062L174.068 319.834C174.006 319.786 173.936 319.753 173.861 319.736L173.861 319.737Z"
            fill="#014A4F"
          />
          <path
            d="M206.102 92.9267L184.506 82.8966L174.357 74.8781L170.693 74.8781L173.529 75.4899L183.922 83.7012C183.952 83.7257 183.986 83.7456 184.021 83.7624L205.67 93.8184C205.739 93.8506 205.813 93.8659 205.885 93.8659C206.073 93.8659 206.253 93.7618 206.339 93.5844C206.458 93.3381 206.351 93.0444 206.1 92.9282L206.102 92.9267Z"
            fill="#014A4F"
          />
          <path
            d="M200.31 103.385L156.93 87.4519C156.672 87.3571 156.381 87.4856 156.283 87.7411C156.186 87.995 156.317 88.2795 156.577 88.3743L199.957 104.307C200.015 104.329 200.075 104.34 200.133 104.34C200.336 104.34 200.528 104.217 200.603 104.02C200.701 103.766 200.569 103.481 200.309 103.387L200.31 103.385Z"
            fill="#014A4F"
          />
          <path
            d="M191.538 114.68L151.538 99.5914C151.282 99.4935 150.989 99.6205 150.89 99.8744C150.79 100.128 150.919 100.413 151.179 100.511L191.178 115.599C191.238 115.622 191.299 115.633 191.359 115.633C191.561 115.633 191.751 115.512 191.827 115.316C191.926 115.063 191.798 114.778 191.538 114.68Z"
            fill="#014A4F"
          />
          <path
            d="M220.908 311.785L168.885 278.722C168.665 278.583 168.374 278.632 168.214 278.835L156.247 288.252C156.077 288.468 156.118 288.777 156.339 288.942C156.559 289.107 156.874 289.067 157.042 288.852L168.727 279.794L220.36 312.609C220.444 312.663 220.539 312.689 220.632 312.689C220.798 312.689 220.958 312.609 221.054 312.464C221.206 312.236 221.139 311.93 220.906 311.783L220.908 311.785Z"
            fill="#014A4F"
          />
          <path
            d="M215.783 285.624C215.539 285.494 215.234 285.584 215.103 285.823L206.201 302.328C206.07 302.567 206.16 302.866 206.405 302.995C206.481 303.035 206.562 303.055 206.643 303.055C206.822 303.055 206.995 302.961 207.086 302.796L215.987 286.291C216.118 286.052 216.028 285.753 215.783 285.624Z"
            fill="#014A4F"
          />
          <path
            d="M218.381 259.148C218.147 259.003 217.836 259.07 217.688 259.3L191.94 293.968C191.792 294.198 191.861 294.502 192.095 294.648C192.177 294.7 192.272 294.724 192.364 294.724C192.531 294.724 192.693 294.643 192.788 294.496L218.535 259.828C218.683 259.598 218.615 259.294 218.381 259.148Z"
            fill="#014A4F"
          />
          <path
            d="M175.309 300.737L162.257 298.4L147.899 289.924C147.676 289.791 147.385 289.851 147.232 290.059L144.392 293.944C144.23 294.166 144.282 294.473 144.508 294.631C144.733 294.788 145.048 294.738 145.209 294.518L147.778 291.002L161.816 299.29C161.868 299.321 161.925 299.342 161.985 299.353L175.127 301.705C175.158 301.712 175.189 301.713 175.218 301.713C175.456 301.713 175.667 301.546 175.712 301.309C175.762 301.042 175.58 300.785 175.308 300.737L175.309 300.737Z"
            fill="#014A4F"
          />
          <path
            d="M228.86 201.4C227.992 200.726 148.963 139.332 144.601 136.089C140.661 133.16 136.618 130.97 132.909 129.758C129.753 128.727 98.0199 123.854 97.7706 123.776C97.506 123.691 97.4264 123.818 97.3407 124.075C97.2551 124.334 97.3989 124.612 97.6635 124.696C97.9128 124.776 129.438 129.663 132.591 130.693C136.197 131.871 140.141 134.009 143.994 136.874C148.346 140.111 227.367 201.498 228.236 202.173C228.328 202.245 228.438 202.28 228.548 202.28C228.697 202.28 228.844 202.216 228.941 202.093C229.114 201.881 229.078 201.57 228.86 201.402L228.86 201.4Z"
            fill="#014A4F"
          />
          <path
            d="M153.734 147.265L153.665 147.227C153.191 146.962 152.733 146.626 152.181 146.135C151.646 145.659 151.048 145.062 150.321 144.331C148.851 142.849 144.753 139.65 142.986 138.864C141.322 138.122 126.085 132.271 125.436 132.024C125.181 131.926 124.895 132.045 124.789 132.293L97.047 188.68C96.94 188.93 97.0608 189.22 97.3178 189.324C97.3805 189.35 97.8945 189.247 97.974 189.059L125.528 133.117C128.142 134.122 141.089 139.101 142.57 139.762C144.109 140.449 148.075 143.482 149.6 145.021C150.339 145.765 150.948 146.373 151.505 146.867C152.114 147.407 152.626 147.784 153.165 148.085L153.235 148.123C154.22 148.674 154.716 148.951 153.871 150.727C153.139 152.264 151.257 156.012 149.61 157.584C148.031 159.091 144.793 163.781 144.657 163.98C144.501 164.205 144.562 164.511 144.791 164.664C145.021 164.815 145.333 164.757 145.489 164.531C145.522 164.483 148.805 159.726 150.31 158.288C152.013 156.662 153.842 153.113 154.781 151.141C155.927 148.735 155.028 147.99 153.732 147.267L153.734 147.265Z"
            fill="#014A4F"
          />
          <path
            d="M140.002 156.804C139.342 156.578 138.775 156.384 138.428 156.231C137.764 155.935 136.889 155.325 135.878 154.618C134.256 153.486 132.237 152.076 130.04 151.264C126.582 149.983 105.076 142.442 104.859 142.367C104.597 142.275 104.31 142.408 104.217 142.665C104.123 142.921 104.259 143.204 104.519 143.294C104.737 143.371 126.233 150.907 129.684 152.185C131.758 152.953 133.718 154.32 135.292 155.42C136.347 156.156 137.257 156.792 138.012 157.127C138.4 157.298 138.987 157.5 139.668 157.734C141.605 158.397 144.845 159.507 145.441 160.788C145.55 161.022 145.556 161.24 145.46 161.477C144.439 164.003 133.037 202.48 132.54 204.164C132.463 204.426 132.601 204.61 132.867 204.684C132.913 204.698 132.977 204.793 133.023 204.793C133.242 204.793 133.442 204.652 133.505 204.437C133.628 204.024 145.391 164.321 146.394 161.838C146.591 161.349 146.579 160.856 146.354 160.376C145.579 158.713 142.354 157.609 139.999 156.803L140.002 156.804Z"
            fill="#014A4F"
          />
          <path
            d="M152.034 146.044C151.777 145.942 151.484 146.061 151.379 146.314L145.466 160.474C145.36 160.726 145.484 161.014 145.74 161.116C145.802 161.141 145.867 161.153 145.929 161.153C146.127 161.153 146.315 161.038 146.394 160.847L152.308 146.687C152.414 146.435 152.29 146.147 152.034 146.044Z"
            fill="#014A4F"
          />
          <path
            d="M150.208 144.246C149.965 144.113 149.66 144.197 149.524 144.434L141.865 157.776C141.729 158.013 141.816 158.313 142.058 158.446C142.136 158.488 142.22 158.508 142.302 158.508C142.478 158.508 142.65 158.418 142.741 158.257L150.401 144.916C150.537 144.679 150.449 144.379 150.208 144.246Z"
            fill="#014A4F"
          />
          <path
            d="M144.822 139.953C144.568 139.846 144.271 139.961 144.162 140.212C141.808 145.587 137.58 154.424 136.254 155.255C136.02 155.402 135.951 155.706 136.101 155.935C136.197 156.082 136.36 156.163 136.525 156.163C136.616 156.163 136.711 156.139 136.795 156.085C138.818 154.817 144.45 142.052 145.086 140.602C145.195 140.353 145.077 140.062 144.823 139.955L144.822 139.953Z"
            fill="#014A4F"
          />
          <path
            d="M135.914 136.083C135.69 135.924 135.376 135.973 135.212 136.193L133.768 138.145L124.015 135.265C123.749 135.187 123.469 135.334 123.389 135.594C123.31 135.854 123.46 136.129 123.726 136.207L133.82 139.189C133.868 139.202 133.917 139.21 133.966 139.21C134.123 139.21 134.276 139.137 134.372 139.006L136.026 136.772C136.188 136.551 136.139 136.244 135.914 136.083Z"
            fill="#014A4F"
          />
          <path
            d="M134.149 138.027C133.897 137.917 133.599 138.027 133.485 138.277L128.621 151.238C128.508 151.486 128.621 151.778 128.875 151.888C128.941 151.917 129.011 151.931 129.08 151.931C129.273 151.931 129.455 151.822 129.539 151.639L134.403 138.678C134.516 138.43 134.403 138.138 134.149 138.027Z"
            fill="#014A4F"
          />
          <path
            d="M133.655 153.128C133.401 153.02 133.104 153.136 132.996 153.385L107.594 211.106C107.484 211.355 107.602 211.646 107.857 211.753C107.921 211.781 107.96 211.736 108.027 211.736C108.221 211.736 108.435 211.683 108.518 211.496L133.92 153.775C134.03 153.526 133.912 153.235 133.657 153.128L133.655 153.128Z"
            fill="#014A4F"
          />
          <path
            d="M130.565 151.473C130.308 151.374 130.016 151.501 129.915 151.755L127.615 157.563L121.263 154.638C121.133 154.579 120.982 154.576 120.85 154.632C120.717 154.689 120.616 154.797 120.572 154.932L116.977 165.828C116.892 166.086 117.037 166.365 117.3 166.447C117.35 166.464 117.404 166.472 117.454 166.472C117.667 166.472 117.863 166.339 117.932 166.131L121.35 155.766L127.673 158.678C127.8 158.736 127.943 158.741 128.073 158.689C128.202 158.638 128.304 158.537 128.355 158.41L130.851 152.111C130.952 151.857 130.824 151.573 130.565 151.475L130.565 151.473Z"
            fill="#014A4F"
          />
          <path
            d="M142.217 173.661L94.2462 157.794C93.9831 157.707 93.6986 157.847 93.6114 158.106C93.5242 158.364 93.6665 158.643 93.9296 158.729L141.9 174.596C141.952 174.613 142.006 174.622 142.059 174.622C142.27 174.622 142.466 174.49 142.535 174.284C142.622 174.025 142.48 173.747 142.217 173.661Z"
            fill="#014A4F"
          />
          <path
            d="M125.845 150.029C125.585 149.936 125.295 150.068 125.201 150.323C125.106 150.578 114.071 180.291 112.584 183.076C112.397 183.427 112.177 183.856 111.929 184.341C110.205 187.713 96.2593 204.047 96.0405 204.267C95.7774 204.53 96.6463 205.05 96.6891 205.009C100.348 201.443 111.042 188.274 112.827 184.783C113.072 184.303 113.289 183.879 113.474 183.534C114.992 180.693 125.754 151.709 126.144 150.663C126.239 150.407 126.106 150.124 125.846 150.031L125.845 150.029Z"
            fill="#014A4F"
          />
          <path
            d="M127.117 157.333C126.984 157.272 126.828 157.272 126.695 157.333C126.516 157.415 125.907 159.262 124.87 162.874C124.124 165.474 123.388 168.07 123.388 168.07C123.313 168.332 123.47 168.604 123.737 168.676C123.781 168.688 123.827 168.694 123.871 168.694C124.091 168.694 124.293 168.552 124.355 168.333C125.451 164.46 127.134 158.586 127.347 158.014C127.351 158.005 127.357 157.996 127.36 157.985C127.477 157.739 127.368 157.445 127.116 157.332L127.117 157.333Z"
            fill="#014A4F"
          />
          <path
            d="M161.656 149.255C161.411 149.128 161.107 149.22 160.977 149.46L151.399 167.238C151.39 167.255 151.381 167.273 151.375 167.292C151.305 167.471 149.654 171.725 149.46 175.338C149.27 178.877 144.502 212.521 144.493 212.786C144.484 213.058 144.701 213.284 144.978 213.294C144.984 213.294 144.989 213.294 144.995 213.294C145.264 213.294 145.487 213.084 145.496 212.818C145.506 212.553 150.272 178.92 150.462 175.39C150.641 172.075 152.161 168.037 152.3 167.671L161.862 149.919C161.992 149.679 161.897 149.381 161.653 149.254L161.656 149.255Z"
            fill="#014A4F"
          />
          <path
            d="M199.214 196.17C198.464 196.109 182.23 194.836 177.529 195.283C172.958 195.716 148.058 191.28 147.991 191.263C147.722 191.199 147.45 191.361 147.384 191.624C147.318 191.889 147.483 192.155 147.752 192.219C148.035 192.287 172.886 196.712 177.625 196.262C182.236 195.826 198.975 197.152 199.157 197.166C199.171 197.166 199.157 197.151 199.172 197.151C199.431 197.151 199.579 197.227 199.602 196.97C199.625 196.7 199.491 196.19 199.214 196.167L199.214 196.17Z"
            fill="#014A4F"
          />
          <path
            d="M192.082 173.315C191.804 173.294 191.562 173.491 191.536 173.762L189.61 196.043C189.586 196.314 189.789 196.554 190.066 196.579C190.082 196.579 190.097 196.58 190.112 196.58C190.369 196.58 190.588 196.388 190.612 196.132L192.538 173.851C192.563 173.58 192.359 173.34 192.082 173.315Z"
            fill="#014A4F"
          />
          <path
            d="M250.755 301.404C250.615 301.17 250.308 301.089 250.068 301.225C249.828 301.361 249.747 301.663 249.886 301.898L251.031 303.833L248.477 320.063L249.493 320.063L252.05 303.812C252.069 303.7 252.046 303.587 251.987 303.489L250.752 301.404L250.755 301.404Z"
            fill="#014A4F"
          />
          <path
            d="M244.871 97.7834L243.109 104.288L237.111 108.323L211.176 103.364C210.905 103.31 210.64 103.486 210.587 103.752C210.533 104.018 210.711 104.278 210.983 104.33L237.125 109.329C237.157 109.335 237.189 109.339 237.221 109.339C237.322 109.339 237.422 109.309 237.506 109.253L243.831 104.997C243.931 104.93 244.001 104.832 244.031 104.717L245.849 98.0113C245.853 97.9945 245.856 97.9776 245.859 97.9593L249.492 74.8765L248.477 74.8765L244.871 97.7834Z"
            fill="#014A4F"
          />
          <path
            d="M229.492 100.138L217.472 93.8858C217.241 93.7649 216.953 93.8399 216.813 94.0571L194.274 128.929C194.126 129.158 194.195 129.463 194.43 129.608C194.515 129.66 194.606 129.685 194.698 129.685C194.865 129.685 195.027 129.604 195.123 129.455L217.412 94.9688L229.022 101.006C229.269 101.133 229.572 101.042 229.702 100.803C229.832 100.563 229.738 100.265 229.494 100.138L229.492 100.138Z"
            fill="#014A4F"
          />
          <path
            d="M221.542 96.0028C221.288 95.8958 220.992 96.0105 220.882 96.2598L199.673 144.48C199.667 144.493 199.66 144.509 199.656 144.522L195.188 158.761C195.102 159.019 195.246 159.298 195.51 159.382C195.562 159.399 195.614 159.406 195.665 159.406C195.876 159.406 196.073 159.273 196.142 159.065L200.603 144.85L221.802 96.6514C221.913 96.4021 221.795 96.1114 221.539 96.0044L221.542 96.0028Z"
            fill="#014A4F"
          />
          <path
            d="M242.279 122.287L204.811 113.253C204.542 113.189 204.269 113.349 204.204 113.614C204.138 113.879 204.302 114.145 204.571 114.209L242.039 123.243C242.078 123.252 242.12 123.257 242.159 123.257C242.386 123.257 242.591 123.107 242.647 122.882C242.713 122.618 242.549 122.351 242.28 122.287L242.279 122.287Z"
            fill="#014A4F"
          />
          <path
            d="M230.543 190.399C227.64 186.896 226.679 183.251 227.604 179.255C228.21 176.643 236.086 123.589 236.421 121.331C236.461 121.062 236.272 120.813 235.996 120.773C235.722 120.735 235.467 120.92 235.427 121.189C235.345 121.741 227.221 176.461 226.624 179.037C225.625 183.346 226.651 187.265 229.763 191.018C233.673 195.736 251.359 222.92 251.446 223.019C251.545 223.134 251.686 223.192 251.828 223.192C251.943 223.192 252.059 223.154 252.154 223.074C252.365 222.898 252.39 222.586 252.211 222.38C252.124 222.28 234.445 195.106 230.543 190.397L230.543 190.399Z"
            fill="#014A4F"
          />
          <path
            d="M238.845 181.643C238.796 181.463 238.647 181.326 238.461 181.288L206.627 174.845L187.194 168.928C186.923 168.863 186.652 169.022 186.583 169.285C186.516 169.548 186.678 169.817 186.947 169.883L206.402 175.804L237.954 182.191L242.28 197.985C242.34 198.205 242.543 198.35 242.765 198.35C242.808 198.35 242.852 198.344 242.895 198.333C243.163 198.263 243.322 197.992 243.25 197.731L238.843 181.645L238.845 181.643Z"
            fill="#014A4F"
          />
          <path
            d="M235.245 180.916C235.175 180.653 234.899 180.496 234.632 180.566C234.364 180.635 234.203 180.904 234.274 181.167L238.786 197.981C238.846 198.202 239.049 198.349 239.271 198.349C239.314 198.349 239.357 198.344 239.4 198.332C239.667 198.263 239.828 197.994 239.758 197.731L235.245 180.916Z"
            fill="#014A4F"
          />
          <path
            d="M221.421 177.84C221.148 177.797 220.891 177.981 220.848 178.25L219.381 194.509C219.338 194.778 219.515 194.867 219.789 194.91C219.815 194.914 219.852 195.078 219.878 195.078C220.121 195.078 220.334 194.905 220.374 194.662L221.841 178.403C221.883 178.134 221.697 177.883 221.423 177.84L221.421 177.84Z"
            fill="#014A4F"
          />
          <path
            d="M248.371 196.302C248.186 196.098 247.87 196.082 247.661 196.264C247.455 196.446 247.437 196.756 247.622 196.96L263.948 214.704C264.047 214.812 264.185 214.868 264.323 214.868C264.442 214.868 264.561 214.826 264.658 214.742C264.864 214.56 264.883 214.25 264.697 214.046L248.371 196.302Z"
            fill="#014A4F"
          />
          <path
            d="M238.35 128.784L235.054 128.178C234.782 128.127 234.518 128.303 234.468 128.571C234.418 128.839 234.596 129.096 234.869 129.146L238.165 129.752C238.196 129.758 238.228 129.76 238.259 129.76C238.496 129.76 238.707 129.594 238.751 129.357C238.803 129.09 238.623 128.833 238.35 128.782L238.35 128.784Z"
            fill="#014A4F"
          />
          <path
            d="M4.06581 96.7386C3.86236 96.5535 3.54419 96.5657 3.35604 96.7661L-0.00158425 100.318L-0.00158431 101.768L4.09335 97.4331C4.2815 97.2327 4.26925 96.9222 4.06581 96.7371L4.06581 96.7386Z"
            fill="#014A4F"
          />
          <path
            d="M253.871 155.541C248.962 154.447 195.645 142.574 193.124 142.34C189.563 142.008 187.001 143.453 185.918 146.409L185.721 146.945C184.54 150.146 183.885 152.154 184.751 153.044C185.136 153.44 185.737 153.518 186.539 153.277C188.644 152.639 194.475 154.603 196.543 155.412C196.8 155.513 197.092 155.391 197.196 155.138C197.299 154.886 197.173 154.6 196.916 154.499C196.6 154.375 189.113 151.467 186.242 152.336C185.864 152.451 185.572 152.461 185.477 152.365C184.977 151.851 185.993 149.101 186.664 147.282L186.863 146.744C188.033 143.551 190.863 143.121 193.029 143.322C195.552 143.556 253.347 156.436 253.93 156.566C253.967 156.573 254.005 156.578 254.042 156.578C254.18 156.578 254.314 156.521 254.411 156.42L305.931 101.77L305.931 100.32L253.871 155.542L253.871 155.541Z"
            fill="#014A4F"
          />
          <path
            d="M230.087 160.168L223.329 159.291L223.329 155.669C223.329 155.424 223.145 155.218 222.899 155.183L218.482 154.549L220.15 148.712C220.225 148.451 220.069 148.178 219.803 148.105C219.535 148.032 219.259 148.185 219.184 148.446L217.36 154.822C217.322 154.958 217.343 155.105 217.423 155.223C217.501 155.34 217.628 155.421 217.772 155.441L222.324 156.094L222.324 159.723C222.324 159.969 222.511 160.179 222.76 160.211L229.954 161.144C229.977 161.147 229.998 161.148 230.021 161.148C230.269 161.148 230.485 160.968 230.518 160.72C230.555 160.451 230.361 160.203 230.087 160.168Z"
            fill="#014A4F"
          />
          <path
            d="M209.82 146.817C209.857 146.835 209.897 146.849 209.938 146.858C209.895 146.849 209.856 146.835 209.82 146.817Z"
            fill="#014A4F"
          />
          <path
            d="M212.237 157.358L207.814 154.736C210.659 146.436 210.631 146.387 210.484 146.135C210.414 146.012 210.293 145.925 210.154 145.895C209.883 145.835 209.616 146.005 209.556 146.269C209.55 146.297 209.547 146.324 209.545 146.352C209.406 146.936 207.76 151.802 206.734 154.796C206.659 155.015 206.749 155.255 206.949 155.374L211.717 158.199C211.798 158.247 211.889 158.27 211.976 158.27C212.146 158.27 212.312 158.186 212.406 158.033C212.549 157.8 212.473 157.497 212.236 157.356L212.237 157.358ZM209.821 146.815C209.784 146.797 209.749 146.774 209.72 146.75C209.749 146.776 209.782 146.797 209.821 146.815Z"
            fill="#014A4F"
          />
          <path
            d="M253.525 169.485L195.781 158.508C195.51 158.456 195.272 159.284 195.59 159.475L253.673 170.507C253.366 170.46 253.398 170.463 253.428 170.463C253.664 170.463 253.875 170.299 253.921 170.063C253.973 169.797 253.795 169.539 253.523 169.487L253.525 169.485Z"
            fill="#014A4F"
          />
          <path
            d="M293.669 143.722C293.613 143.765 288.011 148.038 285.604 149.956C283.277 151.808 279.503 157.138 278.953 157.921L262.06 167.96C261.823 168.101 261.747 168.404 261.891 168.636C261.985 168.789 262.151 168.873 262.32 168.873C262.409 168.873 262.499 168.85 262.58 168.801L279.566 158.707C279.627 158.67 279.681 158.621 279.72 158.563C279.762 158.504 283.89 152.588 286.237 150.719C288.637 148.807 294.229 144.542 294.286 144.5C294.505 144.333 294.544 144.022 294.375 143.808C294.205 143.594 293.89 143.556 293.669 143.721L293.669 143.722Z"
            fill="#014A4F"
          />
          <path
            d="M20.7179 113.55C20.5007 113.381 20.184 113.417 20.0127 113.629L4.39868e-06 138.378L4.32934e-06 139.964L20.8005 114.241C20.9733 114.028 20.9366 113.718 20.7179 113.55Z"
            fill="#014A4F"
          />
          <path
            d="M298.433 147.658L277.291 172.974L265.267 175.041C264.993 175.088 264.811 175.344 264.858 175.612C264.901 175.85 265.114 176.018 265.352 176.018C265.381 176.018 265.41 176.015 265.439 176.011L277.648 173.912C277.767 173.892 277.874 173.831 277.949 173.739L299.213 148.276L305.931 139.966L305.931 138.379L298.433 147.658Z"
            fill="#014A4F"
          />
          <path
            d="M283.873 176.583C283.694 176.375 283.378 176.347 283.165 176.523C282.952 176.698 282.925 177.008 283.104 177.216C283.18 177.306 290.809 186.224 291.831 188.943C292.811 191.552 292.903 193.431 290.471 195.32C287.909 197.308 275.633 207.631 275.534 207.716C275.324 207.894 275.3 208.204 275.482 208.411C275.581 208.524 275.722 208.582 275.862 208.582C275.979 208.582 276.095 208.542 276.19 208.463C276.291 208.379 288.55 198.069 291.095 196.092C293.981 193.851 293.854 191.477 292.775 188.603C291.693 185.724 284.193 176.955 283.875 176.583L283.873 176.583Z"
            fill="#014A4F"
          />
          <path
            d="M281.755 188.452C281.574 188.245 281.258 188.222 281.047 188.398C280.836 188.576 280.811 188.886 280.993 189.093L295.568 205.748C295.667 205.861 295.808 205.919 295.949 205.919C296.065 205.919 296.181 205.881 296.276 205.8C296.487 205.622 296.512 205.312 296.33 205.105L281.755 188.45L281.755 188.452Z"
            fill="#014A4F"
          />
          <path
            d="M26.9452 200.053L13.2821 189.068C13.1751 188.982 13.0374 188.943 12.9013 188.961C12.7651 188.978 12.6412 189.048 12.5601 189.157L0.00153457 190.091L0.00153453 191.121C6.51947 190.642 13.0542 190.157 13.0542 190.157L26.3088 200.814C26.523 200.986 26.8396 200.955 27.0155 200.746C27.1915 200.534 27.1593 200.225 26.9452 200.053Z"
            fill="#014A4F"
          />
          <path
            d="M292.946 191.058C292.669 191.072 292.457 191.303 292.47 191.575C292.484 191.846 292.715 192.051 292.995 192.042C293.2 192.049 299.557 191.589 305.931 191.121L305.931 190.091L292.946 191.058Z"
            fill="#014A4F"
          />
          <path
            d="M8.61518 196.4C8.40408 196.222 8.08744 196.245 7.90694 196.452C7.72644 196.658 7.7494 196.969 7.96049 197.146L20.0311 208.053C20.126 208.132 20.3478 208.166 20.464 208.166C20.6048 208.166 20.6399 208.114 20.7394 208.001C20.9199 207.794 20.8969 207.484 20.6858 207.306L8.61518 196.4Z"
            fill="#014A4F"
          />
          <path
            d="M213.473 253.319L209.118 253.873C209.027 253.885 208.941 253.92 208.869 253.977L202.067 259.329L190.401 255.246C190.129 255.187 189.863 255.355 189.803 255.621C189.744 255.887 189.915 256.149 190.186 256.208L202.085 260.342C202.12 260.349 202.157 260.354 202.194 260.354C202.307 260.354 202.418 260.316 202.509 260.245L209.389 254.832L213.603 254.295C213.879 254.26 214.073 254.013 214.036 253.743C214.001 253.473 213.75 253.284 213.473 253.317L213.473 253.319Z"
            fill="#014A4F"
          />
          <path
            d="M252.116 286.999L245.622 292.63C244.46 292.807 237.16 293.929 234.649 294.485C233.697 294.697 233.049 295.097 232.721 295.677C232.252 296.512 232.591 297.461 232.775 297.97C232.81 298.066 232.84 298.149 232.857 298.215C232.914 298.438 233.119 298.588 233.344 298.588C233.384 298.588 233.425 298.583 233.466 298.573C233.735 298.507 233.899 298.239 233.832 297.976C233.809 297.889 233.771 297.776 233.723 297.644C233.575 297.23 233.35 296.602 233.601 296.154C233.783 295.832 234.21 295.593 234.87 295.448C237.511 294.862 245.838 293.595 245.922 293.583C246.017 293.569 246.106 293.528 246.178 293.465L252.781 287.738C252.989 287.558 253.008 287.247 252.824 287.044C252.641 286.84 252.324 286.82 252.116 287.001L252.116 286.999Z"
            fill="#014A4F"
          />
          <path
            d="M244.518 306.307C244.33 306.106 244.013 306.093 243.808 306.276C243.603 306.46 243.59 306.772 243.778 306.972L247.626 311.09C247.726 311.196 247.86 311.249 247.996 311.249C248.117 311.249 248.24 311.206 248.336 311.119C248.541 310.936 248.555 310.623 248.367 310.423L244.518 306.305L244.518 306.307Z"
            fill="#014A4F"
          />
          <path
            d="M245.818 278.031C245.65 277.814 245.335 277.772 245.115 277.937C244.894 278.103 244.852 278.412 245.02 278.627L258.521 295.989L252.85 300.54L251.963 299.555C251.871 299.452 251.74 299.393 251.601 299.388C251.463 299.38 251.327 299.437 251.229 299.535L247.056 303.688L242.259 307.511L238.604 302.47C238.932 301.3 240.22 296.189 238.447 294.039C238.272 293.828 237.956 293.794 237.74 293.965C237.524 294.137 237.491 294.446 237.665 294.658C239.025 296.307 238.061 300.823 237.575 302.424C237.531 302.57 237.558 302.727 237.647 302.85L241.751 308.511C241.832 308.623 241.954 308.695 242.092 308.713C242.115 308.716 242.136 308.718 242.159 308.718C242.274 308.718 242.386 308.679 242.476 308.607L247.711 304.436C247.724 304.425 247.738 304.413 247.752 304.399L251.567 300.603L252.419 301.548C252.598 301.747 252.904 301.771 253.113 301.603L259.533 296.45C259.743 296.281 259.778 295.981 259.614 295.77L245.818 278.031Z"
            fill="#014A4F"
          />
          <path
            d="M240.142 249.378L228.567 229.323L214.419 190.436C214.292 190.194 213.989 190.098 213.743 190.223C213.496 190.347 213.398 190.644 213.525 190.885L227.684 229.79L239.291 249.902C239.307 249.928 239.323 249.951 239.342 249.974L295.563 316.328C295.663 316.445 295.806 316.505 295.95 316.505C296.063 316.505 296.177 316.468 296.271 316.392C296.484 316.218 296.515 315.907 296.337 315.699L240.143 249.378L240.142 249.378Z"
            fill="#014A4F"
          />
          <path
            d="M265.982 227.926L221.03 263.318C220.814 263.488 220.779 263.799 220.952 264.011C221.051 264.132 221.197 264.195 221.344 264.195C221.454 264.195 221.565 264.16 221.657 264.086L266.61 228.694C266.825 228.524 266.86 228.214 266.686 228.001C266.512 227.789 266.197 227.755 265.979 227.926L265.982 227.926Z"
            fill="#014A4F"
          />
          <path
            d="M250.73 240.552C250.551 240.344 250.234 240.317 250.022 240.491C249.809 240.665 249.782 240.976 249.959 241.186L287.685 285.294C287.785 285.41 287.927 285.47 288.071 285.47C288.184 285.47 288.299 285.431 288.394 285.355C288.606 285.18 288.634 284.87 288.456 284.662L250.73 240.554L250.73 240.552Z"
            fill="#014A4F"
          />
          <path
            d="M281.795 249.274L237.742 283.698C237.524 283.868 237.489 284.177 237.662 284.39C237.762 284.512 237.907 284.575 238.055 284.575C238.165 284.575 238.276 284.54 238.369 284.468L282.422 250.044C282.639 249.874 282.674 249.565 282.501 249.352C282.329 249.14 282.012 249.105 281.796 249.274L281.795 249.274Z"
            fill="#014A4F"
          />
          <path
            d="M292.779 261.867L256.294 292.356C256.082 292.532 256.056 292.843 256.237 293.051C256.336 293.165 256.477 293.223 256.619 293.223C256.734 293.223 256.85 293.185 256.945 293.106L293.431 262.616C293.642 262.44 293.668 262.13 293.487 261.922C293.307 261.715 292.99 261.689 292.779 261.867Z"
            fill="#014A4F"
          />
          <path
            d="M295.375 286.513C294.928 284.616 294.28 280.464 294.274 280.423C294.232 280.154 293.974 279.97 293.702 280.01C293.428 280.051 293.24 280.302 293.281 280.572C293.307 280.743 293.942 284.801 294.396 286.733C294.857 288.688 296.813 293.272 296.896 293.467C296.977 293.655 297.163 293.768 297.359 293.768C297.423 293.768 297.489 293.756 297.553 293.73C297.809 293.624 297.93 293.337 297.823 293.086C297.803 293.038 295.817 288.385 295.375 286.511L295.375 286.513Z"
            fill="#014A4F"
          />
          <path
            d="M258.903 307.838C258.686 308.006 258.648 308.317 258.82 308.529L268.107 320.063L269.387 320.063L259.608 307.919C259.437 307.705 259.12 307.668 258.903 307.838Z"
            fill="#014A4F"
          />
          <path
            d="M40.6754 132.485L15.5245 119.867L-0.000123024 108.578L-0.000123077 109.805L14.9601 120.684C14.983 120.701 15.006 120.715 15.0304 120.727L40.218 133.364C40.2914 133.4 40.3695 133.417 40.4459 133.417C40.628 133.417 40.8039 133.319 40.8941 133.149C41.0211 132.908 40.9232 132.611 40.6754 132.487L40.6754 132.485Z"
            fill="#014A4F"
          />
          <path
            d="M286.865 94.715L276.425 83.6172L269.387 74.8767L268.107 74.8767L275.648 84.2413C275.656 84.2505 275.665 84.2612 275.673 84.2704L286.157 95.4156C286.178 95.4385 286.201 95.4584 286.227 95.4768L305.931 109.805L305.931 108.578L286.865 94.7135L286.865 94.715Z"
            fill="#014A4F"
          />
          <path
            d="M265.393 301.883C265.179 302.056 265.149 302.366 265.325 302.576L279.99 320.063L281.29 320.063L266.1 301.95C265.924 301.741 265.608 301.71 265.393 301.883Z"
            fill="#014A4F"
          />
          <path
            d="M281.29 74.8765L279.99 74.8765L284.317 80.0361C284.416 80.1539 284.56 80.2151 284.706 80.2151C284.817 80.2151 284.93 80.1784 285.024 80.1034C285.238 79.9306 285.268 79.62 285.093 79.4105L281.29 74.8765Z"
            fill="#014A4F"
          />
          <path
            d="M290.719 309.846L278.307 320.063L279.869 320.063L291.364 310.601C291.577 310.426 291.604 310.116 291.425 309.908C291.246 309.7 290.93 309.672 290.717 309.846L290.719 309.846Z"
            fill="#014A4F"
          />
          <path
            d="M264.372 86.346L258.943 78.2877C258.865 78.1714 258.741 78.0934 258.6 78.072L242.236 75.5985C242.103 75.5786 241.967 75.6108 241.859 75.6903C241.752 75.7683 241.681 75.8861 241.662 76.0176C241.196 79.2942 239.043 81.37 237.621 82.7451C237.277 83.0771 236.98 83.3647 236.761 83.6186C235.637 84.9234 235.349 86.3628 235.95 87.6722C236.446 88.7491 243.597 93.9209 245.778 95.4797C245.866 95.5409 245.968 95.5745 246.074 95.5745C246.101 95.5745 246.129 95.573 246.156 95.5684L256.703 93.8582C256.792 93.8444 256.875 93.8062 256.945 93.7496L279.872 74.8781L278.31 74.8781L264.376 86.3475L264.372 86.346ZM256.401 92.9068L246.198 94.5619C241.841 91.4398 237.199 87.8879 236.862 87.2623C236.429 86.3231 236.665 85.2523 237.526 84.2534C237.716 84.0331 237.997 83.7624 238.323 83.4473C239.684 82.1318 241.922 79.9734 242.572 76.6463L258.227 79.0128L263.598 86.9839L256.402 92.9068L256.401 92.9068Z"
            fill="#014A4F"
          />
          <path
            d="M254.999 82.6121L240.728 79.5787C240.455 79.5221 240.189 79.6904 240.131 79.955C240.073 80.2212 240.244 80.4828 240.515 80.5409L254.787 83.5742C254.822 83.5819 254.859 83.5849 254.894 83.5849C255.125 83.5849 255.333 83.4274 255.385 83.1964C255.443 82.9303 255.272 82.6687 255.001 82.6105L254.999 82.6121Z"
            fill="#014A4F"
          />
          <path
            d="M41.7356 142.439L35.0785 139.508L10.5303 126.017C10.5212 126.012 10.5135 126.007 10.5043 126.003C10.3927 125.949 5.70116 123.716 7.16405e-05 120.995L7.15926e-05 122.09C5.37993 124.658 9.75937 126.743 10.0515 126.882L34.6074 140.379C34.6196 140.386 34.6334 140.392 34.6471 140.398L41.3257 143.339C41.393 143.368 41.4618 143.381 41.5307 143.381C41.7219 143.381 41.9896 142.551 41.7372 142.439L41.7356 142.439Z"
            fill="#014A4F"
          />
          <path
            d="M285.788 111.318C285.288 111.067 284.813 110.824 284.36 110.593C281.469 109.118 279.56 108.145 278.074 108.358C276.554 108.577 258.637 110.914 258.456 110.937C258.181 110.972 257.988 111.22 258.025 111.489C258.062 111.758 258.314 111.948 258.589 111.913C258.77 111.89 276.695 109.551 278.221 109.332C279.385 109.167 281.277 110.131 283.896 111.466C284.351 111.697 284.828 111.942 285.33 112.194C287.424 113.247 297.816 118.217 305.93 122.088L305.93 120.993C297.89 117.155 287.838 112.347 285.787 111.318L285.788 111.318Z"
            fill="#014A4F"
          />
          <path
            d="M270.134 109.322C269.849 109.311 269.625 109.525 269.615 109.797L269.109 124.551L249.971 145.39C249.786 145.592 249.803 145.904 250.008 146.086C250.104 146.17 250.224 146.213 250.345 146.213C250.482 146.213 250.618 146.158 250.718 146.049L269.978 125.077C270.057 124.992 270.103 124.88 270.106 124.765L270.619 109.833C270.628 109.56 270.411 109.332 270.134 109.323L270.134 109.322Z"
            fill="#014A4F"
          />
          <path
            d="M28.8542 147.297L5.5001e-06 134.235L5.45269e-06 135.32L28.4335 148.192C28.5024 148.223 28.5727 148.238 28.6431 148.238C28.8328 148.238 29.0148 148.133 29.099 147.952C29.2152 147.706 29.1051 147.412 28.8527 147.299L28.8542 147.297Z"
            fill="#014A4F"
          />
          <path
            d="M258.784 112.89C258.531 112.777 258.233 112.884 258.117 113.131C258.001 113.377 258.111 113.671 258.363 113.784L305.931 135.32L305.931 134.235L258.784 112.892L258.784 112.89Z"
            fill="#014A4F"
          />
          <path
            d="M286.148 94.7607C285.848 95.1019 278.775 103.137 277.724 104.789C276.502 106.712 276.975 107.702 278.498 109.195C278.596 109.291 278.725 109.339 278.853 109.339C278.982 109.339 279.11 109.291 279.208 109.195C279.404 109.002 279.404 108.69 279.208 108.499C277.814 107.133 277.72 106.657 278.576 105.311C279.586 103.723 286.835 95.4889 286.908 95.4063C287.09 95.1998 287.066 94.8892 286.856 94.7118C286.647 94.5344 286.329 94.5573 286.148 94.7623L286.148 94.7607Z"
            fill="#014A4F"
          />
          <path
            d="M278.63 116.199C278.365 116.279 278.217 116.556 278.298 116.816C278.559 117.648 279.203 120.055 279.04 121.195C278.844 122.558 278.507 123.083 276.92 124.501C275.2 126.033 274.038 128.273 273.989 128.368C273.863 128.611 273.963 128.906 274.211 129.028C274.282 129.064 274.36 129.082 274.437 129.082C274.621 129.082 274.796 128.983 274.885 128.811C274.896 128.79 276.016 126.638 277.594 125.23C279.369 123.647 279.802 122.956 280.034 121.333C280.262 119.743 279.298 116.658 279.257 116.526C279.174 116.266 278.893 116.121 278.628 116.201L278.63 116.199Z"
            fill="#014A4F"
          />
          <path
            d="M27.8445 126.061C27.6288 125.891 27.3122 125.925 27.1378 126.136L-0.000114505 159.272L-0.000114574 160.844L27.921 126.751C28.0938 126.538 28.0602 126.229 27.843 126.059L27.8445 126.061Z"
            fill="#014A4F"
          />
          <path
            d="M291.748 176.591C291.575 176.803 291.609 177.112 291.826 177.282C291.92 177.355 292.03 177.391 292.14 177.391C292.287 177.391 292.432 177.328 292.531 177.207L305.931 160.846L305.931 159.273L291.748 176.592L291.748 176.591Z"
            fill="#014A4F"
          />
          <path
            d="M37.7629 136.186L18.2305 125.033C17.9903 124.895 17.6829 124.976 17.5437 125.21C17.4045 125.446 17.4855 125.747 17.7242 125.883L37.2565 137.036C37.3361 137.082 37.4233 137.104 37.5089 137.104C37.6818 137.104 37.8501 137.016 37.9434 136.859C38.0826 136.623 38.0015 136.322 37.7629 136.186Z"
            fill="#014A4F"
          />
          <path
            d="M5.25588 164.411C5.23905 164.28 5.16869 164.159 5.06161 164.079L-6.96317e-05 160.304L-6.96858e-05 161.542L4.04284 164.557L-7.00324e-05 169.471L-7.0101e-05 171.041L5.14881 164.781C5.23447 164.677 5.27271 164.544 5.25588 164.413L5.25588 164.411Z"
            fill="#014A4F"
          />
          <path
            d="M298.901 178.018C298.785 178.16 298.757 178.354 298.831 178.521L300.789 182.977C300.871 183.164 301.056 183.275 301.251 183.275C301.316 183.275 301.384 183.261 301.449 183.235C301.705 183.128 301.821 182.838 301.713 182.588L299.875 178.406L305.931 171.039L305.931 169.47L298.901 178.016L298.901 178.018Z"
            fill="#014A4F"
          />
          <path
            d="M299.868 156.365L292.063 165.311L287.243 161.8C287.022 161.638 286.706 161.684 286.541 161.901C286.376 162.12 286.422 162.427 286.645 162.589L291.842 166.374C292.053 166.528 292.351 166.495 292.522 166.299L300.324 157.358L305.933 161.542L305.933 160.304L300.555 156.293C300.343 156.136 300.042 156.166 299.869 156.365L299.868 156.365Z"
            fill="#014A4F"
          />
          <path
            d="M44.9218 127.425C43.3799 125.848 37.6987 122.455 34.2845 122.137C32.3801 121.96 28.9612 119.33 26.2155 117.216C24.5359 115.925 23.0873 114.81 22.0196 114.235C19.2708 112.756 11.5536 106.405 8.50035 102.513C7.11905 100.752 5.20239 98.2101 3.34995 95.7519C2.20882 94.2376 1.04933 92.6987 0.00303066 91.3281L0.00303058 92.9756C0.822935 94.0571 1.68873 95.2043 2.54228 96.3378C4.39777 98.8006 6.31751 101.347 7.70492 103.114C10.8836 107.166 18.6528 113.547 21.5377 115.099C22.5336 115.635 24.0219 116.78 25.5975 117.992C28.5957 120.299 31.9946 122.913 34.1927 123.118C37.2245 123.401 42.7635 126.638 44.1998 128.106C44.2993 128.207 44.4308 128.257 44.5624 128.257C44.6878 128.257 44.8132 128.212 44.9096 128.12C45.11 127.932 45.1161 127.62 44.9249 127.424L44.9218 127.425Z"
            fill="#014A4F"
          />
          <path
            d="M303.386 88.0577C300.73 84.7735 298.664 85.8641 297.003 86.7422L296.729 86.886C295.038 87.764 284.592 92.6513 284.487 92.7003C284.236 92.8165 284.13 93.1117 284.25 93.3565C284.369 93.6012 284.667 93.7068 284.92 93.589C285.351 93.3871 295.49 88.6451 297.198 87.7563L297.478 87.6095C299.081 86.7636 300.466 86.0324 302.598 88.6696C303.441 89.7113 304.628 91.2578 305.93 92.9741L305.93 91.3266C304.943 90.0325 304.056 88.8868 303.384 88.0577L303.386 88.0577Z"
            fill="#014A4F"
          />
          <path
            d="M34.9193 112.227C34.7893 112.162 31.6978 110.643 29.2748 111.119C27.0093 111.563 20.4348 113.183 20.1564 113.251C19.8872 113.317 19.7235 113.585 19.7923 113.85C19.8489 114.073 20.0539 114.221 20.2788 114.221C20.3201 114.221 20.3599 114.217 20.4012 114.206C20.4685 114.189 27.2311 112.523 29.4721 112.084C31.5509 111.677 34.4359 113.089 34.4649 113.105C34.7128 113.227 35.0141 113.129 35.1395 112.887C35.265 112.644 35.1656 112.349 34.9193 112.225L34.9193 112.227Z"
            fill="#014A4F"
          />
          <path
            d="M55.989 112.253C55.9798 112.245 55.9706 112.237 55.9615 112.231L42.4392 102.418C42.3306 102.34 42.1944 102.307 42.0629 102.328C41.9298 102.348 41.8105 102.42 41.7325 102.527L33.0699 114.38C32.9093 114.601 32.9598 114.908 33.1846 115.067L46.9517 124.8C47.1766 124.96 47.4902 124.909 47.6523 124.689C47.8144 124.468 47.764 124.161 47.5391 124.002L34.1789 114.556L42.2541 103.509L55.3511 113.013L59.0743 116.141L55.4215 120.686C55.4215 120.686 55.4108 120.699 55.4062 120.705L51.4597 126.162C51.3113 126.367 51.3449 126.647 51.5346 126.813L56.4372 131.106C56.5336 131.19 56.6529 131.231 56.7722 131.231C56.9099 131.231 57.0475 131.176 57.147 131.066C57.3321 130.864 57.3137 130.552 57.1072 130.37L52.5396 126.37L56.2185 121.282L60.1619 116.375C60.3287 116.169 60.2981 115.869 60.0946 115.696L55.9921 112.249L55.989 112.253Z"
            fill="#014A4F"
          />
          <path
            d="M52.1573 118.206L38.0216 108.444C37.7952 108.288 37.4816 108.34 37.321 108.563C37.1619 108.785 37.2155 109.092 37.4419 109.248L51.5776 119.011C51.6647 119.072 51.7657 119.101 51.8667 119.101C52.0242 119.101 52.1803 119.029 52.2782 118.891C52.4372 118.67 52.3837 118.362 52.1573 118.206Z"
            fill="#014A4F"
          />
          <path
            d="M58.2422 127.372L36.0773 111.127C35.8555 110.965 35.5404 111.009 35.3736 111.226C35.2069 111.443 35.2528 111.752 35.4746 111.914L57.3351 127.936L55.4398 132.747C55.3404 133.001 55.4689 133.286 55.7274 133.383C55.7871 133.406 55.8483 133.417 55.9079 133.417C56.1098 133.417 56.2995 133.298 56.376 133.102L58.4089 127.944C58.49 127.737 58.4212 127.505 58.2407 127.372L58.2422 127.372Z"
            fill="#014A4F"
          />
          <path
            d="M94.7463 174.057C94.7295 173.989 94.6974 173.923 94.6515 173.866L92.4992 171.194C92.8541 168.05 97.2076 129.539 97.642 126.512C97.9632 124.268 97.8332 123.213 97.177 122.751C96.5467 122.307 95.6886 122.641 95.0966 122.931C94.6484 123.151 93.2641 123.74 91.5126 124.487C85.9232 126.867 76.5677 130.853 73.0127 133.139C69.6123 135.325 65.3781 135.583 60.0686 133.926C57.2724 133.055 42.277 130.35 41.4097 130.557C41.1405 130.622 40.9768 130.89 41.0426 131.153C41.1053 131.401 41.3501 131.557 41.6024 131.522L41.6101 131.522C42.661 131.522 56.506 133.85 59.7642 134.866C61.8278 135.51 68.1101 137.468 73.5634 133.963C77.048 131.724 86.7186 127.604 91.9134 125.391C93.7474 124.609 95.0737 124.045 95.5463 123.814C96.3418 123.424 96.5559 123.526 96.5911 123.552C96.7165 123.641 96.975 124.094 96.6477 126.378C96.1964 129.532 91.5264 170.873 91.4789 171.289C91.4636 171.417 91.5019 171.546 91.5845 171.648L93.7948 174.394L99.2803 200.242C99.3353 200.469 99.5418 200.62 99.7682 200.62C99.8065 200.62 99.8462 200.616 99.886 200.606C100.155 200.544 100.322 200.278 100.258 200.013L94.7463 174.059L94.7463 174.057Z"
            fill="#014A4F"
          />
          <path
            d="M49.8963 131.871C49.6761 131.707 49.361 131.748 49.1927 131.965L31.3078 154.985C31.1395 155.203 31.1839 155.512 31.4041 155.675C31.4944 155.743 32.0084 155.709 32.1078 155.581L49.9927 132.56C50.161 132.343 50.1166 132.034 49.8963 131.871Z"
            fill="#014A4F"
          />
          <path
            d="M51.7777 132.44C51.5055 132.489 51.3234 132.744 51.3724 133.012C51.6187 134.347 51.0557 136.593 49.237 138.367C47.3983 140.161 35.5495 156.099 35.06 156.761C34.8963 156.982 34.9468 157.289 35.1716 157.45C35.2603 157.512 35.3644 157.543 35.4669 157.543C35.6229 157.543 35.7759 157.473 35.8738 157.34C35.9931 157.179 48.1631 140.804 49.9467 139.065C52.0332 137.029 52.6634 134.469 52.3621 132.836C52.3131 132.568 52.0516 132.389 51.7777 132.44Z"
            fill="#014A4F"
          />
          <path
            d="M74.5041 184.656L41.8333 160.264C41.8104 160.248 41.7844 160.231 41.7584 160.218L25.2226 151.889C24.9763 151.765 24.6735 151.86 24.5465 152.102C24.4195 152.344 24.5174 152.64 24.7637 152.766L41.2597 161.075L73.8937 185.44C73.984 185.507 74.091 185.541 74.1981 185.541C74.3496 185.541 74.4995 185.473 74.5974 185.346C74.7656 185.131 74.7228 184.822 74.5025 184.656L74.5041 184.656Z"
            fill="#014A4F"
          />
          <path
            d="M30.3258 154.468C30.107 154.3 29.7919 154.338 29.6206 154.553L18.584 168.384C18.4127 168.598 18.4509 168.907 18.6697 169.075C18.7614 169.146 18.8701 169.179 18.9787 169.179C19.1286 169.179 19.2754 169.115 19.3749 168.99L30.4114 155.158C30.5828 154.944 30.5445 154.635 30.3258 154.467L30.3258 154.468Z"
            fill="#014A4F"
          />
          <path
            d="M33.0241 156.041L21.1768 170.435C21.0024 170.646 21.0376 170.957 21.2533 171.128C21.3466 171.202 21.4567 171.237 21.5684 171.237C21.7153 171.237 21.8606 171.174 21.96 171.053L33.8685 156.555C34.0429 156.344 33.1985 155.83 33.0257 156.041L33.0241 156.041Z"
            fill="#014A4F"
          />
          <path
            d="M60.0748 145.332C59.7306 145.067 59.4231 144.813 59.1509 144.591C58.204 143.816 57.5799 143.303 56.8319 143.406C56.3271 143.476 55.885 143.823 55.3971 144.53C54.2116 146.256 28.5207 175.749 28.2622 176.048C28.0817 176.254 28.1077 176.565 28.3173 176.742C28.4121 176.822 28.5284 176.86 28.6431 176.86C28.7854 176.86 28.9261 176.802 29.0255 176.687C30.0856 175.469 55.0116 146.852 56.2292 145.081C56.604 144.535 56.8395 144.399 56.9711 144.38C57.277 144.336 57.8185 144.783 58.5053 145.345C58.7822 145.573 59.0973 145.832 59.4537 146.106C61.5372 147.71 73.7287 159.215 73.851 159.331C74.0514 159.521 74.368 159.513 74.5608 159.319C74.7535 159.123 74.7474 158.811 74.547 158.623C74.0438 158.147 62.1964 146.967 60.0733 145.332L60.0748 145.332Z"
            fill="#014A4F"
          />
          <path
            d="M79.2187 149.235C79.0413 149.119 78.9938 148.907 75.1238 150.014C74.9616 150.06 74.8331 150.184 74.7842 150.343C74.7352 150.502 74.7704 150.675 74.879 150.802C76.9762 153.268 76.606 156.385 73.8082 159.819C70.0514 164.43 55.7153 182.299 55.5715 182.48C55.4002 182.694 55.4384 183.003 55.6556 183.171C55.7474 183.242 55.8575 183.277 55.9661 183.277C56.1145 183.277 56.2629 183.212 56.3608 183.089C56.5046 182.908 70.8392 165.041 74.593 160.434C78.1816 156.03 77.5299 152.819 76.1089 150.767C77.4336 150.416 78.8057 150.13 79.1453 150.184C79.3686 150.291 79.4497 149.387 79.2187 149.237L79.2187 149.235Z"
            fill="#014A4F"
          />
          <path
            d="M59.3604 184.701C59.3451 184.686 59.3283 184.672 59.3115 184.658L28.9505 161.739C28.7303 161.574 28.4152 161.614 28.2469 161.829C28.0771 162.045 28.1184 162.354 28.3387 162.519L58.6736 185.418C58.9596 185.7 61.7819 188.475 63.2198 189.807C64.0733 190.598 64.6531 198.372 64.7204 205.431C64.7234 205.702 64.9468 205.919 65.2221 205.919L65.2267 205.919C65.5036 205.916 65.7269 205.694 65.7238 205.422C65.7009 202.954 65.502 190.569 63.9081 189.091C62.4121 187.704 59.391 184.73 59.3604 184.699L59.3604 184.701Z"
            fill="#014A4F"
          />
          <path
            d="M73.6017 167.995L44.6114 145.41C44.3942 145.24 44.0791 145.275 43.9062 145.486C43.7334 145.699 43.7685 146.008 43.9842 146.178L72.5768 168.451L28.3387 221.07C28.1628 221.28 28.1934 221.59 28.4076 221.763C28.5009 221.838 28.6141 221.875 28.7257 221.875C28.8711 221.875 29.0148 221.814 29.1143 221.696L73.6767 168.691C73.7623 168.589 73.8021 168.459 73.7883 168.327C73.7746 168.196 73.7073 168.076 73.6017 167.994L73.6017 167.995Z"
            fill="#014A4F"
          />
          <path
            d="M82.746 160.558C80.1792 148.163 72.9408 133.978 72.8689 133.838C72.7434 133.594 72.4421 133.497 72.1943 133.619C71.9465 133.741 71.8471 134.037 71.971 134.28C72.0429 134.421 79.2216 148.489 81.7609 160.754C84.3108 173.066 89.088 210.353 89.1278 210.676C89.1599 210.926 89.3756 211.108 89.6249 211.108C89.6463 211.108 89.9186 210.737 89.94 210.734C90.2153 210.701 90.1587 210.823 90.1236 210.554C90.0823 210.231 85.3005 172.906 82.7429 160.557L82.746 160.558Z"
            fill="#014A4F"
          />
          <path
            d="M93.2213 158.941L82.3714 161.291C82.1006 161.349 81.9293 161.612 81.989 161.878C82.041 162.108 82.249 162.264 82.4785 162.264C82.5152 162.264 82.5504 162.261 82.5871 162.251L93.437 159.902C93.7078 159.844 93.8791 159.581 93.8194 159.314C93.7598 159.048 93.4906 158.882 93.2213 158.94L93.2213 158.941Z"
            fill="#014A4F"
          />
          <path
            d="M95.2603 140.752L77.7104 145.896C77.4442 145.974 77.2943 146.248 77.3723 146.51C77.4381 146.724 77.6369 146.861 77.8526 146.861C77.9 146.861 77.949 146.855 77.9964 146.84L95.5464 141.696C95.8125 141.618 95.9624 141.344 95.8844 141.082C95.8049 140.822 95.5249 140.674 95.2588 140.752L95.2603 140.752Z"
            fill="#014A4F"
          />
          <path
            d="M90.9957 125.506C91.0217 125.235 90.8198 124.995 90.5445 124.969C90.2676 124.94 90.0228 125.14 89.9968 125.411C89.977 125.608 88.0343 145.246 87.4102 148.763C86.7646 152.394 88.6324 160.371 88.7119 160.709C88.7654 160.937 89.7536 160.752 89.6909 160.488C89.6725 160.407 87.7895 152.368 88.4014 148.933C89.0332 145.379 90.9192 126.316 90.9988 125.506L90.9957 125.506Z"
            fill="#014A4F"
          />
          <path
            d="M80.5831 129.884C80.4975 129.625 80.2145 129.484 79.9514 129.567C79.6867 129.651 79.543 129.928 79.6286 130.186L84.3446 144.478C84.4134 144.686 84.6108 144.819 84.8219 144.819C84.8723 144.819 84.9259 144.812 84.9764 144.795C85.2395 144.711 85.3848 144.434 85.2991 144.175L80.5831 129.884Z"
            fill="#014A4F"
          />
          <path
            d="M89.4414 133.114L82.2458 136.788C81.9996 136.914 81.9047 137.211 82.0317 137.452C82.122 137.621 82.2963 137.717 82.4768 137.717C82.5548 137.717 82.6344 137.699 82.7078 137.662L89.9034 133.988C90.1497 133.862 90.2445 133.565 90.1175 133.324C89.9891 133.082 89.6862 132.989 89.4399 133.114L89.4414 133.114Z"
            fill="#014A4F"
          />
          <path
            d="M83.6655 168.197C83.4054 168.219 83.2081 168.433 83.2066 168.685C83.0031 169.611 78.4095 176.291 76.4439 179.151C75.6882 180.251 75.0901 181.12 74.8393 181.505C74.0668 182.695 74.3192 184.932 74.5639 187.095C74.6924 188.234 74.8148 189.313 74.7628 190.079C74.6113 192.316 75.0733 212.858 75.0978 213.352C75.1115 213.615 75.3333 213.82 75.5995 213.82C75.6072 213.82 75.7953 213.82 75.8045 213.82C76.0813 213.806 76.1165 213.575 76.1028 213.303C76.0967 213.182 75.6194 192.325 75.7678 190.145C75.8259 189.291 75.6989 188.172 75.5659 186.986C75.3517 185.086 75.1069 182.931 75.6913 182.033C75.9345 181.658 76.528 180.794 77.2806 179.7C82.6513 171.888 84.2712 169.305 84.2131 168.644C84.1886 168.373 83.9454 168.17 83.6685 168.196L83.6655 168.197Z"
            fill="#014A4F"
          />
          <path
            d="M87.3719 193.366L64.7587 195.132C64.4818 195.153 64.2753 195.39 64.2983 195.662C64.3197 195.919 64.5384 196.115 64.7985 196.115C64.8123 196.115 64.826 196.115 64.8383 196.115L87.4514 194.35C87.7283 194.329 87.9348 194.092 87.9118 193.819C87.8904 193.548 87.6503 193.348 87.3719 193.368L87.3719 193.366Z"
            fill="#014A4F"
          />
          <path
            d="M88.233 199.779L65.1855 201.391C64.9086 201.411 64.7005 201.647 64.7204 201.917C64.7388 202.176 64.9591 202.375 65.2206 202.375C65.2329 202.375 65.2451 202.375 65.2573 202.375L88.3049 200.762C88.5818 200.743 88.7898 200.507 88.7699 200.236C88.75 199.965 88.5068 199.762 88.233 199.78L88.233 199.779Z"
            fill="#014A4F"
          />
          <path
            d="M56.5597 137.13L57.8676 134.26C57.9807 134.012 57.8676 133.72 57.6136 133.61C57.3597 133.5 57.0629 133.61 56.9497 133.859L55.5057 137.026C55.4231 137.205 55.4583 137.416 55.5945 137.559L67.0701 149.783L66.3221 151.414L17.4414 210.799C17.267 211.01 17.3007 211.32 17.5179 211.492C17.6112 211.565 17.7213 211.6 17.833 211.6C17.9798 211.6 18.1252 211.537 18.2246 211.417L67.145 151.981C67.1725 151.949 67.194 151.912 67.2123 151.874L68.124 149.889C68.2066 149.71 68.1714 149.499 68.0353 149.353L56.5582 137.13L56.5597 137.13Z"
            fill="#014A4F"
          />
          <path
            d="M273.741 204.541L263.686 211.625C263.466 211.788 263.422 212.097 263.59 212.315C263.689 212.441 263.839 212.509 263.991 212.509C264.096 212.509 264.203 212.477 264.294 212.408L274.348 205.324C274.569 205.16 274.613 204.851 274.445 204.634C274.276 204.417 273.961 204.376 273.741 204.539L273.741 204.541Z"
            fill="#014A4F"
          />
          <path
            d="M6.69541 225.947L3.70724e-05 221.757L3.70213e-05 222.926L6.15544 226.778C6.23957 226.83 6.33288 226.856 6.42466 226.856C6.58986 226.856 6.75201 226.775 6.84838 226.628C6.99829 226.398 6.92945 226.094 6.69541 225.947Z"
            fill="#014A4F"
          />
          <path
            d="M297.699 216.605L285.096 201.249C284.921 201.038 284.606 201.004 284.389 201.174C284.173 201.344 284.138 201.654 284.313 201.867L296.966 217.286C297 217.327 297.041 217.364 297.087 217.393L305.931 222.926L305.931 221.757L297.699 216.605Z"
            fill="#014A4F"
          />
          <path
            d="M297.03 216.605L271.993 237.909C271.783 238.086 271.76 238.398 271.942 238.603C272.041 238.715 272.181 238.773 272.321 238.773C272.438 238.773 272.555 238.733 272.65 238.652L297.688 217.349C297.898 217.171 297.92 216.859 297.738 216.654C297.556 216.449 297.24 216.426 297.03 216.605Z"
            fill="#014A4F"
          />
          <path
            d="M46.1655 286.418L28.299 280.853L29.1403 275.409C29.1617 275.268 29.1204 275.124 29.0256 275.016L15.1132 258.982C15.0949 258.96 15.075 258.942 15.0536 258.924L-0.00300015 246.484L-0.0030002 247.772L14.3744 259.65L28.1108 275.482L27.2435 281.092C27.2083 281.318 27.3368 281.538 27.5556 281.622L45.7923 287.331C45.8535 287.354 45.9162 287.366 45.9774 287.366C46.1778 287.366 46.3659 287.249 46.444 287.056C46.5464 286.803 46.4195 286.517 46.1625 286.416L46.1655 286.418Z"
            fill="#014A4F"
          />
          <path
            d="M299.14 240.874C298.927 240.698 298.61 240.725 298.431 240.933C298.252 241.141 298.28 241.452 298.491 241.628L305.931 247.772L305.931 246.484L299.14 240.872L299.14 240.874Z"
            fill="#014A4F"
          />
          <path
            d="M23.2954 291.212C23.5723 291.212 23.7971 290.992 23.7971 290.719L23.7971 272.079C23.7971 271.973 23.7619 271.871 23.6992 271.787L18.3806 264.729C18.2154 264.51 17.9018 264.464 17.6784 264.625L5.59862 273.339C5.48542 273.422 5.41201 273.546 5.39671 273.683C5.38141 273.821 5.42576 273.959 5.51907 274.061L10.3819 279.461C10.5655 279.664 10.8836 279.684 11.0901 279.504C11.2982 279.323 11.3181 279.013 11.1345 278.809L6.63574 273.812L17.8696 265.708L22.7921 272.239L22.7921 290.718C22.7921 290.99 23.017 291.21 23.2939 291.21L23.2954 291.212Z"
            fill="#014A4F"
          />
          <path
            d="M5.83269 284.576C5.94894 284.576 6.06673 284.536 6.16157 284.455L23.6258 269.541C23.8354 269.362 23.8568 269.052 23.6748 268.847C23.4928 268.642 23.1746 268.62 22.9665 268.799L5.50228 283.713C5.29271 283.892 5.2713 284.203 5.45333 284.408C5.55276 284.52 5.69196 284.576 5.83269 284.576Z"
            fill="#014A4F"
          />
          <path
            d="M46.5709 296.283C46.6963 296.283 46.8217 296.281 46.9502 296.277C51.5362 296.128 78.7689 293.822 79.0443 293.799C79.3212 293.776 79.5246 293.537 79.5017 293.266C79.4772 292.996 79.2324 292.794 78.9586 292.818C78.6848 292.841 51.4781 295.146 46.9181 295.293C43.1077 295.415 40.5256 293.436 38.82 292.125C38.5401 291.909 38.2739 291.706 38.0292 291.53C36.0467 290.114 24.1963 290.043 22.1129 290.228C19.7878 290.435 18.6972 289.396 18.4677 288.445C18.2612 287.581 18.9786 279.978 19.4804 275.387C19.4957 275.253 19.4528 275.118 19.3626 275.016L9.25299 263.383C9.07249 263.176 8.75585 263.15 8.54475 263.328C8.33366 263.503 8.30765 263.814 8.48816 264.022L18.457 275.495C18.2475 277.431 17.1874 287.415 17.4872 288.671C17.8084 290.008 19.2341 291.477 22.2001 291.21C24.8434 290.975 35.8846 291.218 37.4341 292.327C37.6651 292.492 37.9236 292.691 38.1974 292.901C39.9627 294.256 42.6044 296.283 46.5693 296.284L46.5709 296.283Z"
            fill="#014A4F"
          />
          <path
            d="M4.75728 288.257C4.83835 288.257 4.91943 288.237 4.99592 288.197L18.5641 281L27.7054 281.659C27.9838 281.677 28.2225 281.476 28.2423 281.205C28.2622 280.934 28.0542 280.697 27.7788 280.677L18.4907 280.009C18.3958 280.001 18.2995 280.022 18.2153 280.067L4.51713 287.333C4.27238 287.463 4.18212 287.761 4.31367 288C4.40392 288.165 4.57678 288.258 4.75575 288.258L4.75728 288.257Z"
            fill="#014A4F"
          />
          <path
            d="M28.6447 275.828C28.7594 275.828 28.8757 275.79 28.9705 275.71L36.6892 269.269C36.8391 269.143 36.9003 268.945 36.8468 268.759L36.342 267.02L38.0797 265.374C38.1776 265.281 38.2327 265.154 38.2327 265.019L38.2327 259.86C38.2327 259.774 38.2097 259.69 38.1669 259.615L36.0468 255.984L35.1794 252.464C35.1672 252.413 35.1473 252.366 35.1198 252.322L33.6146 249.921C33.5458 249.811 33.4356 249.732 33.3071 249.701L31.343 249.224L29.9357 247.309C29.7736 247.089 29.46 247.038 29.2351 247.197C29.0103 247.356 28.9583 247.664 29.1204 247.884L30.6348 249.946C30.7036 250.041 30.8061 250.108 30.9208 250.136L32.8635 250.608L34.2188 252.771L35.0846 256.283C35.0953 256.329 35.1137 256.372 35.1366 256.412L37.2261 259.99L37.2261 264.81L35.4242 266.515C35.2911 266.642 35.2391 266.829 35.2896 267.005L35.7883 268.723L28.3143 274.958C28.1032 275.134 28.0772 275.444 28.2577 275.652C28.3571 275.767 28.4978 275.825 28.6401 275.825L28.6447 275.828Z"
            fill="#014A4F"
          />
          <path
            d="M22.1557 268.349C22.275 268.349 22.3928 268.308 22.4891 268.226L35.909 256.539C36.117 256.358 36.1354 256.046 35.9503 255.844C35.7667 255.641 35.4485 255.623 35.242 255.803L21.8222 267.491C21.6142 267.672 21.5958 267.984 21.7809 268.186C21.8803 268.296 22.018 268.351 22.1572 268.351L22.1557 268.349Z"
            fill="#014A4F"
          />
          <path
            d="M8.12857 276.708C8.23871 276.708 8.34884 276.672 8.44215 276.599L15.3501 271.181C15.5674 271.011 15.6025 270.701 15.4282 270.489C15.2553 270.277 14.9387 270.243 14.723 270.413L7.81498 275.831C7.59777 276.001 7.56259 276.311 7.73697 276.523C7.8364 276.643 7.98173 276.708 8.13011 276.708L8.12857 276.708Z"
            fill="#014A4F"
          />
          <path
            d="M76.4177 172.893C76.2005 172.724 75.8854 172.759 75.7126 172.97L69.0279 181.17C68.855 181.383 68.8902 181.694 69.1059 181.863C69.1992 181.935 69.3093 181.97 69.4195 181.97C69.5663 181.97 69.7132 181.908 69.8126 181.785L76.4973 173.585C76.6701 173.372 76.6349 173.062 76.4177 172.892L76.4177 172.893Z"
            fill="#014A4F"
          />
          <path
            d="M74.4521 175.189L70.3495 172.852C70.1093 172.714 69.8019 172.795 69.6627 173.031C69.5235 173.267 69.6045 173.568 69.8447 173.704L73.9473 176.041C74.0268 176.087 74.114 176.109 74.1997 176.109C74.3725 176.109 74.5408 176.021 74.6341 175.864C74.7733 175.628 74.6922 175.327 74.4521 175.191L74.4521 175.189Z"
            fill="#014A4F"
          />
          <path
            d="M71.7539 156.381L52.6636 180.254C52.4922 180.468 52.5305 180.777 52.7492 180.946C52.841 181.016 52.9496 181.051 53.0582 181.051C53.2066 181.051 53.355 180.987 53.4544 180.863L72.5447 156.989C72.716 156.775 71.9252 156.168 71.7539 156.382L71.7539 156.381Z"
            fill="#014A4F"
          />
          <path
            d="M69.7193 187.184C69.7009 187.17 67.7919 185.785 65.8951 184.435C64.7799 183.641 63.8866 183.012 63.238 182.568C62.8495 182.302 62.5466 182.1 62.334 181.966C62.0128 181.762 61.6135 181.51 61.3106 181.872C61.1347 182.082 61.1653 182.393 61.3795 182.565C61.4391 182.613 61.5065 182.645 61.5753 182.662C62.1872 182.989 66.3035 185.932 68.9391 187.844L69.5969 195.253C69.6198 195.51 69.8386 195.702 70.0971 195.702C70.1124 195.702 70.1262 195.702 70.1415 195.701C70.4183 195.678 70.6218 195.439 70.5988 195.167L69.9212 187.537C69.9089 187.396 69.8355 187.268 69.7193 187.184ZM61.8812 182.642C61.9195 182.627 61.9562 182.608 61.9898 182.584C62.0235 182.561 62.0556 182.532 62.0847 182.497C62.0556 182.53 62.0235 182.559 61.9898 182.584C61.9546 182.608 61.9179 182.628 61.8812 182.642Z"
            fill="#014A4F"
          />
          <path
            d="M61.9899 182.584C62.0251 182.559 62.0572 182.53 62.0847 182.497C62.0557 182.532 62.0235 182.559 61.9899 182.584Z"
            fill="#014A4F"
          />
          <path
            d="M86.0761 186.194L76.7451 186.194C76.4683 186.194 76.2434 186.414 76.2434 186.686C76.2434 186.959 76.4683 187.179 76.7451 187.179L86.0761 187.179C86.353 187.179 86.5779 186.959 86.5779 186.686C86.5779 186.414 86.353 186.194 86.0761 186.194Z"
            fill="#014A4F"
          />
          <path
            d="M69.0326 154.176L49.2095 177.632C49.0321 177.842 49.0627 178.152 49.2753 178.325C49.3686 178.402 49.4818 178.438 49.595 178.438C49.7388 178.438 49.8826 178.377 49.982 178.261L69.805 154.805C69.9825 154.595 69.9519 154.285 69.7393 154.112C69.5251 153.939 69.2085 153.968 69.0326 154.176Z"
            fill="#014A4F"
          />
          <path
            d="M63.2153 148.342C62.9843 148.365 62.2791 148.434 57.0017 155.646C56.8396 155.867 56.8916 156.176 57.1165 156.333C57.2052 156.396 57.3077 156.425 57.4086 156.425C57.5646 156.425 57.7191 156.353 57.817 156.22C60.0611 153.153 62.6829 149.824 63.3499 149.338C63.4738 149.33 63.5946 149.278 63.6864 149.182C63.8745 148.983 63.8638 148.671 63.6604 148.486C63.5411 148.377 63.382 148.327 63.2153 148.342Z"
            fill="#014A4F"
          />
          <path
            d="M60.0672 145.326C59.8454 145.162 59.5303 145.205 59.3635 145.422L53.5646 152.937C53.3978 153.154 53.4407 153.463 53.6625 153.627C53.7527 153.694 53.8598 153.727 53.9653 153.727C54.1168 153.727 54.2682 153.659 54.3661 153.531L60.1651 146.015C60.3318 145.798 60.289 145.489 60.0672 145.326Z"
            fill="#014A4F"
          />
          <path
            d="M47.2394 164.3C47.0207 164.133 46.704 164.173 46.5342 164.388L33.4907 179.989C33.3209 180.205 33.3622 180.514 33.581 180.679C33.6728 180.748 33.7798 180.782 33.8869 180.782C34.0368 180.782 34.1852 180.716 34.2846 180.591L47.3281 164.99C47.4979 164.774 47.4566 164.465 47.2379 164.3L47.2394 164.3Z"
            fill="#014A4F"
          />
          <path
            d="M56.278 188.626L44.1661 179.148C43.9489 178.978 43.6338 179.014 43.4609 179.226C43.2881 179.439 43.3232 179.749 43.5405 179.918L55.6524 189.395C55.7442 189.467 55.8558 189.503 55.966 189.503C56.1128 189.503 56.2597 189.44 56.3591 189.317C56.5319 189.105 56.4968 188.794 56.2795 188.626L56.278 188.626Z"
            fill="#014A4F"
          />
          <path
            d="M53.3764 191.168L41.8472 181.978C41.6315 181.807 41.3164 181.839 41.1405 182.05C40.9661 182.261 40.9982 182.572 41.2139 182.743L52.7431 191.933C52.8364 192.008 52.9481 192.043 53.0597 192.043C53.205 192.043 53.3503 191.981 53.4498 191.861C53.6242 191.65 53.592 191.34 53.3764 191.168Z"
            fill="#014A4F"
          />
          <path
            d="M82.7674 314.989C84.5755 314.989 87.9102 314.714 94.1849 313.814C100.356 312.93 107.574 312.002 107.634 311.991L107.455 311.023C96.0526 313.048 82.4768 314.556 81.2607 313.854C80.505 312.825 80.2435 305.718 80.0859 301.459C80.014 299.515 79.9528 297.837 79.8656 296.86C79.7769 295.858 79.4435 292.731 79.0197 288.772C77.9903 279.135 76.4346 264.573 76.2204 260.291C76.0139 256.169 71.3775 244.37 70.6693 243.303C68.7327 240.381 63.6679 232.17 62.1337 229.162C61.7375 228.384 61.2863 227.587 60.809 226.741C59.4767 224.382 58.0999 221.942 57.9118 219.928C57.5233 215.756 59.544 209.324 61.3337 207.569C61.4377 207.469 61.4912 207.326 61.479 207.182C61.4683 207.039 61.3933 206.907 61.274 206.823L49.0137 197.441L48.4217 198.237L60.2384 207.302C58.2437 209.8 56.5473 216.113 56.9114 220.016C57.1179 222.237 58.5466 224.769 59.9294 227.217C60.4021 228.052 60.8472 228.841 61.2342 229.601C62.7823 232.636 67.8776 240.901 69.8264 243.838C70.4077 244.716 75.0166 256.357 75.2154 260.339C75.4327 264.648 76.9883 279.227 78.0193 288.875C78.4415 292.829 78.775 295.951 78.8637 296.945C78.9478 297.897 79.009 299.564 79.0809 301.494C79.3272 308.179 79.5872 313.381 80.4913 314.491C80.658 314.695 80.8982 314.989 82.7659 314.989L82.7674 314.989Z"
            fill="#014A4F"
          />
          <path
            d="M49.234 242.428C49.4848 242.428 49.7005 242.244 49.7326 241.995L50.9625 232.108L79.2798 216.59C79.5215 216.457 79.6087 216.157 79.4725 215.92C79.3379 215.683 79.032 215.597 78.7887 215.73L50.2481 231.371C50.1089 231.448 50.0141 231.585 49.9942 231.741L48.7322 241.877C48.6986 242.146 48.8944 242.392 49.1697 242.426C49.1896 242.429 49.211 242.429 49.2309 242.429L49.234 242.428Z"
            fill="#014A4F"
          />
          <path
            d="M115.71 242.862C115.943 242.862 116.151 242.703 116.201 242.472C116.26 242.206 116.085 241.944 115.814 241.888L98.9039 238.646L95.2067 235.312C95.1134 235.227 94.9925 235.182 94.8671 235.182L88.6138 235.182L82.4309 225.33L83.6439 221.673C83.6898 221.534 83.6715 221.382 83.5904 221.259C78.9325 214.023 79.4603 217.466 82.6144 221.589L81.4014 225.242C81.3555 225.379 81.3739 225.529 81.4519 225.652L87.9041 235.931C87.9958 236.076 88.158 236.165 88.3324 236.165L94.6698 236.165L98.3242 239.462C98.39 239.521 98.4726 239.563 98.5597 239.581L115.605 242.851C115.64 242.859 115.675 242.862 115.709 242.862L115.71 242.862Z"
            fill="#014A4F"
          />
          <path
            d="M61.6856 229.872C61.7713 229.872 61.8585 229.851 61.938 229.805L81.348 218.727C81.3786 218.709 81.4077 218.689 81.4337 218.666L96.7243 204.977C96.9293 204.793 96.943 204.481 96.7564 204.281C96.5698 204.08 96.2516 204.065 96.0466 204.249L80.7958 217.902L61.4332 228.954C61.1931 229.09 61.112 229.393 61.2512 229.627C61.3445 229.785 61.5128 229.872 61.6856 229.872Z"
            fill="#014A4F"
          />
          <path
            d="M65.0645 235.757C65.1548 235.757 65.2435 235.732 65.3231 235.686L82.1387 225.817C82.3774 225.678 82.4539 225.375 82.3116 225.141C82.1693 224.907 81.8603 224.832 81.6232 224.971L65.2435 234.585L64.1926 232.814L82.8179 220.965C83.0504 220.816 83.1162 220.512 82.9663 220.284C82.8149 220.056 82.5043 219.99 82.2718 220.139L63.2458 232.243C63.0209 232.385 62.9505 232.677 63.0852 232.904L64.6332 235.51C64.7005 235.624 64.8121 235.706 64.9422 235.74C64.9835 235.751 65.0263 235.755 65.0676 235.755L65.0645 235.757Z"
            fill="#014A4F"
          />
          <path
            d="M94.7281 313.729C94.7556 313.729 94.7847 313.727 94.8122 313.721C95.086 313.675 95.2696 313.421 95.2237 313.154L86.2445 261.383C86.2414 261.366 86.2369 261.35 86.2323 261.333L79.6333 238.324L80.5817 236.329C80.6429 236.2 80.6444 236.053 80.5862 235.923L78.5747 231.399L78.5747 227.628C78.5747 227.356 78.3499 227.135 78.073 227.135C77.7961 227.135 77.5713 227.356 77.5713 227.628L77.5713 231.501C77.5713 231.568 77.585 231.636 77.6126 231.699L79.5751 236.113L78.6436 238.073C78.5931 238.18 78.5824 238.301 78.616 238.414L85.2579 261.575L94.2325 313.319C94.2738 313.559 94.4864 313.729 94.7265 313.729L94.7281 313.729Z"
            fill="#014A4F"
          />
          <path
            d="M79.4282 298.302C79.4557 298.302 79.4832 298.3 79.5123 298.296L91.7849 296.277C92.0587 296.232 92.2422 295.978 92.1963 295.709C92.1504 295.442 91.8935 295.261 91.6181 295.305L79.3456 297.325C79.0718 297.369 78.8882 297.623 78.9341 297.892C78.9754 298.132 79.188 298.304 79.4282 298.304L79.4282 298.302Z"
            fill="#014A4F"
          />
          <path
            d="M77.5575 280.238C77.5865 280.238 77.6156 280.235 77.6446 280.23L88.6812 278.355C88.955 278.309 89.1371 278.054 89.0897 277.786C89.0422 277.518 88.7822 277.339 88.5084 277.385L77.4718 279.261C77.198 279.306 77.016 279.562 77.0634 279.83C77.1062 280.07 77.3173 280.238 77.5575 280.238Z"
            fill="#014A4F"
          />
          <path
            d="M76.2938 267.765C76.3275 267.765 76.3611 267.762 76.3963 267.754L86.5028 265.706C86.7751 265.651 86.9495 265.391 86.8929 265.125C86.8363 264.859 86.5717 264.687 86.2994 264.742L76.1929 266.791C75.9206 266.846 75.7462 267.106 75.8028 267.372C75.8517 267.604 76.0613 267.765 76.2938 267.765Z"
            fill="#014A4F"
          />
          <path
            d="M75.7785 258.491C75.8626 258.491 76.4638 258.194 76.5418 258.151L83.3229 254.373C83.5645 254.238 83.6487 253.938 83.511 253.701C83.3733 253.466 83.0674 253.382 82.8257 253.516L75.5276 257.57C75.286 257.704 75.2018 258.004 75.3395 258.241C75.4313 258.4 75.6011 258.491 75.777 258.491L75.7785 258.491Z"
            fill="#014A4F"
          />
          <path
            d="M70.2502 244.061C70.3389 244.061 70.4292 244.038 70.5118 243.989L79.3625 238.701C79.5996 238.559 79.6745 238.256 79.5307 238.025C79.3869 237.793 79.078 237.719 78.8409 237.862L69.9902 243.15C69.7531 243.292 69.6781 243.595 69.8219 243.826C69.9167 243.977 70.0819 244.061 70.2517 244.061L70.2502 244.061Z"
            fill="#014A4F"
          />
          <path
            d="M84.0357 297.126C84.0494 297.126 84.0647 297.126 84.0785 297.124C84.3554 297.101 84.5603 296.863 84.5359 296.592C83.7802 287.859 81.7396 267.586 81.8222 266.407C81.8498 266.327 81.8574 266.239 81.839 266.148C81.7824 265.882 81.5178 265.711 81.2455 265.766C80.7759 265.861 80.7897 266.193 80.8371 267.289C80.8631 267.903 80.9167 268.799 80.9947 269.956C81.1262 271.897 81.3312 274.6 81.6035 277.986C82.067 283.752 83.5309 296.618 83.537 296.676C83.5599 296.933 83.7787 297.127 84.0372 297.127L84.0357 297.126Z"
            fill="#014A4F"
          />
          <path
            d="M90.8778 314.261C90.8992 314.261 90.9207 314.261 90.9421 314.256C91.2174 314.221 91.4117 313.975 91.3765 313.706L89.0912 296.24C89.056 295.971 88.8051 295.781 88.5298 295.815C88.2544 295.85 88.0602 296.096 88.0954 296.365L90.3807 313.833C90.4128 314.081 90.63 314.263 90.8778 314.263L90.8778 314.261Z"
            fill="#014A4F"
          />
          <path
            d="M103.781 302.655C103.82 302.655 103.859 302.651 103.898 302.642C104.168 302.577 104.334 302.311 104.269 302.048L97.6467 274.303C97.5824 274.038 97.3101 273.876 97.0409 273.94C96.7717 274.005 96.6049 274.271 96.6707 274.534L103.293 302.279C103.348 302.505 103.554 302.657 103.781 302.657L103.781 302.655Z"
            fill="#014A4F"
          />
          <path
            d="M96.842 295.308C96.8925 295.308 96.9429 295.301 96.9919 295.285L101.867 293.793C102.132 293.711 102.28 293.435 102.197 293.176C102.115 292.916 101.833 292.772 101.569 292.853L97.2213 294.184L93.2075 275.23C93.1509 274.964 92.8847 274.792 92.6124 274.849C92.3417 274.904 92.1673 275.166 92.2239 275.432L96.351 294.917C96.38 295.053 96.4657 295.171 96.588 295.241C96.6661 295.285 96.7533 295.308 96.842 295.308Z"
            fill="#014A4F"
          />
          <path
            d="M88.2422 276.689C88.5971 276.689 89.6587 276.429 94.0458 275.355C96.5927 274.731 99.1319 274.103 99.5526 273.999L107.177 272.579C107.453 272.579 107.678 272.359 107.678 272.086C107.678 271.814 107.453 271.594 107.177 271.594L99.4899 273.013C99.4486 273.013 99.4073 273.018 99.3675 273.029C95.1242 274.08 88.6644 275.666 88.256 275.727L88.3325 276.21L88.308 276.215L88.0342 276.628C88.0893 276.663 88.1306 276.689 88.2438 276.689L88.2422 276.689Z"
            fill="#014A4F"
          />
          <path
            d="M96.3862 237.536C96.481 237.536 96.5759 237.51 96.66 237.455C99.0906 235.896 106.959 230.62 106.132 228.154C105.446 226.115 100.223 214.603 98.5644 212.977C97.3866 211.823 95.7406 207.504 95.2053 205.937C95.1165 205.679 94.832 205.541 94.5689 205.627C94.3058 205.712 94.1636 205.992 94.2523 206.249C94.3334 206.488 96.2684 212.119 97.8546 213.673C99.2665 215.057 104.422 226.205 105.179 228.462C105.581 229.659 100.785 233.634 96.1123 236.63C95.8798 236.779 95.8156 237.084 95.967 237.312C96.0634 237.456 96.224 237.536 96.3877 237.536L96.3862 237.536Z"
            fill="#014A4F"
          />
          <path
            d="M94.8656 236.165C94.9773 236.165 95.089 236.13 95.1823 236.055C95.3979 235.884 95.4301 235.573 95.2557 235.362L83.5552 221.21C83.3808 220.998 83.0642 220.966 82.8485 221.138C82.6328 221.309 82.6007 221.62 82.7751 221.831L94.4756 235.983C94.575 236.104 94.7203 236.165 94.8656 236.165Z"
            fill="#014A4F"
          />
          <path
            d="M99.831 235.153C99.9258 235.153 100.021 235.126 100.106 235.071C100.339 234.922 100.402 234.617 100.25 234.389L86.3638 213.69C86.2108 213.463 85.9003 213.399 85.6678 213.549C85.4353 213.699 85.3725 214.003 85.524 214.231L99.4103 234.931C99.5067 235.074 99.6673 235.153 99.831 235.153Z"
            fill="#014A4F"
          />
          <path
            d="M103.839 231.853C103.94 231.853 104.042 231.824 104.129 231.761C104.356 231.604 104.408 231.296 104.247 231.074L95.4164 218.938L99.7943 215.619C100.013 215.452 100.054 215.143 99.8845 214.929C99.7147 214.713 99.3996 214.673 99.1794 214.84L94.419 218.448C94.2049 218.611 94.1605 218.909 94.3165 219.125L103.427 231.647C103.525 231.781 103.68 231.853 103.837 231.853L103.839 231.853Z"
            fill="#014A4F"
          />
          <path
            d="M57.8216 222.265C57.9103 222.265 57.999 222.242 58.0816 222.193L74.0254 212.568C74.2625 212.428 74.339 212.125 74.1952 211.892C74.0514 211.66 73.7424 211.585 73.5053 211.726L57.5615 221.35C57.3244 221.491 57.248 221.794 57.3917 222.026C57.4866 222.179 57.6518 222.263 57.8216 222.263L57.8216 222.265Z"
            fill="#014A4F"
          />
          <path
            d="M54.3186 249.498C54.3324 249.498 54.3461 249.498 54.3599 249.496L72.0139 248.089C72.2907 248.068 72.4957 247.829 72.4743 247.558C72.4513 247.287 72.2081 247.087 71.9328 247.107L54.2788 248.514C54.002 248.536 53.797 248.774 53.8184 249.045C53.8398 249.302 54.0601 249.498 54.3186 249.498Z"
            fill="#014A4F"
          />
          <path
            d="M55.7045 260.046C55.7213 260.046 55.7381 260.046 55.755 260.043L75.0609 258.53C75.3363 258.503 75.5382 258.263 75.5107 257.992C75.4831 257.721 75.2368 257.524 74.9615 257.55L55.6555 259.063C55.3802 259.09 55.1783 259.33 55.2058 259.601C55.2318 259.855 55.4506 260.045 55.7045 260.045L55.7045 260.046Z"
            fill="#014A4F"
          />
          <path
            d="M70.2486 275.828C70.2623 275.828 70.2761 275.828 70.2883 275.826C72.6502 275.64 76.485 275.349 76.9715 275.348C77.0128 275.354 77.0541 275.355 77.0969 275.351C77.4487 275.317 77.5329 275.109 77.5344 274.812L77.5466 274.812C77.5436 274.78 77.539 274.753 77.5329 274.727C77.5283 274.626 77.5176 274.516 77.5069 274.399L76.6013 265.177C76.5753 264.908 76.3321 264.712 76.0583 264.733L56.4693 266.381C56.1924 266.404 55.9874 266.642 56.0119 266.913C56.0348 267.184 56.2811 267.384 56.5549 267.361L75.6483 265.755C75.8089 267.39 76.329 272.691 76.4957 274.382C76.3397 274.392 76.1546 274.402 75.9481 274.416C75.3087 274.457 74.4567 274.519 73.6062 274.583C72.1362 274.691 70.6677 274.808 70.296 274.837L57.5783 270.965C57.3106 270.892 57.0337 271.043 56.9588 271.305C56.8838 271.566 57.0383 271.839 57.306 271.912L70.1109 275.808C70.1553 275.82 70.2011 275.826 70.247 275.826L70.2486 275.828Z"
            fill="#014A4F"
          />
          <path
            d="M45.936 287.38C45.9559 287.38 45.9758 287.38 45.9956 287.377L77.9811 283.678C78.2564 283.646 78.4537 283.401 78.4216 283.132C78.3895 282.861 78.1402 282.669 77.8633 282.701L45.8779 286.4C45.6025 286.432 45.4052 286.676 45.4373 286.946C45.4679 287.197 45.6836 287.38 45.936 287.38Z"
            fill="#014A4F"
          />
          <path
            d="M71.9726 293.77C71.9879 293.77 72.0048 293.77 72.02 293.768C72.2969 293.742 72.4988 293.502 72.4728 293.231L70.7489 275.29C70.7229 275.019 70.4781 274.82 70.2013 274.846C69.9244 274.872 69.7225 275.112 69.7485 275.383L71.4724 293.324C71.4969 293.58 71.7157 293.771 71.9711 293.771L71.9726 293.77Z"
            fill="#014A4F"
          />
          <path
            d="M39.2499 317.505C39.2652 317.505 39.282 317.505 39.2973 317.504L80.5525 313.727C80.8294 313.701 81.0313 313.463 81.0053 313.192C80.9793 312.921 80.7361 312.721 80.4592 312.747L39.204 316.523C38.9271 316.548 38.7252 316.788 38.7512 317.059C38.7757 317.314 38.9944 317.505 39.2499 317.505Z"
            fill="#014A4F"
          />
          <path
            d="M51.3679 315.909C50.5602 308.259 49.3074 296.697 49.1284 295.812C49.1116 295.767 49.1009 295.72 49.0979 295.669C49.0795 295.399 49.2891 295.163 49.5659 295.145C50.0356 295.116 50.109 295.568 50.1426 295.787C50.1656 295.937 50.1962 296.159 50.2329 296.44C50.3048 296.994 50.3981 297.786 50.5082 298.736C50.7025 300.414 50.9625 302.753 51.2822 305.69C51.8329 310.747 52.3683 315.804 52.3683 315.804C52.3974 316.075 52.197 316.317 51.9201 316.344C51.9033 316.346 51.8849 316.348 51.8681 316.348C51.6142 316.348 51.3954 316.159 51.3694 315.905L51.3679 315.909ZM49.4329 296.102C49.4925 296.122 49.5583 296.133 49.6317 296.128C49.5629 296.133 49.4956 296.124 49.4329 296.102Z"
            fill="#014A4F"
          />
          <path
            d="M71.1098 314.588C71.1236 314.588 71.1373 314.588 71.1526 314.587C71.4295 314.564 71.6345 314.327 71.6115 314.056L69.9197 294.065C69.8968 293.794 69.6566 293.591 69.3782 293.615C69.1013 293.638 68.8963 293.875 68.9193 294.146L70.6111 314.137C70.6325 314.394 70.8528 314.588 71.1113 314.588L71.1098 314.588Z"
            fill="#014A4F"
          />
          <path
            d="M41.9222 310.634L47.0558 309.375C47.325 309.309 47.4887 309.042 47.4214 308.779C47.3541 308.514 47.0818 308.355 46.8126 308.421L41.4098 309.745C41.2415 309.787 41.1069 309.909 41.0534 310.071L37.7478 320.063L38.8032 320.063L41.9222 310.634Z"
            fill="#014A4F"
          />
          <path
            d="M57.3657 315.826C57.3474 315.555 57.1088 315.349 56.8319 315.367C56.555 315.385 56.3454 315.619 56.3638 315.892L56.6468 320.063L57.6533 320.063L57.3657 315.826Z"
            fill="#014A4F"
          />
          <path
            d="M37.0074 77.1909L37.0074 80.7658C37.0074 80.905 37.0686 81.038 37.1741 81.1314C37.2675 81.2124 37.3868 81.2583 37.5091 81.2583C37.5275 81.2583 37.5443 81.2583 37.5627 81.2552L57.4622 79.1703C57.7299 79.1428 57.9272 78.9118 57.9104 78.6487L57.6549 74.8781L56.6484 74.8781L56.8763 78.2418L38.0124 80.2197L38.0124 77.2705L38.8032 74.8796L37.7478 74.8796L37.0334 77.041C37.0166 77.09 37.0089 77.142 37.0089 77.1924L37.0074 77.1909Z"
            fill="#014A4F"
          />
          <path
            d="M47.4595 316.351C47.4687 316.351 47.4764 316.351 47.4855 316.351C47.7624 316.337 47.975 316.106 47.9613 315.834L47.4611 306.213L60.705 305.527C60.9818 305.513 61.1945 305.28 61.1792 305.01C61.1639 304.739 60.9283 304.529 60.6514 304.545L46.9073 305.257C46.6305 305.271 46.4178 305.504 46.4316 305.774L46.9578 315.884C46.9716 316.147 47.1934 316.351 47.4595 316.351Z"
            fill="#014A4F"
          />
          <path
            d="M40.5332 313.729L47.3235 313.729C47.6003 313.729 47.8252 313.508 47.8252 313.236C47.8252 312.964 47.6003 312.744 47.3235 312.744L40.5332 312.744C40.2564 312.744 40.0315 312.964 40.0315 313.236C40.0315 313.508 40.2564 313.729 40.5332 313.729Z"
            fill="#014A4F"
          />
          <path
            d="M106.372 81.4434C105.742 81.5107 43.2852 88.1724 41.4879 88.3193C39.6201 88.4722 38.6901 88.4248 38.1165 87.7502C37.7478 87.3158 37.9145 86.3108 38.1042 85.6898L63.3744 82.9165C63.6497 82.8859 63.8486 82.6427 63.818 82.3719C63.7874 82.1012 63.5381 81.9069 63.2627 81.9375L37.6744 84.746C37.4878 84.7658 37.3287 84.8867 37.2614 85.058C37.1726 85.2844 36.4231 87.2959 37.344 88.3805C38.0124 89.1667 38.9578 89.3747 40.1127 89.3747C40.567 89.3747 41.055 89.3426 41.5705 89.2998C43.3801 89.1514 103.905 82.6962 106.479 82.4208C106.754 82.3918 106.955 82.1486 106.924 81.8778C106.894 81.6071 106.646 81.4128 106.37 81.4419L106.372 81.4434Z"
            fill="#014A4F"
          />
          <path
            d="M93.8224 313.345C93.7704 313.077 92.7838 313.262 92.8358 313.528L94.1039 320.062L95.1257 320.062L93.8209 313.343L93.8224 313.345Z"
            fill="#014A4F"
          />
          <path
            d="M96.0802 82.9195C96.1108 82.9195 96.1429 82.9165 96.175 82.9104C96.4473 82.8599 96.6263 82.6014 96.5743 82.3352L95.1257 74.8765L94.1038 74.8765L95.5876 82.5188C95.6335 82.7543 95.8446 82.9195 96.0802 82.9195Z"
            fill="#014A4F"
          />
          <path
            d="M108.626 85.6424L51.8022 92.9404C51.5269 92.9756 51.3326 93.2219 51.3693 93.4926C51.4014 93.7404 51.6187 93.9225 51.8665 93.9225C51.8879 93.9225 51.9093 93.9225 51.9323 93.9179L108.757 86.6198C109.032 86.5846 109.226 86.3384 109.189 86.0676C109.154 85.7984 108.903 85.6072 108.626 85.6424Z"
            fill="#014A4F"
          />
          <path
            d="M69.3967 90.7086C69.1198 90.7209 68.9057 90.9518 68.9179 91.2241L69.7638 108.844C69.776 109.108 69.9978 109.314 70.2655 109.314C70.2732 109.314 70.2808 109.314 70.29 109.314C70.5669 109.302 70.781 109.071 70.7688 108.799L69.9229 91.1782C69.9106 90.9059 69.6735 90.701 69.3982 90.7101L69.3967 90.7086Z"
            fill="#014A4F"
          />
          <path
            d="M90.8029 125.4L66.348 109.802L65.1151 103.613C65.0616 103.345 64.7985 103.171 64.5262 103.224C64.2539 103.276 64.0765 103.535 64.13 103.803L65.4057 110.209C65.4333 110.347 65.5189 110.466 65.6413 110.538L90.2889 126.248C90.37 126.295 90.4587 126.316 90.5459 126.316C90.7172 126.316 90.884 126.231 90.9773 126.076C91.1195 125.842 91.0415 125.541 90.8029 125.402L90.8029 125.4Z"
            fill="#014A4F"
          />
          <path
            d="M100.695 105.586C100.496 105.545 95.8065 104.557 92.611 104.408C89.882 104.286 86.3026 106.111 85.0284 106.819L83.9179 104.678C83.8368 104.522 83.6762 104.419 83.4972 104.408C83.3182 104.401 83.1484 104.483 83.049 104.63L80.4807 108.471L78.0317 107.567C77.9476 107.537 77.8542 107.529 77.7671 107.543L65.8096 109.628C65.5358 109.675 65.3538 109.93 65.4027 110.198C65.4455 110.437 65.6582 110.604 65.8968 110.604C65.9259 110.604 65.9549 110.6 65.9855 110.596L77.8068 108.534L80.5021 109.528C80.7224 109.609 80.9702 109.53 81.0987 109.337L83.4131 105.877L84.3722 107.726C84.4364 107.849 84.5481 107.94 84.6842 107.977C84.8204 108.014 84.9642 107.994 85.0835 107.922C85.1278 107.896 89.5578 105.25 92.562 105.387C95.6749 105.533 100.435 106.535 100.483 106.545C100.755 106.602 101.021 106.434 101.079 106.167C101.137 105.901 100.966 105.64 100.694 105.582L100.695 105.586Z"
            fill="#014A4F"
          />
          <path
            d="M96.5698 87.7013C96.504 87.4367 96.2333 87.273 95.964 87.3388C95.6948 87.403 95.5296 87.6692 95.5939 87.9338L105.128 125.449C105.183 125.674 105.39 125.825 105.616 125.825C105.656 125.825 105.696 125.821 105.736 125.812C106.005 125.747 106.17 125.481 106.106 125.217L96.5713 87.7013L96.5698 87.7013Z"
            fill="#014A4F"
          />
          <path
            d="M91.5234 88.3912C91.2465 88.3912 91.0217 88.6114 91.0217 88.8837L91.0217 104.893C91.0217 105.166 91.2465 105.386 91.5234 105.386C91.8003 105.386 92.0251 105.166 92.0251 104.893L92.0251 88.8837C92.0251 88.6114 91.8003 88.3912 91.5234 88.3912Z"
            fill="#014A4F"
          />
          <path
            d="M91.4424 96.4021L64.7175 100.688C64.4437 100.733 64.2586 100.985 64.303 101.254C64.3427 101.496 64.5569 101.667 64.7986 101.667C64.8261 101.667 64.8521 101.666 64.8796 101.661L91.6045 97.3749C91.8783 97.3306 92.0634 97.0782 92.0191 96.809C91.9747 96.5413 91.7162 96.3592 91.4424 96.4021Z"
            fill="#014A4F"
          />
          <path
            d="M109.638 91.6387C109.658 91.8926 109.474 92.1205 109.217 92.1603L98.0275 95.5439C97.9755 95.5592 97.9235 95.5669 97.873 95.5669C97.6603 95.5669 97.463 95.4338 97.3957 95.2258C97.3101 94.9657 97.4554 94.6888 97.72 94.6062L108.602 91.2593L107.358 74.8765L108.367 74.8765L109.638 91.6387Z"
            fill="#014A4F"
          />
          <path
            d="M79.6332 239.686L80.0876 241.97L180.143 304.47L203.295 320.063L206.399 320.063L181.101 303.024L79.6332 239.686Z"
            fill="#014A4F"
          />
          <path
            d="M256.231 159.613L256.22 159.582L233.659 96.3195L220.938 84.6695L206.399 74.8765L203.295 74.8765L219.889 86.0538L232.143 97.2893L254.55 160.121L263.397 192.721L287.624 225.318L289.044 224.303L265.011 191.965L256.231 159.613Z"
            fill="#014A4F"
          />
          <path
            d="M136.568 275.299L135.012 276.1C135.104 276.273 144.289 293.468 147.269 303.108C149.172 309.265 149.415 315.362 148.77 320.063L150.532 320.063C151.167 315.154 150.893 308.892 148.952 302.608C145.923 292.81 136.949 276.009 136.568 275.299Z"
            fill="#014A4F"
          />
          <path
            d="M147.303 80.5608C146.897 81.4924 145.767 84.1463 144.204 87.8191C139.021 99.9953 128.237 125.331 126.366 128.773L126.105 129.255C123.851 133.412 122.609 135.702 115.628 137.893C108.062 140.267 94.4603 146.849 94.4022 146.877L94.7969 148.504C94.855 148.478 108.715 141.872 116.163 139.534C123.825 137.131 125.349 134.32 127.656 130.064L127.916 129.585C129.826 126.072 140.234 101.62 145.825 88.483C147.387 84.8148 148.514 82.1639 148.918 81.2384C149.629 79.6063 150.203 77.4234 150.532 74.8765L148.77 74.8765C148.458 77.1481 147.941 79.0938 147.301 80.5608L147.303 80.5608Z"
            fill="#014A4F"
          />
          <path
            d="M131.368 106.015L98.7006 94.3646L98.1499 96.0013L130.798 107.644L174.306 124.036L174.337 124.045L250.091 146.543L250.6 144.894L174.877 122.405L131.368 106.015Z"
            fill="#014A4F"
          />
          <path
            d="M232.877 95.5761L228.473 100.13L228.414 100.33L206.573 173.788C206.059 174.507 203.162 178.495 201.075 180.031C198.317 182.059 198.579 186.154 198.882 190.896L198.902 191.202C199.208 195.993 191.521 226.62 191.634 227.061L193.146 226.92C193.118 226.813 200.952 195.727 200.656 191.093L200.636 190.787C200.353 186.368 200.13 182.879 202.129 181.408C204.646 179.557 207.96 174.851 208.1 174.651L208.179 174.536L208.219 174.403L230.041 101.011L232.925 98.0297L244.076 108.725L295.69 183.586L297.146 182.621L245.431 107.615L232.876 95.573L232.877 95.5761Z"
            fill="#014A4F"
          />
          <path
            d="M5.06782 281.471C4.39476 280.634 2.54998 278.496 1.53212e-05 275.571L1.52052e-05 278.227C1.83715 280.341 3.14961 281.872 3.68805 282.54C5.45635 284.737 5.65522 285.711 5.5099 286.144C5.30645 286.748 3.99552 287.163 3.02571 287.423C2.25782 287.628 1.21305 288.007 0.00154592 288.498L0.00154584 290.368C1.43485 289.771 2.6601 289.308 3.48919 289.086C5.13511 288.645 6.72138 288.05 7.18029 286.684C7.61012 285.404 6.97838 283.845 5.06935 281.474L5.06782 281.471Z"
            fill="#014A4F"
          />
          <path
            d="M295.351 293.263C294.037 293.88 292.796 294.462 291.797 294.92C286.974 297.126 264.584 316.433 260.609 320.063L263.211 320.063C269.846 314.175 288.501 298.33 292.541 296.482C293.547 296.021 294.792 295.437 296.111 294.819C299.17 293.383 302.99 291.591 305.933 290.366L305.933 288.497C302.906 289.721 298.826 291.634 295.352 293.265L295.351 293.263Z"
            fill="#014A4F"
          />
          <path
            d="M270.457 234.583C267.526 230.478 266.299 226.692 265.597 219.576C264.921 212.731 264.376 193.24 264.365 193.114L262.614 193.267C262.625 193.392 263.171 212.89 263.847 219.741C264.583 227.194 265.887 231.188 269.016 235.572C270.98 238.324 284.421 253.657 295.22 265.978C299.395 270.74 303.228 275.115 305.93 278.225L305.93 275.569C303.361 272.623 300.079 268.877 296.554 264.856C285.779 252.563 272.37 237.265 270.457 234.583Z"
            fill="#014A4F"
          />
          <path
            d="M260.571 74.9117C255.826 79.2529 255.429 83.3356 255.429 87.146C255.429 90.2222 255.647 98.4442 257.663 108.343C259.085 115.333 250.131 118.731 244.78 120.761C243.548 121.229 242.484 121.631 241.754 122.001L242.562 123.532C243.206 123.205 244.229 122.818 245.413 122.368C250.894 120.289 261.098 116.418 259.385 108.006C257.4 98.2591 257.185 90.1717 257.185 87.1475C257.185 83.5742 257.536 80.0468 261.768 76.1737C262.146 75.828 262.633 75.3905 263.209 74.8796L260.607 74.8796C260.595 74.8903 260.581 74.904 260.569 74.9148L260.571 74.9117Z"
            fill="#014A4F"
          />
          <path
            d="M42.6931 238.279L41.5872 236.939C41.194 237.25 20.5771 253.6 -2.79e-05 269.823L-2.79966e-05 272.033C20.9442 255.525 42.2939 238.596 42.6931 238.279Z"
            fill="#014A4F"
          />
          <path
            d="M256.804 313.625C256.517 314.754 256.191 316.034 255.76 317.521C255.501 318.411 255.275 319.254 255.076 320.063L256.884 320.063C257.054 319.401 257.24 318.714 257.45 317.993C257.889 316.479 258.218 315.185 258.508 314.042C259.815 308.907 260.254 307.177 266.224 302.963C269.73 300.488 287.676 286.419 305.93 272.033L305.93 269.823C287.324 284.491 268.754 299.053 265.198 301.565C258.704 306.148 258.147 308.337 256.803 313.626L256.804 313.625Z"
            fill="#014A4F"
          />
          <path
            d="M256.884 74.8765L255.076 74.8765C253.375 81.7861 253.803 86.1028 255.749 94.5099L257.464 94.129C255.519 85.7311 255.134 81.7325 256.885 74.8765L256.884 74.8765Z"
            fill="#014A4F"
          />
          <path
            d="M191.342 224.267C183.998 214.968 181.597 201.513 180.477 195.47L178.747 195.779C179.902 202.006 182.346 215.69 189.952 225.323C197.245 234.559 225.68 270.61 225.68 270.61L225.698 270.633L258.536 309.447L259.888 308.347L227.06 269.544C226.482 268.81 198.558 233.407 191.339 224.267L191.342 224.267Z"
            fill="#014A4F"
          />
          <path
            d="M263.263 220.486C262.111 222.745 260.921 225.08 255.186 223.09C247.544 220.434 241.659 221.932 234.64 225.21C231.715 226.576 226.336 229.546 220.641 232.691C213.646 236.555 206.412 240.549 202.131 242.487C195.54 245.47 192.09 250.934 189.572 254.923C188.974 255.872 188.457 256.692 187.965 257.369C185.386 260.926 165.511 292.44 164.666 293.78L166.161 294.686C166.367 294.358 186.851 261.879 189.398 258.367C189.923 257.643 190.479 256.762 191.068 255.829C193.47 252.026 196.757 246.816 202.868 244.051C207.214 242.083 214.48 238.071 221.504 234.192C227.172 231.062 232.526 228.105 235.395 226.765C242.179 223.597 247.496 222.247 254.599 224.714C261.797 227.215 263.625 223.629 264.834 221.257C265.048 220.836 265.251 220.439 265.456 220.131L263.985 219.187C263.723 219.579 263.498 220.021 263.26 220.488L263.263 220.486Z"
            fill="#014A4F"
          />
          <path
            d="M55.6219 251.06C55.01 246.261 52.6314 242.763 48.3483 240.363C44.6251 238.278 40.2839 235.426 36.3128 230.1C32.8145 225.405 9.3386 203.083 -4.90039e-05 194.231L-4.91115e-05 196.692C10.648 206.769 31.733 226.877 34.8948 231.117C39.0601 236.707 43.5925 239.683 47.4764 241.86C51.2455 243.973 53.3396 247.052 53.8781 251.275C54.3217 254.758 58.6583 294.287 61.4117 320.065L63.1785 320.065C60.422 294.256 56.0685 254.553 55.6234 251.061L55.6219 251.06Z"
            fill="#014A4F"
          />
          <path
            d="M302.13 190.624L299.8 190.904C299.863 190.966 302.288 193.243 305.93 196.69L305.93 194.229C303.738 192.152 302.323 190.813 302.128 190.622L302.13 190.624Z"
            fill="#014A4F"
          />
          <path
            d="M65.4286 96.7554C65.3537 95.4782 64.4313 86.6137 63.1769 74.8765L61.4102 74.8765C62.6691 86.6611 63.5976 95.573 63.6726 96.8533C63.8913 100.595 63.6695 102.857 61.8461 104.844C60.6285 106.171 41.8487 129.758 25.2793 150.569C13.4595 165.415 3.25051 178.236 1.64282 180.185C1.00342 180.959 0.472626 181.492 -4.23111e-05 181.846L-4.23986e-05 183.849C0.894816 183.45 1.85697 182.665 3.00881 181.27C4.6272 179.309 14.8408 166.482 26.6652 151.629C42.3963 131.871 61.9761 107.278 63.1525 105.996C65.3781 103.572 65.6688 100.878 65.4286 96.7539L65.4286 96.7554Z"
            fill="#014A4F"
          />
          <path
            d="M302.757 182.227C301.85 181.999 300.721 181.713 299.267 181.882C295.409 182.327 280.133 188.32 278.826 188.836C276.216 189.405 234.962 198.41 230.439 200.184C229.622 200.505 228.902 200.73 228.267 200.929C225.079 201.928 185.872 217.947 185.872 217.947L187.417 219.669C187.417 219.669 226.082 203.424 228.802 202.572C229.465 202.364 230.216 202.128 231.091 201.786C235.531 200.045 278.839 190.599 279.277 190.504L279.414 190.463C279.578 190.399 295.756 184.025 299.472 183.596C300.602 183.466 301.475 183.687 302.32 183.899C303.606 184.224 304.706 184.4 305.933 183.852L305.933 181.85C304.76 182.734 303.949 182.53 302.759 182.23L302.757 182.227Z"
            fill="#014A4F"
          />
          <path
            d="M17.2456 211.615L15.7725 210.675L5.73176 225.828L8.56273e-05 231.79L8.55174e-05 234.304L7.11918 226.897L17.2456 211.615Z"
            fill="#014A4F"
          />
          <path
            d="M285.204 253.353L286.485 254.535L305.931 234.304L305.931 231.79L285.204 253.353Z"
            fill="#014A4F"
          />
          <path
            d="M81.8542 217.509L-2.79349e-05 152.491L-2.80318e-05 154.709L80.7483 218.848L81.8542 217.509Z"
            fill="#014A4F"
          />
          <path
            d="M257.471 113.998L256.364 115.336L305.931 154.709L305.931 152.491L257.471 113.998Z"
            fill="#014A4F"
          />
          <path
            d="M258.821 166.554C258.392 166.528 257.949 166.502 257.52 166.449L257.299 166.421L257.091 166.501C256.931 166.562 253.172 168.021 252.131 170.574C251.149 172.981 252.39 177.66 253.485 181.788L253.617 182.284C254.538 185.767 258.131 187.355 261.08 187.355C261.774 187.355 262.395 187.257 262.879 187.072C265.088 186.223 266.55 184.11 266.89 181.276C267.225 178.48 265.915 173.54 264.555 170.135C263.231 166.816 260.889 166.678 258.822 166.556L258.821 166.554ZM265.143 181.074C264.881 183.257 263.821 184.858 262.236 185.467C262.077 185.528 261.715 185.631 261.08 185.631C259.166 185.631 256.052 184.624 255.318 181.851L255.186 181.355C254.21 177.675 252.996 173.095 253.764 171.215C254.354 169.77 256.525 168.61 257.534 168.187C257.938 168.229 258.333 168.252 258.715 168.275C260.694 168.391 262.002 168.47 262.917 170.762C264.208 173.996 265.426 178.709 265.143 181.074Z"
            fill="#014A4F"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_380_46"
          x1="9.20887e-06"
          y1="173.684"
          x2="305.931"
          y2="173.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D9D9D9" />
          <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_380_46">
          <rect
            width="735.566"
            height="305.931"
            fill="white"
            transform="translate(305.931 -170.313) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
