import { useSettings } from "../../application/SettingsProvider";
import { Box, TouchableOpacity, Typography } from "../../components";
import { COLORS } from "../../constants";
import React, { useState, useMemo, CSSProperties } from "react";
import { Logo } from "../../svg/Logo";
import { Menu } from "../../svg/Menu";
import { MenuCross } from "../../svg/MenuCross";

export function Header() {
  const [open, setOpen] = useState(false);
  const settings = useSettings();

  function handleLinkClick(id: string) {
    const element = document.getElementById(id);
    setOpen(false);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  function handleMenuClick() {
    setOpen((prev) => !prev);
  }

  const style = useMemo(() => {
    return {
      position: "absolute",
      left: 0,
      top: "56px",
      right: 0,
      bottom: 0,
      transform: open ? "translateX(0)" : "translateX(-100%)",
      transition: "transform 0.1s ease-out",
      zIndex: 4,
    } as CSSProperties;
  }, [open]);

  return (
    <>
      <Box
        paddingTop={12}
        paddingBottom={12}
        paddingLeft={16}
        paddingRight={16}
        backgroundColor={COLORS.BLACK}
        flexDirection="row"
        alignItems="center"
      >
        <Logo />
        <Box position="absolute" style={{ right: 16, top: 20 }}>
          <TouchableOpacity onPress={handleMenuClick}>
            {open ? <MenuCross /> : <Menu />}
          </TouchableOpacity>
        </Box>
      </Box>
      <Box
        style={style}
        backgroundColor={COLORS.BLACK}
        paddingTop={60}
        paddingRight={16}
        paddingLeft={16}
        overflow="hidden"
        gap={40}
      >
        <TouchableOpacity onPress={() => handleLinkClick("info")}>
          <Typography
            color={COLORS.WHITE}
            size={32}
            lineHeight={38}
            weight={300}
          >
            {settings?.menu.info}
          </Typography>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => handleLinkClick("possibilities")}>
          <Typography
            color={COLORS.WHITE}
            size={32}
            lineHeight={38}
            weight={300}
          >
            {settings?.menu.possibilities}
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleLinkClick("contacts")}>
          <Typography
            color={COLORS.WHITE}
            size={32}
            lineHeight={38}
            weight={300}
          >
            {settings?.menu.contacts}
          </Typography>
        </TouchableOpacity>
      </Box>
    </>
  );
}
