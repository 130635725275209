import React from "react";
import { Box, Typography } from "../../components";
import { Container } from "./components/Container";
import { useSettings } from "../../application/SettingsProvider";
import { COLORS } from "../../constants";

export function Info() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.TURQUOISE} id="info">
      <Container>
        <Box>
          <Box flexDirection="row" position="relative">
            <img
              src="image/collage.png"
              alt="collage"
              style={{ width: "100%", height: "auto", maxWidth: "640px" }}
            />

            <Box
              position="absolute"
              style={{
                top: "50%",
                right: "0%",
                transform: "translate(0%, -50%)",
                width: "60%",
              }}
              backgroundColor={COLORS.BLACK}
              paddingTop={30}
              paddingRight={30}
              paddingBottom={30}
              paddingLeft={30}
            >
              <Typography
                size={28}
                lineHeight={38}
                weight={700}
                color={COLORS.WHITE}
              >
                {settings?.info?.title}
              </Typography>
              <Box paddingTop={20}>
                <Typography
                  size={16}
                  lineHeight={28}
                  weight={400}
                  color={COLORS.WHITE}
                >
                  {settings?.info?.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
