import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from "react";

export type Settings = {
  menu: {
    info: string;
    possibilities: string;
    contacts: string;
  };
  home: {
    title1: string;
    title2: string;
    description: string;
  };
  info: {
    title: string;
    description: string;
  };
  possibilities: {
    items: [];
  };
  contacts: {
    title: string;
    mail: string;
  };
  footer: {
    copyright: string;
  };
};

const SettingsContext = createContext<Settings | undefined>(undefined);

export function SettingsProvider({ children }: PropsWithChildren) {
  const [settings, setSettings] = useState<Settings>();

  useEffect(() => {
    fetch("static/settings.json")
      .then((response) => response.json())
      .then((data) => setSettings(data));
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {settings && children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  return useContext(SettingsContext);
}
