import React, { Suspense } from "react";
import { SettingsProvider } from "./SettingsProvider";
import { MainPage } from "../pages";

export function Application() {
  return (
    <Suspense>
      <SettingsProvider>
        <MainPage />
      </SettingsProvider>
    </Suspense>
  );
}
