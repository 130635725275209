import React from "react";
import { PropsWithChildren, CSSProperties } from "react";

type Props = PropsWithChildren<{
  style?: CSSProperties;
}>;

export function Video({ style }: Props) {
  return (
    <video
      src="video/Video.webm"
      autoPlay
      loop
      muted
      style={{ width: "100%", height: "auto", maxHeight: "700px" }}
    ></video>
  );
}
