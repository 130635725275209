import React from "react";
import { Box, Typography } from "../../../../components";
import { Container } from "../../components/Container";
import { useSettings } from "../../../../application/SettingsProvider";
import { COLORS } from "../../../../constants";

export function Info() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.TURQUOISE} id="info">
      <Container>
        <Box>
          <Box flexDirection="row" position="relative">
            <img src="image/collage.png" alt="collage" width={924} />

            <Box
              position="absolute"
              style={{
                top: "50%",
                right: "0%",
                transform: "translate(0%, -50%)",
              }}
              backgroundColor={COLORS.BLACK}
              width={794}
              paddingTop={40}
              paddingRight={40}
              paddingBottom={40}
              paddingLeft={40}
            >
              <Typography
                size={38}
                lineHeight={48}
                weight={700}
                color={COLORS.WHITE}
              >
                {settings?.info?.title}
              </Typography>
              <Box paddingTop={32}>
                <Typography
                  size={20}
                  lineHeight={30}
                  weight={400}
                  color={COLORS.WHITE}
                >
                  {settings?.info?.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
