import { useSettings } from "../../../application/SettingsProvider";
import { Container } from "../components/Container";
import { Box } from "../../../components";
import { COLORS } from "../../../constants";
import { Item } from "./Item";
import React from "react";

export function Possibilities() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.WHITE} id="possibilities">
      <Container>
        <Box flexDirection={"column"} paddingTop={20} paddingBottom={60}>
          {settings?.possibilities?.items?.map((item, index) => (
            <Box marginTop={40} key={index}>
              <Item text={item} />
            </Box>
          ))}
        </Box>
      </Container>

      <Box backgroundColor={COLORS.TURQUOISE}>
        <img src="image/imagePossibilitiesSmall.png" alt="drons and map" />
      </Box>
    </Box>
  );
}
