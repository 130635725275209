import React from "react";
import { PropsWithChildren, CSSProperties } from "react";

type Props = PropsWithChildren<{
  style?: CSSProperties;
}>;

export function ContactImageSmall({ style }: Props) {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 744 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_380_2435"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="744"
        height="270"
      >
        <rect width="744" height="270" fill="#1B1A1A" />
      </mask>
      <g mask="url(#mask0_380_2435)">
        <g opacity="0.7">
          <path
            d="M744 -0.00012207L0 -0.00012207L0 270L744 270L744 -0.00012207Z"
            fill="black"
          />
          <path
            d="M445.943 228.37L356.002 350.975L358.397 352.74L448.663 229.703C448.858 229.447 448.96 229.136 448.96 228.821C448.96 228.789 448.96 228.762 448.96 228.731L443.636 141.675L440.652 141.855L445.938 228.37H445.943Z"
            fill="#014A4F"
          />
          <path
            d="M365.397 193.889L444.676 181.601L444.3 178.665L365.021 190.957L365.397 193.889Z"
            fill="#014A4F"
          />
          <path
            d="M561.158 144.219C568.969 153.279 556.702 189.755 552.668 201.742C551.879 204.083 551.313 205.772 551.034 206.749C549.67 211.567 548.463 211.238 542.647 209.666C542.183 209.54 541.7 209.41 541.199 209.279C534.598 207.537 445.971 170.142 445.075 169.763C444.314 169.444 443.437 169.79 443.117 170.542C442.792 171.299 443.145 172.163 443.906 172.483C447.568 174.027 533.624 210.342 540.433 212.138C540.929 212.269 541.408 212.4 541.862 212.521C547.604 214.075 551.749 215.196 553.912 207.55C554.167 206.641 554.757 204.894 555.504 202.678C561.06 186.167 566.686 167.336 566.686 154.508C566.686 149.231 565.734 144.967 563.432 142.301C562.898 141.68 561.951 141.608 561.325 142.135C560.698 142.666 560.624 143.598 561.158 144.219Z"
            fill="#014A4F"
          />
          <path
            d="M543.078 131.427L516.329 216.983C516.087 217.753 516.519 218.582 517.299 218.829L535.457 224.647C536.241 224.894 537.081 224.467 537.337 223.692C537.587 222.913 537.156 222.08 536.371 221.833L519.624 216.461L545.933 132.305C545.979 132.161 545.998 132.012 545.998 131.868C545.998 131.238 545.585 130.653 544.944 130.454C544.155 130.211 543.32 130.648 543.074 131.432L543.078 131.427Z"
            fill="#014A4F"
          />
          <path
            d="M454.841 345.486C454.289 346.094 454.34 347.026 454.953 347.575C455.565 348.12 456.512 348.071 457.065 347.467L545.622 212.678C545.877 212.395 546.007 212.044 546.007 211.692C546.007 211.287 545.84 210.886 545.515 210.594C544.903 210.049 543.956 210.098 543.403 210.702L454.846 345.491L454.841 345.486Z"
            fill="#014A4F"
          />
          <path
            d="M514.425 200.215C513.618 201.863 494.652 240.645 491.496 249.115C490.326 252.253 488.943 254.288 487.244 254.491C483.011 254.986 448.139 236.457 445.275 234.035C444.648 233.504 443.706 233.576 443.167 234.197C442.634 234.818 442.703 235.75 443.33 236.282C447.029 239.416 482.194 258.588 487.996 257.35C490.716 256.769 492.749 254.297 494.299 250.137C497.4 241.802 516.918 201.908 517.113 201.507C517.215 201.3 517.262 201.08 517.262 200.863C517.262 200.314 516.95 199.787 516.417 199.531C515.674 199.175 514.783 199.481 514.421 200.215H514.425Z"
            fill="#014A4F"
          />
          <path
            d="M520.883 114.024C520.623 114.708 494.973 182.515 493.316 186.684C491.408 191.484 487.384 196.329 482.552 199.643C481.364 200.463 480.11 201.255 478.899 202.025C473.891 205.204 469.161 208.207 467.94 213.034C467.741 213.827 468.228 214.628 469.026 214.826C469.825 215.024 470.637 214.543 470.837 213.75C471.77 210.058 476.017 207.365 480.51 204.511C481.744 203.727 483.016 202.921 484.251 202.07C489.547 198.432 493.975 193.087 496.092 187.765C497.758 183.573 522.618 117.851 523.677 115.055C523.742 114.884 523.774 114.708 523.774 114.537C523.774 113.938 523.403 113.375 522.804 113.155C522.029 112.867 521.17 113.258 520.883 114.019V114.024Z"
            fill="#014A4F"
          />
          <path
            d="M459.989 59.7485C459.302 60.1853 459.102 61.0903 459.534 61.7747C467.93 75.0082 490.029 110.359 491.468 114.974C493.566 121.728 505.425 130.747 515.409 133.183C516.212 133.377 517.02 132.895 517.22 132.103C517.248 131.986 517.261 131.864 517.261 131.747C517.261 131.085 516.807 130.477 516.124 130.315C506.525 127.969 495.938 119.297 494.323 114.105C492.624 108.639 467.722 69.1412 462.848 61.437L483.897 47.9559C484.589 47.5146 484.789 46.5961 484.339 45.9117C483.888 45.2273 482.965 45.0291 482.273 45.4749L459.984 59.744L459.989 59.7485Z"
            fill="#014A4F"
          />
          <path
            d="M505.24 155.346C505.003 156.125 505.453 156.953 506.247 157.183L534.05 165.378C534.839 165.612 535.675 165.166 535.907 164.383C535.949 164.243 535.967 164.099 535.967 163.959C535.967 163.324 535.55 162.735 534.9 162.541L507.096 154.346C506.307 154.112 505.472 154.558 505.24 155.341V155.346Z"
            fill="#014A4F"
          />
          <path
            d="M558.563 141.261L553.875 154.044C552.315 153.697 549.558 153.09 546.815 152.509C544.383 151.991 542.433 151.595 541.031 151.333C538.246 150.811 537.095 150.591 536.659 151.986C536.418 152.765 536.854 153.594 537.643 153.837C537.87 153.909 538.107 153.923 538.33 153.882C539.843 154.017 548.574 155.886 554.501 157.219C555.235 157.385 555.977 156.98 556.237 156.282L561.376 142.278C561.436 142.112 561.464 141.941 561.464 141.774C561.464 141.171 561.088 140.604 560.48 140.383C559.705 140.104 558.846 140.5 558.567 141.27L558.563 141.261ZM539.509 152.86C539.458 153.013 539.388 153.157 539.295 153.283C539.388 153.157 539.458 153.017 539.509 152.86Z"
            fill="#014A4F"
          />
          <path
            d="M528.959 176.603C528.68 177.368 529.084 178.219 529.859 178.494L558.391 188.62C559.166 188.895 560.025 188.494 560.304 187.729C560.364 187.562 560.392 187.396 560.392 187.229C560.392 186.621 560.011 186.054 559.403 185.838L530.871 175.711C530.096 175.436 529.237 175.837 528.959 176.603Z"
            fill="#014A4F"
          />
          <path
            d="M543.087 198.347C545.102 204.421 553.099 205.177 553.443 205.209C554.264 205.281 554.989 204.677 555.063 203.862C555.063 203.817 555.067 203.777 555.067 203.732C555.067 202.975 554.483 202.327 553.703 202.259C553.642 202.255 547.33 201.634 545.928 197.423C545.673 196.649 544.828 196.226 544.044 196.478C543.259 196.73 542.832 197.568 543.092 198.342L543.087 198.347Z"
            fill="#014A4F"
          />
          <path
            d="M543.148 153.373L539.783 179.948C539.681 180.763 540.261 181.497 541.078 181.601C541.895 181.704 542.642 181.128 542.744 180.318L546.109 153.743C546.119 153.68 546.123 153.616 546.123 153.558C546.123 152.824 545.571 152.189 544.814 152.095C543.997 151.991 543.25 152.567 543.148 153.378V153.373Z"
            fill="#014A4F"
          />
          <path
            d="M505.416 218.843C504.966 219.527 505.161 220.441 505.852 220.892L527.886 235.255C528.578 235.701 529.502 235.507 529.952 234.823C530.114 234.575 530.193 234.292 530.193 234.017C530.193 233.535 529.956 233.062 529.515 232.779L507.482 218.415C506.79 217.969 505.866 218.163 505.416 218.847V218.843Z"
            fill="#014A4F"
          />
          <path
            d="M669.706 265.734L694.293 418.75C694.409 419.561 695.165 420.119 695.982 420.006C696.73 419.903 697.263 418.408 697.254 418.336L672.668 265.32C672.551 264.51 671.795 263.951 670.978 264.064C670.161 264.176 669.599 264.928 669.706 265.734Z"
            fill="#014A4F"
          />
          <path
            d="M714.266 252.766L735.088 415.314C735.162 416.129 735.887 416.728 736.708 416.656C737.53 416.584 738.138 415.864 738.063 415.053L717.26 252.708L743.991 112.934V97.0308L714.284 252.361C714.261 252.496 714.252 252.631 714.266 252.766Z"
            fill="#014A4F"
          />
          <path
            d="M2.20915 85.5129L0.0090332 97.0264L0.0090332 112.93L5.14728 86.0623C5.16584 85.9677 5.17515 85.8777 5.17515 85.7876C5.17515 85.0897 4.67386 84.4728 3.95905 84.3377C3.14677 84.1846 2.36697 84.7115 2.2138 85.5129H2.20915Z"
            fill="#014A4F"
          />
          <path
            d="M741.419 216.645C739.409 225.448 741.41 231.725 743.995 236.39V227.902C743.503 225.335 743.42 222.404 743.995 219.023V215.997C743.796 215.772 743.526 215.605 743.206 215.533C742.403 215.353 741.6 215.853 741.419 216.65V216.645Z"
            fill="#014A4F"
          />
          <path
            d="M0.343468 217.298C0.454866 216.817 0.310985 216.339 0.00463867 215.997L0.00463867 219.023C0.102113 218.46 0.208861 217.888 0.343468 217.298Z"
            fill="#014A4F"
          />
          <path
            d="M3.36981 241.554C3.83397 242.203 4.27493 242.82 4.65555 243.396L20.5809 386.483C20.6366 387.298 21.3468 387.915 22.1684 387.861C22.9574 387.807 23.5655 387.159 23.5655 386.389C23.5655 386.357 23.5655 386.321 23.5655 386.29L7.61225 242.82C7.59369 242.572 7.51478 242.333 7.38017 242.122C6.91601 241.401 6.38222 240.649 5.8113 239.852C3.65759 236.835 1.00259 233.107 0.00463867 227.902L0.00463867 236.39C1.10934 238.384 2.3208 240.082 3.36981 241.554Z"
            fill="#014A4F"
          />
          <path
            d="M662.275 207.15C662.275 207.965 662.944 208.627 663.77 208.627H724.144L743.996 217.191V213.967L725.053 205.794C724.863 205.713 724.663 205.673 724.454 205.673H663.77C662.944 205.673 662.275 206.335 662.275 207.15Z"
            fill="#014A4F"
          />
          <path
            d="M172.779 291.71C173.536 292.035 174.418 291.692 174.747 290.945C174.831 290.751 174.873 290.553 174.873 290.355C174.873 289.783 174.538 289.243 173.977 289L0.0090332 213.962L0.0090332 217.186L172.784 291.706L172.779 291.71Z"
            fill="#014A4F"
          />
          <path
            d="M560.689 98.5753C560.215 99.2462 560.382 100.169 561.055 100.633L683.371 179.498C683.645 179.687 683.975 179.782 684.309 179.764C684.736 179.741 726.952 177.49 737.831 181.52C738.76 181.862 740.886 182.731 743.991 184.028V180.822C741.466 179.773 739.711 179.061 738.876 178.751C727.959 174.707 689.795 176.517 684.657 176.783L562.764 98.2106C562.086 97.7423 561.158 97.9044 560.684 98.5753H560.689Z"
            fill="#014A4F"
          />
          <path
            d="M190.107 265.855C190.863 266.184 191.745 265.842 192.075 265.094C192.158 264.901 192.2 264.698 192.2 264.5C192.2 263.933 191.866 263.388 191.304 263.145C184.309 260.114 31.5305 193.915 0 180.812L0 184.018C34.2366 198.315 188.408 265.112 190.102 265.851L190.107 265.855Z"
            fill="#014A4F"
          />
          <path
            d="M678.376 52.0983C677.977 52.8097 678.233 53.7102 678.957 54.111L743.995 89.8896V86.508L680.41 51.5309C679.69 51.1347 678.78 51.3913 678.376 52.1028V52.0983Z"
            fill="#014A4F"
          />
          <path
            d="M185.047 191.678L136.287 307.209C135.967 307.965 136.329 308.83 137.09 309.14C137.851 309.456 138.729 309.1 139.044 308.348L188.32 191.597C188.399 191.412 188.436 191.219 188.436 191.03C188.436 190.507 188.152 190.003 187.665 189.737L0.00463867 86.5034L0.00463867 89.885L185.043 191.678H185.047Z"
            fill="#014A4F"
          />
          <path
            d="M624.594 74.5938C624.302 75.2602 624.543 76.0347 625.161 76.4264L714.869 133.107C714.98 133.179 715.101 133.233 715.227 133.273L737.042 140.05L743.995 142.909V139.708L738.128 137.294C738.087 137.276 738.045 137.263 738.003 137.249L716.313 130.513L627.932 74.6704C629.575 71.8517 635.047 63.7017 649.38 51.2472C669.381 33.8622 705.725 40.8865 709.805 41.742C713.231 42.4624 726.998 49.6082 743.995 58.8208V55.4482C727.23 46.3798 714.075 39.6167 710.427 38.8467C706.199 37.9552 668.509 30.6878 647.408 49.0229C628.841 65.1606 624.757 74.2201 624.594 74.5983V74.5938Z"
            fill="#014A4F"
          />
          <path
            d="M35.8751 78.5292C60.768 92.294 84.2824 105.298 91.0035 107.963C105.564 113.736 148.04 158.389 150.839 163.14C151.256 163.842 152.171 164.081 152.881 163.671C153.596 163.257 153.832 162.352 153.419 161.649C150.528 156.746 107.625 111.372 92.1128 105.221C85.5728 102.628 62.1373 89.6689 37.3325 75.9491C24.452 68.8258 11.3952 61.608 0.00463867 55.4482L0.00463867 58.8208C11.2327 64.9085 23.8718 71.8967 35.8751 78.5337V78.5292Z"
            fill="#014A4F"
          />
          <path
            d="M216.991 232.117C217.752 232.432 218.629 232.072 218.945 231.32C219.024 231.135 219.061 230.942 219.061 230.753C219.061 230.172 218.713 229.622 218.137 229.388L0.00463867 139.708L0.00463867 142.909L216.991 232.117Z"
            fill="#014A4F"
          />
          <path
            d="M743.007 182.286L736.276 212.562C736.095 213.354 736.611 214.147 737.414 214.322C738.221 214.498 739.015 213.994 739.191 213.197L743.991 191.611V180.827L743.299 181.678C743.155 181.858 743.053 182.065 743.002 182.286H743.007Z"
            fill="#014A4F"
          />
          <path
            d="M54.7755 87.3813L24.3403 150.951L0.0090332 180.826L0.0090332 191.61L1.86568 183.262L26.7818 152.671C26.8561 152.581 26.9165 152.482 26.9675 152.378L57.4815 88.6511C57.579 88.4485 57.6254 88.2323 57.6254 88.0207C57.6254 87.4669 57.3097 86.9356 56.7713 86.6834C56.024 86.3322 55.1329 86.6474 54.7801 87.3858L54.7755 87.3813Z"
            fill="#014A4F"
          />
          <path
            d="M86.5101 104.429L38.163 232.121C37.8752 232.882 38.2651 233.738 39.0403 234.026C39.8108 234.314 40.6742 233.927 40.9619 233.161L89.309 105.469C89.374 105.298 89.4065 105.122 89.4065 104.951C89.4065 104.352 89.0352 103.789 88.4364 103.569C87.6659 103.281 86.8025 103.668 86.5148 104.433L86.5101 104.429Z"
            fill="#014A4F"
          />
          <path
            d="M120.784 130.252L74.9992 247.993C74.7022 248.75 75.0875 249.61 75.858 249.902C76.6285 250.195 77.4918 249.817 77.7888 249.051L123.574 131.31C123.643 131.134 123.676 130.954 123.676 130.779C123.676 130.184 123.309 129.626 122.715 129.401C121.945 129.108 121.081 129.486 120.784 130.252Z"
            fill="#014A4F"
          />
          <path
            d="M718.875 43.7997L618.551 196.933C618.128 197.581 618.277 198.437 618.894 198.909L647.468 234.134C648.123 234.634 649.06 234.512 649.562 233.864C650.063 233.216 649.942 232.288 649.288 231.793L621.805 197.396L721.377 45.4117C721.539 45.164 721.618 44.8849 721.618 44.6102C721.618 44.1239 721.377 43.6511 720.936 43.3675C720.244 42.9217 719.316 43.1198 718.87 43.8043L718.875 43.7997Z"
            fill="#014A4F"
          />
          <path
            d="M639.494 58.8836C639.099 59.604 639.373 60.5001 640.097 60.8873L690.18 87.0886C690.904 87.4758 691.814 87.2102 692.204 86.4898C692.325 86.2646 692.385 86.026 692.385 85.7874C692.385 85.2605 692.102 84.7517 691.6 84.4861L641.517 58.2847C640.793 57.8975 639.884 58.1632 639.494 58.8836Z"
            fill="#014A4F"
          />
          <path
            d="M559.157 51.238C558.716 51.9269 558.92 52.841 559.616 53.2778L664.814 129.068C665.51 129.504 666.434 129.302 666.875 128.613C667.032 128.37 667.107 128.09 667.107 127.82C667.107 127.329 666.861 126.852 666.415 126.573L561.217 50.7833C560.521 50.3465 559.598 50.5491 559.157 51.238Z"
            fill="#014A4F"
          />
          <path
            d="M685.353 178.021L678.261 216.443L652.542 258.705C652.138 259.363 652.319 260.218 652.95 260.668L664.74 269.03C665.413 269.507 666.346 269.354 666.824 268.688C667.302 268.026 667.149 267.098 666.48 266.625L655.814 259.061L680.962 217.739C681.055 217.586 681.12 217.42 681.153 217.244L688.291 178.557C688.31 178.467 688.315 178.377 688.315 178.291C688.315 177.589 687.809 176.967 687.089 176.837C686.277 176.688 685.497 177.224 685.353 178.025V178.021Z"
            fill="#014A4F"
          />
          <path
            d="M383.927 20.39C381.88 22.9475 195.589 255.576 185.748 268.413C176.86 280.012 170.213 291.913 166.537 302.832C163.408 312.121 148.62 405.53 148.383 406.264C148.128 407.043 148.513 407.277 149.293 407.529C150.077 407.781 150.922 407.358 151.178 406.579C151.419 405.845 166.249 313.049 169.373 303.769C172.947 293.151 179.436 281.543 188.129 270.201C197.951 257.391 384.224 24.7847 386.271 22.2271C386.489 21.9569 386.596 21.6327 386.596 21.3086C386.596 20.8718 386.401 20.4395 386.029 20.1514C385.384 19.6425 384.442 19.7506 383.931 20.39H383.927Z"
            fill="#014A4F"
          />
          <path
            d="M219.659 241.532L219.543 241.734C218.74 243.13 217.719 244.477 216.229 246.102C214.786 247.678 212.975 249.439 210.757 251.577C206.259 255.905 196.553 267.967 194.168 273.168C191.916 278.067 174.162 322.918 173.41 324.828C173.113 325.58 173.475 326.422 174.227 326.732L345.327 408.394C346.088 408.709 346.965 408.353 347.281 407.597C347.36 407.412 347.049 405.899 346.478 405.665L176.729 324.557C179.779 316.862 194.887 278.751 196.892 274.393C198.976 269.863 208.181 258.187 212.85 253.698C215.106 251.523 216.953 249.731 218.453 248.092C220.091 246.3 221.233 244.792 222.147 243.207L222.263 243C223.934 240.1 224.775 238.641 230.163 241.127C234.828 243.283 246.2 248.822 250.972 253.671C255.544 258.318 269.775 267.85 270.378 268.251C271.061 268.71 271.989 268.53 272.453 267.855C272.913 267.179 272.736 266.261 272.049 265.802C271.905 265.702 257.47 256.04 253.107 251.609C248.173 246.597 237.404 241.212 231.421 238.447C224.12 235.075 221.86 237.723 219.664 241.536L219.659 241.532Z"
            fill="#014A4F"
          />
          <path
            d="M248.605 281.953C247.918 283.894 247.329 285.564 246.864 286.586C245.969 288.54 244.117 291.116 241.972 294.092C238.537 298.865 234.258 304.809 231.793 311.275C227.908 321.455 205.025 384.759 204.798 385.398C204.519 386.168 204.923 387.015 205.703 387.29C206.478 387.564 207.336 387.163 207.61 386.398C207.842 385.759 230.712 322.482 234.587 312.324C236.918 306.218 241.067 300.45 244.404 295.817C246.637 292.71 248.568 290.031 249.585 287.811C250.104 286.667 250.717 284.938 251.427 282.935C253.437 277.234 256.807 267.697 260.692 265.941C261.402 265.622 262.066 265.604 262.785 265.887C270.449 268.891 387.204 302.454 392.314 303.917C393.108 304.142 393.665 303.737 393.892 302.954C393.934 302.819 394.222 302.629 394.222 302.494C394.222 301.85 393.795 301.261 393.14 301.076C391.887 300.716 271.414 266.09 263.881 263.136C262.395 262.555 260.901 262.591 259.443 263.253C254.398 265.536 251.047 275.028 248.6 281.962L248.605 281.953Z"
            fill="#014A4F"
          />
          <path
            d="M215.956 246.534C215.645 247.291 216.007 248.155 216.773 248.462L259.74 265.869C260.506 266.18 261.378 265.815 261.689 265.063C261.764 264.878 261.801 264.689 261.801 264.505C261.801 263.924 261.453 263.37 260.873 263.136L217.905 245.728C217.139 245.418 216.267 245.782 215.956 246.534Z"
            fill="#014A4F"
          />
          <path
            d="M210.497 251.911C210.093 252.626 210.349 253.523 211.068 253.923L251.552 276.468C252.272 276.869 253.182 276.612 253.585 275.901C253.715 275.671 253.776 275.424 253.776 275.181C253.776 274.663 253.502 274.158 253.014 273.888L212.53 251.343C211.811 250.942 210.901 251.199 210.497 251.911Z"
            fill="#014A4F"
          />
          <path
            d="M197.473 267.765C197.148 268.512 197.496 269.386 198.257 269.705C214.568 276.635 241.383 289.081 243.903 292.984C244.349 293.673 245.272 293.876 245.969 293.435C246.414 293.151 246.66 292.674 246.66 292.187C246.66 291.917 246.586 291.638 246.423 291.39C242.576 285.438 203.841 268.859 199.441 266.986C198.684 266.666 197.802 267.013 197.478 267.76L197.473 267.765Z"
            fill="#014A4F"
          />
          <path
            d="M225.699 218.212C225.314 218.933 225.592 219.829 226.321 220.212L280.266 248.403C280.317 248.43 280.373 248.457 280.428 248.475C280.971 248.682 293.88 253.541 304.843 254.113C315.584 254.671 417.672 268.706 418.475 268.733C419.301 268.76 419.988 268.121 420.016 267.306C420.016 267.288 420.016 267.274 420.016 267.256C420.016 266.464 419.38 265.806 418.572 265.779C417.769 265.752 315.714 251.722 305.001 251.163C294.943 250.637 282.689 246.161 281.579 245.751L227.713 217.605C226.985 217.222 226.08 217.501 225.694 218.221L225.699 218.212Z"
            fill="#014A4F"
          />
          <path
            d="M368.057 107.657C367.871 109.863 364.009 157.651 365.365 171.487C366.678 184.942 353.218 258.237 353.167 258.435C352.972 259.228 353.464 260.029 354.262 260.223C355.065 260.416 355.873 259.93 356.068 259.137C356.272 258.304 369.7 185.153 368.335 171.204C367.013 157.633 371.037 108.359 371.079 107.824C371.079 107.783 371.032 107.824 371.032 107.779C371.032 107.018 371.264 106.581 370.485 106.513C369.663 106.446 368.117 106.842 368.048 107.657H368.057Z"
            fill="#014A4F"
          />
          <path
            d="M298.706 128.649C298.642 129.468 299.24 130.18 300.062 130.256L367.672 135.925C368.493 135.997 369.222 135.399 369.296 134.584C369.296 134.539 369.301 134.494 369.301 134.448C369.301 133.692 368.716 133.048 367.941 132.976L300.331 127.307C299.509 127.235 298.781 127.834 298.706 128.649Z"
            fill="#014A4F"
          />
          <path
            d="M69.5128 -26.7401L89.249 -21.5529L101.494 -3.89777L86.4454 72.4458C86.283 73.2428 86.8168 74.0218 87.6244 74.1794C88.4321 74.337 89.2211 73.8147 89.3789 73.0132L104.548 -3.93829C104.566 -4.03285 104.576 -4.12741 104.576 -4.22197C104.576 -4.51914 104.487 -4.81182 104.316 -5.05946L91.4027 -23.6782C91.1985 -23.9709 90.9014 -24.178 90.5533 -24.2681L70.2044 -29.6173C70.1534 -29.6308 70.1023 -29.6398 70.0466 -29.6488L0.00463867 -40.3428L0.00463867 -37.353L69.5128 -26.7401Z"
            fill="#014A4F"
          />
          <path
            d="M76.6566 18.5305L57.6863 53.9128C57.3196 54.5927 57.5471 55.4392 58.2062 55.8535L164.021 122.197C164.717 122.633 165.641 122.431 166.082 121.737C166.24 121.49 166.314 121.219 166.314 120.949C166.314 120.458 166.068 119.981 165.618 119.698L60.9726 54.0884L79.293 19.9128C79.6783 19.1879 79.3998 18.2963 78.6757 17.9136C77.947 17.5309 77.0419 17.8055 76.6566 18.526V18.5305Z"
            fill="#014A4F"
          />
          <path
            d="M64.1102 41.9309C63.7853 42.6784 64.1334 43.5519 64.89 43.8761L211.208 106.306C211.249 106.324 211.296 106.342 211.338 106.356L254.542 119.508C255.326 119.76 256.171 119.337 256.426 118.558C256.477 118.405 256.501 118.252 256.501 118.103C256.501 117.482 256.097 116.901 255.465 116.699L212.331 103.569L66.0782 41.1655C65.3216 40.8413 64.4397 41.188 64.1148 41.94L64.1102 41.9309Z"
            fill="#014A4F"
          />
          <path
            d="M143.867 -19.1078L116.454 91.1818C116.259 91.9743 116.746 92.7758 117.549 92.9694C118.352 93.163 119.16 92.6812 119.355 91.8887L146.768 -18.4009C146.796 -18.5179 146.81 -18.6395 146.81 -18.7566C146.81 -19.423 146.355 -20.0263 145.673 -20.1929C144.87 -20.3866 144.062 -19.9048 143.867 -19.1123V-19.1078Z"
            fill="#014A4F"
          />
          <path
            d="M350.544 15.4372C339.915 23.9834 328.854 26.8111 316.73 24.087C308.802 22.3039 147.817 -0.880604 140.966 -1.8667C140.149 -1.98377 139.392 -1.42543 139.272 -0.614944C139.156 0.191042 139.717 0.942997 140.534 1.06007C142.21 1.30321 308.25 25.2172 316.066 26.9732C329.142 29.9135 341.033 26.8922 352.424 17.7336C366.739 6.22469 449.225 -45.8357 449.527 -46.0924C449.875 -46.3851 450.051 -46.7993 450.051 -47.2181C450.051 -47.5558 449.935 -47.898 449.694 -48.1772C449.16 -48.7985 448.213 -48.8706 447.586 -48.3438C447.285 -48.0871 364.827 3.95081 350.54 15.4372H350.544Z"
            fill="#014A4F"
          />
          <path
            d="M323.976 -8.99901C323.428 -8.85492 323.015 -8.41817 322.899 -7.86884L303.348 85.8372L285.395 143.04C285.195 143.837 285.678 144.634 286.476 144.836C287.275 145.034 288.091 144.557 288.291 143.765L306.259 86.4991L325.637 -6.37842L373.562 -19.1121C374.23 -19.2877 374.671 -19.8866 374.671 -20.5395C374.671 -20.6656 374.653 -20.7961 374.62 -20.9222C374.407 -21.7102 373.585 -22.1785 372.792 -21.9668L323.98 -8.99452L323.976 -8.99901Z"
            fill="#014A4F"
          />
          <path
            d="M321.771 1.59563C320.972 1.80276 320.494 2.61324 320.708 3.40122C320.917 4.18919 321.734 4.66199 322.532 4.45486L373.553 -8.82816C374.221 -9.00376 374.667 -9.60263 374.667 -10.2555C374.667 -10.3816 374.653 -10.5077 374.616 -10.6338C374.407 -11.4217 373.59 -11.8945 372.791 -11.6874L321.771 1.59563Z"
            fill="#014A4F"
          />
          <path
            d="M312.436 42.2869C312.306 43.0929 312.863 43.8493 313.68 43.9754L363.016 48.2935C363.833 48.4196 364.102 47.8973 364.232 47.0913C364.246 47.0148 364.742 46.9067 364.742 46.8302C364.742 46.1142 364.218 45.4884 363.48 45.3713L314.144 41.0532C313.327 40.9271 312.566 41.4764 312.436 42.2824V42.2869Z"
            fill="#014A4F"
          />
          <path
            d="M163.58 -7.54485L161.742 2.15852C161.589 2.96 162.123 3.73446 162.935 3.88305C163.747 4.03164 164.527 3.50483 164.68 2.70334L166.518 -7.00001C166.537 -7.09007 166.542 -7.18463 166.542 -7.27468C166.542 -7.97261 166.04 -8.59398 165.321 -8.72456C164.508 -8.87765 163.729 -8.34633 163.576 -7.54485H163.58Z"
            fill="#014A4F"
          />
          <path
            d="M244.771 -53.2294C241.452 -38.7802 205.424 118.162 204.714 125.583C203.706 136.065 208.093 143.607 217.06 146.795L218.69 147.376C228.4 150.852 234.494 152.779 237.196 150.23C238.398 149.096 238.635 147.326 237.901 144.967C235.966 138.771 241.925 121.607 244.381 115.519C244.687 114.763 244.316 113.903 243.55 113.596C242.784 113.295 241.916 113.664 241.61 114.42C241.234 115.352 232.41 137.389 235.047 145.84C235.395 146.952 235.427 147.812 235.135 148.092C233.575 149.564 225.23 146.574 219.711 144.598L218.077 144.012C208.39 140.568 207.086 132.238 207.694 125.862C208.404 118.437 247.486 -51.6895 247.881 -53.405C247.904 -53.5131 247.918 -53.6257 247.918 -53.7337C247.918 -54.139 247.746 -54.5352 247.44 -54.8189L81.6087 -206.475H77.2085L244.775 -53.2294H244.771Z"
            fill="#014A4F"
          />
          <path
            d="M258.812 16.779L256.152 36.672H245.161C244.418 36.672 243.791 37.2123 243.685 37.9373L241.763 50.9411L224.051 46.0287C223.257 45.808 222.431 46.2673 222.208 47.0508C221.985 47.8387 222.449 48.6537 223.243 48.8743L242.589 54.2416C243.002 54.3542 243.448 54.2911 243.805 54.057C244.163 53.8273 244.409 53.4536 244.469 53.0304L246.451 39.6303H257.461C258.208 39.6303 258.844 39.0809 258.942 38.347L261.773 17.1707C261.782 17.1032 261.787 17.0401 261.787 16.9726C261.787 16.2432 261.239 15.6083 260.487 15.5092C259.67 15.4011 258.918 15.973 258.812 16.779Z"
            fill="#014A4F"
          />
          <path
            d="M218.3 76.4353C218.356 76.3273 218.397 76.2102 218.425 76.0886C218.397 76.2147 218.356 76.3318 218.3 76.4353Z"
            fill="#014A4F"
          />
          <path
            d="M250.285 69.3209L242.33 82.3428C217.144 73.9677 216.996 74.0487 216.23 74.481C215.859 74.6881 215.594 75.0438 215.501 75.4536C215.32 76.2506 215.835 77.0385 216.638 77.2141C216.722 77.2322 216.805 77.2412 216.889 77.2457C218.662 77.6554 233.427 82.5003 242.511 85.5217C243.174 85.7423 243.903 85.4767 244.265 84.8868L252.838 70.8518C252.982 70.6132 253.052 70.3475 253.052 70.0908C253.052 69.591 252.797 69.1002 252.332 68.8256C251.627 68.4023 250.708 68.6275 250.281 69.3254L250.285 69.3209ZM218.295 76.4352C218.24 76.5433 218.17 76.6468 218.096 76.7324C218.175 76.6468 218.24 76.5478 218.295 76.4352Z"
            fill="#014A4F"
          />
          <path
            d="M287.084 -52.2118L253.775 117.761C253.618 118.558 256.129 119.261 256.709 118.324L290.184 -52.6486C290.04 -51.7435 290.05 -51.8381 290.05 -51.9282C290.05 -52.6216 289.553 -53.2429 288.838 -53.378C288.03 -53.5311 287.246 -53.0088 287.088 -52.2073L287.084 -52.2118Z"
            fill="#014A4F"
          />
          <path
            d="M541.468 65.6828L543.148 78.5021C543.185 78.7722 543.292 79.0244 543.464 79.236L559.705 99.2596L547.316 133.597C547.135 134.399 547.646 135.182 548.453 135.358C549.261 135.533 550.055 135.029 550.236 134.232L562.778 99.2056C562.801 99.102 562.815 98.9939 562.815 98.8859C562.815 98.5526 562.699 98.224 562.485 97.9583L546.058 77.7051L544.429 65.3001C544.322 64.4896 543.575 63.9177 542.754 64.0258C541.937 64.1294 541.361 64.8678 541.463 65.6828H541.468Z"
            fill="#014A4F"
          />
          <path
            d="M643.667 -48.0647L660.753 -28.9507C661.291 -25.5286 664.694 -4.04166 666.383 3.3518C667.024 6.15249 668.24 8.06165 669.999 9.02523C672.533 10.4076 675.411 9.40796 676.957 8.86763C677.249 8.76407 677.5 8.67401 677.699 8.62448C678.377 8.45788 678.832 7.85452 678.832 7.19262C678.832 7.07555 678.818 6.95398 678.786 6.83241C678.586 6.03993 677.774 5.55813 676.975 5.75625C676.711 5.82379 676.367 5.93635 675.968 6.07594C674.71 6.5127 672.807 7.17461 671.447 6.43616C670.468 5.90034 669.744 4.64407 669.303 2.6989C667.525 -5.07279 663.682 -29.5856 663.645 -29.8332C663.603 -30.1124 663.477 -30.3736 663.287 -30.5852L645.909 -50.0234C645.361 -50.6358 644.419 -50.6898 643.802 -50.1495C643.184 -49.6092 643.124 -48.6771 643.672 -48.0647H643.667Z"
            fill="#014A4F"
          />
          <path
            d="M529.511 -12.8174L468.655 21.2547L350.656 62.9004C349.922 63.2741 349.63 64.1656 350.01 64.8906C350.386 65.6155 351.287 65.9037 352.02 65.53L470.07 23.8528L531.098 -10.3139C531.177 -10.3589 531.247 -10.4084 531.317 -10.4625L732.661 -175.955C733.018 -176.248 733.199 -176.671 733.199 -177.095C733.199 -177.428 733.088 -177.761 732.856 -178.04C732.326 -178.666 731.384 -178.756 730.753 -178.234L529.511 -12.8219V-12.8174Z"
            fill="#014A4F"
          />
          <path
            d="M464.417 -88.8819L571.81 43.4395C572.325 44.0744 573.267 44.178 573.913 43.6692C574.279 43.3765 574.47 42.9487 574.47 42.5165C574.47 42.1923 574.363 41.8636 574.14 41.5934L466.747 -90.728C466.232 -91.3629 465.289 -91.4664 464.644 -90.9531C463.999 -90.4398 463.897 -89.5122 464.417 -88.8729V-88.8819Z"
            fill="#014A4F"
          />
          <path
            d="M34.8072 -84.1407L10.3552 -68.1605C10.0024 -67.9309 9.76571 -67.5662 9.70073 -67.1519L2.19522 -18.9818C2.13488 -18.5901 2.23234 -18.1894 2.4737 -17.8742C2.71042 -17.559 3.06784 -17.3474 3.46701 -17.2933C13.4094 -15.92 19.708 -9.58466 23.8808 -5.39713C24.8881 -4.38402 25.7607 -3.51049 26.5312 -2.8666C30.4905 0.442896 34.8583 1.2894 38.8315 -0.480167C42.0992 -1.93905 57.7925 -22.9893 62.5223 -29.4101C62.708 -29.6668 62.8101 -29.9685 62.8101 -30.2792C62.8101 -30.3602 62.8055 -30.4413 62.7915 -30.5223L57.6022 -61.5685C57.5604 -61.8297 57.4444 -62.0729 57.2726 -62.28L0.0090332 -129.767L0.0090332 -125.169L34.8119 -84.1542L34.8072 -84.1407ZM54.7151 -60.677L59.7373 -30.6439C50.2638 -17.8201 39.486 -4.15439 37.5875 -3.1638C34.7376 -1.88953 31.4884 -2.58293 28.4575 -5.11796C27.7891 -5.6763 26.9675 -6.50481 26.0114 -7.46389C22.0196 -11.4713 15.4702 -18.0588 5.37471 -19.9724L12.5553 -66.0533L36.7428 -81.8623L54.7151 -60.6815V-60.677Z"
            fill="#014A4F"
          />
          <path
            d="M500.102 459.653L507.031 458.315L696.679 163.792L743.995 95.6438V86.5078L692.292 160.974L500.102 459.653Z"
            fill="#014A4F"
          />
          <path
            d="M257.127 -60.1772L257.034 -60.1457L65.0708 6.26494L29.7203 43.7096L0.00463867 86.5079L0.00463867 95.6439L33.9209 46.7984L68.0136 10.7271L258.668 -55.2287L357.59 -81.2724L456.503 -152.586L453.421 -156.765L355.297 -86.0228L257.127 -60.1772Z"
            fill="#014A4F"
          />
          <path
            d="M608.163 292.062L610.595 296.641C611.12 296.371 663.296 269.332 692.547 260.561C711.23 254.959 729.731 254.243 743.995 256.144V250.956C729.1 249.088 710.097 249.894 691.03 255.608C661.3 264.523 610.317 290.94 608.163 292.062Z"
            fill="#014A4F"
          />
          <path
            d="M17.2529 260.462C20.0796 261.655 28.1328 264.982 39.2773 269.584C76.2246 284.839 153.104 316.583 163.547 322.09L165.009 322.86C177.625 329.493 184.574 333.149 191.221 353.699C198.424 375.97 218.397 416.008 218.481 416.179L223.42 415.017C223.341 414.846 203.293 374.047 196.201 352.123C188.909 329.569 180.378 325.085 167.465 318.294L166.012 317.529C155.35 311.905 81.154 281.269 41.2918 264.811C30.1612 260.214 22.1173 256.895 19.3091 255.707C14.3565 253.613 7.73293 251.924 0.00463867 250.956L0.00463867 256.143C6.89744 257.062 12.8016 258.584 17.2529 260.466V260.462Z"
            fill="#014A4F"
          />
          <path
            d="M94.4892 307.366L59.1387 403.526L64.1052 405.147L99.4325 309.046L149.172 180.975L149.2 180.885L217.469 -42.1033L212.465 -43.6027L144.224 179.295L94.4892 307.366Z"
            fill="#014A4F"
          />
          <path
            d="M62.815 8.5656L76.6331 21.5289L77.2411 21.7045L300.141 85.9943C302.322 87.5072 314.423 96.0354 319.083 102.177C325.238 110.296 337.664 109.526 352.053 108.634L352.981 108.575C367.519 107.675 460.453 130.301 461.79 129.968L461.363 125.519C461.038 125.6 366.711 102.542 352.651 103.411L351.723 103.469C338.313 104.302 327.726 104.96 323.261 99.0747C317.644 91.6678 303.367 81.9104 302.759 81.5006L302.411 81.2665L302.007 81.1494L79.3066 16.9136L70.2601 8.42602L102.714 -24.3988L329.87 -176.33L326.941 -180.616L99.3445 -28.3882L62.8057 8.57011L62.815 8.5656Z"
            fill="#014A4F"
          />
          <path
            d="M453.314 130.828C425.098 152.446 384.27 159.515 365.931 162.811L366.869 167.903C385.765 164.504 427.288 157.309 456.517 134.921C484.543 113.452 593.936 29.7511 593.936 29.7511L594.006 29.697L711.782 -66.9628L708.445 -70.9432L590.701 25.6896C588.473 27.3916 481.048 109.589 453.314 130.837V130.828Z"
            fill="#014A4F"
          />
          <path
            d="M441.84 -80.8762C448.696 -77.4857 455.779 -73.9826 449.74 -57.1019C441.682 -34.6063 446.226 -17.2843 456.173 3.37864C460.318 11.9878 469.332 27.8194 478.875 44.583C490.6 65.174 502.719 86.4673 508.6 99.0704C517.651 118.473 534.231 128.626 546.337 136.038C549.214 137.798 551.702 139.32 553.758 140.77C564.55 148.362 660.177 206.866 664.243 209.351L666.991 204.952C665.997 204.344 567.442 144.048 556.785 136.551C554.589 135.007 551.916 133.368 549.084 131.634C537.545 124.565 521.736 114.888 513.344 96.9001C507.375 84.1079 495.2 62.72 483.429 42.0435C473.932 25.3609 464.96 9.6014 460.894 1.1543C451.281 -18.8153 447.182 -34.4667 454.669 -55.3728C462.258 -76.5626 451.378 -81.9434 444.179 -85.5005C442.903 -86.1309 441.696 -86.7298 440.763 -87.3331L437.899 -83.0015C439.087 -82.2316 440.429 -81.5697 441.845 -80.8672L441.84 -80.8762Z"
            fill="#014A4F"
          />
          <path
            d="M325.749 -197.132C325.057 -194.462 324.189 -191.139 324.7 -186.857C326.05 -175.501 344.236 -130.537 345.8 -126.687C347.527 -119.005 374.852 2.42865 380.237 15.7432C381.211 18.1476 381.894 20.2684 382.497 22.1371C385.528 31.5207 434.135 146.93 434.135 146.93L439.361 142.382C439.361 142.382 390.067 28.5669 387.482 20.5611C386.851 18.6114 386.136 16.4006 385.096 13.825C379.814 0.753644 351.152 -126.727 350.864 -128.015L350.739 -128.42C350.544 -128.902 331.202 -176.523 329.903 -187.46C329.508 -190.788 330.177 -193.359 330.822 -195.844C331.806 -199.631 332.34 -202.868 330.678 -206.479H324.602C327.285 -203.026 326.668 -200.639 325.758 -197.136L325.749 -197.132Z"
            fill="#014A4F"
          />
        </g>
        <mask
          id="mask1_380_2435"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="292"
          y="87"
          width="160"
          height="114"
        >
          <rect x="292" y="87" width="160" height="114" fill="#8D5151" />
        </mask>
        <g mask="url(#mask1_380_2435)">
          <path
            d="M382.665 166.977C389.266 173.562 398.22 177.261 407.555 177.261C416.891 177.261 425.844 173.562 432.446 166.977C439.047 160.393 442.755 151.462 442.755 142.149C442.755 132.837 439.047 123.906 432.446 117.321L422.125 127.616C425.989 131.471 428.16 136.698 428.16 142.149C428.16 147.6 425.989 152.828 422.125 156.683C418.261 160.537 413.02 162.703 407.555 162.703C402.091 162.703 396.85 160.537 392.986 156.683L382.665 166.977Z"
            fill="white"
          />
          <path
            d="M396.241 153.435C399.242 156.428 403.312 158.109 407.555 158.109C411.799 158.109 415.868 156.428 418.869 153.435C421.869 150.442 423.555 146.382 423.555 142.149C423.555 137.917 421.869 133.857 418.869 130.864L411.784 137.931C412.905 139.05 413.535 140.567 413.535 142.149C413.535 143.732 412.905 145.249 411.784 146.367C410.662 147.486 409.141 148.115 407.555 148.115C405.969 148.115 404.448 147.486 403.327 146.367L396.241 153.435Z"
            fill="#11B0B9"
          />
          <path
            d="M348.469 153.435C345.469 156.428 341.399 158.109 337.156 158.109C332.912 158.109 328.843 156.428 325.842 153.435C322.841 150.442 321.156 146.382 321.156 142.149C321.156 137.917 322.841 133.857 325.842 130.864L332.927 137.931C331.806 139.05 331.176 140.567 331.176 142.149C331.176 143.732 331.806 145.249 332.927 146.367C334.049 147.486 335.57 148.115 337.156 148.115C338.742 148.115 340.263 147.486 341.384 146.367L348.469 153.435Z"
            fill="#11B0B9"
          />
          <path
            d="M362.046 166.977C355.444 173.562 346.491 177.261 337.156 177.261C327.82 177.261 318.867 173.562 312.265 166.977C305.664 160.393 301.956 151.462 301.956 142.149C301.956 132.837 305.664 123.906 312.265 117.321L322.586 127.616C318.722 131.471 316.551 136.698 316.551 142.149C316.551 147.6 318.722 152.828 322.586 156.683C326.45 160.537 331.691 162.703 337.156 162.703C342.62 162.703 347.861 160.537 351.725 156.683L362.046 166.977Z"
            fill="white"
          />
          <rect
            width="25.6838"
            height="25.6838"
            transform="matrix(0.707991 -0.706221 0.707991 0.706221 353.867 179.794)"
            fill="white"
          />
          <path
            d="M334.667 107.518L372.379 124.418L410.091 107.518L372.379 145.136L334.667 107.518Z"
            fill="white"
          />
          <path
            d="M452 92.1836L432.611 92.1836L404.783 119.942L362.4 162.219L372.095 171.889L452 92.1836Z"
            fill="white"
          />
          <path
            d="M292 92.1836L311.389 92.1836L339.217 119.942L381.6 162.219L371.905 171.889L292 92.1836Z"
            fill="white"
          />
          <path
            d="M297.419 102.563L322.1 127.181L319.733 130.445L309.422 120.869L297.419 102.563Z"
            fill="white"
          />
          <path
            d="M447.856 101.644L422.597 126.84L424.952 130.114L435.644 120.515L447.856 101.644Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
