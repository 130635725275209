import React from "react";
import { useSettings } from "../../application/SettingsProvider";
import { Box, Typography } from "../../components";
import { COLORS } from "../../constants";
import { MapHomeSmall } from "../../svg/MapHomeSmall";

export function Home() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.BLACK} position="relative" id="home">
      <Box>
        <MapHomeSmall />
      </Box>
      <Box
        position="absolute"
        style={{ left: "5%", top: "40%", transform: "translate(-5%, -40%)" }}
        flexDirection={"column"}
        paddingLeft={16}
        paddingRight={16}
        zIndex={2}
      >
        <Typography size={24} lineHeight={28} weight={700} color={COLORS.WHITE}>
          {settings?.home?.title1}
          <Typography
            size={24}
            lineHeight={28}
            weight={700}
            color={COLORS.TURQUOISE}
          >
            {settings?.home?.title2}
          </Typography>
        </Typography>

        <Box marginTop={16}>
          <Typography
            size={14}
            lineHeight={20}
            weight={400}
            color={COLORS.WHITE}
          >
            {settings?.home?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
