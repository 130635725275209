import React from "react";
import { Header } from "../mobile/Header";
import { Home } from "./Home";
import { Info } from "./Info";
import { Box } from "../../components";
import { Footer } from "../desktop/blocks/Footer/Footer";
import { Possibilities } from "./Possibities/Possibilities";
import { Contacts } from "./Contacts";

export function Laptop() {
  return (
    <Box position="relative" style={{ minHeight: "100%" }}>
      <Header />
      <Box overflow="auto">
        <Home />
        <Info />
        <Possibilities />
        <Contacts />
        <Footer />
      </Box>
    </Box>
  );
}
