import React from "react";

export function MenuCross() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.36401"
        y="4.94977"
        width="16"
        height="2"
        transform="rotate(45 6.36401 4.94977)"
        fill="#11B0B9"
      />
      <rect
        x="4.94971"
        y="16.2635"
        width="16"
        height="2"
        transform="rotate(-45 4.94971 16.2635)"
        fill="#11B0B9"
      />
    </svg>
  );
}
