import React from "react";
import { Box, Typography } from "../../../../components";
import { COLORS } from "../../../../constants";

type Props = {
  text: string;
};

export function Item({ text }: Props) {
  return (
    <Box
      flexDirection="row"
      borderTop="border-top"
      borderTopWidth={1}
      borderTopStyle="solid"
      borderTopColor={COLORS.BLACK}
      borderBottom={0}
      borderLeft={0}
      borderRight={0}
      paddingTop={35}
      position="relative"
    >
      <Box
        width={16}
        height={16}
        backgroundColor={COLORS.TURQUOISE}
        position="absolute"
        style={{ left: 0, top: 0 }}
      ></Box>
      <Typography color={COLORS.BLACK} size={20} lineHeight={30} weight={400}>
        {text}
      </Typography>
    </Box>
  );
}
