import { Box } from "../../../components/Box";
import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren;

export function Container({ children }: Props) {
  return (
    <Box flexDirection="row" justifyContent="center">
      <Box
        flex={1}
        style={{ maxWidth: "1556px" }}
        marginLeft={30}
        marginRight={30}
      >
        {children}
      </Box>
    </Box>
  );
}
