import { useSettings } from "../../../../application/SettingsProvider";
import { Box, Typography, TouchableOpacity } from "../../../../components";
import { COLORS } from "../../../../constants";
import { Container } from "../../components/Container";
import { Logo } from "../../../../svg/Logo";
import React from "react";

export function Header() {
  const settings = useSettings();

  function handleLinkClick(id: string) {
    const element = document.getElementById(id);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return (
    <Box backgroundColor={COLORS.BLACK}>
      <Container>
        <Box
          paddingTop={20}
          paddingBottom={20}
          flexDirection="row"
          alignItems="center"
          gap={60}
        >
          <Logo />
          <Box flex={1} />
          <TouchableOpacity onPress={() => handleLinkClick("info")}>
            <Typography
              color={COLORS.WHITE}
              size={16}
              lineHeight={19}
              weight={500}
            >
              {settings?.menu.info}
            </Typography>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => handleLinkClick("possibilities")}>
            <Typography
              color={COLORS.WHITE}
              size={16}
              lineHeight={19}
              weight={500}
            >
              {settings?.menu.possibilities}
            </Typography>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleLinkClick("contacts")}>
            <Typography
              color={COLORS.WHITE}
              size={16}
              lineHeight={19}
              weight={500}
            >
              {settings?.menu.contacts}
            </Typography>
          </TouchableOpacity>
        </Box>
      </Container>
    </Box>
  );
}
