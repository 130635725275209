import React from "react";
import { Box, Typography } from "../../components";
import { Link } from "../../components/Link";
import { useSettings } from "../../application/SettingsProvider";
import { COLORS } from "../../constants";
import { ContactImageSmall } from "../../svg/ContactImageSmall";
import { FilinSmall } from "../../svg/FilinSmall";

export function Contacts() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.BLACK} id="contacts">
      <Box
        flexDirection={"column"}
        alignItems="center"
        gap={24}
        paddingTop={30}
        paddingBottom={30}
      >
        <FilinSmall />

        <Typography
          size={16}
          lineHeight={24}
          weight={400}
          color={COLORS.WHITE}
          style={{ textAlign: "center" }}
        >
          {settings?.contacts?.title}
        </Typography>
        <Link href={`mailto:${settings?.contacts?.mail}`}>
          <Typography
            size={24}
            lineHeight={30}
            weight={900}
            color={COLORS.TURQUOISE}
          >
            {settings?.contacts?.mail}
          </Typography>
        </Link>
      </Box>
      <ContactImageSmall style={{ maxWidth: "100%", height: "auto" }} />
    </Box>
  );
}
