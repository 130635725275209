import React from "react";
import { useSettings } from "../../../../application/SettingsProvider";
import { Container } from "../../components/Container";
import { Box, Typography } from "../../../../components";
import { LogoWhite } from "../../../../svg/LogoWhite";
import { COLORS } from "../../../../constants";
export function Footer() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.TURQUOISE}>
      <Container>
        <Box
          paddingTop={20}
          paddingBottom={20}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <LogoWhite />
          <Typography
            color={COLORS.WHITE}
            size={16}
            weight={500}
            lineHeight={19}
          >
            {settings?.footer.copyright}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
