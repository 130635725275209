import React from "react";

export function Filin() {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 796 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 221V221.5H1H53.6966H54.1966V221V142.3H124.508H125.008V141.8V94.6572V94.1572H124.508H54.1966V48.6429H134.388H134.888V48.1429V1V0.5H134.388H1H0.5V1V221ZM177.623 221V221.5H178.123H233.246H233.746L233.746 220.999L233.592 92.6661L233.592 92.428L233.407 92.2781L178.438 47.7543L177.623 47.0944V48.1429V221ZM295.439 221V221.5H295.939H442.501H443.001V221V173.857V173.357H442.501H349.135V1V0.5H348.635H295.939H295.439V1V221ZM476.9 221V221.5H477.4H531.401H531.909L531.901 220.992L529.912 92.6589L529.908 92.4275L529.729 92.2805L477.718 49.5027L476.9 48.8303V49.8889V221ZM795 221.5H795.5V221V1V0.5H795H745.597H745.097V1V132.236L636.637 0.681935L636.487 0.5H636.251H594.094H593.594V1V221V221.5H594.094H643.497H643.997V221V90.3874L752.458 221.319L752.608 221.5H752.843H795Z"
        stroke="white"
      />
      <path
        d="M178.123 1H233.092V62.1111L178.123 19.3333V1Z"
        stroke="#14B2BB"
      />
      <path d="M477.4 1H532.369V62.1111L477.4 19.3333V1Z" stroke="#14B2BB" />
    </svg>
  );
}
