import { useSettings } from "../../application/SettingsProvider";
import { Box, Typography } from "../../components";
import { COLORS } from "../../constants";
import React from "react";
import { Container } from "./components/Container";
import { MapHomeSmall } from "../../svg/MapHomeSmall";

export function Home() {
  const settings = useSettings();
  return (
    <Box backgroundColor={COLORS.BLACK} position="relative" id="home">
      <Container>
        <Box
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          paddingRight={100}
          style={{ minHeight: "395px" }}
        >
          <Box
            position="absolute"
            style={{
              left: 0,
              top: 0,
            }}
          >
            <MapHomeSmall />
          </Box>

          <Box zIndex={2}>
            <Typography
              size={40}
              lineHeight={47}
              weight={700}
              color={COLORS.WHITE}
            >
              {settings?.home?.title1}
              <Typography
                size={40}
                lineHeight={47}
                weight={700}
                color={COLORS.TURQUOISE}
              >
                {settings?.home?.title2}
              </Typography>
            </Typography>

            <Box marginTop={32}>
              <Typography
                size={18}
                lineHeight={24}
                weight={400}
                color={COLORS.WHITE}
              >
                {settings?.home?.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
